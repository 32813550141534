@use "../../common/styles/variables.scss" as *;
.excrement {
  background-color: #fff;
  //    background-image: radial-gradient(circle, brown 20%, transparent 10%), radial-gradient(circle, brown 20%, transparent 10%);
  background-image: url(../../resources/assets/themes/lely/16x9/actors/discovery/cow_excrement_brown.svg);
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 100%;
}

.excrement.is-rainbow {
  background-image: url(../../resources/assets/themes/lely/16x9/actors/discovery/cow_excrement_rainbow.svg);
}

.excrement-container {
  position: absolute;
  z-index: 8;
}
