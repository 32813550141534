@use "../../common/styles//variables.scss" as *;

// .iceCreamFactoryPopup {
//   position: absolute;
//   top: vhFn(13.5);
//   left: vwFn(10.5);
//   background-color: $color-LelyWhite;
//   width: vwFn(67.73);
//   height: vhFn(68.27);
//   border-left: vwFn(0.12) solid $color-GrayDairyLight;
//   border-right: vwFn(0.12) solid $color-GrayDairyLight;
//   border-top: vhFn(0.27) solid $color-GrayDairyLight;
//   box-sizing: border-box;
//   box-shadow: 0px 2px 5px rgba(222, 219, 214, 0.4);
//   border-radius: vwFn(0.5) vwFn(0.5) vwFn(0.5) vwFn(0.5);
//   padding: vhFn(4) vwFn(2.46);
// }

// .iceCreamFactoryPopup--title {
//   width: vwFn(43.1);
//   font-family: $secondaryFont;
//   font-weight: $font-bold;
//   font-style: $font-DefaultFontStyle;
//   color: $color-gray-popup;
//   font-size: vhFn(6.4);
//   line-height: vhFn(9.33);
//   margin-bottom: vhFn(1.87);
// }

// .iceCreamFactoryPopup--description {
//   width: vwFn(43.1);
//   font-family: $primaryFont;
//   font-weight: $font-regular;
//   font-style: $font-DefaultFontStyle;
//   color: $color-gray-popup;
//   min-height: vhFn(36.4);
//   font-size: vhFn(3.73);
//   line-height: vhFn(6.4);
//   margin-bottom: vhFn(4);
// }

// .iceCreamFactoryPopup--btnCallToAction {
//   background-color: #e9a7aa;
//   background-repeat: no-repeat;
//   border-radius: vwFn(0.5) vwFn(0.5) vwFn(0.5) vwFn(0.5);
//   border-left: vwFn(0.12) solid $color-GrayDairyLight;
//   border-right: vwFn(0.12) solid $color-GrayDairyLight;
//   border-top: vhFn(0.27) solid $color-GrayDairyLight;
//   border-bottom: vhFn(0.27) solid $color-GrayDairyLight;
//   background-size: 100% 100%;
//   width: vwFn(25.18);
//   height: vhFn(8.44);
//   font-size: vwFn(1.5);
//   color: $color-LelyWhite;
// }

// .iceCreamFactoryPopup--btnClosePopup {
//   position: absolute;
//   top: vhFn(-2);
//   left: vwFn(45);
//   background-image: url(../../resources/assets/themes/lely/16x9/ui/popup/close-popup-button.svg);
//   background-repeat: no-repeat;
//   background-size: 100% 100%;
//   width: vwFn(8.5);
//   height: vhFn(11.5);
// }

.iceCreamFactoryPopup--iceCream {
  background-image: url(../../resources/assets/themes/lely/16x9/popups/icecreamfactory/icecream-factory-popup.svg);
  position: absolute;
  width: vhFn(73.35);
  height: vhFn(73.35);
  background-size: 100% 100%;
  left: vwFn(56);
  top: vhFn(0);
}
