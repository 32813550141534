@use "../../common/styles/variables.scss" as *;

.discoveryStoryThirdSlide {
  position: absolute;
  background-size: 100% 100%;
  width: vwFn(100.5);
  height: vhFn(100);
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/resting/resting3.svg);
}

.discoveryStoryThirdSlide--textCaption {
  position: absolute;
  width: vwFn(55.411);
  min-height: vhFn(20.46);
  top: vhFn(30.6266);
  left: vwFn(39.861);
  padding: vhFn(3.2) vwFn(1.48);
  z-index: 2;
}

.discoveryStoryThirdSlide--title {
  font-family: $secondaryFont;
  font-style: $font-DefaultFontStyle;
  font-weight: $font-bold;
  font-size: vhFn(4.27);
  line-height: vhFn(4.8);
  letter-spacing: vwFn(0.1);
  color: $color-gray-popup;
}

.discoveryStoryThirdSlide--text {
  font-family: $primaryFont;
  font-style: $font-DefaultFontStyle;
  font-size: vhFn(3.73);
  line-height: vhFn(6.4);
  color: $color-gray-popup;
  margin: vhFn(1.87) 0;
}

.discoveryStoryThirdSlide--imgContainer {
  width: 100%;
  height: vhFn(57.77);
  border-width: vhFn(0.53) vwFn(0.246);
  border-style: solid;
  border-color: $color-GrayDairyDarker;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/discoverystory/thirdSlide.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-color: $color-GrayDairyDarker;
}

.discoveryStoryThirdSlide--imgCaption {
  position: absolute;
  width: vwFn(29.85);
  height: vhFn(78.93);
  background-color: $color-LelyWhite;
  box-shadow: 0px vwFn(0.25) vwFn(0.7) rgba(18, 18, 18, 0.2);
  padding: vhFn(3.2) vwFn(1.48);
  transform: rotate(-5.18deg);
  left: vwFn(9.77);
  top: vhFn(8);
}

.discoveryStoryThirdSlide .discoveryStory--bottomColorOverlay {
  width: 80%;
}
.theme-adult .discoveryStoryThirdSlide {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowDailyForAdult/backgrounds/resting/background-pcd-restingAdult5.svg);
}
.discoveryStoryThirdSlide--treeForAdultVersion {
  display: none;
}
.theme-adult .discoveryStoryThirdSlide--treeForAdultVersion {
  position: absolute;
  background-size: 100% 100%;
  width: vwFn(60);
  left: vwFn(40);
  height: vhFn(100);
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowDailyForAdult/backgrounds/eating/full_tree.png);
  display: block;
}
.theme-adult .discoveryStoryThirdSlide--title {
  //font-family: "Chelsea Market" !important;
  font-weight: 400;
  line-height: vhFn(6.4);
  font-size: vhFn(4.8);
}
.theme-adult .discoveryStoryThirdSlide--imgCaption {
  border-color: #000000;
  border: solid vhFn(1);
}
.theme-adult .discoveryStoryThirdSlide--imgContainer {
  border: none;
}
