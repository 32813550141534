@use "../../common/styles/variables.scss" as *;

.cow-daily--resting--slide {
  width: vwFn(100);
  height: vhFn(100);
}

.cow-daily--outro--slide--grass {
  position: absolute;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowhistory/background-layers/grass/grass6.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: vwFn(2.92);
  height: vhFn(5.62);
  left: vwFn(40.2);
  top: vhFn(76);
}
