@use "../../../../common/styles/variables.scss" as *;

.MeadowScreen {
  background-image: url(../../../../resources/assets/themes/mijnmelk/16x9/onboarding/meadowScreen/background-image-organic.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  width: vwFn(100);
  height: vhFn(100);
}

.textCaption--MeadowScreen {
  position: absolute;
  width: vwFn(30.78);
  height: vhFn(19.2);
  left: vwFn(47.98);
  top: vhFn(40.4);
  padding: vwFn(1.4) vwFn(1.4) vwFn(1.8);
  z-index: 1;
}

.MeadowScreen--text {
  position: static;
  width: vwFn(28.78);
  height: vhFn(19.2);
  font-size: vwFn(1.72);
  line-height: vhFn(6.4);
  margin: 0px;
}

.arrowMeadowScreen {
  position: absolute;
  background-repeat: no-repeat;
  background-color: gray;
  background-size: 100% 100%;
  border: vhFn(0.2) solid $color-GrayDairyDarker;
  width: 100%;
  height: vhFn(0.25);
  top: vhFn(74.7);
  left: vwFn(0);
}

.MeadowScreen--farmer {
  position: absolute;
  right: vwFn(1.97);
  top: vhFn(34.56);
  width: vwFn(18.47);
  height: vhFn(45.54);
  background-image: url(../../../../resources/assets/themes/mijnmelk/16x9/onboarding/farmerPresentation/farmer.svg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  z-index: 1;
}

.MeadowScreen--cloud {
  position: absolute;
  left: vwFn(30.09);
  top: vhFn(12.26);
  width: vwFn(14.46);
  height: vhFn(20.72);
  background-image: url(../../../../resources/assets/themes/lely/16x9/ui/elements/cloud.svg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.MeadowScreen--smallCloud {
  position: absolute;
  left: vwFn(43.52);
  top: vhFn(30.26);
  width: vwFn(6.89);
  height: vhFn(10.13);
  background-image: url(../../../../resources/assets/themes/lely/16x9/ui/elements/cloud.svg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.MeadowScreen--arrow {
  position: absolute;
  transform: rotate(-150deg);
  left: vwFn(30.92);
  bottom: vhFn(11.712);
  width: vwFn(2.92);
  height: vhFn(11.62);
  background-image: url(../../../../resources/assets/themes/lely/16x9/ui/arrows/arrow-right-curve-down.svg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.MeadowScreen--smallCow {
  position: absolute;
  left: vwFn(6.4);
  bottom: vhFn(25.385);
  width: vwFn(8);
  height: vhFn(9);
  background-image: url(../../../../resources/assets/themes/mijnmelk/16x9/onboarding/organicFarming/little-cow.svg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.MeadowScreen--farmText {
  position: absolute;
  left: vwFn(14.781);
  bottom: vhFn(11.45);
  line-height: vhFn(6.4);
  font-family: $secondaryFont;
  letter-spacing: vwFn(0.2);
  font-weight: 700;
  font-size: vwFn(2.21);
}

.MeadowScreen--bigCow {
  position: absolute;
  left: vwFn(31.07);
  bottom: vhFn(7.696);
  width: vwFn(16.62);
  height: vhFn(39.46);
  background-image: url(../../../../resources/assets/themes/lely/16x9/actors/cow/body/cow-friesian.svg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  z-index: 1;
}

.MeadowScreen--microCow {
  position: absolute;
  left: vwFn(34.711);
  top: vhFn(34.6);
  width: vwFn(4.67);
  height: vhFn(11.064);
  background-image: url(../../../../resources/assets/themes/lely/16x9/actors/cow/body/cow-friesian.svg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.MeadowScreen--midCow {
  position: absolute;
  left: vwFn(28.28);
  top: vhFn(38.352);
  width: vwFn(5.78);
  height: vhFn(13.86);
  background-image: url(../../../../resources/assets/themes/lely/16x9/actors/cow/body/cow-friesian.svg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  transform: scaleX(-1);
}
