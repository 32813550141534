@use "../../common/styles/variables.scss" as *;
//
//.sideMenu--mainMenu--menuOption {
//  width: 100%;
//  font-size: calc(var(--vh, 1vh) * 3.7);
//  color: $color-gray-popup;
//  margin-top: calc(var(--vh, 1vh) * 5);
//  margin-bottom: calc(var(--vh, 1vh) * 6);
//}
.sideMenu--mainMenu--ens {
  font-size: vhFn(2.4);
  width: vhFn(8.533);
  height: vhFn(8.533);
  color: $color-GrayDairyDarker;
  margin-left: vwFn(2.5);
  background-image: 100% 100%;
  background-image: url(../../resources/assets/themes/lely/16x9/ui/sidemenu/englishLanguage.svg);
}
.sideMenu--mainMenu--en {
  width: vhFn(8.533);
  height: vhFn(8.533);
  font-size: vhFn(2.4);
  margin-left: vwFn(2.5);
  color: $color-GrayDairyDarker;
  background-image: url(../../resources/assets/themes/lely/16x9/ui/sidemenu/englishLanguage.svg);
  background-image: 100% 100%;
}
.sideMenu--mainMenu--nls {
  font-size: vhFn(2.4);
  width: vhFn(8.533);
  height: vhFn(8.533);
  color: $color-GrayDairyDarker;
  background-image: 100% 100%;
  background-image: url(../../resources/assets/themes/lely/16x9/ui/sidemenu/dutchLanguage.svg);
}
.sideMenu--mainMenu--nl {
  width: vhFn(8.533);
  height: vhFn(8.533);
  font-size: vhFn(2.4);
  color: $color-GrayDairyDarker;
  background-image: url(../../resources/assets/themes/lely/16x9/ui/sidemenu/dutchLanguage.svg);
  background-image: 100% 100%;
}
