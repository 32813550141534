@use "../../common/styles/variables.scss" as *;

.flame {
  position: absolute;
  top: vhFn(22.9573);
  left: vwFn(17.96422);
  width: vwFn(19.033);
  overflow: visible;
}

.flame-outer {
  transform-origin: bottom center;
}

/*    animation: none !important; */

.flame-outer {
  animation: flameOuterMove;
  animation-duration: 2s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
}

.flame-middle {
  animation: flameMiddleMove;
  animation-duration: 2s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  transform-origin: center center;
}

.flame-inner {
  animation: flameInnerMove;
  animation-duration: 2s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  transform-origin: center top;
}

@keyframes flameOuterMove {
  0% {
    transform: rotateY(0deg) scaleX(1.1);
    filter: drop-shadow(0px 0px 5px yellow);
  }

  50% {
    transform: rotateY(-15deg) scaleX(1.1) translateY(1px) translateX(1px);
    filter: drop-shadow(5px 5px 25px yellow);
  }

  100% {
    transform: rotateY(15deg) scaleX(1.1);
    filter: drop-shadow(5px 5px 25px yellow);
  }
}

@keyframes flameMiddleMove {
  0% {
    transform: rotateY(0deg);
  }

  50% {
    transform: rotateY(-10deg) translateY(1px);
  }

  100% {
    transform: rotateY(10deg) translateY(-1px);
  }
}

@keyframes flameInnerMove {
  0% {
    transform: rotateY(0deg);
  }

  50% {
    transform: rotateY(-10deg) translateY(1px);
  }

  100% {
    transform: rotateY(10deg) translateY(-1px);
  }
}
