.account-activated-container {
  background-color: white;
  border-radius: 4px;
  width: 40vw;
  padding-left: 1vw;
  padding-top: 1vw;
  padding-bottom: 10px;

  font-family: "Roboto", "Ubuntu", sans-serif;
  font-size: 26px;
  font-weight: 300;
  text-align: left;

  h4 {
    font-family: "Roboto", "Ubuntu", sans-serif;
    font-size: 26px;
    font-weight: 300;
    text-align: left;
    margin-top: 0;
    margin-bottom: 10px;
  }

  h5 {
    color: #121212;
    font-family: "Roboto", "Ubuntu", sans-serif;
    font-weight: 300;
    font-size: 14px;
    text-align: left;
    margin-top: 0;
  }

  .btn-login {
    position: relative;
    text-transform: none;
    width: auto;
  }
}
