@use "../../common/styles/variables.scss" as *;

.textCaption {
  border: 1px solid #efedea;
  box-sizing: border-box;
  box-shadow: 0px vwFn(0.25) vwFn(0.7) rgba(18, 18, 18, 0.2);
  border-radius: vwFn(0.5);
  background: #ffffff;
}

.textCaption--heading {
  font-family: $secondaryFont;
  text-align: center;
}

.textCaption--label {
  width: 100%;
  text-align: center;
}
