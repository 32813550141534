.adminPanel--section {
  margin-top: 10px;
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #CFCBC3;
  padding-left: 10px;
}

.adminPanel--section--header {
  display: block;
  font-weight: normal;
  font-family: Ubuntu, sans-serif;
  font-size: 20px;
  color: #121212;
}

.adminPanel--section--subheading {
  font-weight: normal;
  font-family: Ubuntu, sans-serif;
  font-size: 14px;
  color: #121212;
}