@use "../../styles/variables.scss" as *;

.storyNavigationHint--mobile {
  background-image: url(../../../resources/assets//themes//lely/16x9/ui/icons/mobile_drag_and_swipe_white.svg);
}

.storyNavigationHint--desktop {
  background-image: url(../../../resources/assets//themes//lely/16x9/ui/icons/desktop_scroll.svg);
}

.storyNavigationHint {
  background-repeat: no-repeat;
  width: vwFn(6.896);
  height: vhFn(16);
  left: vwFn(91.871);
}

.storyNavigationHint {
  filter: drop-shadow(1px 1px 1px black);
}

.theme-adult .storyNavigationHint--mobile {
  background-image: url(../../../resources/assets//themes//lely/16x9/ui/icons/mobile_drag_and_swipe.svg);
}

.theme-adult .storyNavigationHint {
  //  filter: none;
}

.theme-adult .storyNavigationHint--gestureLabel {
  color: black;
}

.storyNavigationHint--container {
  position: absolute;
  top: vhFn(40);
  left: calc(var(--vw, 1vw) * 91.871);
}

.storyNavigationHint--gestureLabel {
  position: relative;
  text-transform: uppercase;
  font-family: "Amatic SC", sans-serif;
  margin-top: 0;
  left: vwFn(0.4);
  font-size: vwFn(2);
  color: white;
  filter: drop-shadow(1px 1px 1px black);
}

.storyNavigationHint--mobile + .storyNavigationHint--gestureLabel {
  left: vwFn(1.4);
  top: vhFn(-1);
}
