@use "../../../common/styles/variables.scss" as *;

.CowGaveBirthFirstSlide {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  width: vwFn(100);
  height: vhFn(100);
}

.textCaption--CowGaveBirthFirstSlide {
  position: absolute;
  min-height: vhFn(20.1);
  width: vwFn(30.8);
  left: vwFn(62.295);
  top: vhFn(29.3);
  padding: vhFn(1) vwFn(1);
}

.cow-CowGaveBirthFirstSlide--calendar {
  position: absolute;
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistory/background-layers/firstYear/calendar.svg);
  background-repeat: no-repeat;
  width: vwFn(15.4);
  height: vhFn(31.821);
  left: vwFn(29.908);
  top: vhFn(19.36);
  z-index: 5;
}

.cow-CowGaveBirthFirstSlide--calendarMonth {
  position: fixed;
  margin-top: vhFn(6);
  text-align: center;
  color: #ffffff;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  font-size: vhFn(3.73);
  line-height: vhFn(6.4);
  width: vwFn(15.394);
}

.cow-CowGaveBirthFirstSlide--calendarDay {
  position: fixed;
  margin-top: vhFn(12);
  text-align: center;
  color: black;
  font-family: $secondaryFont;
  font-style: normal;
  font-weight: 700;
  font-size: vhFn(12.8);
  line-height: vhFn(14.4);
  width: vwFn(15.394);
}

.textCaption--CowGaveBirthFirstSlide--year {
  position: absolute;
  padding: vwFn(1.5);
  min-height: vhFn(11.2);
  min-width: vwFn(1);
  top: vhFn(36.6);
  left: vwFn(44.257);
  z-index: 4;
  font-weight: 300;
  font-size: vhFn(4.266);
  line-height: vhFn(4.8);
}

.cow-CowGaveBirthFirstSlide--text {
  position: static;
  font-size: vhFn(3.7);
  line-height: vhFn(6.4);
  font-weight: normal;
  margin: vhFn(2.7) 0px;
  width: vwFn(27.9);
  min-height: vhFn(20);
  left: vwFn(1.47);
  top: vhFn(12.26);
}

.cow-CowGaveBirthFirstSlide-date {
  position: static;
  font-size: vhFn(6);
  font-weight: bold;
  line-height: vhFn(6.4);
  display: flex;
  left: vwFn(1.5);
  top: vhFn(3.2);
  align-items: center;
  font-family: $secondaryFont !important;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin-top: vhFn(2.7);
  margin-bottom: 0;
}

.cow-CowGaveBirthFirstSlide--stork {
  position: absolute;
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistory/background-layers/firstYear/stork.svg);
  background-repeat: no-repeat;
  width: vwFn(12.56157);
  height: vhFn(17.321);
  left: vwFn(50.908);
  top: vhFn(15.36);
  z-index: 5;
}
