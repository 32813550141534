@use "../../common/styles/variables.scss" as *;
@use "./styles/Cow_16x9.scss";
@use "./styles/Cow_19-5x9.scss";
@use "./styles/Cow_20x9.scss";

.cow {
  position: absolute;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: block;
  margin: 0 !important;
  pointer-events: fill;
  cursor: pointer;
  z-index: 9;
}

.cow--friesian {
  background-image: url(../../resources/assets/themes/lely/16x9/actors/cow/body/cow-friesian.svg);
}

.cow--holstein {
  background-image: url(../../resources/assets/themes/lely/16x9/actors/cow/body/cow-holstein.svg);
}

.cow--whiteBlack {
  background-image: url(../../resources/assets/themes/lely/16x9/actors/cow/body/cow-whiteBlack.svg);
}

.cow--pride {
  background-image: url(../../resources/assets/themes/lely/16x9/actors/cow/body/cow-pride.svg);
  background-size: 100%;
}

.cow--cow-holstein-friesian-one {
  background-image: url(../../resources/assets/themes/lely/16x9/adminpanel/cowBreed/cow-holstein-friesian-one.svg);
}

.cow--cow-holstein-friesian-two {
  background-image: url(../../resources/assets/themes/lely/16x9/adminpanel/cowBreed/cow-holstein-friesian-two.svg);
}

.cow--cow-holstein-friesian-three {
  background-image: url(../../resources/assets/themes/lely/16x9/adminpanel/cowBreed/cow-holstein-friesian-three.svg);
}

.cow--cow-blaarkop-one {
  background-image: url(../../resources/assets/themes/lely/16x9/adminpanel/cowBreed/cow-blaarkop-one.svg);
}

.cow--cow-blaarkop-two {
  background-image: url(../../resources/assets/themes/lely/16x9/adminpanel/cowBreed/cow-blaarkop-two.svg);
}

.cow--cow-jersey-one {
  background-image: url(../../resources/assets/themes/lely/16x9/adminpanel/cowBreed/cow-jersey-one.svg);
}

.cow--cow-jersey-two {
  background-image: url(../../resources/assets/themes/lely/16x9/adminpanel/cowBreed/cow-jersey-two.svg);
}

.cow--cow-lakenvender-one {
  background-image: url(../../resources/assets/themes/lely/16x9/adminpanel/cowBreed/cow-lakenvelder-one.svg);
}

.cow--cow-lakenvender-two {
  background-image: url(../../resources/assets/themes/lely/16x9/adminpanel/cowBreed/cow-lakenvelder-two.svg);
}

.cow--cow-maas-rijn-one {
  background-image: url(../../resources/assets/themes/lely/16x9/adminpanel/cowBreed/cow-maas-rijn.svg);
}

.cow--cow-pride-one {
  background-image: url(../../resources/assets/themes/lely/16x9/adminpanel/cowBreed/cow-pride-one.svg);
}

.cow--bigger {
  width: vhFn(60) !important;
  height: vhFn(65) !important;
  background-size: 100% 100%;
}

.cow--bigger .cow--title {
  display: none;
}

.cow--title {
  opacity: 0;
  display: none;
  width: 50%;
  margin: 0;
}

.cow--fixed {
  transition: 0.5s all;
  position: absolute;
  width: vhFn(67) !important;
  height: vhFn(64) !important;
  left: vwFn(53.631) !important;
  top: vhFn(30.709) !important;
  background-size: 100% 100%;
  -webkit-transform: translate3d(0, 0, 1px);
  transform: translate3d(0, 0, 1px);
  cursor: default;
}

.flow-container-Cow-slide-CowHistoryFirstYearSlide .cow--fixed {
  opacity: 0;
}

.flow-container-Cow-slide-CowHistoryFirstYearSlide .cow--fixed {
  opacity: 0;
}

.flow-container-Cow-slide-CowHistoryBirthdayPage .cow--fixed {
  visibility: hidden;
}

.flow-container-Cow-slide-CowHistoryCalmRobotSlide .cow--fixed {
  visibility: hidden;
}

.flow-container-Cow-slide-CowHistoryCalmRobotSecondSlide .cow--fixed {
  visibility: hidden;
}

.flow-container-Cow-slide-CowHistoryGaveBirthFirstSlide .cow--fixed {
  opacity: 0;
}

.flow-container-Cow-slide-CowHistoryBirthFirstPage .cow--fixed {
  opacity: 0;
}

.flow-container-Cow-slide-CowHistoryParentsPage .cow--bigger {
  width: vwFn(14.28) !important;
  height: vhFn(33.66) !important;
  left: vwFn(67.66) !important;
  top: vhFn(65.13) !important;
  background-size: 100% 100%;
  background-repeat: none;
}

.flow-container-Cow-slide-CowHistoryFirstBirthdaySlide .cow--fixed,
.flow-container-Cow-slide-CowHistoryBirthdayCakeSlide .cow--fixed {
  width: vhFn(58) !important;
  height: vhFn(63.66) !important;
  left: vwFn(37.557) !important;
  top: vhFn(32) !important;
  background-size: 100% 100%;
  position: absolute;
}

.flow-container-Cow-slide-CowHistoryFirstBirthdaySlide .cow--fixed {
  width: vhFn(58) !important;
  height: vhFn(63.66) !important;
  left: vwFn(64.407) !important;
  top: vhFn(32) !important;
  background-size: 100% 100%;
  position: absolute;
}

.flow-container-Cow-slide-CowHistoryOutroPage .cow--fixed {
  width: vhFn(58) !important;
  height: vhFn(63.66) !important;
  left: vwFn(13.487) !important;
  top: vhFn(32) !important;
  background-size: 100% 100%;
  position: absolute;
}

.flow-container-Cow-slide-CowHistoryFirstYearSlide .cow--fixed {
  opacity: 0;
  left: vwFn(60.35);
  top: vhFn(60.13);
  width: vwFn(14.55);
  height: vhFn(34.66);
}

.flow-container-Cow-slide-CowHistoryCalmRobotSecondSlide .cow--fixed {
  opacity: 0;
}

.flow-container-Cow-slide-CowHistoryRelativesPage .cow--fixed,
.flow-container-Cow-slide-CowHistoryIntroPage .cow--fixed,
.flow-container-Cow-slide-CowDailyIntroPage .cow--fixed {
  background-size: 100% 100%;
}

.cow--highlighted path:first-of-type {
  fill: #ffc700;
  stroke: #ffc700;
}
