@use "../../common/styles/variables.scss" as *;

.cowDailyIntroPage {
  position: absolute;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  width: vwFn(100);
  /* Fallback for browsers that do not support Custom Properties */
  height: vhFn(100);
}

.cow-daily-intro--text {
  width: vwFn(19.63);
  height: vhFn(9.6);
  left: vwFn(1.5);
  top: vhFn(3.2);
  font-weight: 300;
  font-size: vhFn(4.5);
  line-height: vhFn(4.8);
  margin: vwFn(0.5) 0;
}
.cow-daily-intro--cow {
  position: absolute;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/orbiterstory/cowlastslide.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: vwFn(5.5);
  height: calc(var(--vh, 1vh) * 8.8);
  left: vwFn(45.5);
  transform: scaleX(-1);
  top: calc(var(--vh, 1vh) * 71.97);
  z-index: 6;
}
.cow-daily-intro--house--style1 {
  position: absolute;
  background-repeat: no-repeat;
  width: vwFn(25.5);
  height: calc(var(--vh, 1vh) * 39.488);
  left: vwFn(50.5);
  top: calc(var(--vh, 1vh) * 41.51);
  z-index: 5;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowhistory/background-layers/elements/house.svg);
}
.cow-daily-intro--house--style2 {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowhistory/background-layers/elements/houseStyle2.svg);
  position: absolute;
  background-repeat: no-repeat;
  width: vwFn(25.5);
  height: calc(var(--vh, 1vh) * 39.488);
  left: vwFn(50.5);
  top: calc(var(--vh, 1vh) * 41.52);
  z-index: 5;
}
.cow-daily-intro--house--style3 {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowhistory/background-layers/elements/houseStyle3.svg);
  position: absolute;
  background-repeat: no-repeat;
  width: vwFn(25.5);
  height: calc(var(--vh, 1vh) * 39.488);
  left: vwFn(50.5);
  top: calc(var(--vh, 1vh) * 41.51);
  z-index: 5;
}

.cow-daily-intro--tree {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/background-trees/trees3.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  top: calc(var(--vh, 1vh) * 45.452);
  left: vwFn(1);
  width: vwFn(38.5);
  height: calc(var(--vh, 1vh) * 35.48);
}
.textCaption--cowDailyIntro {
  position: absolute;
  right: vwFn(56.85);
  top: vhFn(35.73);
  min-width: vwFn(22.59);
  min-height: vhFn(29.06);
  padding: vhFn(3) vwFn(1.85);
}

.cow-daily-intro--name {
  position: static;
  width: vwFn(18.22);
  height: vhFn(10.4);
  left: vwFn(1.47);
  top: vhFn(14.66);
  font-family: $secondaryFont;
  font-style: normal;
  font-weight: bold;
  font-size: vwFn(5.91);
  line-height: vhFn(10.4);
  margin: vhFn(1.86) 0px;
}

.cow-daily-intro--swipe {
  background-image: url(../../resources/assets/themes/lely/16x9/ui/icons/swipe.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  top: vhFn(39.73);
  left: vwFn(44.08);
  width: vwFn(6.77);
  height: vhFn(22.4);
}

.cow-daily-intro--calendar {
  background-image: url(../../resources/assets/themes/lely/16x9/ui/icons/calendar-icon.svg);
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: vhFn(-1);
  left: vwFn(-6.92);
  width: vwFn(4.77);
  height: vhFn(22.4);
}

.cow--daily-intro {
  left: vwFn(50) !important;
  top: vhFn(20) !important;
  height: vhFn(20) !important;
  width: vhFn(60) !important;
  height: vhFn(65) !important;
  position: absolute;
}
.cow-daily-intro--monthForAdultTheme {
  display: none;
} 
.cow-daily-intro--dayForAdultTheme {
  display: none;
} 
.textCaption--cowDailyIntroAdultVersion {
  display: none;
} 
.cow-daily-intro--calendarAdultVersion {
  display: none;
} 
