@use "../../../../common/styles/variables.scss" as *;

.OrganicFarming {
  background-image: url(../../../../resources/assets/themes/mijnmelk/16x9/onboarding/organicFarming/background-image-organic.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  width: vwFn(100);
  height: vhFn(100);
}

.textCaption--OrganicFarming {
  position: absolute;
  width: vwFn(30.78);
  height: vhFn(44.8);
  left: vwFn(50.98);
  top: vhFn(30);
  padding: vwFn(1.4) vwFn(1.4) vwFn(1.8);
  z-index: 1;
}

.OrganicFarming--text {
  position: static;
  width: vwFn(28.78);
  height: vhFn(44.8);
  left: vwFn(1.477);
  top: vhFn(3.2);
  font-size: vwFn(1.72);
  line-height: vhFn(6.4);
  margin: 0px;
}

.OrganicFarming--text--second {
  font-size: vwFn(1.72);
  line-height: vhFn(6.4);
  margin: 0px;
}

.OrganicFarming--farmer {
  position: absolute;
  left: vwFn(79.55);
  top: vhFn(34.56);
  width: vwFn(18.47);
  height: vhFn(45.54);
  background-image: url(../../../../resources/assets/themes/mijnmelk/16x9/onboarding/farmerPresentation/farmer.svg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  z-index: 1;
}

.OrganicFarming--cloud {
  position: absolute;
  left: vwFn(60.09);
  top: vhFn(16.26);
  width: vwFn(14.46);
  height: vhFn(20.72);
  background-image: url(../../../../resources/assets/themes/lely/16x9/ui/elements/cloud.svg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.OrganicFarming--smallCloud {
  position: absolute;
  left: vwFn(92.09);
  top: vhFn(30.26);
  width: vwFn(6.89);
  height: vhFn(10.13);
  background-image: url(../../../../resources/assets/themes/lely/16x9/ui/elements/cloud.svg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.OrganicFarming--arrow {
  position: absolute;
  transform: rotate(-30deg);
  left: vwFn(24.92);
  bottom: vhFn(33.712);
  width: vwFn(4.92);
  height: vhFn(10.62);
  background-image: url(../../../../resources/assets/themes/lely/16x9/ui/arrows/arrow-left-curve-down.svg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.OrganicFarming--smallCow {
  position: absolute;
  left: vwFn(6.4);
  bottom: vhFn(25.384);
  width: vwFn(5.788);
  height: vhFn(6.66);
  background-image: url(../../../../resources/assets/themes/mijnmelk/16x9/onboarding/organicFarming/little-cow.svg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.OrganicFarming--smallGrass {
  position: absolute;
  left: vwFn(32.84);
  top: vhFn(65.584);
  width: vwFn(6.125);
  height: vhFn(13.49);
  background-image: url(../../../../resources/assets/themes/mijnmelk/16x9/onboarding/organicFarming/small-grass.svg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  z-index: 1;
}

.OrganicFarming--bigGrass {
  position: absolute;
  left: vwFn(24.51);
  top: vhFn(60.23);
  width: vwFn(10.98);
  height: vhFn(24.19);
  background-image: url(../../../../resources/assets/themes/mijnmelk/16x9/onboarding/organicFarming/big-grass.svg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  z-index: 1;
}

.OrganicFarming--farmText {
  position: absolute;
  left: vwFn(9.01);
  bottom: vhFn(34.528);
  line-height: vhFn(6.4);
  font-family: "Amatic SC", sans-serif;
  letter-spacing: vwFn(0.2);
  font-weight: 700;
  font-size: vwFn(2.21);
}

.arrowOrganicFarming {
  position: absolute;
  background-repeat: no-repeat;
  background-color: gray;
  background-size: 100% 100%;
  border: vhFn(0.2) solid $color-GrayDairyDarker;
  width: 100%;
  height: vhFn(0.25);
  top: vhFn(74.7);
  left: vwFn(0);
}

.OrganicFarming--poison {
  position: absolute;
  left: vwFn(13.24);
  top: vhFn(13.51);
  width: vwFn(15.72);
  height: vhFn(29.81);
  background-image: url(../../../../resources/assets/themes/mijnmelk/16x9/onboarding/organicFarming/poison.svg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  z-index: 1;
}

.OrganicFarming--clover {
  position: absolute;
  left: vwFn(38.9);
  top: vhFn(27);
  width: vwFn(7.85);
  height: vhFn(13.037);
  background-image: url(../../../../resources/assets/themes/mijnmelk/16x9/onboarding/organicFarming/cloves.svg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  z-index: 1;
}
