@use "../../common/styles/variables.scss" as *;

.sustainabilityStorySecondSlide {
  position: absolute;
  background-size: 100% 100%;
  width: vwFn(100.5);
  height: vhFn(100);
}

.sustainabilityStorySecondSlide--textCaption {
  position: absolute;
  min-width: vwFn(30.6);
  min-height: vhFn(22.26);
  top: vhFn(15.46);
  left: vwFn(38.38);
  padding: vhFn(3.2) vwFn(1.48);
  z-index: 1;
  max-width: vwFn(60);
}

.sustainabilityStorySecondSlide--text {
  font-family: $primaryFont;
  font-weight: 400;
  font-size: vhFn(3.73);
  line-height: vhFn(6.4);
  color: $color-gray-popup;
  padding-bottom: vhFn(5);
}

.sustainabilityStorySecondSlideFarmer--text {
  font-family: $primaryFont;
  font-weight: 400;
  font-size: vhFn(3.73);
  line-height: vhFn(6.4);
  color: $color-gray-popup;
  margin-bottom: vhFn(5);
}

.sustainabilityStorySecondSlide-title {
  font-family: "Chelsea Market";
  font-style: $font-DefaultFontStyle;
  font-weight: 400;
  font-size: vhFn(4.27);
  line-height: vhFn(4.8);
  letter-spacing: vwFn(0.1);
  color: $color-gray-popup;
  padding-bottom: vhFn(2);
}

.theme-adult .sustainabilityStorySecondSlide-title {
  font-family: BourtonBase !important;
  padding-bottom: vhFn(2);
  font-weight: 400;
  line-height: vhFn(6.4);
  font-size: vhFn(4.8);
}

@media only screen and (orientation: portrait) {
  .sustainabilityStorySecondSlide--title {
    font-family: "Chelsea Market";
    font-style: $font-DefaultFontStyle;
    font-weight: 400;
    font-size: vhFn(4.27);
    line-height: vhFn(4.8);
    letter-spacing: vwFn(0.1);
    color: $color-gray-popup;
    padding-bottom: vhFn(2);
  }
  .sustainabilityStorySecondSlide--textCaption {
    position: absolute;
    min-width: vwFn(30.6);
    min-height: vhFn(24.26);
    top: vhFn(29.072);
    left: vwFn(38.339);
    padding: vhFn(3.2) vwFn(1.48);
  }
}

.sustainabilityStorySecondSlide-farmer-title {
  padding-bottom: vhFn(1);
  font-weight: 700;
  font-size: vhFn(3.75);
}

.theme-adult .sustainabilityStory-treeAndSmallBush {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowhistoryForAdult/background-layers/elements/treeAndBushSecondSlide.svg);
  background-repeat: no-repeat;
  background-size: 50% 100%;
  position: absolute;
  top: vhFn(45.852);
  left: vwFn(2.365);
  width: vwFn(38.834);
  height: vhFn(35.48);
}

.sustainabilityStorySecondSlide--link {
  color: #aca7a7;
  font-size: vhFn(3.5);
}
