@use "../../common/styles/variables.scss" as *;
@use "../../common/styles/icons.scss";
@use "../../common/styles/tables.scss";
@use "../../common/styles/autorotate.scss";
@use "../../themes/mijnmelkgeneric.scss";
@use "../../themes/mijnmelkpicnic.scss";
@use "../../themes/themagroen.scss";
@use "../../themes/milkbottle.scss";
@use "../../themes/adult.scss";

body {
  background-color: $color-GrayDairyLighter;
  overflow: hidden;
}

.App {
  text-align: center;
}

.help-table {
  margin: 0;
  padding: 0;
}

.next-to-sidebar {
  margin-left: 55px !important;
}

.App-intro {
  font-size: large;
}

.MuiContainer-root,
.MuiInputBase-root,
.MuiButton-root,
.MuiTypography-root,
.MuiTable-root,
.MuiTableHead-root,
.MuiTableRow-root,
.MuiTableBody-root,
.MuiTableFooter-root,
.MuiTableCell-root,
.menu-title {
  font-family: $font-DefaultFont, sans-serif !important;
}

.MuiAppBar-colorPrimary,
.MuiButton-root,
.MuiButton-containedPrimary,
.MuiPickersToolbar-toolbar,
.MuiPickersClock-pin,
.MuiPickersClockPointer-pointer,
.MuiPickersClockPointer-noPoint,
.MuiPickersDay-daySelected,
.MuiPickerDTTabs-tabs {
  background-color: $color-LelyRed !important;
}

.MuiPickersClockPointer-thumb {
  border-color: $color-LelyRed !important;
}

.MuiButton-root {
  color: $color-LelyWhite !important;
}

.MuiButton-root.Mui-disabled {
  background-color: $color-GrayDairyLight !important;
  color: $color-GrayDairyDarker !important;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 130px;
}

.MuiTableRow-root .MuiButton-root {
  opacity: $opacity-default;
}

.MuiTableRow-root .MuiButton-root:hover {
  opacity: $opacity-hover;
}

.pointer {
  cursor: pointer;
}

.btn-filter {
  margin-right: 5px !important;
}

h3.selected-farm-header {
  color: $color-RecommendedTextColor;
  font-weight: bold;
}

.is-clickable {
  cursor: pointer;
}

/*
@media (prefers-color-scheme: dark) {
  body {
    background-color: white;
  }

  div {
    background: white;
  }
}
*/
