@use "../../common/styles/variables.scss" as *;

.lunaFeature {
  position: absolute;
  background-repeat: no-repeat;
  pointer-events: fill;
  background-image: url(../../resources/assets/themes/lely/16x9/actors/luna/lunaRobot.svg);
  background-size: 100% 100%;
  z-index: 11;
}
