.create-account-container--creationPage,
.create-account-container--verificationPage {
  background-color: white;
  border-radius: 4px;
  width: 40vw;
  padding-left: 1vw;
  padding-top: 1vw;
  padding-bottom: 10px;

  .labelled-text-input--label {
    width: 90%;
    text-align: left;
  }

  .labelled-text-input--textInput {
    position: relative;
    width: 90%;
    left: -5%;
  }

  .labelled-text-input--textInput.is-valid {
    border: 1px solid #cfcbc3;
  }

  .labelled-text-input--textInput.is-invalid {
    border: 1px solid #f50d19;
  }

  h4 {
    font-family: "Roboto", "Ubuntu", sans-serif;
    font-size: 26px;
    font-weight: 300;
    text-align: left;
    margin-top: 0;
    margin-bottom: 10px;
  }

  h5 {
    color: #766e68;
    font-family: "Roboto", "Ubuntu", sans-serif;
    font-weight: 300;
    text-align: left;
    margin-top: 0;
  }

  .create-account-container--error-msg {
    color: #f50d19;
    font-size: 14px;
    text-align: left;
  }

  .btn-createAccount {
    position: relative;
    text-transform: none;
    width: 90%;
    margin-left: -10%;
  }

  .btn-resendVerificationEmail {
    position: relative;
    display: block;
    text-transform: none;
    width: auto;
    text-align: left;
  }

  .create-account-container--emailVerificationParagraph {
    font-family: "Roboto", "Ubuntu", sans-serif;
    font-weight: 300;
    text-align: left;
    width: 90%;
  }

  a {
    margin-left: 10px;
    color: #766e68;
    text-decoration: underline;
    text-align: left;
    margin-top: 10px;
    cursor: pointer;
    display: inline-block;
  }

  .create-account-container--alreadyCreatedAccountLabel {
    color: #646060;
  }
} 
.create--account--checkbox {
  display: flex;
}
.create--account--checkbox--text {
margin-top: 10px;
} 
.termsAndConditionsButton {
  border: none;
  background-color: transparent;
  cursor: pointer;
}