@use "../../../common/styles/admin_variables.scss" as adminVar;
@use "../../../common/components/SaveChangesButton/SaveChangesButton.scss";

.AboutTheFarmPage {
  margin-left: adminVar.$sideMenuWidth;
  margin-top: adminVar.$pagesMarginTop;
  height: 90vh;
  overflow: auto;
  padding: 0 19.17vw;
}
.farm-features-block .image-radio-selection {
  padding-bottom: 2vh !important;
}
.farm-features-block .image-radio-selection--label {
  color: black;
}
.about-the-farm--header {
  font-weight: 400;
  font-size: 26px;
}

.about-the-farm--intro {
  font-size: 14px;
  padding-bottom: 2.11vh;
}

.about-the-farm--textField {
  width: 100%;
  margin-bottom: 2vh !important;
}

.about-the-farm--textArea {
  width: 100%;
}

.about-you--language {
  margin-bottom: 20px !important;
}

.about-the-farm--save-button {
  margin-top: 2vh !important;
}

/* Farm production radio buttons */
.image-radio-selection--form-control-farmstyle1 {
  background-image: url(../../../resources/assets/themes/lely/16x9/adminpanel/farmStyles/farmStyleOne.svg);
  height: 140px;
  width: 113px;
}
.image-radio-selection--form-control-farmstyle2 {
  background-image: url(../../../resources/assets/themes/lely/16x9/adminpanel/farmStyles/farmStyleTwo.svg);
  height: 140px;
  width: 113px;
}
.image-radio-selection--form-control-farmstyle3 {
  background-image: url(../../../resources/assets/themes/lely/16x9/adminpanel/farmStyles/farmStyleThree.svg);
  height: 140px;
  width: 113px;
}
.image-radio-selection--form-control-farmProduction1 {
  background-image: url(../../../resources/assets/themes/lely/16x9/adminpanel/producingDevice/radio-orbiter.svg);
  height: 140px;
  width: 113px;
}

//radio-orbiter.svg

.image-radio-selection--form-control-farmProduction2 {
  background-image: url(../../../resources/assets/themes/lely/16x9/adminpanel/producingDevice/radio-dairy2.svg);
  height: 140px;
  width: 113px;
}

.image-radio-selection--form-control-farmProduction3 {
  background-image: url(../../../resources/assets/themes/lely/16x9/adminpanel/producingDevice/radio-cheese.svg);
  height: 140px;
  width: 113px;
}

.image-radio-selection--form-control-farmProduction4 {
  background-image: url(../../../resources/assets/themes/lely/16x9/adminpanel/producingDevice/radio-icecream.svg);
  height: 140px;
  width: 113px;
}

.save-button-style {
  margin-top: 2vh !important;
  margin-bottom: 5vh;
  min-height: 4vh;
  position: center;
  background-color: #73bd07 !important;
  padding: 6px 8px;
}

.farm-features-block {
  background-color: #ffffff;
  margin: 1%;
  width: 95%;
  padding: 1.25vw;
  border-radius: 0.5vw 0.5vw 0.5vw 0.5vw;
  border: 1px solid #cfcbc3;
}
.solarPanel-block {
  background-color: #ffffff;
  margin: 1%;
  width: 95%;
  padding-left: 1.25vw;
  padding-right: 1.25vw;
  padding-top: 1.25vw;
  padding-bottom: 0;
  border-radius: 0.5vw 0.5vw 0.5vw 0.5vw;
  border: 1px solid #cfcbc3;
}
.solarPanel-block .image-radio-selection--radio-item {
  padding-bottom: 0;
}
.solarPanel-block .labelled-text-input--label {
  padding-top: 0vh !important;
}
.MuiSvgIcon-root .PrivateRadioButtonIcon-layer-11 {
  fill: #c30a14 !important;
}
.about-the-farm--solarPanel {
  width: 100%;
  margin-bottom: 2vh !important;
  padding-top: 0vh !important;
}
.image-radio-selection--form-control-inline {
  display: flex !important;
  align-items: center !important;
  height: fit-content;
  margin-bottom: 10px;
}

.about-the-farm--payment-methods {
  margin-top: 20px;

  .checkbox-group-selection--label {
    color: adminVar.$color-LelyBlack;
  }

  .checkbox-group-selection--form-control {
    display: flex;
    align-items: center !important;
    width: 120px;
    height: fit-content;
    margin-left: 0 !important;
    margin-bottom: 10px;

    .MuiIconButton-root {
      padding-left: 0;
    }
  }
}

.about-the-farm--shop-image svg {
  width: 71.85px;
  height: 90px;
  margin-top: 10px;
  margin-left: 10px;
}

.about-the-farm--working-time {
  .checkbox-group-selection--label {
    color: adminVar.$color-LelyBlack;
    margin-bottom: 10px;
  }

  .checkbox-group-selection--container {
    display: block;

    .checkbox-group-selection--checkbox-item {
      .checkbox-group-selection--form-control {
        display: flex;
        align-items: center !important;
        width: 120px;
        height: fit-content;
        margin-left: 0 !important;
        margin-bottom: 0px;

        .MuiIconButton-root {
          padding-left: 0;
        }

        .about-the-farm--working-time--option {
          display: flex;
          align-items: center;
          width: 370px;

          & > div:first-child {
            flex-basis: 30%;
          }

          & > div:nth-child(2) {
            flex-basis: 70%;
          }
        }

        .about-the-farm--working-time--inputs-container {
          display: flex;
          justify-content: space-between;
        }
      }

      &:last-child .checkbox-group-selection--form-control {
        margin-bottom: 10px;
      }
    }
  }
}

.about-the-farm--error {
  margin-top: 10px;
  color: adminVar.$color-LelyRed;
}

.about-the-farm--empty-line {
  height: 20px;
  margin-top: 10px;
}

.about-the-farm--google-maps-popup {
  position: absolute;
  top: 20%;
  width: calc(560px + 2 * 16px);
  height: 444px;
  border-radius: 4px;
  background: adminVar.$color-LelyWhite;
  z-index: 1;
  box-shadow: 0px 2px 5px rgba(222, 219, 214, 0.4);

  .about-the-farm--google-maps-popup--header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 20px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background: adminVar.$color-LelyRed;
    color: adminVar.$color-LelyWhite;
    font-weight: 300;
    font-size: 26px;
    line-height: 29px;

    .about-the-farm--google-maps-popup--close {
      margin-top: -10px;
      cursor: pointer;
    }
  }

  .about-the-farm--google-maps-popup--content {
    padding: 16px;
  }

  .about-the-farm--google-maps-popup--video {
    padding: 0 16px 16px;
  }
}
.AboutTheFarmPage .btn-save-changes {
  margin-left: calc(100% - 200px) !important;
  width: 170px;
}

.AboutTheFarmPage .btn-already-saved {
  margin-left: calc(100% - 270px) !important;
  width: 270px;
  margin-top: 20px !important;
}

.MuiSnackbarContent-root {
  position: fixed;
  left: 45%;
  bottom: 20%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}