@use "../../styles/variables.scss" as *;

.labelled-text-area--label {
  display: block;
  font-size: 16px;
  padding-bottom: 2vh;
  padding-top: 2vh;
}

.labelled-text-area--textArea {
  min-height: 6em;
  font-family: Ubuntu;
  font-size: 14px;
  border: 1px solid $color-gray-light;
  box-shadow: inset 0px 2px 5px rgba(222, 219, 214, 0.4);
  border-radius: 4px;
  color: $color-gray-popup;
}

.labelled-text-area--charactersLeft {
  display: none;
}
