@use "../../../common/styles/variables.scss" as *;

.hudDisplay--box {
  position: absolute;
  animation: hudFadeIn ease-in 4s forwards;
  background-color: white;
  background-size: 100% 100%;
  font-family: $secondaryFont;
  width: vwFn(25);
  height: vhFn(30);
  right: vwFn(1.966);
  bottom: vhFn(0);
  -webkit-transform: translate3d(0, 0, 1px);
  transform: translate3d(0, 0, 1px);
  z-index: 18;
  border-radius: 4px;
  border: 1px solid #f0f0e6;
  padding: vhFn(0.8) vwFn(0.8);
  pointer-events: none;
  box-shadow: 1px 1px 5px black;
}

.hudDisplay--box .milkBatchWeatherIcon {
  width: vwFn(4.666);
  height: vhFn(8.3036);
  background-size: 100% 100%;
}

.hudDisplay--box--topArea {
  display: flex;
  height: 40%;
}

.hudDisplay--box--weatherIconContainer {
  //align-items: flex-start;
  background-image: url(../../../resources/assets/themes/adult/16x9/ui/hud/hexagon.svg);
  width: vwFn(6.666);
  height: vhFn(11.8623);
  position: absolute;
  padding: vhFn(1.75) vwFn(1);
}

.hudDisplay--box--weatherParametersContainer {
  position: relative;
  left: vwFn(8.1333);
  width: 60%;
  height: vhFn(2);
}

.hudDisplay--box--separator {
  display: block;
  width: 90%;
  height: 10%;
  position: absolute;
  left: 5%;
  top: vhFn(14.183);
  background-repeat: no-repeat;
  background-image: url(../../../resources/assets/themes/adult/16x9/ui/hud/bottomCowLine.svg);
}

.hudDisplay--box--farmDate {
  font-size: vhFn(3);
  margin: 0 0;
  //margin-bottom: vhFn(1);
}
.theme-adult .hudDisplay--box--farmDate {
  font-family: LumiosMarker !important;
}
.hudDisplay--box--icon {
  display: inline-block;
  background-repeat: no-repeat;
  width: vwFn(1.4);
  height: vhFn(2.4);
  background-size: 100% 100%;
}

.hudDisplay--box--numberOfCowsMilked--icon {
  background-image: url(../../../resources/assets/themes/adult/16x9/ui/hud/cow_head_icon.svg);
}

.hudDisplay--box--farmLocation--icon {
  background-image: url(../../../resources/assets/themes/adult/16x9/ui/hud/location.svg);
}

.hudDisplay--box--farmTemperature--icon {
  background-image: url(../../../resources/assets/themes/adult/16x9/ui/hud/temperature_icon.svg);
  //background-position-y: vhFn(0.25);
  background-size: 100% 100%;
}

.hudDisplay--box--farmHumidity--icon {
  background-image: url(../../../resources/assets/themes/adult/16x9/ui/hud/humidity_icon.svg);
}

.hudDisplay--box--numberOfCowsMilked,
.hudDisplay--box--farmHumidity,
.hudDisplay--box--farmLocation {
  display: flex;
  margin-bottom: vhFn(1);
  width: 90%;
  height: vhFn(2.37244);
  font-family: $primaryFont;
  gap: vwFn(0.5);
  font-size: vhFn(2.2666);
}
.hudDisplay--box--farmTemperature,
.hudDisplay--box--farmHumidity {
  display: inline;
  margin-right: vwFn(1);
  font-family: $primaryFont;
  margin-bottom: vhFn(0);
  font-size: vhFn(2.2666);
}
.hudDisplay--box--secondRowTempHumidity {
  display: flex;
}
.hudDisplay--box--farmTemperature {
  display: inline-flex;
  gap: vwFn(0.5);
}
.hudDisplay--box--farmHumidity {
  display: inline-flex;
}
.hudDisplay--box--numberOfCowsMilked {
  margin-top: vhFn(1);
  width: max-content;
}
.hudDisplay--box--logsContainer {
  position: relative;
  top: 18%;
  left: vwFn(0.25);
  height: 40%;
  font-family: $primaryFont;
  line-height: vhFn(2.25);
  width: 75%;
}

@keyframes hudFadeIn {
  0% {
    opacity: 0;
    bottom: vhFn(-30);
  }
  100% {
    opacity: 1;
    bottom: 0;
    -webkit-transform: translate3d(0, 0, 1px);
    transform: translate3d(0, 0, 1px);
  }
}

.liveDataPopup--liveIcon {
  animation: wiggle 2.5s infinite;
  background-image: url(../../../resources/assets/themes/lely/16x9/popups/livedata/liveIcon.svg);
  width: vwFn(4.187);
  height: vhFn(6.6666);
  //display: block;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  top: vhFn(-4.5);
  left: vwFn(-0.5);
  position: absolute;
  background-color: transparent;
  box-shadow: vhFn(1) rgba(18, 18, 18, 0.2);
}
