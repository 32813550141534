@use "../../common/styles/variables.scss" as *;

.textCaption--cowDailyGrazing {
  position: absolute;
  left: vwFn(19.6);
  top: vhFn(34);
  width: vwFn(30.78);
  min-height: vhFn(32);
}

.cowDailyGrazingText {
  font-size: vwFn(1.724);
  line-height: vhFn(6.4);
  margin: vhFn(2) vhFn(3);
}
