.confetti--container--are-confetti-stopped * {
    //animation-play-state: paused !important;
}

.confetti--container,
.confetti--container * {
    pointer-events: none !important;
    overflow: visible;
}

.confetti--container>div:nth-of-type(2) {
    left: 25px;
}

.confetti--container>div:nth-of-type(3) {
    left: 5px;
}


.confetti--container--are-confetti-stopped *::after {
    //animation-play-state: paused !important;
}

.btn-activate-confetti {
    pointer-events: all !important;
}