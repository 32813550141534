@use "../../../common/styles/variables.scss" as *;

.firstContainer {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  width: vwFn(100);
  height: vhFn(100);
}

// .contentContainer {
//   position: absolute;
//   bottom: vhFn(21) !important;
//   right: 0;
//   width: vwFn(100);
//   height: vhFn(65);
//   background-repeat: no-repeat;
//   background-size: 100% 100%;
//   z-index: 999;
// }

// .arrowOutroPage {
//   position: absolute;
//   background-repeat: no-repeat;
//   background-color: gray;
//   background-size: 100% 100%;
//   border: vhFn(0.3) solid $color-GrayDairyDarker;
//   width: 100%;
//   height: vhFn(0.25);
//   bottom: vhFn(3.7);
//   left: vwFn(0);
// }

// .rightArrowButton {
//   position: absolute;
//   background-image: url(../../../resources/assets/themes/lely/16x9/stories/vectorstory/vectorlastslidesign.svg);
//   left: vwFn(57.49);
//   top: vhFn(45.21);
//   min-width: vwFn(22.63);
//   max-width: vwFn(24);
//   min-height: vhFn(12);
//   background-repeat: no-repeat;
//   background-size: 100% 100%;
//   z-index: 1;
//   padding: vhFn(2) vwFn(4);
// }

// .bottomArrowText {
//   width: 100%;
//   font-size: vhFn(3.733);
//   line-height: vhFn(6.4);
//   font-family: "Ubuntu";
//   font-style: normal;
//   font-weight: 400;
//   color: #ffffff;
//   text-align: center;
// }

// .cowOutroPage--pole {
//   position: absolute;
//   background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistory/background-layers/firstYear/pole.svg);
//   background-repeat: no-repeat;
//   width: vwFn(1.2315);
//   height: vhFn(49.98);
//   left: vwFn(68.093);
//   top: vhFn(31.01);
//   z-index: 5;
// }

// .cowOutroPage--house {
//   position: absolute;
//   background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistory/background-layers/elements/house.svg);
//   background-repeat: no-repeat;
//   width: vwFn(25.23);
//   height: vhFn(39.488);
//   left: vwFn(80.57);
//   top: vhFn(41.51);
//   z-index: 5;
// }

// .cowOutroPage--garden {
//   position: absolute;
//   background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistory/background-layers/elements/fenceOutroSlide.svg);
//   background-repeat: no-repeat;
//   width: vwFn(40.448);
//   height: vhFn(8.8);
//   left: vwFn(70.57);
//   top: vhFn(72.31);
//   z-index: 5;
// }

// .cowOutroPage--tree {
//   background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/background-trees/trees3.svg);
//   background-repeat: no-repeat;
//   background-size: 100% 100%;
//   position: absolute;
//   top: vhFn(46.52);
//   left: vwFn(2.365);
//   width: vwFn(38.834);
//   height: vhFn(35.48);
// }

.cowOutroPage--bigTree {
  position: absolute;
  background-size: 100% 100%;
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistory/background-layers/firstYear/rightPartOfTheTree.svg);
  width: vwFn(30);
  height: vhFn(124.61);
  left: vwFn(0);
  top: vhFn(-18.37);
  z-index: 6;
}
