@use "../../common/styles/variables.scss" as *;

.cow-daily--milking--slide {
  width: vwFn(100);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  /* Fallback for browsers that do not support Custom Properties */
  height: vhFn(100);
}

.textCaption--cowDailyMilkingBottles {
  position: absolute;
  left: vwFn(6.73);
  top: vhFn(30.6);
  width: vwFn(30);
  min-height: vhFn(28.26);
  padding: vwFn(0.2);
}

.cow-daily--milking--text {
  position: static;
  width: vwFn(19.33);
  height: vhFn(12.8);
  font-family: $secondaryFont;
  font-weight: bold;
  font-size: vhFn(4);
  line-height: vhFn(6.4);
  color: #766e68;
}

.bottleDaily {
  display: inline-block;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowdaily/graphics/milk_bottle.svg);
  background-size: contain;
  height: vhFn(10);
  width: vwFn(3);
}

.cow-daily--bottles-container {
  position: absolute;
  bottom: vhFn(20);
}

.cow-daily--astronaut--arrow {
  position: absolute;
  background-image: url(../../resources/assets/themes/lely/16x9/ui/arrows/arrow-left-curve-down-black.svg);
  background-repeat: no-repeat;
  background-size: 100%;
  width: vwFn(4.92);
  height: vhFn(5.62);
  top: vhFn(18);
  transform: scale(1);
}
