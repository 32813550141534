@use "../../common/styles/variables.scss" as *;

.lastSlideMijnMelk {
  position: absolute;
  background-size: 101% 100%;
  width: vwFn(101);
  left: vwFn(-0.1);
  height: vhFn(100);
}

.lastSlideMijnMelk--pole {
  position: absolute;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowdaily/graphics/pole.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: vwFn(1.2315);
  height: vhFn(37.7386381);
  left: vwFn(55.0751);
  top: vhFn(43.5133);
}

.lastSlideMijnMelk--sign {
  position: absolute;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/vectorstory/vectorlastslidesign.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  min-width: vwFn(22.471);
  max-width: vwFn(28);
  min-height: vhFn(11.733);
  left: vwFn(44.45);
  top: vhFn(60.7283);
  padding: vhFn(2) vwFn(4.2);
}

.lastSlideMijnMelk--redirectionLely {
  position: absolute;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/lelystory/lelyRedirectionButton.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-size: 100% 100%;
  width: vwFn(25.12);
  height: vhFn(17.333);
  left: vwFn(43);
  top: vhFn(62.018);
  z-index: 10;
  // padding: vhFn(1) 0;
  display: flex;
  //padding: vhFn(5) vwFn(6);
  text-align: center;
  justify-content: center;
  cursor: pointer;
}

.lastSlideMijnMelk--secondText {
  // width: 100%;
  // height: 100%;
  font-size: vhFn(3.733);
  padding: 0 vhFn(5);
  line-height: vhFn(6);
  font-family: $primaryFont;
  font-weight: 400;
  color: #766e68;
  text-align: center;
  margin: auto auto;
  justify-content: center;
  align-items: center;
}

.lastSlideMijnMelk--aboutTheFarm {
  position: absolute;
  font-size: vhFn(4.8);
  line-height: vhFn(6.4);
  font-family: $secondaryFont;
  font-weight: 700;
  text-align: center;
  letter-spacing: vwFn(0.1);
  left: vwFn(18.826);
  top: vhFn(53.2853);
  max-width: vwFn(20);
}

.lastSlideMijnMelk--aboutLely {
  position: absolute;
  font-size: vhFn(4.8);
  line-height: vhFn(6.4);
  font-family: $secondaryFont;
  font-weight: 700;
  text-align: center;
  letter-spacing: vwFn(0.1);
  left: vwFn(18.826);
  top: vhFn(35.2853);
  max-width: vwFn(20);
}

.lastSlideMijnMelk--arrow {
  position: relative;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/vectorstory/robotstoryarrow.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: vwFn(4.926);
  height: vhFn(5.626);
  left: vwFn(20.45);
  top: vhFn(-11.283);
}

.lastSlideMijnMelk--arrowLely {
  position: relative;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/vectorstory/robotstoryarrow.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: vwFn(4.9266);
  height: vhFn(5.626);
  left: vwFn(19.45);
  top: vhFn(-5.283);
}

.theme-adult .lastSlideMijnMelk--redirectionLely {
  display: none
}