@use "../../../../common/styles/variables.scss" as *;

.RobotsOnboarding {
  background-image: url(../../../../resources/assets/themes/mijnmelk/16x9/onboarding/robotsOnboarding/barn-background.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  width: vwFn(100);
  height: vhFn(100);
}

.textCaption--RobotsOnboarding {
  position: absolute;
  width: vwFn(30.78);
  height: vhFn(38.4);
  left: vwFn(47.98);
  top: vhFn(30.8);
  padding: vwFn(1.4) vwFn(1.4) vwFn(1.8);
  z-index: 1;
}

.RobotsOnboarding--text {
  position: static;
  width: vwFn(28.78);
  height: vhFn(38.4);
  font-size: vwFn(1.72);
  line-height: vhFn(6.4);
  margin: 0px;
}

.RobotsOnboarding--farmer {
  position: absolute;
  right: vwFn(1.97);
  top: vhFn(34.56);
  width: vwFn(18.47);
  height: vhFn(45.54);
  background-image: url(../../../../resources/assets/themes/mijnmelk/16x9/onboarding/farmerPresentation/farmer.svg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  z-index: 1;
}

.RobotsOnboarding--astronaut {
  position: absolute;
  left: vwFn(8.899);
  bottom: vhFn(10.41);
  width: vwFn(16.07);
  height: vhFn(43.84);
  background-image: url(../../../../resources/assets/themes/lely/16x9/actors/astronautfactory/astronaut-landing-page.svg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  z-index: 1;
}

.RobotsOnboarding--cloud {
  position: absolute;
  left: vwFn(6.979);
  top: vhFn(12.26);
  width: vwFn(14.46);
  height: vhFn(20.72);
  background-image: url(../../../../resources/assets/themes/lely/16x9/ui/elements/cloud.svg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  z-index: 1;
}

.RobotsOnboarding--smallCloud {
  position: absolute;
  left: vwFn(4.469);
  top: vhFn(29.53);
  width: vwFn(6.89);
  height: vhFn(10.13);
  background-image: url(../../../../resources/assets/themes/lely/16x9/ui/elements/cloud.svg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
