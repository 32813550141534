@use "../../common/styles/variables.scss" as *;

.reference-value-chart--board-base {
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.reference-value-chart--currentArrow {
  background-repeat: no-repeat;
  background-size: cover;
  transition: 2s transform ease-in;
  transition-delay: 3s;
}

.reference-value-chart--referenceArrow {
  background-repeat: no-repeat;
  background-size: cover;
  transition: 2s transform ease-in;
}

.reference-value-chart--diagram path {
  stroke-linecap: round;
}

.reference-value-chart {
  position: absolute;
}

.reference-value-chart--board-base {
  position: absolute;
}

.reference-value-chart--diagram-container {
  position: absolute;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-top: vhFn(10);
}

.reference-value-chart--diagram-container > svg {
  position: absolute;
  bottom: vhFn(1);
}

.reference-value-chart--legend-container {
  position: absolute;
  bottom: 0;
}

.reference-value-chart--currentArrow {
  position: absolute;
  background-size: cover;
  transform-origin: center bottom;
  bottom: vhFn(1);
}

.reference-value-chart--referenceArrow {
  position: absolute;
  background-size: cover;
  transform-origin: center bottom;
  bottom: vhFn(1);
}

.reference-value-chart--diagramLabelText {
  position: absolute;
  top: vhFn(1);
  width: 100%;
  font-weight: bold;
  text-align: center;
}

.reference-value-chart--labelContainer1 {
  background-repeat: no-repeat;
  background-size: contain;
}

.reference-value-chart--labelContainer2 {
  background-repeat: no-repeat;
  background-size: contain;
}

.reference-value-chart--currentLabelText,
.reference-value-chart--referenceLabelText {
  display: inline-block;
  vertical-align: middle;
}

.reference-value-chart--currentValueText {
  position: absolute;
}

//#region renewable energy chart-specific styles
.renewableEnergyChart .reference-value-chart--referenceLabelText,
.renewableEnergyChart .reference-value-chart--labelContainer2,
.renewableEnergyChart .reference-value-chart--referenceArrow,
.renewableEnergyChart .reference-value-chart--referenceValueText {
  display: none;
}
//#endregion

//#region setting the position of the components throughout the sustainability story
.kilogramCO2PerKiloMilkChart,
.roughageProducedLocallyChart,
.renewableEnergyChart,
.gramsOfFertilizerPerLiterMilkChart,
.grazingDaysForMilkChart,
.gramsOfConcentratePerLiterMilkChart {
  position: absolute;
  left: vwFn(17.5);
  font-size: vhFn(2.5) !important;
}
//#endregion

//#region children's theme styling
.reference-value-chart--currentValueText,
.reference-value-chart--currentLabelText,
.reference-value-chart--referenceLabelText {
  color: white;
}

.reference-value-chart--currentValueText,
.reference-value-chart--referenceValueText,
.reference-value-chart--minValueText,
.reference-value-chart--maxValueText,
.reference-value-chart--currentLabelText,
.reference-value-chart--referenceLabelText {
  font-size: vhFn(2) !important;
}

.reference-value-chart--referenceValueText {
  color: black;
}

.reference-value-chart--diagram-container {
  background-image: url(../../resources/assets/themes/lely/16x9/ui/reference-value-chart/board_children_version.svg);
}

.reference-value-chart--labelContainer1 {
  background-image: url(../../resources/assets/themes/lely/16x9/ui/reference-value-chart/legend_board_1_children_version.svg);
}

.reference-value-chart--labelContainer2 {
  background-image: url(../../resources/assets/themes/lely/16x9/ui/reference-value-chart/legend_board_2_children_version.svg);
}

.reference-value-chart--minValueText,
.reference-value-chart--maxValueText {
  color: #b8ac81;
}

.reference-value-chart--referenceArrow {
  background-image: url(../../resources/assets/themes/lely/16x9/ui/reference-value-chart/reference_arrow_children_version.svg);
}

.reference-value-chart--currentArrow {
  background-image: url(../../resources/assets/themes/lely/16x9/ui/reference-value-chart/current_arrow_children_version.svg);
}

.reference-value-chart--diagramLabelText {
  color: white;
  padding-left: 10%;
  padding-right: 10%;
}

.reference-value-chart--board-base {
  background-image: url(../../resources/assets/themes/lely/16x9/ui/reference-value-chart/board_base_children_version.svg);
}

.kilogramCO2PerKiloMilkChart,
.roughageProducedLocallyChart,
.renewableEnergyChart,
.gramsOfFertilizerPerLiterMilkChart,
.gramsOfConcentratePerLiterMilkChart,
.grazingDaysForMilkChart {
  bottom: vhFn(16);
}
//#endregion

//#region adult theme styling
.theme-adult .reference-value-chart--currentValueText {
  color: #ff684d;
}

.theme-adult .reference-value-chart--referenceValueText,
.theme-adult .reference-value-chart--referenceLabelText,
.theme-adult .reference-value-chart--currentLabelText {
  color: black;
}

.theme-adult .reference-value-chart--diagram-container {
  background-image: url(../../resources/assets/themes/lely/16x9/ui/reference-value-chart/board.svg);
}

.theme-adult .reference-value-chart--labelContainer1 {
  background-image: url(../../resources/assets/themes/lely/16x9/ui/reference-value-chart/legend_board_1.svg);
}

.theme-adult .reference-value-chart--labelContainer2 {
  background-image: url(../../resources/assets/themes/lely/16x9/ui/reference-value-chart/legend_board_2.svg);
}

.theme-adult .reference-value-chart--minValueText,
.theme-adult .reference-value-chart--maxValueText {
  color: black;
}

.theme-adult .reference-value-chart--referenceArrow {
  background-image: url(../../resources/assets/themes/lely/16x9/ui/reference-value-chart/reference_arrow.svg);
}

.theme-adult .reference-value-chart--currentArrow {
  background-image: url(../../resources/assets/themes/lely/16x9/ui/reference-value-chart/current_arrow.svg);
}

.theme-adult .reference-value-chart--diagramLabelText {
  color: black;
  width: 100%;
  text-align: center;
  height: 20%;
  padding-left: 10%;
  padding-right: 10%;
}

.theme-adult .reference-value-chart--board-base {
  background-image: url(../../resources/assets/themes/lely/16x9/ui/reference-value-chart/board_base.svg);
}

.theme-adult .kilogramCO2PerKiloMilkChart,
.theme-adult .roughageProducedLocallyChart,
.theme-adult .renewableEnergyChart,
.theme-adult .gramsOfFertilizerPerLiterMilkChart,
.theme-adult .grazingDaysForMilkChart,
.theme-adult .gramsOfConcentratePerLiterMilkChart {
}
//#endregion
