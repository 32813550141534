@use "../../common/styles/variables.scss" as *;

.farmerStoryStartingPoint {
  position: absolute;
  top: 0;
  background-size: 100% 100%;
  width: vwFn(100);
  height: vhFn(100);
  //background-color: $color-GrayDairyLighter;
}

.farmerStoryStartingPoint--background {
  position: absolute;
  top: vhFn(-6.67);
  left: vwFn(0.62);
  width: vwFn(96.07);
  height: vhFn(89.87);
  //background-image: url(../../resources/assets/themes/lely/16x9/stories/farmer/starting-point-background.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  z-index: 1;
}

.farmerStoryStartingPoint--farmer--male,
.farmerStoryStartingPoint--farmer--female {
  position: absolute;
  width: vwFn(5.4187);
  height: vhFn(22.4);
  left: vwFn(71.1822);
  top: vhFn(61.133);
  z-index: 6 !important;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.farmerStoryStartingPoint--farmer--male {
  background-image: url(../../resources/assets/themes/lely/16x9/actors/farmer/farmer-male.svg);
}

.farmerStoryStartingPoint--farmer--female {
  background-image: url(../../resources/assets/themes/lely/16x9/actors/farmer/farmer-female.svg);
}
.theme-adult .farmerStoryStartingPoint--farmer--male {
  background-image: url(../../resources/assets/themes/adult/16x9/actors/farmer/farmer-male.svg);
}

.theme-adult .farmerStoryStartingPoint--farmer--female {
  background-image: url(../../resources/assets/themes/adult/16x9/actors/farmer/farmer-female.svg);
}
.farmerStoryStartingPoint--dutchSilo {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/farmer/dutchSilo.svg);
  position: absolute;
  width: vwFn(7.266);
  height: vhFn(42.133);
  left: vwFn(79.2438);
  top: vhFn(38.856);
  z-index: 2 !important;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.theme-adult .farmerStoryStartingPoint--dutchSilo {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/farmer/dutchSiloAdult.svg);
}
.farmerStoryStartingPoint--textCaption {
  position: absolute;
  top: vhFn(34.93);
  right: vwFn(64.35);
  padding: vhFn(3.2) vwFn(1.48);
  z-index: 3;
}

.farmerStoryStartingPoint--title {
  font-family: $secondaryFont;
  font-style: $font-DefaultFontStyle;
  font-weight: $font-light;
  font-size: vhFn(4.27);
  line-height: vhFn(4.8);
  color: $color-gray-popup;
}

.farmerStoryStartingPoint--name {
  font-family: $secondaryFont;
  font-style: $font-DefaultFontStyle;
  font-weight: $font-bold;
  font-size: vhFn(12.8);
  line-height: vhFn(10.4);
  color: $color-gray-popup;
  margin: vhFn(1.87) 0;
}

.farmerStoryStartingPoint--farmName {
  font-family: $secondaryFont;
  font-style: $font-DefaultFontStyle;
  font-weight: $font-light;
  font-size: vhFn(4.27);
  line-height: vhFn(4.8);
  color: $color-gray-popup;
}
.farmer-intro-leftTree {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/background-trees/trees3.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  top: vhFn(45.852);
  left: vwFn(2.365);
  width: vwFn(38.834);
  height: vhFn(35.48);
}
.theme-adult .farmer-intro-leftTree {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowhistoryForAdult/background-layers/trees/background_tree_01.svg);
  width: vwFn(23.0295);
  height: vhFn(37.3333);
}
.farmer-intro-smallCow {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/farmer/grazingCowAndBush.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  top: vhFn(70.666);
  left: vwFn(33.1366);
  width: vwFn(16.8719);
  height: vhFn(10.4);
}
.theme-adult .farmer-intro-smallCow {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowhistoryForAdult/background-layers/elements/smallCowAndBush.svg);
}
.farmerStoryStartingPoint .cow-intro--text {
  min-height: vhFn(0);
}
.farmerStoryStartingPoint .cow-intro--name {
  position: initial;
  line-height: vhFn(16.8);
  height: vhFn(12);
  font-size: vhFn(14.8);
}
