@use "../../../common/styles/variables.scss" as *;

.cowBirthdayPage {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  width: vwFn(100);
  height: vhFn(100);
}

.textCaption--cowBirthdayPage {
  position: absolute;
  padding: vwFn(1.5);
  min-height: vhFn(15.6);
  width: vwFn(28);
  top: vhFn(46.6);
  left: vwFn(65.257);
  z-index: 1;
}

.cow-cowBirthdayPage--tree {
  position: absolute;
  background-size: 100% 100%;
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistory/background-layers/firstYear/trees-1.svg);
  width: vwFn(25);
  height: vhFn(124.61);
  left: vwFn(89);
  top: vhFn(-18.37);
  z-index: 6;
}


.cow-cowBirthdayPage--date {
  position: static;
  width: vwFn(27.83);
  height: vhFn(6.4);
  left: vwFn(1.477);
  top: vhFn(3.2);
  font-family: $secondaryFont;
  font-style: normal;
  font-size: vhFn(5);
  line-height: vhFn(6.4);
  margin: vwFn(1) 0;
}

.cow-cowBirthdayPage--text {
  position: static;
  width: vwFn(27.83);
  height: vhFn(19.2);
  left: vwFn(1.477);
  top: vhFn(12.26);
  font-family: Ubuntu;
  font-style: normal;
  font-weight: normal;
  font-size: vhFn(3.75);
  line-height: vhFn(6.4);
}
.cow-cowBirthdayPage--farmName {
  font-family: Ubuntu;
  font-style: normal;
  font-weight: normal;
  font-size: vhFn(3.75);
  line-height: vhFn(6.4);
}
.cow-cowBirthdayPage--temperature {
  position: absolute;
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistory/background-layers/firstYear/thermometer.svg);
  background-repeat: no-repeat;
  width: vwFn(5);
  height: vhFn(15.2);
  left: vwFn(81.28571);
  top: vhFn(22.077);
  z-index: 5;
}

.cow-cowBirthdayPage--temperature--degrees {
  position: absolute;
  padding: vwFn(1.5);
  min-height: vhFn(10.2);
  min-width: vwFn(1);
  top: vhFn(23.6);
  left: vwFn(84.257);
  z-index: 4;
  font-weight: 300;
  font-size: vhFn(4.266);
  line-height: vhFn(4.8);
}

.cow-cowBirthday--calendar {
  position: absolute;
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistory/background-layers/firstYear/calendar.svg);
  background-repeat: no-repeat;
  width: vwFn(15.4);
  height: vhFn(31.821);
  left: vwFn(30.038);
  top: vhFn(19.36);
  z-index: 5;
}

.cow-cowBirthday--calendarMonth {
  position: fixed;
  margin-top: vhFn(6);
  text-align: center;
  color: #ffffff;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  font-size: vhFn(3.73);
  line-height: vhFn(6.4);
  width: vwFn(15.394);
}

.cow-cowBirthday--calendarDay {
  position: fixed;
  margin-top: vhFn(12);
  text-align: center;
  color: black;
  font-family: $secondaryFont;
  font-style: normal;
  font-weight: 700;
  font-size: vhFn(12.8);
  line-height: vhFn(14.4);
  width: vwFn(15.394);
}

.textCaption--cowBirthdayPage--year {
  position: absolute;
  padding: vwFn(1.5);
  min-height: vhFn(11.2);
  min-width: vwFn(1);
  top: vhFn(36.6);
  left: vwFn(44.257);
  z-index: 4;
  font-weight: 300;
  font-size: vhFn(4.266);
  line-height: vhFn(4.8);
}
