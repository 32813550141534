@use "../../../../common/styles/variables.scss" as *;

.FarmerPresentationKool {
  background-image: url(../../../../resources/assets/themes/lely/16x9/screens/cowIntro/cow-intro-second-slide.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  width: vwFn(100);
  height: vhFn(100);
}

.textCaption--FarmerPresentationKool {
  position: absolute;
  width: vwFn(30.78);
  height: vhFn(34.86);
  left: vwFn(50.98);
  top: vhFn(39);
  padding: vwFn(1.4) vwFn(1.4) vwFn(1.8);
  z-index: 1;
}

.FarmerPresentationKool--text {
  position: static;
  width: vwFn(25.63);
  height: vhFn(12.8);
  left: vwFn(1.477);
  top: vhFn(3.2);
  font-size: vwFn(1.72);
  line-height: vhFn(6.4);
  margin: 0px;
}

.FarmerPresentationKool--text--second {
  font-size: vwFn(1.72);
  line-height: vhFn(6.4);
  margin: 0px;
}

.FarmerPresentationKool--farmer {
  position: absolute;
  left: vwFn(79.55);
  top: vhFn(34.56);
  width: vwFn(18.47);
  height: vhFn(45.54);
  background-image: url(../../../../resources/assets/themes/kaasfarm/16x9/onboarding/welcomeScreenKaas/farmer-image.svg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  z-index: 1;
}

.FarmerPresentationKool--farmImage {
  position: absolute;
  background-repeat: no-repeat;
  object-fit: cover;
  width: vwFn(49.61);
  height: vhFn(48.89);
  left: vwFn(7.7);
  top: vhFn(27.53);
  border: vwFn(1) solid #ffffff;
}

.FarmerPresentationKool--cloud {
  position: absolute;
  left: vwFn(80.09);
  top: vhFn(16.26);
  width: vwFn(14.46);
  height: vhFn(20.72);
  background-image: url(../../../../resources/assets/themes/lely/16x9/ui/elements/cloud.svg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.FarmerPresentationKool--arrow {
  position: absolute;
  left: vwFn(17.92);
  bottom: vhFn(12.712);
  width: vwFn(4.92);
  height: vhFn(5.62);
  background-image: url(../../../../resources/assets/themes/mijnmelk/16x9/onboarding/farmerPresentation/arrow-up-curve.svg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.FarmerPresentationKool--farmText {
  position: absolute;
  left: vwFn(23.89);
  bottom: vhFn(12.95);
  font-size: vwFn(2.21);
  line-height: vhFn(6.4);
  font-family: $secondaryFont;
  letter-spacing: vwFn(0.2);
}
