@use "../../common/styles/variables.scss" as *;

.discoveryFeaturesSW {
  position: absolute;
  background-repeat: no-repeat;
  pointer-events: fill;
  width: vhFn(10);
  height: vhFn(8);
  background-image: url(../../resources/assets/themes/lely/16x9/actors/discovery/discoveryRobot.svg);
  //background-image: url(../../resources/assets/themes/lely/16x9/actors/discovery/discoveryRobotSprite.svg#moveLeftEdge);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  top: vhFn(70.7);
  left: vhFn(105.7);
  z-index: 11;
}
.discoveryFeaturesSW--collector {
  position: absolute;
  background-repeat: no-repeat;
  pointer-events: fill;
  width: vhFn(10);
  height: vhFn(8);
  background-image: url(../../resources/assets/themes/lely/16x9/actors/discovery/discoveryRobot.svg);
  //background-image: url(../../resources/assets/themes/lely/16x9/actors/discovery/discoveryRobotSprite.svg#moveLeftEdge);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  top: vhFn(70.7);
  left: vhFn(105.7);
  z-index: 11;
}

.discoveryFeaturesSW--title {
  display: none;
}

.discoveryFeaturesSW--bigger {
  width: vwFn(30);
  height: vhFn(65);
  background-size: cover;
}

.discoveryFeaturesSW--fixed {
  position: absolute;
  width: vwFn(16);
  height: vhFn(72);
  right: vwFn(55);
  top: vhFn(20);
}

.discoveryFeaturesSW.moveAlongLeftEdge {
  background-image: url(../../resources/assets/themes/lely/16x9/actors/discoveryssw90/discoveryRobot_moveLeftEdge.svg);
  //background-image: url(../../resources/assets/themes/lely/16x9/actors/discovery/discoveryRobotSprite.svg#moveLeftEdge);
}

.discoveryFeaturesSW.moveAlongBottomEdge {
  background-image: url(../../resources/assets/themes/lely/16x9/actors/discoveryssw90/discoveryRobot_moveBottomEdge.svg);
  //background-image: url(../../resources/assets/themes/lely/16x9/actors/discovery/discoveryRobotSprite.svg#moveBottomEdge);
}

.discoveryFeaturesSW.moveAlongRightEdge {
  background-image: url(../../resources/assets/themes/lely/16x9/actors/discoveryssw90/discoveryRobot_moveRightEdge.svg);
  //background-image: url(../../resources/assets/themes/lely/16x9/actors/discovery/discoveryRobotSprite.svg#moveRightEdge);
}

.discoveryFeaturesSW.moveAlongTopEdge {
  background-image: url(../../resources/assets/themes/lely/16x9/actors/discoveryssw90/discoveryRobot_moveTopEdge.svg);
  //background-image: url(../../resources/assets/themes/lely/16x9/actors/discovery/discoveryRobotSprite.svg#moveTopEdge);
}

.is-virtual-farm-tour-active .discoveryFeaturesSW {
  transition: 0s all;
}
