@use "../../common/styles/variables.scss" as *;

.AgeSelectionPage {
  background-image: url(../../resources/assets/themes/lely/16x9/screens/ageSelection/background-screen-select-age.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  width: vwFn(100);
  /*  Fallback for browsers that do not support Custom Properties */
  height: vhFn(100);
}

.AgeSelectionPage--lely {
  background-image: url(../../resources/assets/themes/lely/16x9/screens/ageSelection/background-screen-select-age-horizon.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  width: vwFn(100);
  /*  Fallback for browsers that do not support Custom Properties */
  height: vhFn(100);
}

.AgeSelectionPage--welcome {
  position: absolute;
  width: vwFn(17.85);
  height: vhFn(21.86);
  top: vhFn(39.86);
  left: vwFn(41.022);
  padding: vwFn(1.47);
}

.AgeSelectionPage--welcome--heading {
  font-size: vwFn(2.95);
  line-height: vhFn(6.4);
  font-family: $secondaryFont;
  width: vwFn(14.9);
  height: vhFn(6.4);
  text-align: center;
  left: vwFn(1.477);
  top: vhFn(3.2);
  margin: vhFn(1.1) 0px;
  letter-spacing: vwFn(0.2);
  font-weight: 700;
}

.AgeSelectionPage--welcome--question {
  font-size: vwFn(1.72);
  line-height: vhFn(6.4);
  text-align: center;
  width: vwFn(14.9);
  padding-top: vhFn(1.5);
  margin: 0;
}

.AgeSelectionPage--welcome--kid-answer {
  text-align: center;
  letter-spacing: vwFn(0.2);
  font-size: vhFn(5.5);
  line-height: vhFn(5.17);
  font-family: $secondaryFont;
  font-style: $font-DefaultFontStyle;
  font-weight: $font-bold;
  color: $color-LelyWhite;
  padding-top: vhFn(3);
  padding-bottom: vhFn(3.33);
}
@media only screen and (orientation: portrait) {
  .AgeSelectionPage--welcome--kid-answer {
    font-size: vwFn(2.5);
  }
  .AgeSelectionPage--welcome--man-answer {
    font-size: vwFn(2.5) !important;
  }
}
.AgeSelectionPage--welcome--kid {
  position: absolute;
  background-image: url(../../resources/assets/themes/lely/16x9/screens/ageSelection/age-selection-under-10.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  left: vwFn(12.27);
  top: vhFn(13.33);
  width: vhFn(58.51);
  height: vhFn(43.77);
  pointer-events: fill;
  cursor: pointer;
}

.AgeSelectionPage--welcome--man {
  position: absolute;
  background-image: url(../../resources/assets/themes/lely/16x9/screens/ageSelection/age-selection-above-10.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  left: vwFn(63.19);
  top: vhFn(42.82);
  width: vhFn(58.51);
  height: vhFn(43.84);
  pointer-events: fill;
  cursor: pointer;
}

.AgeSelectionPage--welcome--man--lely {
  position: absolute;
  background-image: url(../../resources/assets/themes/lely/16x9/screens/ageSelection/age-selection-above-10-horizon.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  left: vwFn(63.19);
  top: vhFn(42.82);
  width: vhFn(58.51);
  height: vhFn(43.84);
  pointer-events: fill;
  cursor: pointer;
}

.AgeSelectionPage--welcome--man-answer {
  text-align: center;
  letter-spacing: vwFn(0.2);
  font-size: vhFn(5.5);
  line-height: vhFn(5.17);
  font-family: $secondaryFont;
  font-style: $font-DefaultFontStyle;
  font-weight: $font-bold;
  color: $color-LelyWhite;

  padding-bottom: vhFn(3.33);
  padding-left: vwFn(0.83);
  padding-right: vwFn(1.11);
  padding-top: vhFn(23);
}

.AgeSelectionPage--welcome--man-answer--lely {
  text-align: center;
  letter-spacing: vwFn(0.2);
  font-size: vhFn(5.5);
  line-height: vhFn(5.17);
  font-family: $secondaryFont;
  font-style: $font-DefaultFontStyle;
  font-weight: $font-bold;
  color: $color-LelyWhite;
  padding-bottom: vhFn(3.33);
  padding-left: vwFn(0.83);
  padding-right: vwFn(1.11);
  padding-top: vhFn(23);
}
// @media only screen and (min-device-height: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
//   .AgeSelectionPage--welcome--man-answer {
//     padding-top: vhFn(24);
//   }
// }

// @media only screen and (min-device-height: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
//   .AgeSelectionPage--welcome--man-answer {
//     padding-top: vhFn(19);
//   }
// }

// @media screen and (device-width: 915px) and (device-height: 412px) and (-webkit-device-pixel-ratio: 3.5) and (orientation: landscape) {
//   .AgeSelectionPage--welcome--man-answer {
//     padding-top: vhFn(24.1);
//   }
// }

.is-age-selected-true .AgeSelectionPage,
.AgeSelectionPage.slide-0,
.is-age-selected-true .bl,
.bl.slide-0 {
  opacity: 1 !important;
}
