@use "../../common/styles/variables.scss" as *;

@keyframes cookie_statement_popup_slide_in_show {
  0% {
    bottom: vhFn(-70.512);
  }

  100% {
    bottom: 0;
  }
}

@keyframes slide_in_hide {
  0% {
    bottom: 0;
    opacity: 1;
  }

  100% {
    bottom: vhFn(-70.512);
    opacity: 0;
  }
}

.cookieStatementPopup.is-hiding {
  animation-name: slide_in_hide !important;
  animation-duration: 0.5s !important;
  animation-fill-mode: forwards;
}

.cookieStatementPopup {
  animation-name: cookie_statement_popup_slide_in_show !important;
  animation-duration: 0.5s !important;
  position: absolute;
  left: vwFn(12.5);
  top: vhFn(5);
  background-color: $color-LelyWhite;
  width: vwFn(75);
  height: vhFn(90);
  border-left: vwFn(0.12) solid $color-GrayDairyLight;
  border-right: vwFn(0.12) solid $color-GrayDairyLight;
  border-top: vhFn(0.27) solid $color-GrayDairyLight;
  box-sizing: border-box;
  box-shadow: 0px 2px 5px rgba(222, 219, 214, 0.4);
  border-radius: vwFn(0.5) vwFn(0.5) 0px 0px;
  z-index: 1000000;
}

.cookieStatementPopup--title {
  margin: auto;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-top: vhFn(3);
  text-align: left;
  padding-left: vwFn(2);
  font-family: "BourtonBase";
  color: $color-gray-popup;
  font-size: vhFn(6);
  line-height: vhFn(9.6);
  font-weight: $font-regular;
}

.cookieStatementPopup--body {
  position: relative;
  left: vwFn(-4.8);
  height: vhFn(57);
  width: 99%;
  padding-left: vwFn(6.97438);
  padding-right: vwFn(6.97438);
  //padding-right: vwFn(2.463);
  padding-bottom: vhFn(7.47);
  margin-top: vhFn(3);
  font-size: vhFn(3.7);
  overflow: auto;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  scrollbar-color: #766e68 #cbc6c5;
  /* Firefox */
}

.cookieStatementPopup--body::-webkit-scrollbar {
  width: vwFn(0.8);
}

.cookieStatementPopup--body::-webkit-scrollbar-track {
  //box-shadow: inset 0 0 6px #cac6c5;
  background-color: #cac6c5;
  border-radius: vwFn(0.4);
}

.cookieStatementPopup--body::-webkit-scrollbar-thumb {
  background-color: #756e69;
  outline: 1px solid #756e69;
  border-radius: vwFn(0.4);
}

// .cookieStatementPopup--footer {
//   position: absolute;
//   bottom: vhFn(5);
//   text-align: center;
//   width: 100%;
//   height: vhFn(5);
// } 

// .cookieStatementPopup--footer--btnAcceptCookies {
//   // background-repeat: no-repeat;
//   // background-position: center;
//   // background-image: url(../../resources/assets/themes/adult/16x9/ui/cookies/close_button.svg);
//   // min-width: vwFn(15);
//   // height: vhFn(11);
//   // margin: auto;
//   // cursor: pointer;
//   // color: $color-LelyWhite;
//   // top: vhFn(75);
//   // vertical-align: middle;
//   // line-height: vwFn(2.5);
//   // font-size: vwFn(2);
//   //left: vwFn(20.5);
//   font-family: "BourtonBase";
//   // padding-right: vwFn(3.5);
//   // padding-top: vhFn(1);
//   // padding-left: vwFn(3.5);
//   // position: absolute; 
//   background-repeat: no-repeat;
//   border-radius: vwFn(0.5) vwFn(0.5) vwFn(0.5) vwFn(0.5);
//   border-left: vwFn(0.12) solid $color-GrayDairyLight;
//   border-right: vwFn(0.12) solid $color-GrayDairyLight;
//   border-top: vhFn(0.27) solid $color-GrayDairyLight;
//   border-bottom: vhFn(0.27) solid $color-GrayDairyLight;
//   background-size: 100% 100%;
//   min-width: vwFn(19.889);
//   min-height: vhFn(10.66);
//   font-size: vwFn(1.5);
//   color: $color-LelyWhite;
//   top: vhFn(3.86);
//   line-height: vhFn(4.266);  
    
// }

.cookieStatementPopup--footer--btnAcceptCookies {
  color: $color-LelyWhite;
  //vertical-align: middle;
  line-height: vhFn(4.5);
  font-size: vhFn(3.9);
  //left: vwFn(2);
  top: vhFn(3.4);
  text-transform: none;
  font-family: "BourtonBase";
  //position: absolute;
  white-space: nowrap;
  //padding-left: vwFn(8);
  font-weight: 400;  
  border-radius: 4px;
}
.cookieStatementPopup--footer--btnAcceptCookies { 
  background-color: transparent;
  display: inline;
}
//#region CHILDREN VERSION STYLES
.cookieStatementPopup.is-children
  .cookieStatementPopup--footer--btnAcceptCookies {
    background-color: #c30a14;
    color: #fff;
    background-image: none;
    height: vhFn(9);
    min-width: vwFn(1);
    padding-left: vwFn(2);
    padding-right: vwFn(2);
    font-family: "Roboto"; 
    margin: auto;
    display: block; 
    margin-top: vhFn(2);
    border-radius: 4px;
  }

.cookieStatementPopup.is-children
  .cookieStatementPopup--footer--btnAcceptCookies--label {
  color: #fff;
  width: 100%;
  text-align: center;
  vertical-align: middle;
  line-height: vwFn(2.5);
  border-radius: 0.5px;
  font-family: "Roboto";
}
//#endregion
.cookieStatementPopup.is-children
  .cookieStatementPopup--title {
    font-family: "Chelsea Market";
  }