@use "../../../common/styles/variables.scss" as *;

.background-pcd-grass5 {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/eating-improved/grass_last.svg);
  background-size: auto;
}

.background-grassDailyStoryAdult1 {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistoryForAdult/background-layers/grass/grass1.svg);
  width: 100vw;
  height: vhFn(100);
  background-size: 100% 100%;
  //top: vhFn(2.05)
}
.background-mountainAdult1 {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistoryForAdult/background-layers/mountains/mountain1.svg);
}
.background-mountainAdult2 {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistoryForAdult/background-layers/mountains/mountain2.svg);
}
.background-mountainAdult3 {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistoryForAdult/background-layers/mountains/mountain3.svg);
}
.background-mountainAdult4 {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistoryForAdult/background-layers/mountains/mountain4.svg);
}
.background-mountainAdult5 {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistoryForAdult/background-layers/mountains/mountain5.svg);
}
/* Background panels */
// intro slide cow daily adult version with transition tree in the right part
.background-pcd-introSlideAdult {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistoryForAdult/backgrounds/background--intro--transition-tree.svg);
}
// if the grazing period is not matching the current date
.background-pcd-eatingAdult1 {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowDailyForAdult/backgrounds/eating/background-pcd-eatingAdult1.svg);
}
// if the grazing period is not matching the current date - second slide
.background-pcd-eatingAdult2 {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowDailyForAdult/backgrounds/eating/background-pcd-eatingAdult2.svg);
}
// milking slide adult version with transition tree on the left part of the screen
.background-pcd-milkingAdult1 {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowDailyForAdult/backgrounds/milking/background-pcd-milkingAdult1.svg);
}
// milking slide adult version with transition tree on the right part of the screen
.background-pcd-milkingAdult2 {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowDailyForAdult/backgrounds/milking/background-pcd-milkingAdult2.svg);
}
// resting first slide with transition tree on the left part
.background-pcd-restingAdult1 {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowDailyForAdult/backgrounds/resting/background-pcd-restingAdult1.svg);
}
// resting first slide with transition tree on the right part
.background-pcd-restingAdult2 {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowDailyForAdult/backgrounds/resting/background-pcd-restingAdult2.svg);
}
//outro slide cow daily adult version
.background-pcd-outroSlide {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowDailyForAdult/backgrounds/outro/background-pcd-outro-slide.svg);
}
.theme-adult .cow--daily-intro,
.theme-adult .cow--daily-eating {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistoryForAdult/elements/cow.svg);
}
.theme-adult .cow--daily-intro {
  left: vwFn(50.5) !important;
  top: vhFn(27) !important;
}
.theme-adult .cow-daily-intro--tree {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowDailyForAdult/elements/treesIntroPage.svg);
  width: vwFn(23.02);
  height: vhFn(37.333); 
  display: none;
}
.theme-adult .cow-daily-intro--calendarAdultVersion {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistoryForAdult/elements/calendar.svg);
  width: vwFn(15.39);
  height: vhFn(31.821);
  top: vhFn(34.36);
  left: vwFn(26.971);
  position: absolute;
  background-size: 100% 100%;
  display: block;
  z-index: 2;
}
.theme-adult .cow-daily-intro--dayForAdultTheme {
  display: block;
  font-family: "Chelsea Market" !important;
  font-style: normal;
  font-weight: 400;
  font-size: vhFn(12.8);
  text-transform: none;
  text-align: center;
  padding-top: vhFn(2);
  z-index: 2;
}
.theme-adult .cow-daily-intro--monthForAdultTheme {
  display: block;
  font-family: "Roboto" !important;
  font-style: normal;
  font-weight: 400;
  color: white;
  font-size: vhFn(3.7333);
  line-height: vhFn(5.6);
  text-transform: none;
  z-index: 2;
  text-align: center;
  margin-top: vhFn(5);
}
.theme-adult .textCaption--cowDailyIntro {
  display: none;
}
.theme-adult .cow-daily-intro--swipe {
  display: none;
}
.theme-adult .cow-daily-intro--cow {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistoryForAdult/elements/smallCowIntroPage.svg);
  transform: scaleX(1);
  left: vwFn(38.5);
  width: vwFn(6.87); 
  display: none;

}
.theme-adult .textCaption--cowDailyIntroAdultVersion {
  display: inline;
  position: absolute;
  right: vwFn(60.85);
  top: vhFn(38.73);
  min-width: vwFn(11.69);
  height: vhFn(12);
  padding: vhFn(2) vwFn(1);
  z-index: 1;
}
.theme-adult .cow-daily--robot--grazeway {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowDailyForAdult/elements/grazewayAdult.svg);
}
.theme-adult .cowDailyGrazeway--button {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowDailyForAdult/elements/buttonGrazeway.svg);
  transform: scaleX(1);
  left: vwFn(31);
}
.theme-adult .rainfall.thunderstorm {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowDailyForAdult/weather/heavyRain.svg);
  animation-duration: 2s;
}
.theme-adult .rainfall.heavyrain {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowDailyForAdult/weather/heavyRain2.svg);
  animation-duration: 2s;
}

.theme-adult .cow-daily--robot--astronaut {
  width: vwFn(35.98);
  height: vhFn(65);
  top: vhFn(17.5);
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowDailyForAdult/elements/astronautAdult.svg);
}
.theme-adult .cowDailyAstronaut--button {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowDailyForAdult/elements/buttonGrazeway.svg);
  transform: scaleX(-1);
  left: vwFn(85.4);
}
.theme-adult .cow-daily--robot--vector {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowDailyForAdult/elements/vectorAdult.svg);
  width: vwFn(40.487);
  height: vhFn(64.06);
  top: vhFn(18.45);
}
.theme-adult .bottleDaily {
  //display: none; 
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowdaily/graphics/milk_bottleAdult.svg);
  height: vhFn(10);
  width: vwFn(2.5);
}
.theme-adult .cow-daily--astronaut--arrowText {
  width: vwFn(25.39);
  height: vhFn(12.8);
  left: vwFn(10.78);
  top: vhFn(82); 
} 
.theme-adult .cow-daily--astronaut--arrow, .theme-adult .cow-daily--rumination--arrow {
  left: vwFn(-5);
  top: vhFn(3);
  transform: rotate(0) scaleX(1); 
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowdaily/graphics/arrowAdultVersion.svg);
}
.theme-adult .CowDailyMilkingPage .cow-daily--astronaut--arrowText {
  display: none;
}
.theme-adult .cow-daily--rumination--arrowText {
  //display: none;
}
.theme-adult .cow-daily--rumination-cow {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowDailyForAdult/elements/ruminationCowAdult.svg);
}
.theme-adult .cowDailyRumination--button {
  display: none;
}
.theme-adult .CowDailyOutroPage .lastSlideRobotStory--trees {
  display: none;
}
.theme-adult .cow-daily--outro--slide--grass {
  display: none;
}
.theme-adult .rainfall.drizzle {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowDailyForAdult/weather/drizzleAdult.svg);
}

.theme-adult .rainfall.averagerain {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowDailyForAdult/weather/averageRainAdult.svg);
}
.theme-adult .thunder-1 {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowDailyForAdult/weather/thunder/thunderAdult1.svg);
}

.theme-adult .thunder-2 {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowDailyForAdult/weather/thunder/thunderAdult2.svg);
}

.theme-adult .thunder-3 {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowDailyForAdult/weather/thunder/thunderAdult3.svg);
}

.theme-adult .thunder-4 {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowDailyForAdult/weather/thunder/thunderAdult4.svg);
}

.theme-adult .thunder-5 {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowDailyForAdult/weather/thunder/thunderAdult5.svg);
}

.theme-adult .thunder-6 {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowDailyForAdult/weather/thunder/thunderAdult6.svg);
}

/* ADULT THEME CLOUD STYLES */
.cloud--adult.cloud-1 {
  background-image: url(../../../resources/assets/themes/adult/16x9/stories/cowdaily/background-layers/clouds/adult_cloud_1.svg);
}

.cloud--adult.cloud-2 {
  background-image: url(../../../resources/assets/themes/adult/16x9/stories/cowdaily/background-layers/clouds/adult_cloud_2.svg);
}

.cloud--adult.cloud-3 {
  background-image: url(../../../resources/assets/themes/adult/16x9/stories/cowdaily/background-layers/clouds/adult_cloud_3.svg);
}

.cloud--adult.cloud-4 {
  background-image: url(../../../resources/assets/themes/adult/16x9/stories/cowdaily/background-layers/clouds/adult_cloud_4.svg);
}

.cloud--adult.cloud-5 {
  background-image: url(../../../resources/assets/themes/adult/16x9/stories/cowdaily/background-layers/clouds/adult_cloud_5.svg);
}

.cloud--adult.cloud-6 {
  background-image: url(../../../resources/assets/themes/adult/16x9/stories/cowdaily/background-layers/clouds/adult_cloud_6.svg);
}

.cloud--adult.cloud-7 {
  background-image: url(../../../resources/assets/themes/adult/16x9/stories/cowdaily/background-layers/clouds/adult_cloud_7.svg);
}

.cloud--adult.cloud-8 {
  background-image: url(../../../resources/assets/themes/adult/16x9/stories/cowdaily/background-layers/clouds/adult_cloud_8.svg);
}

.cloud--adult.cloud-9 {
  background-image: url(../../../resources/assets/themes/adult/16x9/stories/cowdaily/background-layers/clouds/adult_cloud_9.svg);
}

.cloud--adult.cloud-10 {
  background-image: url(../../../resources/assets/themes/adult/16x9/stories/cowdaily/background-layers/clouds/adult_cloud_10.svg);
}

.cloud--adult.cloud-11 {
  background-image: url(../../../resources/assets/themes/adult/16x9/stories/cowdaily/background-layers/clouds/adult_cloud_11.svg);
}

.cloud--adult.cloud-12 {
  background-image: url(../../../resources/assets/themes/adult/16x9/stories/cowdaily/background-layers/clouds/adult_cloud_12.svg);
}

.cloud--adult.cloud-13 {
  background-image: url(../../../resources/assets/themes/adult/16x9/stories/cowdaily/background-layers/clouds/adult_cloud_13.svg);
}

.cloud--adult.cloud-14 {
  background-image: url(../../../resources/assets/themes/adult/16x9/stories/cowdaily/background-layers/clouds/adult_cloud_14.svg);
}

.cloud--adult.cloud-15 {
  background-image: url(../../../resources/assets/themes/adult/16x9/stories/cowdaily/background-layers/clouds/adult_cloud_15.svg);
}

.cloud--adult.cloud-16 {
  background-image: url(../../../resources/assets/themes/adult/16x9/stories/cowdaily/background-layers/clouds/adult_cloud_16.svg);
}

.cloud--adult.cloud-17 {
  background-image: url(../../../resources/assets/themes/adult/16x9/stories/cowdaily/background-layers/clouds/adult_cloud_17.svg);
}

.cloud--adult.cloud-18 {
  background-image: url(../../../resources/assets/themes/adult/16x9/stories/cowdaily/background-layers/clouds/adult_cloud_18.svg);
}

.cloud--adult.cloud-19 {
  background-image: url(../../../resources/assets/themes/adult/16x9/stories/cowdaily/background-layers/clouds/adult_cloud_19.svg);
}

.cloud--adult.cloud-1--grey,
.cloud--adult.cloud-2--grey,
.cloud--adult.cloud-3--grey,
.cloud--adult.cloud-4--grey,
.cloud--adult.cloud-5--grey,
.cloud--adult.cloud-6--grey,
.cloud--adult.cloud-7--grey,
.cloud--adult.cloud-8--grey,
.cloud--adult.cloud-9--grey,
.cloud--adult.cloud-10--grey,
.cloud--adult.cloud-11--grey,
.cloud--adult.cloud-12--grey,
.cloud--adult.cloud-13--grey,
.cloud--adult.cloud-14--grey,
.cloud--adult.cloud-15--grey,
.cloud--adult.cloud-16--grey,
.cloud--adult.cloud-17--grey,
.cloud--adult.cloud-18--grey,
.cloud--adult.cloud-19--grey {
  background-image: inherit;
}
/**/

/* Adult theme mist styles */
.mist-cloud--adult.mist-cloud-1 {
  background-image: url(../../../resources/assets/themes/adult/16x9/stories/cowdaily/background-layers/mist/adult_mist_1.svg);
}

.mist-cloud--adult.mist-cloud-2 {
  background-image: url(../../../resources/assets/themes/adult/16x9/stories/cowdaily/background-layers/mist/adult_mist_2.svg);
}

.mist-cloud--adult.mist-cloud-3 {
  background-image: url(../../../resources/assets/themes/adult/16x9/stories/cowdaily/background-layers/mist/adult_mist_3.svg);
}

.mist-cloud--adult.mist-cloud-4 {
  background-image: url(../../../resources/assets/themes/adult/16x9/stories/cowdaily/background-layers/mist/adult_mist_4.svg);
}

.mist-cloud--adult.mist-cloud-5 {
  background-image: url(../../../resources/assets/themes/adult/16x9/stories/cowdaily/background-layers/mist/adult_mist_5.svg);
}

.mist-cloud--adult.mist-cloud-6 {
  background-image: url(../../../resources/assets/themes/adult/16x9/stories/cowdaily/background-layers/mist/adult_mist_6.svg);
}

.mist-cloud--adult.mist-cloud-7 {
  background-image: url(../../..//resources/assets/themes/adult/16x9/stories/cowdaily/background-layers/mist/adult_mist_7.svg);
}
/**/

/* Adult theme sun styles */
.sun.sun--adult {
  background-image: url(../../../resources/assets/themes/adult/16x9/stories/cowdaily/background-layers/sky/adult_sun.svg);
}
/**/

/* Adult theme moon styles */
.moon.moon--adult {
  background-image: url(../../../resources/assets/themes/adult/16x9/stories/cowdaily/background-layers/sky/adult_moon.svg);
  width: vwFn(5.54);
  height: vhFn(16);
}
/**/

/* Adult theme sky styles */
.sky--container--adult {
  background-color: #dedbd6;
}
/**/
.theme-adult .textCaption--cowDailyMilkingBottles {
  //display: none;
}
.theme-adult .textCaption--heading--milking,
.theme-adult .cowDailyRumination--date {
  font-family: BourtonBase !important;
  font-size: vhFn(4.8);
}
.theme-adult .cowMilkingText,
.theme-adult .cowDailyRumination--date {
  margin: vwFn(1);
}
.theme-adult .cowDailyRumination {
  font-size: vhFn(4.8);
  min-width: vwFn(32);
  min-height: vhFn(35.01);
}
.theme-adult .cowDailyRumination--date {
  font-size: vhFn(4);
}
.theme-adult .cow-daily-intro--text {
  font-family: "Roboto" !important;
}
.background-pcd-treesAdult1 {
  bottom: vhFn(1.75);
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowDailyForAdult/background-layers/trees/background-pcd-treesAdult.svg);
} 
.theme-adult .cow-daily-intro--transitionTree{
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistoryForAdult/background-layers/trees/tree_on_the_right.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  top: vhFn(-1);
  //left: vwFn(50);
  width: vwFn(100);
  height: vhFn(105);
}