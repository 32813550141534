@import "../../common/styles/variables.scss";

.astronautNameSlide {
  position: absolute;
  background-size: 100% 100%;
  width: vwFn(100.5);
  height: vhFn(100);
  background-image: url(../../resources/assets/themes/lely/16x9/stories/astronautstory/firstmilkingslide.svg);
}

.astronautNameSlide--textCaption {
  position: absolute;
  min-width: vwFn(34.6);
  min-height: vhFn(24.26);
  top: vhFn(29.072);
  left: vwFn(22.339);
  padding: vhFn(3.2) vwFn(1.48);
}

.astronautNameSlide--robot {
  position: absolute;
  background-size: 100% 100%;
  background-image: url(../../resources/assets/themes/lely/16x9/ui/elements/astronaut.svg);
  width: vwFn(32.729);
  height: vhFn(53.66);
  left: vwFn(51.15);
  top: vhFn(27.2);
}

.astronautNameSlide--title {
  font-family: $primaryFont;
  font-weight: $font-light;
  font-size: vhFn(4.27);
  line-height: vhFn(4.8);
  color: $color-gray-popup;
}

.astronautNameSlide--name {
  font-family: Ubuntu Mono;
  font-size: vhFn(12.8);
  line-height: vhFn(10.4);
  color: #000000;
  margin: vhFn(1.87) 0;
  letter-spacing: vwFn(0.1);
}
@media only screen and (orientation: portrait) {
  .astronautNameSlide--name {
    font-family: $primaryFont;
    font-size: vhFn(8.8);
    line-height: vhFn(10.4);
    color: #000000;
    margin: vhFn(1.87) 0;
    letter-spacing: vhFn(0.01);
  }
  .astronautNameSlide--textCaption {
    position: absolute;
    min-width: vwFn(30.6);
    min-height: vhFn(24.26);
    top: vhFn(29.072);
    left: vwFn(22.339);
    padding: vhFn(3.2) vwFn(1.48);
  }
}
.theme-adult .astronautNameSlide { 
  background-image: url(../../resources/assets/themes/lely/16x9/stories/astronautstory/milkingRobotStory.svg);
} 
.theme-adult .astronautNameSlide--robot {
  width: vwFn(32.88);
  height: vhFn(55.466);
  left: vwFn(51.15);
  top: vhFn(26.2);
  background-image: url(../../resources/assets/themes/lely/16x9/ui/elements/astronautAdultVersion.svg);

} 
.theme-adult .astronautNameSlide--name {
  font-family: Arial, Helvetica, sans-serif;
}