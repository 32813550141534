@use "../../common/styles/variables.scss" as *;

.cow-daily--milking--slide {
  width: vwFn(100);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  /* Fallback for browsers that do not support Custom Properties */
  height: vhFn(100);
}

.textCaption--heading--milking {
  margin: vwFn(1) !important;
}

.textCaption--cowDailyMilking {
  position: absolute;
  left: vwFn(19.73);
  top: vhFn(41.6);
  width: vwFn(30);
  min-height: vhFn(28.26);
  padding: vwFn(0.2);
}

.cowMilkingText {
  font-size: vwFn(1.724);
  line-height: vhFn(6.4);
  margin: vwFn(1.25) vhFn(1);
}

.textCaption--heading--milking {
  font-size: vwFn(2.214);
}

.cow-daily--robot--astronaut {
  position: absolute;
  background-image: url(../../resources/assets/themes/lely/16x9/ui/elements/astronaut.svg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  top: vhFn(35.26);
  left: vwFn(50);
  width: vwFn(26);
  height: vhFn(45.66);
  cursor: pointer;
}

.cowDailyAstronaut--button {
  position: absolute;
  background-image: url(../../resources/assets/themes/lely/16x9/screens/cowCalmRobot/cow-calm-slide-button.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  top: vhFn(50.26);
  left: vwFn(74);
  height: vhFn(6.63);
  width: vwFn(3);
}

.cow-daily--astronaut--arrowText {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  position: absolute;
  width: vwFn(25.39);
  height: vhFn(12.8);
  left: vwFn(47.78);
  top: vhFn(19);
}

.cow-daily--astronaut--arrowDown {
  position: absolute;
  background-image: url(../../resources/assets/themes/lely/16x9/ui/arrows/arrow-left-curve-down-black.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: vwFn(4.92);
  height: vhFn(5.62);
  top: vhFn(15);
  transform: scale(1) rotate(-120deg);
}

.cow-daily--astronaut--text {
  position: absolute;
  font-family: $secondaryFont;
  font-weight: bold;
  font-size: vhFn(4.5);
  line-height: vhFn(6.4);
  color: black;
  width: vwFn(19);
}
