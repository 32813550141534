@use "../../common/styles/variables.scss" as *;

.StoryBlock .labelled-text-area--label,
.StoryBlock .labelled-text-input-for-language--label {
  position: absolute;
  top: 0;
  color: #766e68;
  font-size: 12px;
  padding-top: 1px;
  padding-left: 5px;
}

.StoryBlock .labelled-text-input-for-language {
  width: 100%;
}

.StoryBlock .labelled-text-area {
  position: relative;
  width: 48%;
  margin-right: 2%;
}

.StoryBlock .labelled-text-input,
.story-description-input--label {
  margin-bottom: 2%;
}

.StoryBlock textarea {
  padding-top: 20px;
  padding-left: 5px;
  resize: none;
}

.labelled-text-area--charactersLeft {
  display: block;
  color: #766e68;
  position: absolute;
  right: 10px;
  bottom: 10px;
  font-size: 10px;
}

.btn-delete-story-block {
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
}

.btn-delete-story-block .lely-icon-trash {
  font-size: 16px;
  color: #766e68;
  position: relative;
  top: 2px;
}

.btn-delete-story-block--label {
  text-decoration: underline;
  color: #766e68;
  font-size: 14px;
  margin: 0;
}

.StoryBlock {
  border-bottom: 1px solid #cfcbc3;
}

.StoryBlock--title {
  color: #121212;
  font-size: 16px;
}

.btn-move-down-story-block .lely-icon-triangle {
  position: relative;
  top: 3px;
}

.btn-move-up-story-block .lely-icon-triangle {
  display: inline-block;
  position: relative;
  top: -2px;
  transform: scaleY(-1);
  transform-origin: 50% 50%;
}

.btn-move-down-story-block,
.btn-move-up-story-block {
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  color: #766e68;
}

.story-block-buttons-container {
  display: flex;
  justify-content: space-between;
}
