@use "../../../common/styles/variables.scss" as *;

/* Background trees */
.background-pcd-trees10 {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/background-trees/trees1.svg);
}

.background-pcd-trees2 {
  // background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/background-trees/trees2.svg);
}

.background-pcd-trees3 {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/background-trees/trees3.svg);
}

.background-pcd-trees1,
.background-pcd-trees2,
.background-pcd-trees3 {
  background-position-y: 66%;
}

/**/

/* Background clouds */
.background-pcd-clouds1 {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/clouds/clouds1.svg);
}

.background-pcd-clouds2 {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/clouds/clouds2.svg);
}

.background-pcd-clouds3 {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/clouds/clouds3.svg);
}

/**/

/* Background eating, resting, milking and rumination */
.background-layer--rumination .background-layer--slide {
  background-size: cover;
  overflow: visible;
}

// .CowDailyEating1Slide,
// .CowDailyEating3Slide,
// .CowDailyResting1Slide,
// .CowDailyResting3Slide,
// .CowDailyMilking2Slide {
//   opacity: 0;
// }

.CowDailyOutroSlide .background-layer--trees {
  opacity: 1;
}

.background-pcd-eating1 {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/eating-improved/eating_left.svg);
}

.background-pcd-eating2 {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/eating/eating2.svg);
}

.background-pcd-eating3 {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/eating-improved/eating_last.svg);
  background-size: cover;
}

.background-pcd-resting1 {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/resting/resting_left.svg);
}

.background-pcd-resting2 {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/resting/resting2.svg);
}

.background-pcd-resting3 {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/resting/resting_last.svg);
}

.background-pcd-milking1 {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/milking/milking_left.svg);
  background-size: cover;
}

.background-pcd-milking2 {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/milking/milking_last.svg);
}

.background-pcd-rumination {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/rumination/rumination_1.svg);
}

/**/

/* Background forefront tree */
.background-pcd-forefronttree {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/forefront-tree/forefronttree2.svg);
  background-position: 0% 0%;
}

/**/

/* Background grass */
.background-pcd-grass3 {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/eating-improved/grass_initial.svg);
  background-size: 100%;
}

.background-pcd-grass5 {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/eating-improved/grass_last.svg);
  background-size: auto;
}
.background-pcd-grass9 {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/eating-improved/grass_without_tree.svg);
}
/**/

/* Sun and birds */
.background-pcd-sky1 {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/sky/sun.svg);
}

.background-pcd-sky2 {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/sky/birds.svg);
}

/**/

.parallax-cow-daily {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  width: vwFn(100);
  /* Fallback for browsers that do not support Custom Properties */
  height: vhFn(100);
}

.is-cow-daily-currently-shown .parallax-cow-daily {
  opacity: 1;
}

.parallax-cow-daily {
  background-color: #c2eaf2;
}

.parallax-cow-daily.is-dusk {
  background-color: rgb(7, 6, 51);
}

.parallax-cow-daily .background-layer--stars {
  background-image: linear-gradient(10deg, #000746, #00104c);
}

.background-pcd-stars {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/stars/starry_sky.svg);
}

.parallax-cow-daily .background-layer--sky .background-layer--slide {
  background-size: 25% 25%;
  background-position: top;
}

.parallax-cow-daily.is-dusk .background-layer {
  filter: brightness(0.5) sepia(1) hue-rotate(180deg) saturate(5);
}

.RobotStoryOrbiter2, .RobotStoryOrbiter3, .RobotStoryOrbiter4, .RobotStoryOrbiter5, .RobotStoryOrbiter6 {
  .background-layer--windmills{
    display: none;
  }
}