@use "../../common/styles/variables.scss" as *;

.virtual-farm-tour-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; 
  -webkit-transform: translate3d(0, 0, 1px);
  transform: translate3d(0, 0, 1px);
}

.virtual-farm-tour--overlay {
  pointer-events: none;
  display: none;
}

.is-virtual-farm-tour-active .virtual-farm-tour--overlay {
  display: block;
  position: absolute;
  background-color: #121212;
  opacity: 0.4;
  width: vwFn(100);
  height: vhFn(100);
  pointer-events: none;
}

.is-virtual-farm-tour-active.virtual-farm-tour-slide-0
  .virtual-farm-tour--overlay {
  top: -100px;
}

.is-virtual-farm-tour-active.virtual-farm-tour-slide-1
  .virtual-farm-tour--overlay {
  top: -100px;
}
.is-virtual-farm-tour-active.virtual-farm-tour-slide-2
  .virtual-farm-tour--overlay {
  top: -100px;
}
.is-virtual-farm-tour-active .virtualFarm {
  //background-image: url(../../resources/assets/themes/lely/16x9/popups/tour/full-farm.svg) !important;
}

.is-virtual-farm-tour-active .virtualFarm--actors-container {
  position: relative;
  top: vhFn(-1);
  left: vhFn(-2);
}

.is-virtual-farm-tour-active .virtualFarm .vectorFeature {
  top: vhFn(35) !important;
  left: vhFn(95) !important;
}

.is-virtual-farm-tour-active .virtualFarm--animal--fence {
  bottom: vhFn(-110) !important;
  right: vhFn(-5) !important;
}

.is-virtual-farm-tour-active .react-transform-component {
  transition: 1s transform;
}

.is-virtual-farm-tour-active.virtual-farm-tour-slide-0
  .react-transform-component {
  transform: translate3d(vwFn(-45), vhFn(28), 0px) scale(1.9) !important;
}
.is-virtual-farm-tour-active.virtual-farm-tour-slide-1
  .react-transform-component {
  transform: translate3d(vwFn(-45), vhFn(28), 0px) scale(1.9) !important;
}
.is-virtual-farm-tour-active.virtual-farm-tour-slide-2
  .react-transform-component {
  transform: translate3d(vwFn(-45), vhFn(28), 0px) scale(1.9) !important;
}

.is-virtual-farm-tour-active.virtual-farm-tour-slide-3
  .react-transform-component {
  transform: translate3d(vwFn(-20), -10%, 0px) scale(1.4) !important;
  margin-top: vhFn(10) !important;
}

.is-virtual-farm-tour-active .virtualFarm--highlighted path:first-of-type {
  fill: #ffc700;
  stroke: #ffc700;
}

.is-virtual-farm-tour-active .virtualFarm--highlighted #actorOutline {
  fill: #ffc700;
  stroke: #ffc700;
}

.is-virtual-farm-tour-active .virtualFarm--highlighted {
  z-index: 999;
}

.is-virtual-farm-tour-active .ribbonTitle--is-clickable {
  z-index: 999;
  top: vhFn(-12.5);
  //left: vwFn(40.5);
  max-height: vhFn(5) !important;
  padding: vhFn(1) vwFn(2.5);
  padding-bottom: vhFn(1.75);
  padding-top: vhFn(0);
  position: relative;
  margin-left: auto;
  margin-right: auto;
  max-width: vwFn(25); 
  left: 0;
} 
.is-virtual-farm-tour-active .ribbonTitle--title {
  width: unset;
}
.is-virtual-farm-tour-active .ribbonTitle--title {
  font-size: vhFn(3.5);
}
// .is-virtual-farm-tour-active.virtual-farm-tour-slide-1 .ribbonTitle--is-clickable {
//   z-index: 999;
//   top: vhFn(-10);
//   left: vwFn(30);
// }
.virtual-farm-tour-slide-3 .is-actor-pointed {
  background-image: url(../../resources/assets/themes/lely/16x9/ui/icons/desktop_click.svg);
  display: block;
  width: 100%;
  height: vhFn(10);
  position: absolute;
  top: vhFn(5);
  right: vwFn(-1);
}

.virtual-farm-tour--slide0 .vectorPopup-callToActionButton--container,
.virtual-farm-tour--slide1 .vectorPopup-callToActionButton--container,
.virtual-farm-tour--slide2 .vectorPopup-callToActionButton--container,
.virtual-farm-tour--slide3 .vectorPopup-callToActionButton--container {
  display: none;
}

.theme-adult
  .virtual-farm-tour--slide0
  .vectorPopup-callToActionButton--container,
.theme-adult
  .virtual-farm-tour--slide1
  .vectorPopup-callToActionButton--container,
.theme-adult
  .virtual-farm-tour--slide2
  .vectorPopup-callToActionButton--container {
  display: flex;
}

@media (any-pointer: coarse) {
  .virtual-farm-tour-slide-3 .is-actor-pointed {
    background-image: url(../../resources/assets/themes/lely/16x9/ui/icons/click-cursor.svg);
  }
}

.virtual-farm-tour-slide-3 .cow .is-actor-pointed {
  top: vhFn(3);
}

.virtual-farm-tour-slide-0 .arrowPopup--container {
  top: vhFn(21);
  left: vwFn(8.5);
}

// .virtual-farm-tour-slide-1 .arrowPopup--container {
//   top: vhFn(6);
//   left: vwFn(17.5);
//   min-height: vhFn(20);
// }

.virtual-farm-tour-slide-3 .arrowPopup .btn-top-close {
  left: 72%;
  height: vhFn(11.46);
  width: vwFn(8.374);
}

.virtual-farm-tour .virtualFarm--animal--fence {
  opacity: 0;
}

.is-virtual-farm-tour-active .arrowPopup--description {
  margin-top: vhFn(7);
}
.virtual-farm-tour-container .vectorPopup-callToActionButton--container {
  margin-left: vwFn(0);
  justify-content: center;
}
.virtual-farm-tour--slide1 .arrowPopup--container {
}
.virtual-farm-tour-slide-3 .arrowPopup--title {
  font-size: vhFn(3.73);
  font-family: "Ubuntu";
  font-weight: 400;
}
.virtual-farm-tour--slide3 .vectorPopup-callToActionButton--container {
  display: none;
}

.theme-adult .virtual-farm-tour--slide3 .arrowPopup--title {
  width: 100%;
  text-align: left;
  padding-left: vwFn(6.5) !important;
}

.virtual-farm-tour--slide3 .arrowPopup--title {
  padding-right: 20% !important;
}

.theme-adult .virtual-farm-tour-slide-2 .btnIcon,
.theme-adult .virtual-farm-tour-slide-1 .btnIcon,
.theme-adult .virtual-farm-tour-slide-0 .btnIcon {
  display: none;
}
.virtual-farm-tour-slide-3 .ribbonTitle--is-clickable {
  z-index: 999;
  top: vhFn(2.5);
  //left: vwFn(40.5);
  max-height: vhFn(5) !important;
  padding: vhFn(1) vwFn(2.5);
  padding-bottom: vhFn(1.75);
  padding-top: vhFn(0); 
  
}