@use "../../common/styles/variables.scss" as *;

.sideMenu {
  background-color: $color-LelyWhite;
  stroke: 1px solid $color-LelyRedDark;
  transition: 0.5s all;
}

.sideMenu--mainMenu {
  position: absolute;
  width: vwFn(32);
  height: vhFn(96.8);
  padding-left: vwFn(1);
  padding-top: vhFn(1);
  left: vwFn(3.67);
  top: vhFn(-97.5);
  z-index: 99;
  -webkit-transform: translate3d(0, 0, 1px);
  transform: translate3d(0, 0, 1px);
}

.is-sideMenu--mainMenu-displayed {
  border-radius: vhFn(1);
  border-left: vwFn(0.12) solid $color-gray-light;
  border-right: vwFn(0.12) solid $color-gray-light;
  border-top: vhFn(0.27) solid $color-gray-light;
  border-bottom: vhFn(0.27) solid $color-gray-light;
  top: vhFn(-1);
}

.is-sideMenu--mainMenu-displayed .sideMenu--mainMenu--btnCloseMenu {
  opacity: 1;
  pointer-events: auto;
}

.sideMenu--mainMenu--btnCloseMenu {
  position: absolute;
  width: vwFn(10.27);
  height: vhFn(12.27);
  left: vwFn(0);
  bottom: vhFn(-4);
  background-image: url(../../resources/assets/themes/lely/16x9/ui/sidemenu/close-menu-button.svg);
  background-size: 100% 100%;
  opacity: 0;
  pointer-events: none;
}
.sideMenu--mainMenu--btnCloseMenu--adult {
  position: absolute;
  background-image: url(../../resources/assets/themes/adult/16x9/ui/sidemenu/close-menu-button.svg);
  transform: scale(-1);
  width: vwFn(7.27);
  height: vhFn(12);
  left: vwFn(1);
  bottom: vhFn(-1.5);
}
.btnIcon--showMenu {
  position: fixed;
  width: vwFn(6.557);
  height: vhFn(11.46);
  left: vwFn(3.67);
  top: vhFn(0);
  background-image: url(../../resources/assets/themes/lely/16x9/ui/sidemenu/menu-icon.svg);
  background-size: 100% 100%;
  padding: 0;
  margin: 0;
  -webkit-transform: translate3d(0, 0, 1px);
  transform: translate3d(0, 0, 1px);
  z-index: 99999999999999999999;
}

.sideMenu--mainMenu--menuOption {
  width: 100%;
  font-size: vhFn(3.7);
  color: $color-gray-popup;
  margin-top: vhFn(5);
  margin-bottom: vhFn(6);
}

.sideMenu--mainMenu--upper {
  position: absolute;
  z-index: 99;
  margin-left: vwFn(4.5);
  width: vwFn(26);
  height: vhFn(86.1);
}
.sideMenu--mainMenu--languages {
  position: absolute;
  z-index: 99;
  margin-left: vwFn(4);
  margin-top: vhFn(57.5);
  height: vhFn(7);
  width: vwFn(22.5);
  display: flex;
  flex-direction: row;
  //background-color: red;
}
.sideMenu--mainMenu--shareableButtons {
  position: absolute;
  z-index: 99;
  margin-left: vwFn(4);
  margin-top: vhFn(70.5);
  height: vhFn(10);
  width: vwFn(22.5);
  display: flex;
  flex-direction: row;
  gap: vwFn(1);
  //background-color: red;
  align-items: flex-start;
}
.sideMenu--mainMenu--legal {
  position: absolute;
  z-index: 99;
  margin-left: vwFn(10);
  margin-top: vhFn(86.5);
  //height: vhFn(7);
  width: vwFn(22.5);
  display: flex;
  flex-direction: row;
  gap: vwFn(1);
}

.sideMenu--mainMenu--legalDivider {
  font-size: vhFn(2.4);
  color: $color-GrayDairyDarker;
}

.sideMenu--mainMenu--privacyPolicy {
  text-align: right;
  //margin-right: vwFn(1.5);
  font-size: vhFn(2.4);
  color: $color-GrayDairyDarker;
  line-height: vhFn(6.4);
}

.sideMenu--mainMenu--cookies {
  text-align: left;
  font-size: vhFn(2.4);
  color: $color-GrayDairyDarker;
  line-height: vhFn(6.4);
}

.sideMenu--mainMenu--cookies:before {
  content: "|";
  // border: 0.25px ridge #766E68;
  // border-width: 0.5px;
  color: #766e68;
  align-self: stretch;
  margin-right: vwFn(1);
}

.Demo__some-network__share-button {
  // margin-right: vwFn(2);
  // margin-bottom: vhFn(2);
}

.sideMenu--background-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: calc(var(--vw, 1vw) * 100);
  height: 100%;
  background-color: #121212;
  opacity: 0.4;
}

.sideMenu--background-overlay.is-side-menu-displayed {
  display: block;
}
.is-age-selected-undefined.is-onboarding-not-over + .btnIcon--showMenu {
  background-image: url(../../resources/assets/themes/adult/16x9/ui/sidemenu/menu-icon.svg);
}

.Demo__some-network__share-button--mail {
  width: vwFn(3.875);
  height: vwFn(3.875);
  background-image: 100% 100%;
  background-repeat: none;
  background-image: url(../../resources/assets/themes/lely/16x9/ui/sidemenu/email-icon.svg);
}
.btn-social circle {
  fill: #c30a14;
}
.is-adult-selected .Demo__some-network__share-button--mail {
  background-image: url(../../resources/assets/themes/lely/16x9/ui/sidemenu/emailAdultVersion.svg);
}
.is-adult-selected .btn-social circle {
  fill: #eb6752 !important;
  background-image: 100% 100%;
}

.btn-social {
  width: vwFn(3.875);
  height: vwFn(3.875);
}
