@use "../BackgroundLayer/Parallax.scss";
@use "../../common/styles/variables.scss" as *;

.flow-container {
  overflow: hidden;
  position: relative;
  width: vwFn(100);
  height: vhFn(100);
  max-height: -webkit-fill-available;
  max-height: -moz-available;
  max-height: stretch;
  background-color: white;
  margin: 0 !important;
  padding: 0 !important;
}

body {
  position: absolute;
  // @if function-exists(vwFn) {
  //   exists: true;
  // } @else {
  //   exists: false;
  // }
  width: vwFn(100);
  height: vhFn(100);
  min-height: vhFn(100);
  max-height: vhFn(100);
  overflow-y: hidden;
  margin: 0 !important;
  padding: 0 !important;
}
