@use "../../common/styles/variables.scss" as *;

.orbiterStorySecondSlide--background {
  position: absolute;
  background-size: 100% 100%;
  width: vwFn(100);
  height: vhFn(100);
  background-image: url(../../resources/assets/themes/lely/16x9/stories/orbiterstory/orbiterMilking.svg);
  border: none;
}

.orbiterStorySecondSlide--textCaption {
  position: absolute;
  width: vwFn(30.788);
  min-height: vhFn(20.8);
  top: vhFn(42.26);
  left: vwFn(44.2);
  padding: vhFn(1.6) vwFn(1.48);
  z-index: 6;
}

.orbiterStorySecondSlide--robot {
  position: absolute;
  background-size: 100% 100%;
  background-image: url(../../resources/assets/themes/lely/16x9/ui/elements/astronaut.svg);
  width: vwFn(34.729);
  height: vhFn(57.66);
  left: vwFn(6.53);
  top: vhFn(23.994);
}

.orbiterStorySecondSlide--text {
  font-family: $primaryFont;
  font-style: $font-DefaultFontStyle;
  font-size: vhFn(3.73);
  line-height: vhFn(6.4);
  color: $color-gray-popup;
  margin: vhFn(1.87) 0; 
  letter-spacing: vwFn(0.03);
}

.orbiterStorySecondSlide--pipe {
  position: absolute;
  background-size: 100% 100%;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/orbiterstory/pipe.svg);
  width: vwFn(65.729);
  height: vhFn(2.333);
  left: vwFn(39.66953);
  top: vhFn(71.13);
}

.orbiterStorySecondSlide--tree {
  position: absolute;
  background-size: 100% 100%;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/orbiterstory/orbitersixthslide.svg);
  width: vwFn(100);
  height: vhFn(124.61);
  left: vwFn(14);
  top: vhFn(-18.37);
  z-index: 5;
}
.theme-adult .orbiterStorySecondSlide--background { 
  background-image: url(../../resources/assets/themes/lely/16x9/stories/astronautstory/astronautStorySecondSlideAdult.svg);
} 
.theme-adult .orbiterStorySecondSlide--tree {
  position: absolute;
  background-size: 100% 100%;
  width: vwFn(60);
  left: vwFn(40);
  height: vhFn(100);
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowDailyForAdult/backgrounds/eating/full_tree.png);
  display: block;
  z-index: 1; 
  top: 0; 
  z-index: 5;
} 
.theme-adult {
  .orbiterStoryThirdSlide--floor {
    display: none;
} .orbiterStorySecondSlide--robot {
  background-image: url(../../resources/assets/themes/lely/16x9/ui/elements/astronautAdultVersion.svg);
} .orbiterStorySecondSlide--pipe {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/orbiterstory/pipeSecondSlideAdult.svg);
}
} 
