@import "../../common/styles/variables.scss";

.liveDataPopup {
  position: absolute;
  background-color: $color-LelyWhite;
  // border-left: vhFn(0.01) solid $color-GrayDairyDarker;
  // border-right: vhFn(0.01) solid $color-GrayDairyDarker;
  // border-top: vhFn(0.001) solid $color-GrayDairyDarker;
  // border-bottom: vhFn(0.001) solid $color-GrayDairyDarker;
  border-radius: vwFn(0.2) vwFn(0.2) vwFn(0.2) vwFn(0.2);
  box-sizing: border-box;
  background-repeat: no-repeat;
  width: vwFn(9.2943);
  height: vhFn(8.608);
  box-sizing: border-box;
  // padding: vhFn(2) vwFn(2);
  border-width: 0.25px;
  z-index: 40;
  padding: vhFn(0.25) vwFn(0.12);
  box-shadow: 0px vhFn(1) vhFn(1) rgba(18, 18, 18, 0.2);
}

.liveDataPopup--title {
  position: absolute;
  font-family: $secondaryFont;
  font-weight: bold;
  color: $color-LelyBlack;
  font-size: vhFn(1.86128);
  padding-left: vhFn(1);
  padding-top: vhFn(1);
  letter-spacing: vhFn(0.075);
  line-height: vhFn(3);
}

.liveDataPopup--cowHead {
  width: vwFn(3.222);
  height: vhFn(6.7466);
  display: block;
  background-repeat: no-repeat;
  position: absolute;
  top: vhFn(0.25);
  right: vwFn(0.25);
  background-size: 100% 100%;
  margin-top: vhFn(0.25);
  margin-right: vhFn(0.25);
}
.liveDataPopup--arrow {
  width: vwFn(2);
  height: vhFn(5.832);
  //display: block;
  background-repeat: no-repeat;
  position: absolute;
  bottom: vhFn(-4);
  right: vwFn(0.1);
  background-size: 100% 100%;
  background-image: url(../../resources/assets/themes/lely/16x9/popups/livedata/liveDataArrow.svg);
}
.liveDataPopup--liveIcon {
  animation: wiggle 2.5s infinite;
  background-image: url(../../resources/assets/themes/lely/16x9/popups/livedata/liveIcon.svg);
  width: vwFn(4.187);
  height: vhFn(6.6666);
  //display: block;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  top: vhFn(-4.5);
  left: vwFn(-0.5);
  position: absolute;
  background-color: transparent;
  box-shadow: vhFn(1) rgba(18, 18, 18, 0.2);
}

@keyframes wiggle {
  0% {
    transform: rotate(0deg);
  }
  80% {
    transform: rotate(0deg);
  }
  85% {
    transform: rotate(5deg);
  }
  95% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.theme-adult .liveDataPopup--liveIcon {
  background-image: url(../../resources/assets/themes/lely/16x9/popups/livedata/liveIconAdults.svg);
  width: vwFn(3.187);
  height: vhFn(4.6666);
  top: vhFn(-3.5);

}

.theme-adult .liveDataPopup--cowHead {
  background-image: url(../../resources/assets/themes/lely/16x9/actors/cow/popup/cow-list-popup-head-adults.svg);
}

.theme-adult .liveDataPopup--arrow {
  background-image: url(../../resources/assets/themes/lely/16x9/popups/livedata/liveDataArrowAdults.svg);
}

.theme-adult .liveDataPopup {
  box-shadow: none;
}