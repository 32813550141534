@use "../../common/styles/variables.scss" as *;

.cow-daily--robot--grazeway {
  position: absolute;
  background-image: url(../../resources/assets/themes/lely/16x9/ui/elements/grazeway.svg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: vhFn(78);
  height: vhFn(46.66);
  top: vhFn(38.44);
  left: vwFn(31.74);
}

.cowDailyGrazeway--button {
  position: absolute;
  background-image: url(../../resources/assets/themes/lely/16x9/screens/cowCalmRobot/cow-calm-slide-button.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: vhFn(6.63);
  width: vwFn(3.69);
  transform: scale(-1);
  left: vwFn(32.8);
  top: vhFn(44.75);
}
