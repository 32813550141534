@use "../../common/styles/variables.scss" as *;

.orbiterStoryStartingPoint {
  position: absolute;
  background-size: 100% 100%;
  width: vwFn(100.5);
  height: vhFn(100);
  background-image: url(../../resources/assets/themes/lely/16x9/stories/astronautstory/firstmilkingslide.svg);
}

.orbiterStoryStartingPoint--textCaption {
  position: absolute;
  min-width: vwFn(34.6);
  min-height: vhFn(33.33);
  top: vhFn(29.072);
  left: vwFn(22.339);
  padding: vhFn(3.2) vwFn(1.48);
}

.orbiterStoryStartingPoint--robot {
  position: absolute;
  background-size: 100% 100%;
  background-image: url(../../resources/assets/themes/lely/16x9/actors/milkfactory/milk-factory.svg);
  width: vwFn(30.78);
  height: vhFn(66.66);
  left: vwFn(52.68);
  top: vhFn(23.2);
}

.orbiterStoryStartingPoint--title {
  font-family: $primaryFont;
  font-weight: $font-light;
  font-size: vhFn(4.27);
  line-height: vhFn(4.8);
  color: $color-gray-popup;
}

.orbiterStoryStartingPoint--info {
  font-family: $primaryFont;
  font-weight: $font-light;
  font-size: vhFn(4.27);
  line-height: vhFn(4.8);
  color: $color-gray-popup;
}

.orbiterStoryStartingPoint--name {
  font-family: $secondaryFont;
  font-weight: $font-bold;
  font-size: vhFn(12.8);
  line-height: vhFn(10.4);
  color: $color-gray-popup;
  margin: vhFn(1.87) 0;
  letter-spacing: vwFn(0.1);
}
.theme-adult {
  .orbiterStoryStartingPoint { 
    background-image: url(../../resources/assets/themes/lely/16x9/stories/astronautstory/astronautStoryFirstSlideAdult.svg);
  }
  .orbiterStoryStartingPoint--info {
    display: none;
  } 
  .orbiterStoryStartingPoint--textCaption {
    min-height: 0;
  } 
  .orbiterStoryStartingPoint--robot {
    background-image: url(../../resources/assets/themes/lely/16x9/actors/milkfactory/milkFactoryAdult.svg);
  }
}

.orbiterStoryStartingPoint--container {
  display: inline-flex;
  position: absolute;
}

@media only screen and (orientation: portrait) {
  .orbiterStoryStartingPoint--robot {
    left: vwFn(55.68);
    }
  .orbiterStoryStartingPoint--name {
    font-size: vhFn(11.8);
    }
}