@use "../../common/styles/variables.scss" as *;

.discoveryFeature {
  position: absolute;
  background-repeat: no-repeat;
  pointer-events: fill;
  width: vhFn(10);
  height: vhFn(8);
  background-image: url(../../resources/assets/themes/lely/16x9/actors/discovery/discoveryRobot.svg);
  //background-image: url(../../resources/assets/themes/lely/16x9/actors/discovery/discoveryRobotSprite.svg#moveLeftEdge);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  top: vhFn(70.7);
  left: vhFn(105.7);
  z-index: 11;
}
.discoveryFeature--collector {
  position: absolute;
  background-repeat: no-repeat;
  pointer-events: fill;
  width: vhFn(10);
  height: vhFn(8);
  background-image: url(../../resources/assets/themes/lely/16x9/actors/discovery/discoveryRobot.svg);
  //background-image: url(../../resources/assets/themes/lely/16x9/actors/discovery/discoveryRobotSprite.svg#moveLeftEdge);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  top: vhFn(70.7);
  left: vhFn(105.7);
  z-index: 11;
}

.discoveryFeature--title {
  display: none;
}

.discoveryFeature--bigger {
  width: vwFn(30);
  height: vhFn(65);
  background-size: cover;
}

.discoveryFeature--fixed {
  position: absolute;
  width: vwFn(16);
  height: vhFn(72);
  right: vwFn(55);
  top: vhFn(20);
}

.discoveryFeature.moveAlongLeftEdge {
  background-image: url(../../resources/assets/themes/lely/16x9/actors/discovery/discoveryRobot_moveLeftEdge.svg);
  //background-image: url(../../resources/assets/themes/lely/16x9/actors/discovery/discoveryRobotSprite.svg#moveLeftEdge);
}

.discoveryFeature.moveAlongBottomEdge {
  background-image: url(../../resources/assets/themes/lely/16x9/actors/discovery/discoveryRobot_moveBottomEdge.svg);
  //background-image: url(../../resources/assets/themes/lely/16x9/actors/discovery/discoveryRobotSprite.svg#moveBottomEdge);
}

.discoveryFeature.moveAlongRightEdge {
  background-image: url(../../resources/assets/themes/lely/16x9/actors/discovery/discoveryRobot_moveRightEdge.svg);
  //background-image: url(../../resources/assets/themes/lely/16x9/actors/discovery/discoveryRobotSprite.svg#moveRightEdge);
}

.discoveryFeature.moveAlongTopEdge {
  background-image: url(../../resources/assets/themes/lely/16x9/actors/discovery/discoveryRobot_moveTopEdge.svg);
  //background-image: url(../../resources/assets/themes/lely/16x9/actors/discovery/discoveryRobotSprite.svg#moveTopEdge);
}

.is-virtual-farm-tour-active .discoveryFeature {
  transition: 0s all;
}
