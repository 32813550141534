@use "../../common/styles/variables.scss" as *;

.birthdayPartyFlute {
  position: absolute;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  z-index: 9;
  width: vhFn(22);
  height: vhFn(31) !important;
  left: vwFn(64) !important;
  top: vhFn(58.6) !important;
}

.flow-container-Cow-slide-CowHistoryBirthdayCakeSlide .birthdayPartyFlute {
  left: vwFn(37) !important;
}

.birthdayPartyFlute-1 {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowhistory/background-layers/firstYear/partyFlutes/partyFlute_1.svg);
}

.birthdayPartyFlute-2 {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowhistory/background-layers/firstYear/partyFlutes/partyFlute_2.svg);
}

.birthdayPartyFlute-3 {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowhistory/background-layers/firstYear/partyFlutes/partyFlute_3.svg);
}

.cowPopup--hasNameBeenSelected .cowPopup--chosen-name-party-flute {
  animation: moveFluteWithMouthJersey 2s ease-in-out infinite;
}

.cowNameVoting--cowBlaarKop--one.cowPopup--hasNameBeenSelected
  .cowPopup--chosen-name-party-flute,
.cowNameVoting--cowBlaarKop--two.cowPopup--hasNameBeenSelected
  .cowPopup--chosen-name-party-flute,
.cowNameVoting--cowHolsteinFriesian--one.cowPopup--hasNameBeenSelected
  .cowPopup--chosen-name-party-flute,
.cowNameVoting--cowHolsteinFriesian--two.cowPopup--hasNameBeenSelected
  .cowPopup--chosen-name-party-flute,
.cowNameVoting--cowHolsteinFriesian--three.cowPopup--hasNameBeenSelected
  .cowPopup--chosen-name-party-flute,
.cowNameVoting--cowPride--one.cowPopup--hasNameBeenSelected
  .cowPopup--chosen-name-party-flute,
.cowNameVoting--cowLakenvelder--one.cowPopup--hasNameBeenSelected
  .cowPopup--chosen-name-party-flute,
.cowNameVoting--cowLakenvelder--two.cowPopup--hasNameBeenSelected
  .cowPopup--chosen-name-party-flute,
.cowNameVoting--cowMaasRijn--one.cowPopup--hasNameBeenSelected
  .cowPopup--chosen-name-party-flute {
  animation: moveFluteWithMouthHolstein 2s ease-in-out infinite;
}

@keyframes moveFluteWithMouthHolstein {
  0% {
    transform: translateX(50%) translateY(11%);
  }

  50% {
    transform: translateX(52%) translateY(13%) scaleY(1.001) rotate(1.5deg);
  }

  100% {
    transform: translateX(50%) translateY(11%);
  }
}

@keyframes moveFluteWithMouthJersey {
  0% {
    transform: translateX(50%) translateY(-7%);
  }

  50% {
    transform: translateX(52%) translateY(-5%) scaleY(1.001) rotate(1.5deg);
  }

  100% {
    transform: translateX(50%) translateY(-7%);
  }
}
