:root {
  --admin-image-preview-size-px: 120px;
}

.imagePreview {
  position: absolute;
  left: calc(100% - var(--admin-image-dropzone-size-px));
  width: var(--admin-image-preview-size-px);
  height: 100%;
}

.imagePreview--previewFrame {
  position: absolute;
  background-repeat: no-repeat;
  background-image: url(../../../resources/assets/themes/lely/16x9/adminpanel/imageDropZone/imageFrameOnly.svg);
  background-size: 100% 100%;
  width: var(--admin-image-preview-size-px);
  height: var(--admin-image-preview-size-px);
}

.imagePreview--previewImage {
  position: absolute;
  clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
  background-size: 100% 100%;
  width: calc(var(--admin-image-preview-size-px) - 17px);
  height: calc(var(--admin-image-preview-size-px) - 15px);
  background-position: center center;
  margin: 0 auto;
  left: 8px;
  top: 7px;
}


.imagePreview--btnRemoveImage {
  position: absolute;
  bottom: 0;
  width: var(--admin-image-preview-size-px);
  margin: 0 auto;
  cursor: pointer;

  text-align: center;
  font-family: Ubuntu;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  text-decoration-line: underline;
  color: #766e78;
}

.is-no-image-selected .imagePreview--previewImage {
  background-size: 100%;
}

.is-no-image-selected.catImagePreview .imagePreview--previewImage {
  background-image: url(../../../resources/assets/themes/lely/16x9/popups/default/defaultCat.svg);
}

.is-no-image-selected.smallDogImagePreview .imagePreview--previewImage {
  background-image: url(../../../resources/assets/themes/lely/16x9/popups/default/defaultSmallDog.svg);
}

.is-no-image-selected.mediumDogImagePreview .imagePreview--previewImage {
  background-image: url(../../../resources/assets/themes/lely/16x9/popups/default/defaultMediumDog.svg);
}

.is-no-image-selected.bigDogImagePreview .imagePreview--previewImage {
  background-image: url(../../../resources/assets/themes/lely/16x9/popups/default/defaultBigDog.svg);
}

.is-no-image-selected.horseImagePreview .imagePreview--previewImage {
  background-image: url(../../../resources/assets/themes/lely/16x9/popups/default/defaultHorse.svg);
}

.is-no-image-selected.ponyImagePreview .imagePreview--previewImage {
  background-image: url(../../../resources/assets/themes/lely/16x9/popups/default/defaultPony.svg);
}

.is-no-image-selected.llamaImagePreview .imagePreview--previewImage {
  background-image: url(../../../resources/assets/themes/lely/16x9/popups/default/defaultLama.svg);
}

.is-no-image-selected.alpacaImagePreview .imagePreview--previewImage {
  background-image: url(../../../resources/assets/themes/lely/16x9/popups/default/defaultAlpaca.svg);
}

.is-no-image-selected.donkeyImagePreview .imagePreview--previewImage {
  background-image: url(../../../resources/assets/themes/lely/16x9/popups/default/defaultDonkey.svg);
}

.is-no-image-selected.sheepImagePreview .imagePreview--previewImage {
  background-image: url(../../../resources/assets/themes/lely/16x9/popups/default/defaultSheep.svg);
}

.is-no-image-selected.goatImagePreview .imagePreview--previewImage {
  background-image: url(../../../resources/assets/themes/lely/16x9/popups/default/defaultGoat.svg);
}

.is-no-image-selected.chickenImagePreview .imagePreview--previewImage {
  background-image: url(../../../resources/assets/themes/lely/16x9/popups/default/defaultChicken.svg);
}

.is-no-image-selected.tractorImagePreview .imagePreview--previewImage {
  background-image: url(../../../resources/assets/themes/lely/16x9/popups/default/defaultTractor.svg);
}

.is-no-image-selected.farmShopImagePreview .imagePreview--previewImage {
  background-image: url(../../../resources/assets/themes/lely/16x9/popups/default/defaultShop.svg);
}

.is-no-image-selected.farmImagePreview .imagePreview--previewImage {
  background-image: url(../../../resources/assets/themes/lely/16x9/popups/default/defaultHouse.svg);
}

.is-no-image-selected.farmerImagePreview-Male .imagePreview--previewImage {
  background-image: url(../../../resources/assets/themes/lely/16x9/popups/default/maleFarmer.svg);
}

.is-no-image-selected.farmerImagePreview-Female .imagePreview--previewImage {
  background-image: url(../../../resources/assets/themes/lely/16x9/popups/default/femaleFarmer.svg);
}

.is-no-image-selected.storyImagePreview .imagePreview--previewImage {
  background-image: url(../../../resources/assets/themes/lely/16x9/adminpanel/imageDropZone/imageFrameNoImage.svg);
}
