@use "../../common/styles/variables.scss" as *;

.iceCreamFactory {
  position: absolute;
  background-repeat: no-repeat;
  pointer-events: fill;
  width: vhFn(26);
  height: vhFn(23);
  background-image: url(../../resources/assets/themes/lely/16x9/actors/icecreamfactory/ice-cream-factory.svg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  top: vhFn(19.396581);
  left: vhFn(137.2);
  z-index: 9;
}

.iceCreamFactory--title {
  display: none;
}

.iceCreamFactory--bigger {
  width: vwFn(30);
  height: vhFn(65);
  background-size: cover;
}

.iceCreamFactory--fixed {
  position: absolute;
  width: vwFn(16);
  height: vhFn(72);
  right: vwFn(55);
  top: vhFn(20);
}
