@use "../../common/styles/variables.scss" as *;

.lelyStoryLastSlide {
  position: absolute;
  background-size: 101% 100%;
  width: vwFn(101);
  left: vwFn(-0.1);
  height: vhFn(100);
}

.lelyStoryLastSlide--pole {
  position: absolute;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowdaily/graphics/pole.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: vwFn(1.2315);
  height: vhFn(37.7386381);
  left: vwFn(55.0751);
  top: vhFn(43.5133);
}

.lelyStoryLastSlide--aboutTheFarm {
  position: absolute;
  font-size: vhFn(4.8);
  line-height: vhFn(6.4);
  font-family: $secondaryFont;
  font-weight: 700;
  text-align: center;
  letter-spacing: vwFn(0.1);
  left: vwFn(18.826);
  top: vhFn(53.2853);
  max-width: vwFn(20);
}

.lelyStoryLastSlide--aboutLely {
  position: absolute;
  font-size: vhFn(4.8);
  line-height: vhFn(6.4);
  font-family: $secondaryFont;
  font-weight: 700;
  text-align: center;
  letter-spacing: vwFn(0.1);
  left: vwFn(18.826);
  top: vhFn(35.2853);
  max-width: vwFn(20);
}

.lelyStoryLastSlide--arrow {
  position: relative;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/vectorstory/robotstoryarrow.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: vwFn(4.926);
  height: vhFn(5.626);
  left: vwFn(20.45);
  top: vhFn(-11.283);
}

.lelyStoryLastSlide--arrowLely {
  position: relative;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/vectorstory/robotstoryarrow.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: vwFn(4.926);
  height: vhFn(5.626);
  left: vwFn(19.45);
  top: vhFn(-11.283);
}
