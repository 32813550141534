.AnimalTypeSelection .image-radio-selection--radio-item {
  flex-basis: 25%;
}

.AnimalTypeSelection
  .image-radio-selection--radio-item
  .MuiFormControlLabel-label {
  position: relative;
  top: 0px;
  width: 50px;
  white-space: nowrap;
}
.AnimalTypeSelection .image-radio-selection--radio-item .MuiButtonBase-root {
  top: 10px;
}
