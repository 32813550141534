@use "../../common/styles/variables.scss" as *;

.FarmsPage,
.help-screen,
.robot-details-container,
.container-farm-details,
.canbus-screen,
.fluids-screen {
  min-height: calc(100vh - 160px); // footer + header heights + some margin
}

footer {
  width: 100%;
  height: 40px;
  min-height: 40px;
  text-align: center;
  bottom: 0;
  color: $color-GrayDairyDarker;
  line-height: 52px;
  vertical-align: middle;

  a {
    display: inline-block;
    height: 100%;
    position: relative;
    top: 12px;
  }

  a.strypes-logo {
    top: 8px;
  }

  .vertical-separator {
    border: 1px solid $color-gray-light;
  }
}
