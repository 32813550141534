@use "../../common/styles/variables.scss" as *;

.onboarding-clouds-container {
  position: absolute;
  width: vwFn(100);
  height: 100%;
  //  background-color: #000;
  //  opacity: 0.2;
}

.onboarding-clouds-background {
  position: absolute;
  background: radial-gradient(
    circle,
    rgba(0, 0, 0, 1) 0%,
    rgba(255, 255, 255, 0) 51%
  );
  width: 100%;
  height: 100%;
  left: 25%;
  opacity: 0.4;
}

.onboarding-clouds-container.onboarding-clouds--dark {
  background-color: transparent;
  opacity: 1;
}

.onboarding-clouds--dark {
}

.onboarding-clouds-container .cloud {
  position: absolute;
  background-size: contain;
  background-repeat: no-repeat;
  transition: 10s;
}

.onboarding-clouds-container .cloud--small {
  width: vwFn(20);
  height: vhFn(20);
}

.onboarding-clouds-container .cloud--big {
  width: vwFn(35);
  height: vhFn(30);
  background-blend-mode: multiply;
  //background-color: #000;
}

.onboarding-clouds-container .cloud--dark {
  backdrop-filter: unset !important;
}

@keyframes cloudsAnimation {
  from {
    transform: translateX(0%);
  }

  to {
    transform: translateX(20%);
  }
}

@for $i from 1 through 19 {
  .onboarding-clouds-container .cloud-#{$i} {
    animation: cloudsAnimation 20s alternate infinite;
    animation-delay: random() + s;
  }
}

@for $i from 1 through 19 {
  .onboarding-clouds-container .cloud-#{$i}--grey {
    animation: cloudsAnimation 20s infinite;
    animation-delay: random() + s;
  }
}
