@use "../../common/styles/variables.scss" as *;

.orbiterStoryLastSlide {
  position: absolute;
  background-size: 101% 100%;
  width: vwFn(101);
  left: vwFn(-0.1);
  height: vhFn(100);
}

.orbiterStoryLastSlide--pole {
  position: absolute;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowdaily/graphics/pole.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: vwFn(1.2315);
  height: vhFn(22.381);
  left: vwFn(66.0751);
  top: vhFn(58.5133);
}

.orbiterStoryLastSlide--cow {
  position: absolute;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/orbiterstory/cowlastslide.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: vwFn(7.38);
  height: vhFn(8.8);
  left: vwFn(76.45);
  top: vhFn(71.97);
  z-index: 6;
}

.orbiterStoryLastSlide--house {
  position: absolute;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowhistory/background-layers/elements/house.svg);
  background-repeat: no-repeat;
  width: vwFn(25.23);
  height: vhFn(39.488);
  left: vwFn(80.57);
  top: vhFn(41.51);
  z-index: 5;
}

.orbiterStoryLastSlide--garden {
  position: absolute;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowhistory/background-layers/elements/fenceOutroSlide.svg);
  background-repeat: no-repeat;
  width: vwFn(40.448);
  height: vhFn(8.8);
  left: vwFn(70.57);
  top: vhFn(72.31);
  z-index: 7;
}

.orbiterStoryLastSlide--sign {
  position: absolute;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/vectorstory/vectorlastslidesign.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  min-width: vwFn(22.471);
  max-width: vwFn(30.5);
  min-height: vhFn(11.733);
  left: vwFn(55.45);
  top: vhFn(62.0283);
  padding: vhFn(2) vwFn(4.5);
}

.orbiterStoryLastSlide--text {
  width: 100%;
  font-size: vhFn(3.733);
  line-height: vhFn(6.4);
  font-family: $primaryFont;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
}

.orbiterStoryLastSlide--tree {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/background-trees/trees3.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  top: vhFn(45.452);
  left: vwFn(2.365);
  width: vwFn(38.834);
  height: vhFn(35.48);
}

.orbiterStoryLastSlide--aboutTheFarm {
  position: absolute;
  font-size: vhFn(4.8);
  line-height: vhFn(6.4);
  font-family: $secondaryFont;
  font-weight: 700;
  text-align: center;
  letter-spacing: vwFn(0.1);
  right: vwFn(49.826);
  top: vhFn(52.2853);
}

.orbiterStoryLastSlide--arrow {
  position: absolute;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/vectorstory/robotstoryarrow.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: vwFn(4.926);
  height: vhFn(5.626);
  left: vwFn(52.45);
  top: vhFn(53.7283);
}

.orbiterStoryLastSlide--bus {
  position: absolute;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/orbiterstory/orbiterbus.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: vwFn(15.134);
  height: vhFn(18.125);
  left: vwFn(10.45);
  top: vhFn(62.97);
}
.theme-adult .RobotStoryOrbiter6 .parallax-cow-robot-adults .background-layer--hills1  {
  visibility: hidden;
} 
.theme-adult .RobotStoryOrbiter6 .parallax-cow-robot-adults .background-layer--hills2  {
  visibility: hidden;
}  
// .theme-adult .RobotStoryOrbiter7 .parallax-cow-robot-adults .background-layer--hills1  {
//   opacity: 0;
// } 
// .theme-adult .RobotStoryOrbiter7 .parallax-cow-robot-adults .background-layer--hills2  {
//   opacity: 0;
// }  
.theme-adult .orbiterStoryLastSlide--bus {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/orbiterstory/orbiterBusAdult.svg); 
  left: vwFn(20.45);
}