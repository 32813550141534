@use "../../common/styles/variables.scss" as *;

.saveChangesConfirmationDialogContainer {
  width: vwFn(100);
  height: vhFn(100);
  position: fixed;
  background-color: rgba(18, 18, 18, 0.4);
  left: 0;
  top: 0;
  z-index: 999999999;
}
