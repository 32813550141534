@import url("https://fonts.googleapis.com/css?family=Ubuntu:300,400,500,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Amatic%20SC%3Aital%2Cwght%400%2C400");

body {
  margin: 0;
  padding: 0;
  font-family: Ubuntu, sans-serif !important;
}

html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

@font-face {
  font-family: BourtonBase;
  src: url("resources/assets/fonts/bourton/BourtonBase.otf") format("opentype");
}

@font-face {
  font-family: LumiosMarker;
  src: url("resources/assets/fonts/lumiosMarker/Lumios-Marker.otf")
    format("opentype");
}
