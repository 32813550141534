@use "../../styles/variables.scss" as *;

.shareButtonDialog {
  width: 410px;
  height: 480px;
  border-radius: 4px;
  margin: 0 auto;
  margin-top: calc(30vh - 186px / 2);
  box-shadow: 0px 2px 3px rgba(222, 219, 214, 0.4);
  background-color: #fff;
  font-family: $primaryFont;
}

.shareButtonDialog--header {
  position: relative;
  height: 62px;
  background-color: #c30a14;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 16px 20px;
  display: inline-flex;
  width: inherit;
  justify-content: left;
  gap: 20px;
  align-items: center;
}

.confirmationDialog--header h3 {
  font-size: 26px;
  line-height: 62px;
  vertical-align: middle;
  font-weight: 300;
  color: #fff;
  margin: 0;
  padding: 0;
  margin-left: 20px;
}

.shareButtonDialog--header button {
  position: absolute;
  top: 23px;
  right: 20px;
  background: transparent;
  border: 0;
  outline: 0;
  color: #fff;
  cursor: pointer;
}

.confirmationDialog--body {
  padding-left: 20px;
}

.confirmationDialog--body p {
  font-size: 16px;
  color: #121212;
}

.confirmationDialogContainer {
  width: vwFn(100);
  height: vhFn(100);
  position: fixed;
  background-color: rgba(18, 18, 18, 0.4);
  left: 0;
  top: 0;
  z-index: 999999999;
}
.shareButton--dialog {
  background-image: url(../../../resources/images/shareButtonIconDialog.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: inline-flex;
  width: 20px;
  height: 20px;
}
.shareButtonDialog--text {
  color: #fff;
  font-size: 26px;
}

.websiteURL--text {
  width: 360px;
  height: 18px;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: flex-end;
  color: #766e68;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  padding: 20px 0px 8px 16px;
}

.copyToClipboard--button {
  background-image: url(../../../resources/images/copyToClipboard.svg);
  width: 18px;
  height: 18px;
  background-color: white;
  border: none;
  flex: none;
  order: 1;
  flex-grow: 0;
  cursor: pointer;
  margin-left: 347px;
  top: -3vh;
  position: relative;
}

.website--text--box {
  margin-left: 15px;
  display: flow-root;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px 16px;
  width: 360px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #cfcbc3;
  box-shadow: inset 0px 2px 5px rgba(222, 219, 214, 0.4);
  border-radius: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  padding-right: 30px;
}

.websiteQRcode--text {
  width: 360px;
  height: 18px;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: flex-end;
  color: #766e68;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  padding: 30px 0px 8px 16px;
}

.QRcode--box {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 20px;
  gap: 10px;
  width: 202px;
  height: 202px;
  border: 1px solid #cbc6c5;
  border-radius: 4px;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin-left: 15px;
}

.sharePopup--footer--buttons {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 20px 15px 20px;
  gap: 50px;
  height: 72px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: #ffffff;
}

.previewWebsite--button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 11px 15px;
  gap: 5px;
  width: 158px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #cfcbc3;
  box-shadow: 0px 2px 3px rgba(222, 219, 214, 0.4);
  border-radius: 4px;
  flex: none;
  order: 0;
  flex-grow: 0;
  cursor: pointer;
}

.previewWebsite--image {
  background-image: url(../../../resources/images/previewWebsiteButton.svg);
  width: 18px;
  height: 18px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.previewWebsite--button--text {
  width: 105px;
  height: 16px;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #121212;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.downloadQRcode--button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 15px;
  width: 153px;
  height: 40px;
  background: #c30a14;
  box-shadow: 0px 2px 3px rgba(222, 219, 214, 0.4);
  border-radius: 4px;
  flex: none;
  order: 1;
  flex-grow: 0;
  border: none;
  cursor: pointer;
}

.downloadQRcode--button--text {
  width: 123px;
  height: 16px;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.copiedMessage--text {
  width: 98px;
  height: 14px;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;
  color: #339900;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.copiedMessage--mark {
  background-image: url(../../../resources/images/copiedMark.svg);
  order: 0;
  width: 8px;
  height: 10px;
  padding-right: 10px;
}

.copiedMessage--body {
  position: relative;
  top: -20px;
  gap: 4px;
  padding-left: 15px;
  display: inline-flex;
}
