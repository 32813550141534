@use "../../common/styles/variables.scss" as *;

.mijnMelkSecondSlide {
  position: absolute;
  background-size: 100% 100%;
  width: vwFn(101);
  height: vhFn(100);
  background-color: #f7f3f2;
  border: none;
}

.mijnMelkSecondSlide--textCaption {
  position: absolute;
  width: vwFn(55.41);
  min-height: vhFn(49.86);
  top: vhFn(16.893);
  left: vwFn(38.06);
  padding: vhFn(1.6) vwFn(1.48);
  z-index: 2;
}

.mijnMelkSecondSlide--title {
  font-family: $secondaryFont;
  font-style: $font-DefaultFontStyle;
  font-weight: $font-bold;
  font-size: vhFn(4.27);
  line-height: vhFn(4.8);
  letter-spacing: vwFn(0.1);
  color: $color-gray-popup;
}

.mijnMelkSecondSlide--text {
  font-family: $primaryFont;
  font-style: $font-DefaultFontStyle;
  font-size: vhFn(3.73);
  line-height: vhFn(6.4);
  color: $color-gray-popup;
  margin: vhFn(1.87) 0;
}

.mijnMelkSecondSlide--imgContainer {
  width: 100%;
  height: vhFn(57.77);
  border-width: vhFn(0.53) vwFn(0.246);
  border-style: solid;
  border-color: $color-GrayDairyDarker;
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/actors/mijnmelkactor/mijnMelkSecondSlide.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-color: $color-GrayDairyDarker;
  z-index: 10;
}

.mijnMelkSecondSlide--imgCaption {
  position: absolute;
  width: vwFn(29.85);
  height: vhFn(78.93);
  background-color: $color-LelyWhite;
  box-shadow: 0px vwFn(0.25) vwFn(0.7) rgba(18, 18, 18, 0.2);
  padding: vhFn(3.2) vwFn(1.48);
  transform: rotate(5.18deg);
  left: vwFn(6.5197);
  bottom: vhFn(8);
  z-index: 10;
}


.theme-adult .mijnMelkSecondSlide--title {
  font-family: "Chelsea Market" !important;
  font-weight: 400; 
  line-height: vhFn(6.4);
  font-size: vhFn(4.8);
}

.theme-adult .mijnMelkSecondSlide--imgCaption {
  border-color: #000000; 
  border: solid vhFn(1);
} 