@use "../../common/styles/variables.scss" as *;

.ribbonTitle--is-clickable {
  position: absolute;
  text-align: center;
  letter-spacing: vwFn(0.1);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  min-height: vhFn(12.333);
  display: block;
  background-image: url(../../resources/assets/themes/lely/16x9/ui/ribbon/ribbon.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  font-family: $secondaryFont;
  left: vwFn(40);
  top: vhFn(2.65);
  -webkit-transform: translate3d(0, 0, 1px);
  transform: translate3d(0, 0, 1px);
  padding: vhFn(2.5) vwFn(5);
  padding-bottom: vhFn(3.5);
  padding-top: vhFn(0);
}

.ribbonTitle--title {
  font-weight: bold;
  font-size: vhFn(5.5);
  line-height: vhFn(10);
  background-position: top;
  //line-height: vhFn(5.66);
  // color: $color-LelyWhite;
  // margin: 0;
  // text-align: center;
  font-family: $secondaryFont !important;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  // padding: vhFn(0.5);
  width: 100%;
  height: 100%;
  white-space: nowrap;
  text-align: center;
  color: #ffffff;
  display: inline-flex;
  color: white;
  font-weight: normal;
  //font-size: vhFn(3.73333);
  margin: 0;
  padding-top: vhFn(1);
}
.theme-adult .ribbonTitle--title {
  font-family: LumiosMarker !important;
}
 .theme-adult .ribbonTitle--title--text {
   font-family: LumiosMarker !important;
   font-weight: normal;
   position: relative;
   top: vhFn(-1)
//   top: vhFn(3);
//   top: vhFn(1);
//   padding: vhFn(0);
//   font-size: vhFn(5.5);
//   line-height: 7px;
//   margin-top: vhFn(2);
//   margin-bottom: vhFn(5);
//   padding-left: vwFn(1);
//   padding-right: vwFn(1);
 }

// 
@media only screen and (orientation: portrait) {
  .theme-adult .ribbonTitle--title--text {
    top: vhFn(0.7);
  }
}

@media only screen and (max-device-width: 600px) and (orientation: landscape) {
  .theme-adult .ribbonTitle--title--text {
  top:vhFn(1);
  }
}