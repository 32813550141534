@use "../../../common/styles/variables.scss" as *; 
.user-management-container {
  text-align: center;
  width: 100%;
  height: vhFn(100);
  background-color: #bf0011;
  background-repeat: no-repeat;
  background-position: top right;
  background-size: contain;
  background-image: url(../../../resources/assets/themes/lely/16x9/ui/usermanagement/farm_background.png);
  position: absolute;
  overflow-y: auto;
}

.user-management-container--lely-logo {
  position: absolute;
  top: 15vh;
  left: 10vw;
  width: 10vw;
  height: 10vw;
  background-image: url(../../../resources/assets/themes/lely/16x9/ui/usermanagement/lely_logo.png);
  background-repeat: no-repeat;
}


.user-management-container--current-section-container {
  position: absolute;
  top: 34vh;
  left: 10vw;
  padding-bottom: 10vh;
}

.user-management-container--welcome-text {
  position: absolute;
  color: #fff;
  top: 23vh;
  left: 10vw;
  font-size: 35px;
  font-family: "Roboto", "Ubuntu", sans-serif;
  font-weight: 400;
} 
.user-management-container .checkbox-group-selection--container {
  text-align: start;
}
.user-management-container .MuiFormControlLabel-root {
  display: inline; 
  padding-left: 0px;
  margin-left: 0px; 
} 
.user-management-container .checkbox-group-selection--form-control .MuiIconButton-root {
  margin-left: 0px;
} 
.user-management-container .checkbox-group-selection--form-control .MuiIconButton-root {
  padding-left: 0 !important;
} 
.user-management-container .MuiFormControl-root {
  display: block;
}