@font-face {
  font-family: "icons";
  src: url("../../resources/assets/fonts/icons.eot") format("embedded-opentype"),
    url("../../resources/assets/fonts/icons.woff2") format("woff2"),
    url("../../resources/assets/fonts/icons.woff") format("woff"),
    url("../../resources/assets/fonts/icons.ttf") format("truetype"),
    url("../../resources/assets/fonts/icons.svg") format("svg");
}

i[class^="lely-icon-"]:before,
i[class*=" lely-icon-"]:before {
  font-family: icons !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.lely-icon-abortion:before {
  content: "\f101";
}
.lely-icon-account:before {
  content: "\f102";
}
.lely-icon-activities:before {
  content: "\f103";
}
.lely-icon-add-file:before {
  content: "\f104";
}
.lely-icon-add-group:before {
  content: "\f105";
}
.lely-icon-add:before {
  content: "\f106";
}
.lely-icon-alarm:before {
  content: "\f107";
}
.lely-icon-apps:before {
  content: "\f108";
}
.lely-icon-archive:before {
  content: "\f109";
}
.lely-icon-astronaut:before {
  content: "\f10b";
}
.lely-icon-backup:before {
  content: "\f10c";
}
.lely-icon-barn:before {
  content: "\f10d";
}
.lely-icon-calendar:before {
  content: "\f10e";
}
.lely-icon-camera:before {
  content: "\f10f";
}
.lely-icon-card-view:before {
  content: "\f110";
}
.lely-icon-caret-down:before {
  content: "\f111";
}
.lely-icon-caret-left:before {
  content: "\f112";
}
.lely-icon-caret-right:before {
  content: "\f113";
}
.lely-icon-caret-up:before {
  content: "\f114";
}
.lely-icon-category:before {
  content: "\f115";
}
.lely-icon-chart:before {
  content: "\f116";
}
.lely-icon-check:before {
  content: "\f117";
}
.lely-icon-clock-interval:before {
  content: "\f118";
}
.lely-icon-clock:before {
  content: "\f119";
}
.lely-icon-close-large:before {
  content: "\f11a";
}
.lely-icon-close:before {
  content: "\f11b";
}
.lely-icon-comment:before {
  content: "\f11c";
}
.lely-icon-copy:before {
  content: "\f11d";
}
.lely-icon-cow:before {
  content: "\f11e";
}
.lely-icon-dashboard:before {
  content: "\f11f";
}
.lely-icon-desktop:before {
  content: "\f120";
}
.lely-icon-diagram:before {
  content: "\f121";
}
.lely-icon-disease:before {
  content: "\f122";
}
.lely-icon-document:before {
  content: "\f123";
}
.lely-icon-dots-horizontal:before {
  content: "\f124";
}
.lely-icon-dots:before {
  content: "\f125";
}
.lely-icon-drag:before {
  content: "\f126";
}
.lely-icon-dry-off:before {
  content: "\f127";
}
.lely-icon-edit:before {
  content: "\f128";
}
.lely-icon-emotions:before {
  content: "\f129";
}
.lely-icon-export:before {
  content: "\f12a";
}
.lely-icon-farm:before {
  content: "\f12b";
}
.lely-icon-feed:before {
  content: "\f12d";
}
.lely-icon-fence:before {
  content: "\f12e";
}
.lely-icon-fetch-cow:before {
  content: "\f12f";
}
.lely-icon-filter:before {
  content: "\f130";
}
.lely-icon-gear:before {
  content: "\f131";
}
.lely-icon-grabber:before {
  content: "\f132";
}
.lely-icon-graph-down:before {
  content: "\f133";
}
.lely-icon-graph-up:before {
  content: "\f134";
}
.lely-icon-graph-view:before {
  content: "\f135";
}
.lely-icon-graph:before {
  content: "\f136";
}
.lely-icon-grid-view:before {
  content: "\f137";
}
.lely-icon-group:before {
  content: "\f138";
}
.lely-icon-health-report:before {
  content: "\f139";
}
.lely-icon-health-treatment:before {
  content: "\f13a";
}
.lely-icon-heat:before {
  content: "\f13b";
}
.lely-icon-help:before {
  content: "\f13c";
}
.lely-icon-herd:before {
  content: "\f13d";
}
.lely-icon-hide:before {
  content: "\f13e";
}
.lely-icon-home:before {
  content: "\f13f";
}
.lely-icon-human:before {
  content: "\f140";
}
.lely-icon-income:before {
  content: "\f141";
}
.lely-icon-info:before {
  content: "\f142";
}
.lely-icon-insemination:before {
  content: "\f143";
}
.lely-icon-iris-small:before {
  content: "\f144";
}
.lely-icon-iris:before {
  content: "\f145";
}
.lely-icon-list:before {
  content: "\f146";
}
.lely-icon-locate:before {
  content: "\f147";
}
.lely-icon-location:before {
  content: "\f148";
}
.lely-icon-lock:before {
  content: "\f149";
}
.lely-icon-logout:before {
  content: "\f14a";
}
.lely-icon-m4use:before {
  content: "\f14b";
}
.lely-icon-manage:before {
  content: "\f14c";
}
.lely-icon-manure:before {
  content: "\f14d";
}
.lely-icon-medicine:before {
  content: "\f14e";
}
.lely-icon-menu:before {
  content: "\f14f";
}
.lely-icon-mfr-dosing-roller:before {
  content: "\f150";
}
.lely-icon-mfr-feed-door-close:before {
  content: "\f151";
}
.lely-icon-mfr-feed-door-open:before {
  content: "\f152";
}
.lely-icon-mfr-mixer-motor:before {
  content: "\f153";
}
.lely-icon-mfr:before {
  content: "\f154";
}
.lely-icon-microphone:before {
  content: "\f155";
}
.lely-icon-milk-separation:before {
  content: "\f156";
}
.lely-icon-milk:before {
  content: "\f157";
}
.lely-icon-minus:before {
  content: "\f158";
}
.lely-icon-mobile:before {
  content: "\f159";
}
.lely-icon-objects:before {
  content: "\f15a";
}
.lely-icon-ongoing:before {
  content: "\f15b";
}
.lely-icon-open-tab:before {
  content: "\f15c";
}
.lely-icon-opportunities:before {
  content: "\f15d";
}
.lely-icon-pains:before {
  content: "\f15e";
}
.lely-icon-pause:before {
  content: "\f15f";
}
.lely-icon-pending:before {
  content: "\f160";
}
.lely-icon-person-assign:before {
  content: "\f161";
}
.lely-icon-person-unassign:before {
  content: "\f162";
}
.lely-icon-person:before {
  content: "\f163";
}
.lely-icon-picture:before {
  content: "\f164";
}
.lely-icon-pin:before {
  content: "\f165";
}
.lely-icon-play:before {
  content: "\f166";
}
.lely-icon-plus:before {
  content: "\f167";
}
.lely-icon-power-management:before {
  content: "\f168";
}
.lely-icon-pregnancy:before {
  content: "\f169";
}
.lely-icon-print:before {
  content: "\f16a";
}
.lely-icon-refresh:before {
  content: "\f16b";
}
.lely-icon-reminder:before {
  content: "\f16c";
}
.lely-icon-reports:before {
  content: "\f16d";
}
.lely-icon-reproduction-check:before {
  content: "\f16e";
}
.lely-icon-reset:before {
  content: "\f16f";
}
.lely-icon-robot:before {
  content: "\f170";
}
.lely-icon-routing:before {
  content: "\f171";
}
.lely-icon-scatterplot:before {
  content: "\f172";
}
.lely-icon-score:before {
  content: "\f173";
}
.lely-icon-search:before {
  content: "\f174";
}
.lely-icon-send:before {
  content: "\f175";
}
.lely-icon-sensor:before {
  content: "\f176";
}
.lely-icon-shift:before {
  content: "\f177";
}
.lely-icon-show:before {
  content: "\f178";
}
.lely-icon-sires:before {
  content: "\f179";
}
.lely-icon-sort:before {
  content: "\f17a";
}
.lely-icon-star-filled:before {
  content: "\f17b";
}
.lely-icon-star:before {
  content: "\f17c";
}
.lely-icon-stop:before {
  content: "\f17d";
}
.lely-icon-table:before {
  content: "\f17e";
}
.lely-icon-tasks:before {
  content: "\f17f";
}
.lely-icon-thumb-down:before {
  content: "\f180";
}
.lely-icon-thumb-up:before {
  content: "\f181";
}
.lely-icon-transfer:before {
  content: "\f182";
}
.lely-icon-trash:before {
  content: "\f183";
}
.lely-icon-triangle:before {
  content: "\f184";
}
.lely-icon-upload:before {
  content: "\f185";
}
.lely-icon-valve:before {
  content: "\f186";
}
.lely-icon-vector:before {
  content: "\f187";
}
.lely-icon-version:before {
  content: "\f188";
}
.lely-icon-warning:before {
  content: "\f189";
}
.lely-icon-wifi-disconnect:before {
  content: "\f18a";
}
.lely-icon-young-stock:before {
  content: "\f18b";
}
