@use "../../common/styles/variables.scss" as *;

.birthdayPartyHat {
  position: absolute;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  z-index: 9;
  width: vhFn(22);
  height: vhFn(31) !important;
  left: vwFn(72) !important;
  top: vhFn(13) !important;
  transform: rotate(4.36deg);
}

.flow-container-Cow-slide-CowHistoryBirthdayCakeSlide .birthdayPartyHat {
  left: vwFn(44.5) !important;
}

.birthdayPartyHat-1 {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowhistory/background-layers/firstYear/partyHats/partyHat_1.svg);
}

.birthdayPartyHat-2 {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowhistory/background-layers/firstYear/partyHats/partyHat_2.svg);
}

.birthdayPartyHat-3 {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowhistory/background-layers/firstYear/partyHats/partyHat_3.svg);
}
