@use "../../common/styles/variables.scss" as *;

.dairyFactory {
  position: absolute;
  background-repeat: no-repeat;
  pointer-events: fill;
  width: vwFn(25);
  height: vhFn(30);
  background-image: url(../../resources/assets/themes/lely/16x9/actors/dairyfactory/dairy-robot-factory.svg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  top: vhFn(43);
  left: vhFn(138.25066);
  z-index: 9;
}

.dairyFactory--title {
  display: none;
}

.dairyFactory--bigger {
  width: vwFn(30);
  height: vhFn(65);
  background-size: cover;
}

.dairyFactory--fixed {
  position: absolute;
  width: vwFn(16);
  height: vhFn(72);
  right: vwFn(55);
  top: vhFn(20);
}
