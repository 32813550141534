@use "../../common/styles/variables.scss" as *;

// .shopPopup {
//   position: absolute;
//   max-height: vhFn(82);
//   top: vhFn(13.5);
//   left: vwFn(10.5);
//   min-width: vwFn(62);
//   border-left: vwFn(0.12) solid $color-GrayDairyLight;
//   border-right: vwFn(0.12) solid $color-GrayDairyLight;
//   border-top: vhFn(0.27) solid $color-GrayDairyLight;
//   border-bottom: vhFn(0.27) solid $color-GrayDairyLight;
//   box-sizing: border-box;
//   box-shadow: 0px vhFn(0.52) vwFn(0.65) rgba(222, 219, 214, 0.4);
//   margin-bottom: vhFn(9.6);
//   background-color: $color-LelyWhite;
//   border-radius: vwFn(0.5) vwFn(0.5) vwFn(0.5) vwFn(0.5);
//   z-index: 1;
//   color: $color-gray-popup;
// }

// .shopPopup--title {
//   width: 100%;
//   font-family: $secondaryFont;
//   letter-spacing: vwFn(0.25);
//   color: $color-LelyBlack;
//   font-weight: bold;
//   font-size: vwFn(3);
//   line-height: vhFn(9.33);
//   margin-top: 0 !important;
//   padding-top: vhFn(4);
//   margin-bottom: 0;
//   padding-left: vwFn(2.5);
// }

.shopPopup--description {
  display: flex;
  //padding-left: vwFn(2.5);

  .shopPopup--label {
    font-size: vhFn(4);
    line-height: vhFn(6.4);
    font-weight: bold;
    min-width: vwFn(20);
    padding-top: vhFn(1);
    padding-bottom: vhFn(1);
  }

  .shopPopup--hours {
    margin-right: vwFn(2.5);
  }

  .shopPopup--text {
    padding-left: vwFn(0.1);
    padding-bottom: vhFn(1);
    line-height: vhFn(3.18);
    font-size: vhFn(4);

    & > svg {
      margin-right: vwFn(0.5);
    }

    &:not(:first-child) svg {
      margin-left: vwFn(1);
    }

    .shopPopup--day {
      min-width: vwFn(10);
      margin-right: vwFn(3);
    }
  }
}

.shopPopup--noImage {
  width: vhFn(68);
  height: vhFn(77);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -webkit-clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
  clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
  object-fit: cover;
  z-index: 10;
  margin-left: vhFn(4);
  background-image: url(../../resources/assets/themes/lely/16x9/popups/default/defaultShop.svg);
}

.shopPopup--frame {
  // position: absolute;
  // top: vhFn(0.7);
  // left: vwFn(0.25);
  // width: vhFn(73.35);
  // height: vhFn(73.35);
  // background-repeat: no-repeat;
  // background-size: 100% 100%;
  background-image: url(../../resources/assets/themes/lely/16x9/actors/actorFrame/actorFrame.svg);
}
// .theme-adult .shopPopup--frame {
//   background-image: url(../../resources/assets/themes/lely/16x9/actors/actorFrame/actorFrameAdult.svg);
//   z-index: 10;
// }
.shopPopup--action-buttons {
  margin-top: vhFn(4);
  display: inline-flex;
  margin-bottom: vhFn(1);

  .shopPopup--btnCallToAction {
    width: vwFn(15.5);
    height: vhFn(5.5);
    padding-left: vwFn(1.8);
    margin-left: vwFn(2.5);
    background-color: $color-LelyRed;
    background-image: url(../../resources/assets/themes/lely/16x9/ui/icons/route-icon.svg);
    background-repeat: no-repeat;
    background-size: 50% 50%;
    background-position: -5% center;
    box-shadow: 0px 2px 3px rgba(222, 219, 214, 0.4);
    border-radius: 4px;
    font-size: vhFn(2);
    color: $color-LelyWhite;

    &.shopPopup--btnCallToAction-disabled {
      background-color: $color-disabledButton;
    }
  }

  .shopPopup--btnCallToActionSecondary {
    width: vwFn(20);
    height: vhFn(5.5);
    padding-left: vwFn(1.8);
    margin-left: vwFn(0.5);
    background-color: $color-LelyWhite;
    background-image: url(../../resources/assets/themes/lely/16x9/ui/icons/webshop-icon.svg);
    background-repeat: no-repeat;
    background-size: 50% 50%;
    background-position: -15% center;
    box-shadow: 0px 2px 3px rgba(222, 219, 214, 0.4);
    border: 1px solid $color-gray-light;
    border-radius: 4px;
    font-size: vhFn(2);
    color: $color-gray-popup;

    &.shopPopup--btnCallToAction-disabled {
      opacity: 0.5;
    }
  }
}

// .shopPopup--btnClosePopup {
//   position: absolute;
//   top: vhFn(-2);
//   left: vwFn(44);
//   background-image: url(../../resources/assets/themes/lely/16x9/ui/popup/close-popup-button.svg);
//   background-repeat: no-repeat;
//   background-size: 100% 100%;
//   width: vwFn(8.5);
//   height: vhFn(11.5);
// }

.flex-row {
  display: flex;
}
.shopPopup--action-buttons {
  display: inline-flex;
  gap: vwFn(3);
}
