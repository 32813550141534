@use "../common/styles/variables.scss" as *;

.theme-mijnmelkgeneric {
  .vehicle--tractor {
    background-image: url(../resources/assets/themes/mijnmelk/16x9/actors/tractor/green_tractor.svg);
  }

  .virtualFarm {
    background-image: url(../resources/assets/themes/mijnmelk/16x9/screens/virtualFarm/background_farm_overview_812x375.svg);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: absolute;
    width: vwFn(100);
    height: vhFn(100);
  }

  .mijnMelk {
    position: fixed;
    background-repeat: no-repeat;
    pointer-events: fill;
    width: vhFn(16);
    height: vhFn(15);
    top: vhFn(4);
    left: vhFn(15);
    background-size: 100% 100%;
    background-image: url(../resources/assets/themes/mijnmelk/16x9/actors/mijnmelkactor/mijnmelkactor.svg);
    cursor: pointer;
  }

  .brand {
    display: none;
  }

  .ribbonTitle--is-clickable {
    background-image: url(../resources/assets/themes/mijnmelk/16x9/ui/ribbon/ribbon.svg);
  }
}
