@use "../../../common/styles/variables.scss" as *;

.CowGaveBirthThirdSlide {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  width: vwFn(100);
  height: vhFn(100);
}

.textCaption--CowGaveBirthThirdSlide {
  position: absolute;
  min-height: vhFn(32.1);
  width: vwFn(30.8);
  left: vwFn(20.5);
  top: vhFn(34);
  padding: vhFn(1.5);
  border: 1px solid #efedea;
  box-sizing: border-box;
  box-shadow: 0px 2px 5px rgba(18, 18, 18, 0.2);
  border-radius: 4px;
  align-items: center;
  justify-content: center;
}

.cow-CowGaveBirthThirdSlide--text {
  position: static;
  width: vwFn(27.83);
  height: vhFn(25.6);
  left: vwFn(1.5);
  top: vhFn(3.2);
  font-family: Ubuntu;
  font-weight: normal;
  line-height: vhFn(24);
  margin: vhFn(2) 0px;
  font-size: vhFn(3.5);
  line-height: vhFn(6.4);
  font-weight: normal;
}

.cow-CowGaveBirthThirdSlide--calfInfo {
  font-family: $secondaryFont;
  font-style: normal;
  font-weight: bold;
  text-align: center;
  font-size: vhFn(4.5);
  line-height: vhFn(6.4);
  color: #766e68;
  width: vwFn(41.87);
  min-height: vhFn(6.4);
  left: calc(50% - var(--vw, 1vw) * 41.87 / 2 - var(--vw, 1vw) * 2.981);
  top: calc(50% - var(--vh, 1vh) * 6.4 / 2 - 0px);
  background-color: whitesmoke;
  border: 1px solid #efedea;
  box-sizing: border-box;
  box-shadow: 0px vwFn(1) vhFn(2) rgba(18, 18, 18, 0.2);
  border-radius: 4px;
  position: static;
  margin: 0px vhFn(2);
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 8px;
  letter-spacing: vwFn(0.1);
}

.cow-CowGaveBirthThirdSlide--calfInfoTextBlock {
  width: vwFn(46);
  height: vhFn(7.682);
  position: absolute;
  left: vwFn(11.62);
  top: vhFn(75.22);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.cow-CowGaveBirthThirdSlide--arrow {
  position: absolute;
  background-image: url(../../../resources/assets/themes/lely/16x9/ui/arrows/arrow-up-curve.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: vwFn(5.92);
  height: vhFn(7.226);
  left: vwFn(43);
  bottom: vhFn(3.7);
}
