@use "../../common/styles/variables.scss" as *;

.arrowPopup--container {
  position: absolute;
  top: vhFn(31);
  left: vwFn(16.5);
  width: vwFn(70);
  min-height: vhFn(30);
}

.arrowPopup {
  position: absolute;
  width: vwFn(33.99);
  min-height: vhFn(31.733);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border: 1px solid #efedea;
  box-sizing: border-box;
  box-shadow: 0px vwFn(0.25) vwFn(0.7) rgba(18, 18, 18, 0.2);
  border-radius: vwFn(0.5);
  background: #ffffff;
  padding: vhFn(3.25);
}

.arrowPopup--background {
  position: absolute;
  top: 0;
}

.arrowPopup--textElement {
  width: 100%;
  text-align: center;
}

.arrowPopup--title {
  font-size: vhFn(6.4);
  font-family: $secondaryFont;
  margin: 0 !important;
  padding: 0 !important;
  position: relative;
}

.arrowPopup--description {
  font-size: vhFn(3.73);
  font-family: $primaryFont;
  margin-top: vhFn(1.8666) !important;
}

.arrowPopup--textArea {
  position: absolute;
  width: vwFn(33.99);
  left: 35%;
  top: vhFn(22.89);
}

.btn-side-right-arrow {
  background-image: url("../../resources/assets/themes/lely/16x9/ui/buttons/button-with-arrow-up.svg");
  background-size: 100% 100%;
  top: vhFn(3);
  height: vhFn(11.46);
  width: vwFn(6.157);
  position: absolute;
  right: vwFn(-5);
  transform: rotate(90deg);
}

.btn-top-close {
  background-image: url("../../resources/assets/themes/lely/16x9/ui/popup/close-popup-button.svg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: vwFn(4.92);
  height: vhFn(9.6);
  position: absolute;
  top: vhFn(-1.5);
  left: vwFn(50);
}

.arrowPopup--imageContainer {
  position: absolute;
  width: vwFn(20.443);
  height: vhFn(53.8666);
  left: vwFn(14);
  top: vhFn(25.992);
  background-color: $color-LelyWhite;
  box-shadow: 0px vwFn(0.25) vwFn(0.7) rgba(18, 18, 18, 0.2);
  padding: vhFn(3.2) vwFn(1.48);
  transform: rotate(-15deg);
}

.arrowPopup--image {
  width: 100%;
  height: vhFn(40);
  border-width: vhFn(1);
  border-style: solid;
  border-color: black;
}

.farmerStoryDynamicSlide--imgText {
  font-family: $secondaryFont;
  font-style: $font-DefaultFontStyle;
  font-weight: $font-regular;
  font-size: vhFn(3.73);
  line-height: vhFn(6.4);
  text-align: center;
}
// .is-virtual-farm-tour-active.virtual-farm-tour-slide-1 .arrowPopup--textArea {
//   position: absolute;
//   width: vwFn(33.990);
//   min-height: vhFn(17.066);
//   padding: vwFn(2.46);
//   width: vwFn(25.64);
//   height: vhFn(2.733) !important;
//   top: vhFn(4.2186);
//   left: vwFn(21.5);
// }
/** TJOM-941 changes/ 
**/
.theme-adult .virtual-farm-tour--slide0 .arrowPopup--title {
  font-family: "BourtonBase";
  font-size: vhFn(4.8);
}
.theme-adult .virtual-farm-tour--slide1 .arrowPopup--description,
.theme-adult .virtual-farm-tour--slide2 .arrowPopup--description {
  width: vwFn(5);
  height: vhFn(5);
  background-size: 100% 100%;
  padding: 0;
  margin: 0 !important;
  margin-right: 0;
  left: vwFn(3);
}
.virtual-farm-tour--slide1 .arrowPopup--title,
.virtual-farm-tour--slide2 .arrowPopup--title {
  font-size: vhFn(3.733);
  font-family: Roboto, Ubuntu, sans-serif;
  font-weight: 400;
}

.virtual-farm-tour--slide3 .arrowPopup--title {
  text-align: center;
}
.virtual-farm-tour--slide1 .arrowPopup--imageContainer,
.virtual-farm-tour--slide2 .arrowPopup--imageContainer {
  display: none;
}

.arrowPopup--gestureIcon {
  background-repeat: no-repeat;
  width: vwFn(6.896);
  height: vhFn(16);
  background-position: center center;
  width: 100%;
}

.arrowPopup--pinchZoomIcon {
  background-image: url(../../resources/assets/themes/lely/16x9/ui/icons/mobile_pinch.svg);
}

.arrowPopup--scrollZoomIcon {
  background-image: url(../../resources/assets/themes/lely/16x9/ui/icons/desktop_scroll.svg);
}

.arrowPopup--swipeIcon {
  background-image: url(../../resources/assets/themes/lely/16x9/ui/icons/mobile_drag_and_swipe.svg);
}

.arrowPopup--clickAndDragIcon {
  background-image: url(../../resources/assets/themes/lely/16x9/ui/icons/desktop_click_and_drag.svg);
}
.theme-adult  {
  .virtual-farm-tour--slide3 .arrowPopup--title {
    padding-right: 0 !important;
} .is-virtual-farm-tour-active .arrowPopup--description {
  margin-top: vhFn(2) !important; 
  margin-bottom: 0 !important;
}.virtual-farm-tour--slide3 .arrowPopup {
  min-height: vhFn(20);
}
}