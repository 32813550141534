.optionButtonsSelect {
  flex-direction: row;
  display: flex;
  align-items: center;
  width: 43.103vw;
}

.optionButtonsSelect--option {
  height: 8vh;
  //min-width: 14.3682vw;
  border-radius: 0px;
  border: 1px solid #cfcbc3;
  background-color: #fff;
  box-shadow: 0px 2px 3px rgba(222, 219, 214, 0.4);
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  font-size: calc(var(--vh, 1vh) * 3.733);
  line-height: calc(var(--vh, 1vh) * 3.73);
  text-align: center;
  color: #121212;
  padding-top: 7px;
  flex-basis: 33%;
  transition: all 0.3s ease-in 0s;
}

.optionButtonsSelect--option.is-selected {
  background-color: #fabe1f;
}

.optionButtonsSelect--option:last-of-type {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.optionButtonsSelect--option:first-of-type {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.optionButtonsSelect--option {
  position: relative;
}

.optionButtonsSelect--option--optionNamePart {
  position: relative;
  width: 100%;
  text-align: center;
  display: inline-block;
}

.optionButtonsSelect--arePercentagesDisplayed .optionButtonsSelect--option--optionNamePart {
  top: -15px;
}

.optionButtonsSelect--option--percentagePart {
  display: inline-block;
  position: absolute;
  width: 100%;
  left: 0;
  top: 24px;
  text-align: center;
  color: #000;
  opacity: 0.7;
}

.optionButtonsSelect--option.is-selected .optionButtonsSelect--option--percentagePart {}