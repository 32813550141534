@use "../../../common/styles/admin_variables.scss" as adminVar;

.CowsPage {
  margin-left: adminVar.$pagesLeftOffset;
  margin-top: adminVar.$pagesMarginTop;
  height: 90vh;
  overflow: auto;
}
.cows--header {
  font-weight: 400;
  font-size: 26px;
}
.cows--intro {
  font-size: 14px;
}
.cows--textField {
  margin-top: 10px;
  margin-bottom: 25px;
}
.cow-hide-preview--large {
  position: absolute;
  right: 2vw;
  top: 30vh;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  pointer-events: none;
}

.cow-hide-preview--large--white {
  background-image: url(../../../resources/assets/themes/lely/16x9/adminpanel/cowPreview/cow-preview-white.svg);
}

.cow-hide-preview--large--blackstryped {
  background-image: url(../../../resources/assets/themes/lely/16x9/adminpanel/cowPreview/cow-preview-black-stryped.svg);
}

.cow-hide-preview--large--whiteBlack {
  background-image: url(../../../resources/assets/themes/lely/16x9/adminpanel/cowPreview/cow-preview-black-whiteBlack.svg);
}

.cow-hide-preview--large--brown {
  background-image: url(../../../resources/assets/themes/lely/16x9/adminpanel/cowPreview/cow-preview-brown.svg);
}
.cow-hide-preview--large--pride {
  background-image: url(../../../resources/assets/themes/lely/16x9/adminpanel/cowPreview/cow-preview-pride.svg);
}
.cow-hide-preview--small {
  position: absolute;
  right: 6vw;
  top: 15vh;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  pointer-events: none;
}

.save-button-style-cows--page {
  margin-top: 0vh !important;
  margin-bottom: 5vh;
  min-height: 4vh;
  position: center;
  background-color: #73bd07 !important;
  padding: 6px 16px;
  box-sizing: border-box;
}
.cows--horns {
  margin: 10px 0;
}
.cows--grazing {
  margin-top: 10px;
}
.cows--grazing--box {
  background-color: #ffffff;
  margin: 1.25vw 0.5vh;
  width: 33vw;
  padding: 1vw;
  border-radius: 0.5vw 0.5vw 0.5vw 0.5vw;
  border: 1px solid #cfcbc3;
}
.checkbox-group-selection--form-control {
  height: 128px;
  width: 100px;
  background-size: auto 100px;
  background-position-y: 5px;
  background-repeat: no-repeat;
  align-items: flex-end !important;
  margin-left: 10px !important;
  flex: 25% 1;
}
.cow--breed--container {
  .MuiFormControlLabel-label {
    display: none;
  }
}

.checkbox-group-selection--form-control-cowHolsteinFriesian--one,
.cowHolsteinFriesian--one {
  background-image: url(../../../resources/assets/themes/lely/16x9/adminpanel/cowBreed/cow-holstein-friesian-one.svg);
  background-repeat: no-repeat;
}
.checkbox-group-selection--form-control-cowHolsteinFriesian--two,
.cowHolsteinFriesian--two {
  background-image: url(../../../resources/assets/themes/lely/16x9/adminpanel/cowBreed/cow-holstein-friesian-two.svg);
  background-repeat: no-repeat;
}
.checkbox-group-selection--form-control-cowHolsteinFriesian--three,
.cowHolsteinFriesian--three {
  background-image: url(../../../resources/assets/themes/lely/16x9/adminpanel/cowBreed/cow-holstein-friesian-three.svg);
  background-repeat: no-repeat;
}
.checkbox-group-selection--form-control-cowMaasRijn--one,
.cowMaasRijn--one {
  background-image: url(../../../resources/assets/themes/lely/16x9/adminpanel/cowBreed/cow-maas-rijn.svg);
  background-repeat: no-repeat;
}
.checkbox-group-selection--form-control-cowLakenvelder--one,
.cowLakenvelder--one {
  background-image: url(../../../resources/assets/themes/lely/16x9/adminpanel/cowBreed/cow-lakenvelder-one.svg);
  background-repeat: no-repeat;
}
.checkbox-group-selection--form-control-cowLakenvelder--two,
.cowLakenvelder--two {
  background-image: url(../../../resources/assets/themes/lely/16x9/adminpanel/cowBreed/cow-lakenvelder-two.svg);
  background-repeat: no-repeat;
}
.checkbox-group-selection--form-control-cowBlaarKop--one,
.cowBlaarKop--one {
  background-image: url(../../../resources/assets/themes/lely/16x9/adminpanel/cowBreed/cow-blaarkop-one.svg);
  background-repeat: no-repeat;
}
.checkbox-group-selection--form-control-cowBlaarKop--two,
.cowBlaarKop--two {
  background-image: url(../../../resources/assets/themes/lely/16x9/adminpanel/cowBreed/cow-blaarkop-two.svg);
  background-repeat: no-repeat;
}
.checkbox-group-selection--form-control-cowJersey--one,
.cowJersey--one {
  background-image: url(../../../resources/assets/themes/lely/16x9/adminpanel/cowBreed/cow-jersey-one.svg);
  background-repeat: no-repeat;
}
.checkbox-group-selection--form-control-cowJersey--two,
.cowJersey--two {
  background-image: url(../../../resources/assets/themes/lely/16x9/adminpanel/cowBreed/cow-jersey-two.svg);
  background-repeat: no-repeat;
}
.checkbox-group-selection--form-control-cowPride--one,
.cowPride--one {
  background-image: url(../../../resources/assets/themes/lely/16x9/adminpanel/cowBreed/cow-pride-one.svg);
  background-repeat: no-repeat;
}
.checkbox-group-selection--form-control .MuiIconButton-root {
  padding-left: 2vw;
}
.cow--breed--container {
  background-color: #ffffff;
  margin: 1.25vw 0.5vh;
  width: 33vw;
  padding: 1vw;
  border-radius: 0.5vw 0.5vw 0.5vw 0.5vw;
  border: 1px solid #cfcbc3;
}
.cows--grazing--box .custom-datepicker {
  width: 12vw;
}
.cows--grazing--box .MuiGrid-container {
  width: 12vw;
}
.cows--grazing--box .data-container {
  width: 12vw;
}
.cows--grazing--box .labelled-text-input--textInput {
  padding-left: 0.5vw;
}
.CowsPage .btn-save-changes {
  margin-left: 450px !important;
  width: 170px;
}
.CowsPage .btn-already-saved {
  margin-left: 420px !important;
  width: 270px;
  margin-top: 20px !important;
}
