.multiLanguageTextArea .labelled-text-area--label {
  position: absolute;
  top: 0;
  color: #766e68;
  font-size: 12px;
  padding-top: 1px;
  padding-left: 5px;
}

.multiLanguageTextArea .multiLanguageTextArea--label {
  display: block;
  font-size: 16px;
  padding-bottom: 2vh;
  padding-top: 2vh;
}

.multiLanguageTextArea .labelled-text-area--textArea {
  width: 100%;
}

.multiLanguageTextArea .labelled-text-area {
  position: relative;
  width: 48%;
  margin-right: 2%;
}

.multiLanguageTextArea textarea {
  padding-top: 20px;
  padding-left: 5px;
  resize: none;
}

.multiLanguageTextArea .labelled-text-area--charactersLeft {
  display: block;
  color: #766e68;
  position: absolute;
  right: 10px;
  bottom: 10px;
  font-size: 10px;
}
