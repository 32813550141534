@use "../../common/styles/variables.scss" as *;

.react-transform-component,
.react-transform-wrapper {
  width: vwFn(100) !important;
  height: vhFn(100) !important;
}

.pinchview--zoomGraphic {
  position: absolute;
  right: vwFn(5);
  bottom: vhFn(5);
  width: vwFn(5);
  height: vhFn(20);
}

.pinchview--zoomGraphic--in {
  background-image: url(../../resources/assets/themes/lely/16x9/ui/icons/zoom-in-icon.svg);
}

.pinchview--zoomGraphic--out {
  background-image: url(../../resources/assets/themes/lely/16x9/ui/icons/zoom-out-icon.svg);
}
