@import "../../common/styles/variables.scss";

.astronautTimeCleanedAndMoved {
  position: absolute;
  background-size: 100% 100%;
  width: vwFn(100);
  height: vhFn(100);
  background-image: url(../../resources/assets/themes/lely/16x9/stories/astronautLiveDataStory/astronautLiveDataBackgroundWithTree.svg);
  border: none;
}

.astronautTimeCleanedAndMoved--textCaption {
  position: absolute;
  left: vwFn(5.0492);
  top: vhFn(44.08);
  width: vwFn(30.7881);
  min-height: vhFn(0);
  background-color: #ffffff;
  box-sizing: border-box;
  padding: vhFn(3.2) vwFn(1.48);
  z-index: 4;
  border-radius: vwFn(0.5);
}
.astronautTimeCleanedAndMoved--text {
  font-family: $primaryFont;
  font-weight: 400;
  font-size: vhFn(3.73);
  line-height: vhFn(6.4);
  color: $color-gray-popup;
}
.astronautTimeCleanedAndMoved--whiteTextCaptionText {
  font-family: $primaryFont;
  font-weight: 400;
  font-size: vhFn(3.73);
  line-height: vhFn(6.4);
  color: $color-gray-popup;
}
.astronautTimeCleanedAndMoved--whiteTextCaption {
  position: absolute;
  left: vwFn(49.0147);
  top: vhFn(40.48);
  width: vwFn(30.7881);
  min-height: vhFn(0);
  // border-color: #efedea;
  background-color: #ffffff;
  //border: 1px solid;
  box-sizing: border-box;
  padding: vhFn(3.2) vwFn(1.48);
  z-index: 4;
  border-radius: vwFn(0.5);
}
.astronautTimeCleanedAndMoved--arrowConnectingTextBoxes {
  position: absolute;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/astronautLiveDataStory/arrowBetweenSlides.svg);
  width: vwFn(13.25);
  height: vhFn(1.75);
  top: vhFn(49.128);
  left: vwFn(36.007);
  z-index: 2;
}
.astronautTimeCleanedAndMoved--transitionTree {
  position: absolute;
  background-size: 100% 100%;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/astronautLiveDataStory/transitionTree.svg);
  width: vwFn(50);
  height: vhFn(124.61);
  left: vwFn(68);
  top: vhFn(-18.37);
  z-index: 6;
}
.theme-adult {
  .astronautTimeCleanedAndMoved--transitionTree {
    position: absolute;
    background-size: 100% 100%;
    width: vwFn(70);
    left: vwFn(60);
    height: vhFn(120);
    background-image: url(../../resources/assets/themes/lely/16x9/stories/cowDailyForAdult/backgrounds/eating/full_tree.png);
    display: block;
    z-index: 1;
  } 
  .astronautTimeCleanedAndMoved {
    background-image: url(../../resources/assets/themes/lely/16x9/stories/astronautstory/milkingRobotStorySecondSlide.svg);
  } 
  .astronautTimeCleanedAndMoved--arrowConnectingTextBoxes {
    background-image: url(../../resources/assets/themes/lely/16x9/stories/astronautLiveDataStory/astronautLiveDataStoryAdultArrow.svg);
  }
  .astronautTimeMilked--arrowConnectingSlides {
    background-image: url(../../resources/assets/themes/lely/16x9/stories/astronautLiveDataStory/astronautLiveDataStoryAdultArrow.svg);
  }
} 