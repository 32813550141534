@use "../../common/styles/variables.scss" as *;

// .grazewayPopup {
//   position: absolute;
//   top: vhFn(26.13);
//   left: vwFn(5.66);
//   background-color: $color-LelyWhite;
//   width: vwFn(67.73);
//   min-height: vhFn(0);
//   border-left: vwFn(0.12) solid $color-GrayDairyLight;
//   border-right: vwFn(0.12) solid $color-GrayDairyLight;
//   border-top: vhFn(0.27) solid $color-GrayDairyLight;
//   box-sizing: border-box;
//   box-shadow: 0px 2px 5px rgba(222, 219, 214, 0.4);
//   border-radius: vwFn(0.5) vwFn(0.5) vwFn(0.5) vwFn(0.5);
//   padding: vhFn(4) vwFn(2.46);
// }

// .grazewayPopup--title {
//   width: vwFn(43.1);
//   font-family: $secondaryFont;
//   font-weight: $font-bold;
//   font-style: $font-DefaultFontStyle;
//   color: $color-gray-popup;
//   font-size: vhFn(6.4);
//   letter-spacing: vwFn(0.25);
//   line-height: vhFn(9.33);
//   margin-bottom: vhFn(1.87);
// }

// .grazewayPopup--description {
//   width: vwFn(43.1);
//   font-family: $primaryFont;
//   font-weight: $font-regular;
//   font-style: $font-DefaultFontStyle;
//   color: $color-gray-popup;
//   min-height: vhFn(0);
//   font-size: vhFn(3.73);
//   line-height: vhFn(6.4);
//   margin-bottom: vhFn(3.5);
//   margin-top: vhFn(1);
// }

.grazewayPopup--btnCallToAction {
  background-color: #e9a7aa;
  background-repeat: no-repeat;
  border-radius: vwFn(0.5) vwFn(0.5) vwFn(0.5) vwFn(0.5);
  border-left: vwFn(0.12) solid $color-GrayDairyLight;
  border-right: vwFn(0.12) solid $color-GrayDairyLight;
  border-top: vhFn(0.27) solid $color-GrayDairyLight;
  border-bottom: vhFn(0.27) solid $color-GrayDairyLight;
  background-size: 100%;
  min-width: vwFn(0.18);
  height: vhFn(8.44);
  font-size: vwFn(1.5);
  color: $color-LelyWhite; 
  padding: vhFn(2) vwFn(2);
}

// .grazewayPopup--btnClosePopup {
//   position: absolute;
//   top: vhFn(-2);
//   left: vwFn(50);
//   background-image: url(../../resources/assets/themes/lely/16x9/ui/popup/close-popup-button.svg);
//   background-repeat: no-repeat;
//   background-size: 100% 100%;
//   width: vwFn(8.5);
//   height: vhFn(11.5);
// }

.grazewayPopup--grazeway {
  background-image: url(../../resources/assets/themes/lely/16x9/popups/grazeway/grazeway--robot--popup.svg);
  position: absolute;
  width: vhFn(73.35);
  height: vhFn(73.35);
  background-size: 100% 100%;
  left: vwFn(56);
  //top: vhFn(-15);
}

// //Galaxy S20 Ultra
// @media screen and (device-width: 915px) and (device-height: 412px) and (-webkit-device-pixel-ratio: 3.5) and (orientation: landscape) {
//   .grazewayPopup--description {
//     font-size: vhFn(3.73) !important;
//   }
// }

// //IphoneX
// @media only screen and (min-device-height: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
//   .grazewayPopup--description {
//     font-size: vhFn(3.73) !important;
//   }
// }
.theme-adult .grazewayPopup--grazeway {
  background-image: url(../../resources/assets/themes/lely/16x9/popups/grazeway/grazeweay--popup--image.svg);
}