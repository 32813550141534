@use "../../../../common/styles/variables.scss" as *;

.CheeseFactoryScreen {
  background-image: url(../../../../resources/assets/themes/lely/16x9/screens/cowIntro/cow-intro-page.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  width: vwFn(100);
  height: vhFn(100);
}

.textCaption--CheeseFactoryScreen {
  position: absolute;
  width: vwFn(30.78);
  height: vhFn(38.4);
  left: vwFn(47.98);
  top: vhFn(30.8);
  padding: vwFn(1.4) vwFn(1.4) vwFn(1.8);
  z-index: 1;
}

.CheeseFactoryScreen--text {
  position: static;
  width: vwFn(26.78);
  height: vhFn(38.4);
  font-size: vwFn(1.72);
  line-height: vhFn(6.4);
  margin: 0px;
}

.CheeseFactoryScreen--farmer {
  position: absolute;
  right: vwFn(4.97);
  top: vhFn(34.56);
  width: vwFn(18.47);
  height: vhFn(45.54);
  background-image: url(../../../../resources/assets/themes/kaasfarm/16x9/onboarding/welcomeScreenKaas/farmer-image.svg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  z-index: 1;
}

.CheeseFactoryScreen--cheese {
  position: absolute;
  left: vwFn(9.43);
  bottom: vhFn(5.936);
  width: vwFn(36.33);
  height: vhFn(78.86);
  background-image: url(../../../../resources/assets/themes/lely/16x9/actors/cheesefactory/cheese-factory.svg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  z-index: 1;
}
