@use "../../common/styles/variables.scss" as *;

.lelyStoryFifthSlide {
  position: absolute;
  background-size: 100% 100%;
  width: vwFn(100);
  height: vhFn(100);
  background-color: $color-GrayDairyLighter;
}

.lelyStoryFifthSlide--textCaption {
  position: absolute;
  width: vwFn(55.41);
  min-height: vhFn(47.46);
  top: vhFn(20.266);
  left: vwFn(38.819);
  padding: vhFn(3.2) vwFn(1.48);
  z-index: 2;
}

.lelyStoryFifthSlide--title {
  font-family: $secondaryFont;
  font-style: $font-DefaultFontStyle;
  font-weight: $font-bold;
  font-size: vhFn(4.27);
  line-height: vhFn(4.8);
  letter-spacing: vwFn(0.1);
  color: $color-gray-popup;
}

.lelyStoryFifthSlide--text {
  font-family: $primaryFont;
  font-style: $font-DefaultFontStyle;
  font-size: vhFn(3.73);
  line-height: vhFn(6.4);
  color: $color-gray-popup;
  margin: vhFn(1.87) 0;
}

.lelyStoryFifthSlide--imgContainer {
  width: 100%;
  height: vhFn(57.77);
  border-width: vhFn(0.53) vwFn(0.246);
  border-style: solid;
  border-color: $color-GrayDairyDarker;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/lelystory/lelyFifthSlideCowjpg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-color: $color-GrayDairyDarker;
}

.lelyStoryFifthSlide--imgCaption {
  position: absolute;
  width: vwFn(29.85);
  height: vhFn(78.93);
  background-color: $color-LelyWhite;
  box-shadow: 0px vwFn(0.25) vwFn(0.7) rgba(18, 18, 18, 0.2);
  padding: vhFn(3.2) vwFn(1.48);
  transform: rotate(-7.11deg);
  left: vwFn(8.08);
  bottom: vhFn(8);
}
