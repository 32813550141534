@use "../../styles/variables.scss" as *;

.confirmationDialog {
  width: 441px;
  height: 186px;
  border-radius: 4px;
  margin: 0 auto;
  margin-top: calc(50vh - 186px / 2);
  box-shadow: 0px 2px 3px rgba(222, 219, 214, 0.4);
  background-color: #fff;
  font-family: $primaryFont;
}

.confirmationDialog--header {
  position: relative;
  height: 62px;
  background-color: #c30a14;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.confirmationDialog--header h3 {
  font-size: 26px;
  line-height: 62px;
  vertical-align: middle;
  font-weight: 300;
  color: #fff;
  margin: 0;
  padding: 0;
  margin-left: 20px;
}

.confirmationDialog--header button {
  position: absolute;
  top: 23px;
  right: 20px;
  background: transparent;
  border: 0;
  outline: 0;
  color: #fff;
  cursor: pointer;
}

.confirmationDialog--body {
  padding-left: 20px;
}

.confirmationDialog--body p {
  font-size: 16px;
  color: #121212;
}

.confirmationDialog--footer {
  padding-left: 20px;
  padding-right: 20px;
  text-align: right;
}

.confirmationDialog--btn-confirm {
  border-radius: 4px;
  background-color: #c30a14;
  color: #fff;
  font-size: 14px;
  font-weight: 300;
  height: 40px;
  padding: 10px;
  cursor: pointer;
}

.confirmationDialog--btn-cancel {
  text-decoration: underline;
  color: #766e68;
  font-size: 14px;
  font-weight: 300;
  border: 0;
  outline: 0;
  background: transparent;
  margin-right: 20px;
  cursor: pointer;
}

.confirmationDialogContainer {
  width: vwFn(100);
  position: fixed;
  background-color: rgba(18, 18, 18, 0.4);
  left: 0;
  top: 0;
  z-index: 999999999;
}
