@use "../../common/styles/variables.scss" as *;

.bird-container {
  position: absolute;
  width: vwFn(100);
  height: vhFn(100);
  overflow: hidden;
  pointer-events: none;
  transform: translate3d(0, 0, 1px);
  z-index: 4;
}

.is-bird-animation-disabled {
  display: none;
}
