@use "../../common/styles/variables.scss" as *;

.milkBatchWeatherIcon {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: vwFn(10);
  height: vhFn(12); 
}

//#region SUNNY
.milkBatchWeatherIcon--sunny {
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/onboarding/adult/weatherIcons/weatherIcon_sunny.svg);
}
//#endregion

//#region CLOUDY
.milkBatchWeatherIcon--lightCloudy {
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/onboarding/adult/weatherIcons/weatherIcon_cloudy_light.svg);
}

.milkBatchWeatherIcon--cloudy {
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/onboarding/adult/weatherIcons/weatherIcon_cloudy.svg);
}

.milkBatchWeatherIcon--cloudy {
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/onboarding/adult/weatherIcons/weatherIcon_cloudy_heavy.svg);
}

.milkBatchWeatherIcon--cloudyNight {
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/onboarding/adult/weatherIcons/weatherIcon_cloudy_night.svg);
}
//#endregion

//#region RAIN
.milkBatchWeatherIcon--lightRain {
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/onboarding/adult/weatherIcons/weatherIcon_cloudy_rain_light.svg);
}

.milkBatchWeatherIcon--rain {
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/onboarding/adult/weatherIcons/weatherIcon_cloudy_rain.svg);
}

.milkBatchWeatherIcon--heavyRain {
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/onboarding/adult/weatherIcons/weatherIcon_cloudy_rain_heavy.svg);
}

.milkBatchWeatherIcon--rainNight {
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/onboarding/adult/weatherIcons/weatherIcon_cloudy_rain_night.svg);
}
//#endregion

//#region THUNDERSTORM
.milkBatchWeatherIcon--lightThunderstorm {
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/onboarding/adult/weatherIcons/weatherIcon_cloudy_thunder_light.svg);
}

.milkBatchWeatherIcon--thunderstorm {
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/onboarding/adult/weatherIcons/weatherIcon_cloudy_thunder.svg);
}

.milkBatchWeatherIcon--heavyThunderstorm {
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/onboarding/adult/weatherIcons/weatherIcon_cloudy_thunder_heavy.svg);
}

.milkBatchWeatherIcon--thunderstormNight {
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/onboarding/adult/weatherIcons/weatherIcon_cloudy_thunder_night.svg);
}
//#endregion

//#region RAIN THUNDERSTORM
.milkBatchWeatherIcon--lightRainThunderstorm {
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/onboarding/adult/weatherIcons/weatherIcon_cloudy_thunder_rain_light.svg);
}

.milkBatchWeatherIcon--rainThunderstorm {
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/onboarding/adult/weatherIcons/weatherIcon_cloudy_thunder_rain.svg);
}

.milkBatchWeatherIcon--rainHeavyThunderstorm {
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/onboarding/adult/weatherIcons/weatherIcon_cloudy_thunder_rain_heavy.svg);
}

.milkBatchWeatherIcon--rainThunderstormNight {
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/onboarding/adult/weatherIcons/weatherIcon_cloudy_thunder_rain_night.svg);
}
//#endregion

//#region SNOW
.milkBatchWeatherIcon--lightSnow {
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/onboarding/adult/weatherIcons/weatherIcon_cloudy_snow_light.svg);
}

.milkBatchWeatherIcon--snow {
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/onboarding/adult/weatherIcons/weatherIcon_cloudy_snow.svg);
}

.milkBatchWeatherIcon--heavySnow {
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/onboarding/adult/weatherIcons/weatherIcon_cloudy_snow_heavy.svg);
}

.milkBatchWeatherIcon--snowNight {
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/onboarding/adult/weatherIcons/weatherIcon_cloudy_snow_night.svg);
}
//#endregion

//#region MIST
.milkBatchWeatherIcon--mist {
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/onboarding/adult/weatherIcons/weatherIcon_mist.svg);
}

.milkBatchWeatherIcon--mistNight {
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/onboarding/adult/weatherIcons/weatherIcon_mist_night.svg);
}
//#endregion
