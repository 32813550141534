@use "../../common/styles/variables.scss" as *;

.vectorStoryThirdSlide {
  position: absolute;
  background-size: 100% 100%;
  width: vwFn(101);
  height: vhFn(100);
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/eating/eating2.svg);
  border: none;
}

.vectorStoryThirdSlide--textCaption {
  position: absolute;
  width: vwFn(55.41);
  min-height: vhFn(65.66);
  top: vhFn(16.66);
  left: vwFn(39.619);
  padding: vhFn(3.2) vwFn(1.48);
  z-index: 2;
}

.vectorStoryThirdSlide--title {
  font-family: $secondaryFont;
  font-style: $font-DefaultFontStyle;
  font-weight: $font-light;
  font-size: vhFn(4.27);
  line-height: vhFn(4.8);
  letter-spacing: vwFn(0.1);
  color: $color-gray-popup;
}

.vectorStoryThirdSlide--text {
  font-family: $primaryFont;
  font-style: $font-DefaultFontStyle;
  font-size: vhFn(3.73);
  line-height: vhFn(6.4);
  color: $color-gray-popup;
  margin: vhFn(1.87) 0;
}

.vectorStoryThirdSlide--imgContainer {
  width: 100%;
  height: vhFn(57.77);
  border-width: vhFn(0.53) vwFn(0.246);
  border-style: solid;
  border-color: $color-GrayDairyDarker;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/vectorstory/03_vector_cows.jpg); 
  background-repeat: no-repeat;
  background-size: cover;
  background-color: $color-GrayDairyDarker;
}

.vectorStoryThirdSlide--imgCaption {
  position: absolute;
  width: vwFn(29.85);
  height: vhFn(78.93);
  background-color: $color-LelyWhite;
  box-shadow: 0px vwFn(0.25) vwFn(0.7) rgba(18, 18, 18, 0.2);
  padding: vhFn(3.2) vwFn(1.48);
  transform: rotate(-15.18deg);
  left: vwFn(9.77);
  top: vhFn(5.992);
}
.theme-adult .vectorStoryThirdSlide--imgCaption {
  border-color: #000000;
  border: solid vhFn(1);
}
.theme-adult .vectorStoryThirdSlide--imgContainer {
  border: none;
}
.theme-adult .vectorStoryThirdSlide--title {
  //font-family: "Chelsea Market" !important;
  font-weight: 400;
  line-height: vhFn(6.4);
  font-size: vhFn(4.8);
}
