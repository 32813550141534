@use "../../../common/styles/variables.scss" as *;

.CowGaveBirthFourthSlide {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  width: vwFn(100);
  height: vhFn(100);
}

.CowGaveBirthFourthSlide--bottles {
  background-image: url(../../../resources/assets/themes/lely/16x9/screens/cowGaveBirthFourthSlide/milk-bottles.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  width: vwFn(12.43);
  height: vhFn(14.13);
  left: vwFn(50.73);
  top: vhFn(61);
}

.textCaption--CowGaveBirthFourthSlide {
  padding: vwFn(1);
  position: absolute;
  width: vwFn(30.78);
  height: vhFn(19.2);
  left: vwFn(20.44);
  top: vhFn(40.4);
  text-align: start;
}

.cow-CowGaveBirthFourthSlide--text {
  position: static;
  width: vwFn(27.83);
  height: vhFn(12.8);
  left: vwFn(1.477);
  top: vhFn(3.2);
  font-size: vhFn(4);
  line-height: vhFn(6.4);
  color: #121212;
  margin: vhFn(1) 0;
}
