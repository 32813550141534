@use "../../common/styles/variables.scss" as *;

.cow-daily--eating--slide {
  width: vwFn(100);
  height: vhFn(100);
}

.cow-daily--robot--vector {
  position: absolute;
  background-image: url(../../resources/assets/themes/lely/16x9/actors/vector/vector.svg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: vwFn(25.56);
  height: vhFn(60.37);
  top: vhFn(30.44);
  left: vwFn(33.74);
}
