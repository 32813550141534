@use "../../common/styles/variables.scss" as *;

.textCaption--mijnMelkAdultOnboarding-slide1-text1 {
  position: absolute;
  top: vhFn(20.53);
  left: vwFn(5);
  box-shadow: none;
  border: none;
  background: transparent;
  font-size: vhFn(3.7333);
}

.textCaption--mijnMelkAdultOnboarding-slide1-text2 {
  position: absolute;
  top: vhFn(34.864);
  left: vwFn(30.315);
  width: vwFn(10.729);
  box-shadow: none;
  font-size: vhFn(3.7333);
  border: none;
  background: transparent;
}

.textCaption--mijnMelkAdultOnboarding-slide1-text3 {
  position: absolute;
  top: vhFn(42);
  left: vwFn(5);
  box-shadow: none;
  font-size: vhFn(3.7333);
  top: vhFn(54.437);
  border: none;
  background: transparent;
}

.textCaption--mijnMelkAdultOnboarding-slide1-text4 {
  position: absolute;
  left: vwFn(30.315);
  top: vhFn(72.2586);
  font-size: vhFn(3.7333);
  box-shadow: none;
  border: none;
  background: transparent;
}

.mijnMelkAdultOnboarding-slide1--temperatureIcon {
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/onboarding/adult/slide1/temperatureIcon.svg);
}

.mijnMelkAdultOnboarding-slide1--batch {
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/onboarding/adult/slide1/batchLabel.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: vwFn(14.33);
  height: vhFn(17);
  position: absolute;
  top: vhFn(20);
  left: vwFn(48);
}

.mijnMelkAdultOnboarding-slide1--batch > span {
  display: block;
  transform: rotate(14deg);
  font-size: vhFn(3.65);
  padding: vhFn(0.3);
}

.mijnMelkAdultOnboarding-slide1--batch--label {
  position: relative;
  top: vhFn(4.8);
  left: vwFn(1.5);
  font-size: vhFn(3.65);
}

.mijnMelkAdultOnboarding-slide1--batch--batchID {
  position: relative;
  font-weight: bold;
  top: vhFn(4.3);
  left: vwFn(0.9);
  font-size: vhFn(3.65);
}

// iPhone 14 Pro
@media only screen and (min-device-height: 390px) and (max-device-width: 844px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
  .mijnMelkAdultOnboarding-slide1--batch--batchID {
    top: vhFn(3.3)
 }

}

@media only screen and (orientation: portrait) {
  .mijnMelkAdultOnboarding-slide1--batch--batchID {
    top: vhFn(3.4);
 }
 .mijnMelkAdultOnboarding-slide1--batch > span {
  transform: rotate(18deg);
 }
}

.mijnMelkAdultOnboarding-slide1--batchArrow {
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/onboarding/adult/slide1/batchLabelArrow.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: vwFn(4);
  height: vhFn(5);
  position: absolute;
  top: vhFn(17);
  left: vwFn(57);
}

.mijnMelkAdultOnboarding-slide1--dataPassportLabel {
  position: absolute;
  top: vhFn(17);
  left: vwFn(62);
  font-style: italic;
  transform: rotate(5deg);
  font-size: vhFn(3.73);
  font-weight: bold;
}
