.story-language-selector .checkbox-group-selection--checkbox-item {
    display: flex;
    height: 32px;
    width: 100%;
    margin: 0;
    padding: 0;
}

.story-language-selector .checkbox-group-selection--form-control {
    height: auto;
}

.story-language-selector .MuiFormControlLabel-root {
    display: block;
}

.story-language-selector {
    margin-bottom: 10px;
}