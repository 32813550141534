@use "../../common/styles/variables.scss" as *;

// .mijnMelkPopup {
//   position: absolute;
//   left: vwFn(5.603);
//   top: vhFn(17.0666);
//   background-color: $color-LelyWhite;
//   width: vwFn(67.73);
//   min-height: vhFn(65.86);
//   border-left: vwFn(0.12) solid $color-GrayDairyLight;
//   border-right: vwFn(0.12) solid $color-GrayDairyLight;
//   border-top: vhFn(0.27) solid $color-GrayDairyLight;
//   border-bottom: vhFn(0.27) solid $color-GrayDairyLight;
//   box-sizing: border-box;
//   box-shadow: 0px 2px 5px rgba(222, 219, 214, 0.4);
//   border-radius: vwFn(0.5) vwFn(0.5) vwFn(0.5) vwFn(0.5);
//   padding: vhFn(4) vwFn(2.463);
// }

// .mijnMelkPopup--title {
//   width: vwFn(43.103);
//   font-family: $secondaryFont;
//   color: $color-LelyBlack;
//   font-weight: 700;
//   font-size: vhFn(6.4);
//   line-height: vhFn(9.33);
//   letter-spacing: vwFn(0.2);
// }



// .mijnMelkPopup--brand {
//   position: relative;
//   width: vwFn(10);
//   height: vhFn(10);
//   background-repeat: no-repeat;
//   background-size: contain;
//   background-image: url(../../resources/images/lely_logo_white_border.png);
// }

// .mijnMelkPopup--description {
//   width: vwFn(47.78);
//   min-height: vhFn(32);
//   font-family: $primaryFont;
//   font-weight: 400;
//   font-size: vhFn(3.733);
//   line-height: vhFn(6.4);
// }

// .mijnMelkPopup--btnClosePopup {
//   position: absolute;
//   top: vhFn(-2.5);
//   left: vwFn(48.5);
//   background-image: url(../../resources/assets/themes/lely/16x9/ui/popup/close-popup-button.svg);
//   background-repeat: no-repeat;
//   background-size: 100% 100%;
//   width: vwFn(8.5);
//   height: vhFn(11.5);
//   z-index: 2;
// }

.mijnMelkPopup--image--dobblehoeve--milk {
  position: absolute;
  //top: vhFn(-3.5);
  background-repeat: no-repeat;
  width: vhFn(71.35);
  height: vhFn(77.05);
  background-size: 100% 100%;
  left: vwFn(55.5);
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/actors/mijnmelkactor/MMDobbelhoeve.svg);
}
.mijnMelkPopup--image--dobblehoeve--yogurt, .mijnMelkPopup--image--dobblehoeve--yogurt--volle {
  position: absolute;
  //top: vhFn(-3.5);
  background-repeat: no-repeat;
  width: vhFn(71.35);
  height: vhFn(77.05);
  background-size: 100% 100%;
  left: vwFn(55.5);
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/actors/mijnmelkactor/MMDobbelhoeveyoghurt.svg);
}

.mijnMelkPopup--image--oostdam--milk {
  position: absolute;
  //top: vhFn(-3.5);
  background-repeat: no-repeat;
  width: vhFn(71.35);
  height: vhFn(77.05);
  background-size: 100% 100%;
  left: vwFn(55.5);
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/actors/mijnmelkactor/MMOostdam.svg);
}
.mijnMelkPopup--image--oostdam--yogurt, .mijnMelkPopup--image--oostdam--yogurt--volle {
  position: absolute;
  //top: vhFn(-3.5);
  background-repeat: no-repeat;
  width: vhFn(71.35);
  height: vhFn(77.05);
  background-size: 100% 100%;
  left: vwFn(55.5);
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/actors/mijnmelkactor/MMOostdamyoghurt.svg);
}

.mijnMelkPopup--image--rotgans {
  position: absolute;
  //top: vhFn(-3.5);
  background-repeat: no-repeat;
  width: vhFn(71.35);
  height: vhFn(77.05);
  background-size: 100% 100%;
  left: vwFn(55.5);
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/actors/mijnmelkactor/MMReijer.svg);
}
.mijnMelkPopup--image--brosa--milk {
  position: absolute;
  //top: vhFn(-3.5);
  background-repeat: no-repeat;
  width: vhFn(71.35);
  height: vhFn(77.05);
  background-size: 100% 100%;
  left: vwFn(55.5);
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/actors/mijnmelkactor/MMVelden.svg);
}
.mijnMelkPopup--image--brosa--yogurt, .mijnMelkPopup--image--brosa--yogurt--volle {
  position: absolute;
  //top: vhFn(-3.5);
  background-repeat: no-repeat;
  width: vhFn(71.35);
  height: vhFn(77.05);
  background-size: 100% 100%;
  left: vwFn(55.5);
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/actors/mijnmelkactor/MMVeldenyoghurt.svg);
}

.mijnMelkPopup--image--schutte {
  position: absolute;
  //top: vhFn(-3.5);
  background-repeat: no-repeat;
  width: vhFn(71.35);
  height: vhFn(77.05);
  background-size: 100% 100%;
  left: vwFn(55.5);
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/actors/mijnmelkactor/mijnmelkschutte.svg);
}

.mijnMelkPopup--btnCallToAction, 
.mijnMelkPopup--secondBtnCallToAction {
  background-color: $color-LelyRed;
  background-repeat: no-repeat;
  border-radius: vwFn(0.5) vwFn(0.5) vwFn(0.5) vwFn(0.5);
  border-left: vwFn(0.1201) solid $color-GrayDairyLight;
  border-right: vwFn(0.12) solid $color-GrayDairyLight;
  border-top: vhFn(0.27) solid $color-GrayDairyLight;
  border-bottom: vhFn(0.27) solid $color-GrayDairyLight;
  background-size: 100% 100%;
  min-width: vwFn(19.889);
  margin-right: vwFn(2);
  height: vhFn(10.66);
  font-size: vwFn(1.5);
  color: $color-LelyWhite;
  top: vhFn(3.86);
  line-height: vhFn(4.266);
}
.mijnMelkPopupButtons {
  display: inline-flex;
}
// // .theme-adult .mijnMelkPopup--btnCallToAction {
//   // font-family: $primaryFont;
//   // color: #FF684D;
//   // top: vhFn(54.93);
//   // left: vwFn(36.18);
//   // font-size: vhFn(3.73);
//   // background-color: white;
//   // font-weight: 400;
//   // line-height: vhFn(4.29);
//   // border: none;
// // }
// // 
// // .theme-adult .mijnMelkPopup--btnCalltoAction--adult--theme--orange--arrows {
//   // background-image: url(../../resources/assets/themes/lely/16x9/ui/popup/orange_button_arrows.svg);
//   // top: vhFn(54.7);
//   // left: vwFn(20.88);
//   // width: vwFn(1.7);
//   // height: vhFn(4);
//   // display: flex;
//   // position: absolute;
// // }
// // 
// // .theme-adult .mijnMelkPopup--btnCalltoAction--adult--theme--orange--secondArrows {
//   // background-image: url(../../resources/assets/themes/lely/16x9/ui/popup/orange_button_arrows.svg);
//   // top: vhFn(54.7);
//   // left: vwFn(45.5);
//   // width: vwFn(1.7);
//   // height: vhFn(4);
//   // display: flex;
//   // position: absolute;
// // }
// // 
// // .theme-adult .mijnMelkPopup {
//   // position: absolute;
//   // left: vwFn(5.603);
//   // top: vhFn(17.0666);
//   // background-color: $color-LelyWhite;
//   // width: vwFn(67.73);
//   // min-height: vhFn(65.86);
//   // border-left: vwFn(0.12) solid $color-GrayDairyLight;
//   // border-right: vwFn(0.12) solid $color-GrayDairyLight;
//   // border-top: vhFn(0.27) solid $color-GrayDairyLight;
//   // border-bottom: vhFn(0.27) solid $color-GrayDairyLight;
//   // box-sizing: border-box;
//   // box-shadow: 0px 2px 5px rgba(222, 219, 214, 0.4);
//   // border-radius: vwFn(0.5) vwFn(0.5) vwFn(0.5) vwFn(0.5);
// // }
// // 
// // .theme-adult .mijnMelkPopup--description {
//   // margin-top: vhFn(2.13);
// // 
// // }
// // 
// // .theme-adult .mijnMelkPopup--title {
//   // width: vwFn(42.02);
//   // height: vhFn(9.6);
//   // font-family: 'Roboto' !important;
//   // color: #121212;
//   // font-weight: 700;
//   // font-size: vhFn(6.4);
//   // line-height: vhFn(9.6);
//   // top: vhFn(4);
//   // left: vwFn(10.23);
//   // margin: vhFn(0) vwFn(6.9);
//   // flex-direction: row;
//   // align-items: flex-start;
//   // padding: 0px, 0px, 0px, 55px;
//   // gap: 10px;
//   // flex: none;
//   // display: flex;
//   // order: 0;
//   // flex-grow: 0;
//   // display: flex;
//   // letter-spacing: 0.02;
// // }
// // 
// // 
// // 
// // .theme-adult .mijnMelkPopup--btnClosePopup {
//   // position: absolute;
//   // left: vwFn(1.84);
//   // top: vhFn(-0.375);
//   // background-image: url(../../resources/assets/themes/lely/16x9/ui/popup/close-popup-button-adult-theme.svg);
//   // background-repeat: no-repeat;
//   // background-size: 100% 100%;
//   // width: vwFn(6.15);
//   // height: vhFn(13.33);
//   // z-index: 2;
// // }
// // 
.theme-adult .mijnMelkPopup--image--dobblehoeve {
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/actors/mijnmelkactor/mijnmelkdobblehoeveAdult.svg);
}

.theme-adult .mijnMelkPopup--image--oostdam {
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/actors/mijnmelkactor/mijnmelkoostdamAdult.svg);
}

.theme-adult .mijnMelkPopup--image--rotgans {
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/actors/mijnmelkactor/mijnmelkrotgansAdult.svg);
}

.theme-adult .mijnMelkPopup--image--schutte {
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/actors/mijnmelkactor/mijnmelkschutteAdult.svg);
}
