.custom-datepicker {
  margin-right: 2.5vw;
  .MuiInputBase-root.MuiOutlinedInput-root {
    position: relative;
    height: 25px;
    line-height: 20px;
    border: none;
    box-shadow: none;
    color: #eee;
    background: none;
    font-size: 13px;
    text-align: right;
    .MuiSvgIcon-root {
      font-size: 1.2rem;
    }
  }
}
.MuiFormHelperText-contained.Mui-error.MuiFormHelperText-root,
.MuiFormHelperText-contained.MuiFormHelperText-contained.MuiFormHelperText-root {
  bottom: -6px;
  left: 15px;
  color: #ff0000;
  font-size: 0.65rem;
}
.MuiFormHelperText-contained {
  margin: 8px 0 0;
}
