@use "../../common/styles/variables.scss" as *;

.farmer {
  position: absolute;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  pointer-events: fill;
  width: vhFn(6.01);
  height: vhFn(10);
  pointer-events: fill;
  z-index: 10;
}

.farmer--male {
  background-image: url(../../resources/assets/themes/lely/16x9/actors/farmer/farmer-male.svg);
}

.farmer--female {
  background-image: url(../../resources/assets/themes/lely/16x9/actors/farmer/farmer-female.svg);
}

.farmer--title {
  opacity: 0;
  display: none;
}

.farmer--bigger {
  width: vwFn(30);
  height: vhFn(65);
  background-size: cover;
}

.farmer--fixed {
  position: absolute;
  width: vwFn(16);
  height: vhFn(72);
  right: vwFn(55);
  top: vhFn(20);
}
