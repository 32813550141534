@use "../../common/styles/variables.scss" as *;

/* Background grass */
.background-grass1 {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowhistory/background-layers/grass/grass1.svg);
  width: 100vw;
  top: calc(var(--vh, 1vh) * 80.7);
  height: calc(var(--vh, 1vh) * 20.26) !important;
}

.background-grass2 {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowhistory/background-layers/grass/grass2.svg);
}

.background-grass3 {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowhistory/background-layers/grass/grass3.svg);
}

.background-grass4 {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowhistory/background-layers/grass/grass4.svg);
}

.background-grass5 {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowhistory/background-layers/grass/grass5.svg);
}

/**/

/* Background forrest 1 */
.background-forrest1 {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowhistory/background-layers/forrest/forrest1.svg);
}

.background-forrest2 {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowhistory/background-layers/forrest/forrest2.svg);
}

.background-forrest3 {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowhistory/background-layers/forrest/forrest3.svg);
}

.background-forrest4 {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowhistory/background-layers/forrest/forrest4.svg);
}

.background-forrest5 {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowhistory/background-layers/forrest/forrest5.svg);
}

/**/

/* Background forrest 2 */
.background-forrest21 {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowhistory/background-layers/forrest2/forrest1.svg);
}

.background-forrest22 {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowhistory/background-layers/forrest2/forrest2.svg);
}

.background-forrest23 {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowhistory/background-layers/forrest2/forrest3.svg);
}

.background-forrest24 {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowhistory/background-layers/forrest2/forrest4.svg);
}

.background-forrest22 {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowhistory/background-layers/forrest2/forrest5.svg);
}

/**/

/* Background hills 1 */
.background-hills11 {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowhistory/background-layers/hills1/hills11.svg);
}

.background-hills12 {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowhistory/background-layers/hills1/hills12.svg);
}

.background-hills13 {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowhistory/background-layers/hills1/hills13.svg);
}

.background-hills14 {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowhistory/background-layers/hills1/hills14.svg);
}

.background-hills15 {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowhistory/background-layers/hills1/hills15.svg);
}

/**/

/* Background hills 2 */
.background-hills21 {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowhistory/background-layers/hills2/hills21.svg);
}

.background-hills22 {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowhistory/background-layers/hills2/hills22.svg);
}

.background-hills23 {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowhistory/background-layers/hills2/hills23.svg);
}

.background-hills24 {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowhistory/background-layers/hills2/hills24.svg);
}

.background-hills25 {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowhistory/background-layers/hills2/hills25.svg);
}

/**/

/* Background hills with snow (only applicable to weather type SNOW) */
.background-hills11-snow {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/hills/background_hills_snowy_01.svg);
}

.background-hills12-snow {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/hills/background_hills_snowy_02.svg);
}

.background-hills13-snow {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/hills/background_hills_snowy_03.svg);
}

.background-hills14-snow {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/hills/background_hills_snowy_04.svg);
}

.background-hills15-snow {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/hills/background_hills_snowy_05.svg);
}

/**/

/* Background mountains */
.background-mountain1 {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowhistory/background-layers/mountains/mountain4.svg);
}

.background-mountain2 {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowhistory/background-layers/mountains/mountain1.svg);
}

.background-mountain3 {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowhistory/background-layers/mountains/mountain3.svg);
}

.background-mountain4 {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowhistory/background-layers/mountains/mountain4.svg);
}

.background-mountain5 {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowhistory/background-layers/mountains/mountain5.svg);
}

/**/

.background-layer {
  transition: all 0.5s;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  width: vwFn(100);
  /* Fallback for browsers that do not support Custom Properties */
  height: vhFn(100);
}

.background-layer .slidesContainer {
  width: vwFn(100);
  /* Fallback for browsers that do not support Custom Properties */
  height: vhFn(100);
}

.background-layer--slide {
  width: vwFn(101);
  height: vhFn(100);
  background-size: contain;
  background-position: right bottom;
  background-repeat: no-repeat;
}
