@use "../../../common/styles/variables.scss" as *;
.cow--breed-preview {
  position: absolute;
  width: 20vw;
  height: 43vh;
  background-size: 100% 100%;
  display: block;
}

.preview--0 {
  top: vhFn(50.272) !important;
  left: vwFn(14.838) !important;
  width: vwFn(13.79) !important;
  height: vhFn(32.8) !important;
  background-size: 100% 100%;
  z-index: 1;
}

.preview--1 {
  top: vhFn(53.33) !important;
  left: vwFn(77.83);
  width: vwFn(14.0349) !important;
  height: vhFn(33.33) !important;
  transform: scaleX(-1);
}

.preview--2 {
  //position: fixed;
  top: vhFn(36.272) !important;
  left: vwFn(23.27) !important;
  width: vwFn(18.22) !important;
  height: vhFn(42.933) !important;
  background-size: 100% 100%;
  //transform: scale(1.2);
  //z-index: 10;
}

.preview--3 {
  top: calc(var(--vh, 1vh) * 40);
  left: 82vw;
  width: 18.22vw;
  height: calc(var(--vh, 1vh) * 42.93);
  transform: scaleX(-1) scale(1.2);
}

.preview--4 {
  opacity: 0;
}

.preview--5 {
  opacity: 0;
}

.preview--6 {
  opacity: 0;
}

.preview--7 {
  opacity: 0;
}
.slide2 .preview--0 {
  top: vhFn(50.272) !important;
  left: vwFn(32.838) !important;
  width: vwFn(10.714) !important;
  height: vhFn(25.333) !important;
  background-size: 100% 100%;
  z-index: 1;
}
.slide2 .preview--1 {
  top: vhFn(42.272) !important;
  left: vwFn(56.8388) !important;
  width: vwFn(16.009) !important;
  height: vhFn(38.133) !important;
  background-size: 100% 100%;
  z-index: 1;
}
.slide2 .preview--2 {
  top: vhFn(50.272) !important;
  left: vwFn(68.238) !important;
  width: vwFn(14.285) !important;
  height: vhFn(33.866) !important;
  background-size: 100% 100%;
  transform: scaleX(-1);
  z-index: 1;
}
