@use "../../common/styles/variables.scss" as *;

.lunaStoryStartingSlide {
  position: absolute;
  background-size: 100% 100%;
  width: vwFn(100.1);
  height: vhFn(100);
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/resting/resting2.svg);
}

.discoveryStory--bottomColorOverlay {
  position: absolute;
  width: 100%;
  height: vhFn(20);
  bottom: 0;
  background-color: #cfcbc3;
  z-index: -1;
}
.lunaStoryStartingSlide--pole {
  position: absolute;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-color: #998f87;
  width: vwFn(2.0837);
  height: vhFn(68.098);
  left: vwFn(61.47);
  top: vhFn(11.896);
  z-index: 2;
}
.lunaStoryStartingSlide--textCaption {
  position: absolute;
  width: vwFn(24.507);
  min-height: vhFn(24.26);
  top: vhFn(28.9733);
  left: vwFn(20.096);
  padding: vhFn(3.2) vwFn(1.48);
}

.lunaStoryStartingSlide--line {
  position: absolute;
  background-color: #766e68;
  width: vwFn(20.096);
  height: vhFn(0.53);
  top: vhFn(50.29);
}

.lunaStoryStartingSlide--robot {
  position: absolute;
  background-size: 100% 100%;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/lunarobot/lunarobot.svg);
  width: vwFn(8.2426);
  height: vhFn(34.5146);
  left: vwFn(58.54);
  top: vhFn(24.52);
  z-index: 3;
}

.lunaStoryStartingSlide--title {
  font-family: $secondaryFont;
  font-style: $font-DefaultFontStyle;
  font-weight: $font-bold;
  font-size: vhFn(4.27);
  line-height: vhFn(4.8);
  color: $color-gray-popup;
}

.lunaStoryStartingSlide--name {
  font-family: $secondaryFont;
  font-style: $font-DefaultFontStyle;
  font-weight: $font-bold;
  font-size: vhFn(12.8);
  line-height: vhFn(10.4);
  color: $color-gray-popup;
  margin: vhFn(1.87) 0;
}
.theme-adult .lunaStoryStartingSlide {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowDailyForAdult/backgrounds/resting/background-pcd-restingAdult1.svg);
} 
.theme-adult { 
  .lunaStoryStartingSlide--robot {
  position: absolute;
  background-size: 100% 100%;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/lunarobot/lunaRobotAdult.svg);
  width: vwFn(8.2426);
  height: vhFn(34.5146);
  left: vwFn(67.54);
  top: vhFn(24.52);
  z-index: 3; 
} 
.lunaStoryStartingSlide--pole {
  position: absolute;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-color: white;
  border: vhFn(1) solid black;
  width: vwFn(2.0837);
  height: vhFn(66.098);
  left: vwFn(70.47);
  top: vhFn(12.896);
  z-index: 2;
} 
.lunaStorySecondSlide--title,
.lunaStoryThirdSlide--title {
  //font-family: "Chelsea Market" !important;
  font-weight: 400; 
  line-height: vhFn(6.4);
  font-size: vhFn(4.8);
} 
.lunaStorySecondSlide--imgCaption,
.lunaStoryThirdSlide--imgCaption {
  border-color: #000000; 
  border: solid vhFn(1);
}
}