@use "../../../common/styles/variables.scss" as *;

.cow-calm--robot--slide {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  width: vwFn(100);
  height: vhFn(100);
}

.textCaption--cow-calm--robot {
  position: absolute;
  left: vwFn(23.9556);
  top: vhFn(27.09);
  width: vwFn(30.78);
  min-height: vhFn(38.8);
  padding: vwFn(1.5);
}

.textCaption--cow-calm--robot--text {
  position: static;
  line-height: vhFn(6.4);
  font-size: vwFn(1.72);
  width: vwFn(27.83);
  min-height: vhFn(35.4);
  left: vwFn(1.477);
  top: vhFn(3.2);
  margin: vwFn(1.23) 0;
}

.cow-calm-slide--calf {
  position: absolute;
  left: vwFn(66.777);
  top: vhFn(61.484);
  width: vwFn(23.47);
  height: vhFn(32.8);
  background-image: url(../../../resources/assets/themes/lely/16x9/screens/cowCalmRobot/calm-slide-calf.svg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.cow-calm-slide--background-overlay {
  display: none;
  position: fixed;
  width: 100%;
  height: vhFn(100);
  background-color: #121212;
  opacity: 0.4;
  pointer-events: fill;
}

.cow-calm-slide--background-overlay.is-calm-popup-displayed {
  display: block;
  z-index: 10;
}
