@use "../../common/styles/variables.scss" as *;

/*JS implementation using rainfall.js start -- comes at performance costs */
.drop {
  position: absolute;
  width: vwFn(2);
  height: vhFn(4);
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/weather/droplet.svg);
  background-repeat: no-repeat;
  background-size: contain;
  animation: rainfall 1.5s linear infinite;
  transform: translateZ(0.1);
  will-change: transform;
}

@keyframes rainfall {
  0% {
    transform: translateX(0) translateY(0) translateZ(0);
  }

  100% {
    transform: translateX(0) translateY(1000px) translate(0);
  }
}

/*JS implementation using rainfall.js end -- comes at performance costs */

/* CSS-only rainfall implementation. Not so realistic, but great performance */
.rainfall {
  background-repeat: repeat-y;
  background-size: contain;
  width: 100%;
  height: 300%;
  animation-name: animateBackground;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-play-state: running;
  animation-timing-function: linear;
  animation-delay: 0ms;
}

.rainfall.drizzle {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/weather/rains/background_weather_rain_drizzle_01.svg);
}

.rainfall.averagerain {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/weather/rains/background_weather_rain_01.svg);
}

.rainfall.thunderstorm {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/weather/rains/background_weather_rain_heavy_02.svg);
  animation-duration: 2s;
}

.rainfall.heavyrain {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/weather/rains/background_weather_rain_heavy_02.svg);
  animation-duration: 2s;
} 

@keyframes animateBackground {
  0% {
    background-position-x: 0%;
    background-position-y: 0%;
  }

  100% {
    background-position-x: 40%;
    background-position-y: 100%;
  }
}

/**/
