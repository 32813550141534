@use "../../common/styles/variables.scss" as *;

.orbiterStoryThirdSlide {
  position: absolute;
  background-size: 100% 100%;
  width: vwFn(101);
  height: vhFn(100);
  background-color: #f7f3f2;
  border: none;
}

.orbiterStoryThirdSlide--textCaption {
  position: absolute;
  width: vwFn(30.788);
  min-height: vhFn(20.8);
  top: vhFn(37.2);
  left: vwFn(60.33);
  padding: vhFn(1.6) vwFn(1.48);
  z-index: 2;
}

.orbiterStoryThirdSlide--floor {
  position: absolute;
  background-size: 100% 100%;
  left: vwFn(0);
  top: vhFn(78.5);
  width: vwFn(100);
  height: vhFn(21.5);
  background-color: #cfcbc3;
}

.orbiterStoryThirdSlide--robot {
  position: absolute;
  background-size: 100% 100%;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/orbiterstory/orbitercoolingsystem.svg);
  width: vwFn(39.52);
  height: vhFn(74.06);
  left: vwFn(17.28);
  top: vhFn(5.28);
  z-index: 3;
}

.orbiterStoryThirdSlide--text {
  font-family: $primaryFont;
  font-style: $font-DefaultFontStyle;
  font-size: vhFn(3.73);
  line-height: vhFn(6.4);
  color: $color-gray-popup;
  margin: vhFn(1.87) 0; 
  letter-spacing: vwFn(0.03);

}

.orbiterStoryThirdSlide--pipe {
  position: absolute;
  background-size: 100% 100%;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/orbiterstory/pipeThirdSlide.svg);
  width: vwFn(20);
  height: vhFn(2.333);
  left: vwFn(0);
  top: vhFn(71.13);
  z-index: 2;
}

.orbiterStoryThirdSlide--secondPipe {
  position: absolute;
  background-size: 100% 100%;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/orbiterstory/orbiterrightpipe.svg);
  width: vwFn(49.47);
  height: vhFn(27.67);
  left: vwFn(50.5);
  top: vhFn(46.13);
}

.orbiterStoryThirdSlide--temperature {
  position: absolute;
  background-size: 100% 100%;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/orbiterstory/temperaturebeforeorbiter.svg);
  width: vwFn(8.62);
  height: vhFn(10.4);
  left: vwFn(8.665);
  top: vhFn(58.128);
}

.orbiterStoryThirdSlide--temperatureAfter {
  position: absolute;
  background-size: 100% 100%;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/orbiterstory/temperatureafterorbiter.svg);
  width: vwFn(6.1577);
  height: vhFn(10.93);
  left: vwFn(51.79);
  top: vhFn(9.594);
}

.orbiterStoryThirdSlide--milkywayPipe {
  position: absolute;
  background-size: 100% 100%;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/orbiterstory/orbitermilkywaypipe.svg);
  width: vwFn(7.65);
  height: vhFn(20.15);
  left: vwFn(20.25);
  top: vhFn(51.88);
  z-index: 9999;
}

.orbiterStoryThirdSlide--temperatureText {
  position: absolute;
  width: vwFn(27.05);
  min-height: vhFn(9.33);
  left: vwFn(63.09);
  top: vhFn(9.86);
  font-size: vhFn(3.73);
  line-height: vhFn(6.4);
  font-family: $secondaryFont; 
  font-family: "LumiosMarker" !important;
  
}

.orbiterStoryThirdSlide--thirdArrow {
  position: absolute;
  background-size: 100% 100%;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/orbiterstory/orbiterarrow.svg);
  width: vwFn(2);
  height: vhFn(1.486);
  left: vwFn(59.314);
  top: vhFn(11.344);
  transform: rotate(0deg);
}

.orbiterStoryThirdSlide--arrow {
  position: absolute;
  background-size: 100% 100%;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/orbiterstory/orbiterarrow.svg);
  width: vwFn(2);
  height: vhFn(1.486);
  left: vwFn(7.314);
  top: vhFn(71.14);
  transform: rotate(180deg);
  z-index: 9999;
}

.orbiterStoryThirdSlide--secondArrow {
  position: absolute;
  background-size: 100% 100%;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/orbiterstory/orbiterarrow.svg);
  width: vwFn(2);
  height: vhFn(1.486);
  left: vwFn(84.314);
  top: vhFn(72.14);
  transform: rotate(180deg);
  z-index: 9999;
}
.theme-adult {
  .orbiterStoryThirdSlide {
    background-color: white; 
    height: vhFn(80);
  }
  .orbiterStoryThirdSlide--pipe {
    background-image: url(../../resources/assets/themes/lely/16x9/stories/orbiterstory/pipeThirdSlideAdult.svg);
  } 
  .orbiterStoryThirdSlide--robot {
    background-image: url(../../resources/assets/themes/lely/16x9/stories/orbiterstory/orbiterCoolingSystemAdult.svg); 
    height: vhFn(66.12); 
    top: vhFn(12.28);
  } 
  .orbiterStoryThirdSlide--secondPipe{
    background-image: url(../../resources/assets/themes/lely/16x9/stories/orbiterstory/orbiterSecondPipeAdult.svg);
  } 
  .orbiterStoryThirdSlide--temperatureContainerAdult{
  position: absolute;
  background-size: 100% 100%;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/orbiterstory/orbiterThirdSlideTempContainer.svg);
  width: vwFn(11.083);
  height: vhFn(20.8);
  left: vwFn(42.871);
  top: vhFn(5.333);
  }  
  .orbiterStoryThirdSlide--temperatureContainerAdultBefore {
    position: absolute;
    background-size: 100% 100%;
    background-image: url(../../resources/assets/themes/lely/16x9/stories/orbiterstory/orbiterThirdSlideTempContainer.svg);
    width: vwFn(11.083);
    height: vhFn(20.8);
    left: vwFn(4.9704);
    top: vhFn(45.9786);
  }
  .orbiterStoryThirdSlide--temperatureAfter{
    background-image: url(../../resources/assets/themes/lely/16x9/stories/orbiterstory/orbiterTempAfterCoolingAdult.svg); 
    width: vwFn(9.91133);
    height: vhFn(11.1146);
    z-index: 2; 
    left: vwFn(43.79);
    top: vhFn(7.094);
  }  
  .orbiterStoryThirdSlide--milkywayPipe {
    z-index: 0;
  }
  .orbiterStoryThirdSlide--temperature{
    background-image: url(../../resources/assets/themes/lely/16x9/stories/orbiterstory/orbiterTemperatureAdult.svg); 
    width: vwFn(9.91133);
    height: vhFn(11.1146);
    z-index: 1; 
    left: vwFn(5.355); 
    top: vhFn(47.5813);
  }
  .orbiterStoryThirdSlide--thirdArrow { 
    background-image: url(../../resources/assets/themes/lely/16x9/stories/orbiterstory/orbiterThirdArrowAdult.svg); 
    left: vwFn(55.314);
  } 
  .orbiterStoryThirdSlide--temperatureText {
  position: absolute;
  width: vwFn(25.05);
  min-height: vhFn(9.33);
  left: vwFn(59.09);
  top: vhFn(6.86);
  font-size: vhFn(5);
  line-height: vhFn(6.4);
  font-family: $secondaryFont;
  }
  .orbiterStoryThirdSlide--secondArrow {
    display: none;
  }

} 
.theme-adult .RobotStoryOrbiter3 .parallax-cow-robot-adults .background-layer--hills1  {
  opacity: 0;
} 
.theme-adult .RobotStoryOrbiter3 .parallax-cow-robot-adults .background-layer--hills2  {
  opacity: 0;
} 
