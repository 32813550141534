@import "../../common/styles/variables.scss";

.astronautCommonData {
  position: absolute;
  background-size: 100% 100%;
  width: vwFn(101);
  height: vhFn(100);
  background-color: #000;
  border: none;
}
.astronautCommonData--middleTextCaption {
  position: absolute;
  width: vwFn(30.7881);
  height: vhFn(12.8);
  top: vhFn(67.26);
  left: vwFn(8.96619);
  padding: vhFn(3.2) vwFn(1.48);
  z-index: 4;
  background-color: #121212;
  border-color: #43bf66;
}
.astronautCommonData--hourTextCaption {
  position: absolute;
  width: vwFn(30.7881);
  height: vhFn(12.8);
  top: vhFn(20.882);
  left: vwFn(8.96619);
  padding: vhFn(3.2) vwFn(1.48);
  z-index: 4;
  background-color: #121212;
  border-color: #43bf66;
}
.astronautCommonData--text {
  font-family: "Ubuntu Mono";
  font-style: $font-DefaultFontStyle;
  font-size: vhFn(3.533);
  line-height: vhFn(5.4);
  color: #43bf66;
}
.astronautCommonData--seconds {
  border-color: #43bf66;
  border-radius: 0;
  width: vwFn(19.5);
  height: vhFn(4);
  border: 1px solid #43bf66;
  padding: vhFn(0.6) vhFn(0.6);
  box-sizing: border-box;
}
.astronautCommonData--hourText {
  font-family: "Ubuntu Mono";
  font-style: $font-DefaultFontStyle;
  font-size: vhFn(3.733);
  line-height: vhFn(6.4);
  color: #43bf66;
}
.astronautCommonData--arrow {
  position: absolute;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/astronautLiveDataStory/matrixConnectionBetweenTextBoxes.svg);
  width: vwFn(10.055418);
  height: vhFn(47.966);
  top: vhFn(26.94933);
  left: vwFn(-1.178);
  z-index: 2;
}
.astronautCommonData--connectionArrows {
  position: absolute;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/astronautLiveDataStory/connectionArrows.svg);
  width: vwFn(20.525);
  height: vhFn(46.2453);
  top: vhFn(27.32);
  left: vwFn(39.610837);
  z-index: 2;
  transform: translate3d(0, 0, 1px);
}
.astronautCommonData--matrixMilkBottle {
  position: absolute;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/astronautLiveDataStory/matrixMilkBottle.svg);
  background-size: 100% 100%;
  width: vwFn(9.975);
  height: vhFn(61.6);
  top: vhFn(20.2986);
  left: vwFn(81.977);
  z-index: 4;
}
.astronautCommonData--smallMilkBottle {
  position: absolute;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/astronautLiveDataStory/smallMilkBottle.svg);
  width: vwFn(12.586);
  background-size: 100% 100%;
  height: vhFn(61.6);
  top: vhFn(20.2986);
  left: vwFn(81.977);
  transform: translate3d(0, 0, 1px);

  z-index: 5;
}
.astronautCommonData--milkContent {
  display: inline-block;
}
.astronautCommonData--fatPercentage {
  font-family: "Ubuntu Mono";
  font-style: $font-DefaultFontStyle;
  font-size: vhFn(3.733);
  line-height: vhFn(5.6);
  color: #43bf66;
  margin: 0;
  //list-style-type: circle;
}
.astronautCommonData--proteinPercentage {
  font-family: "Ubuntu Mono";
  font-style: $font-DefaultFontStyle;
  font-size: vhFn(3.733);
  line-height: vhFn(5.6);
  color: #43bf66;
  margin: 0;
  //list-style-type: circle;
}
.astronautCommonData--lactosePercentage {
  font-family: "Ubuntu Mono";
  font-style: $font-DefaultFontStyle;
  font-size: vhFn(3.7333);
  line-height: vhFn(5.6);
  color: #43bf66;
  margin: 0;
  //list-style-type: circle;
}
.astronautCommonData--matrixCows {
  position: absolute;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/astronautLiveDataStory/matrixCows.svg);
  background-repeat: none;
  width: vwFn(40.788);
  height: vhFn(46.49);
  top: vhFn(0.45);
  left: vwFn(3.8004);
  z-index: 3;
}

.astronautCommonData--milkBottles {
  position: absolute;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/astronautLiveDataStory/matrixMilkBottles.svg);
  background-repeat: none;
  width: vwFn(39.65);
  height: vhFn(42.66);
  top: vhFn(57.2826);
  left: vwFn(4.4088);
  z-index: 3;
}
.astronautCommonData--milkContentTextBox {
  position: absolute;
  width: vwFn(29.78811);
  min-height: vhFn(28.8);
  top: vhFn(35.952);
  left: vwFn(60.04679);
  padding: vhFn(3.2) vwFn(1.48);
  z-index: 3;
  background-color: #121212;
  border-color: #43bf66;
  transform: translate3d(0, 0, 1px);
}
.astronautCommonData--milkContentText {
  font-family: "Ubuntu Mono";
  font-style: $font-DefaultFontStyle;
  font-size: vhFn(3.733);
  line-height: vhFn(6.4);
  color: #43bf66;
}
.testMatrixColumn7 {
  position: absolute;
  left: vwFn(47) !important;
  z-index: 1 !important;
  transform: translate3d(0, 0, 1px);
}
.testMatrixColumn8 {
  position: absolute;
  left: vwFn(54) !important;
  z-index: 1 !important;
  transform: translate3d(0, 0, 1px);
}
.testMatrixColumn9 {
  position: absolute;
  left: vwFn(75) !important;
  transform: translate3d(0, 0, 1px);

  z-index: 1 !important;
}
