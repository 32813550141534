@use "../../../common/styles/variables.scss" as *;

.cowIntroPage {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  width: vwFn(100);
  height: vhFn(100);
}

.cow-intro--text {
  min-width: vwFn(10.63);
  min-height: vhFn(9.6);
  left: vwFn(1.5);
  top: vhFn(3.2);
  font-weight: 300;
  font-size: vhFn(4.25);
  line-height: vhFn(4.8);
  margin: vwFn(0.5) 0;
}

.textCaption--cowIntro {
  position: absolute;
  right: vwFn(56.85);
  top: vhFn(35.73);
  min-width: vwFn(1.59); 
  max-width: vwFn(30.59);
  min-height: vhFn(29.06);
  padding: vwFn(1.5) vwFn(1.5) vwFn(1.85);
  z-index: 3;
}

.cow-intro--name {
  position: relative;
  min-width: 100%;
  max-width: vwFn(30.59);
  max-height: vhFn(10.04);
  letter-spacing: vwFn(0.2);
  top: vhFn(2.66);
  font-family: $secondaryFont;
  font-style: normal;
  font-weight: bold;
  font-size: vhFn(17.06);
  line-height: vhFn(10.04);
  margin: vhFn(1.86) 0px;
  color: black;
  z-index: 3;
  text-shadow: 0px 10px 0.02px white, 9.8px 2.1px 0.02px white,
    4.2px -9.1px 0.02px white, -8px -6px 0.02px white, -7.6px 6.5px 0.02px white,
    4.8px 8.8px 0.02px white, 9.6px -2.8px 0.02px white,
    -0.7px -10px 0.02px white, -9.9px -1.5px 0.02px white,
    -3.5px 9.4px 0.02px white, 8.4px 5.4px 0.02px white, 7.1px -7px 0.02px white,
    -5.4px -8.4px 0.02px white, -9.4px 3.5px 0.02px white,
    1.4px 9.9px 0.02px white, 10px 0.8px 0.02px white, 2.9px -9.6px 0.02px white,
    -8.7px -4.8px 0.02px white, -6.6px 7.5px 0.02px white,
    5.9px 8px 0.02px white, 9.1px -4.1px 0.02px white,
    -2.1px -9.8px 0.02px white, -10px -0.1px 0.02px white,
    -2.2px 9.8px 0.02px white, 9.1px 4.2px 0.02px white, 6.1px -8px 0.02px white,
    -6.5px -7.6px 0.02px white, -8.8px 4.7px 0.02px white,
    2.7px 9.6px 0.02px white, 10px -0.6px 0.02px white,
    1.5px -9.9px 0.02px white, -9.3px -3.6px 0.02px white,
    -5.5px 8.4px 0.02px white, 7px 7.2px 0.02px white, 8.5px -5.3px 0.02px white,
    -3.4px -9.4px 0.02px white, -9.9px 1.3px 0.02px white,
    -0.8px 10px 0.02px white, 9.6px 2.9px 0.02px white,
    4.9px -8.7px 0.02px white, -7.5px -6.7px 0.02px white,
    -8.1px 5.9px 0.02px white, 4px 9.2px 0.02px white, 9.8px -2px 0.02px white,
    0.2px -10px 0.02px white, -9.7px -2.3px 0.02px white,
    -4.3px 9px 0.02px white, 7.9px 6.1px 0.02px white;
}

.cow-intro--swipe {
  background-image: url(../../../resources/assets/themes/lely/16x9/ui/icons/swipe.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  top: vhFn(39.73);
  left: vwFn(91.32888);
  width: vwFn(6.77);
  height: vhFn(22.4);
}

.cow-intro-leftTree {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/background-trees/trees3.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  top: vhFn(45.852);
  left: vwFn(2.365);
  width: vwFn(38.834);
  height: vhFn(35.48);
}

.cow-intro-house {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistory/background-layers/elements/house.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  top: vhFn(41.921);
  left: vwFn(50.463);
  width: vwFn(25.23);
  height: vhFn(39.488);
}
