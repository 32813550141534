@use "../../common/styles/variables.scss" as *;

.genericSlidePage {
  position: relative;
  background-image: url(../../resources/assets/themes/lely/16x9/screens/cowIntro/cow-intro-second-slide.svg);
  background-repeat: no-repeat;
  background-size: vwFn(100) vhFn(100);
  width: vwFn(100);
}

.textCaption--genericSlide {
  transform: translateX(vwFn(10)) translateY(vhFn(20));
  font-size: 18px;
  margin: 1px 1px 1px 1px;
  padding: 2px 10px 3px 10px;
  font-size: 18px;
  font-weight: 400;
  width: 15em;
  height: 25em;
  left: vwFn(10);
}

.textCaption--heading--genericSlide {
  text-align: left;
}
