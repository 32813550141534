@import "../../common/styles/variables.scss";

.astronautTeetAnimation {
  position: absolute;
  background-size: 100% 100%;
  width: vwFn(101);
  height: vhFn(100);
  background-color: #000;
  border: none;
}

.astronautTeetAnimation--nameTextCaption {
  position: absolute;
  min-width: vwFn(0);
  height: vhFn(12.8);
  top: vhFn(19.913);
  left: vwFn(18.6619);
  //padding: vhFn(3.2) vwFn(1.48);
  z-index: 4;
  background-color: #121212;
  border-color: #43bf66;
}

.astronautTeetAnimation--nameText {
  font-family: "Ubuntu Mono";
  font-style: $font-DefaultFontStyle;
  font-size: vhFn(3.733);
  line-height: vhFn(6.4);
  color: #43bf66;
  display: inline-block;
}

.astronautTeetAnimation--selectionButton {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/astronautLiveDataStory/astronautLiveDataStorySelectionTriangle.svg);
  width: vwFn(0.85);
  height: vhFn(1.5366);
  margin-left: vhFn(2.5);
  z-index: 2;
  display: inline-block;
}

.astronautTeetAnimation--connectionArrow {
  position: absolute;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/astronautLiveDataStory/astronautLiveDataStoryBetweenMatrixCowAndTeetAnimation.svg);
  width: vwFn(15.8251);
  height: vhFn(2);
  top: vhFn(50.613);
  left: vwFn(17.6379);
  z-index: 2;
  transform: translate3d(0, 0, 1px);
}

.astronautTeetAnimation--arrow {
  position: absolute;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/astronautLiveDataStory/astronautLiveDataTeetAnimationSlideArrow.svg);
  width: vwFn(0.85);
  height: vhFn(10.5366);
  top: vhFn(32.533);
  left: vwFn(25.4926);
  z-index: 2;
}

.astronautTeetAnimation--bigMatrixCow {
  position: absolute;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/astronautLiveDataStory/astronautLiveDataBigCow.svg);
  width: vwFn(42.48766);
  height: vhFn(61.333);
  top: vhFn(22.077);
  left: vwFn(9.977);
  z-index: 3;
}

.astronautTeetAnimation--nameTextCaption .labelled-text-input--label {
  display: none;
}
// .MuiPaper-root {
//   background-color: black !important;
//   border-color: #43bf66 !important;
//   box-sizing: border-box;
//   border: 1px solid;
//   width: vwFn(18) !important;
//   top: vhFn(10);
// }
// .MuiPopover-root .MuiMenu-paper {
//   top: vhFn(20.01) !important;
//   left: vwFn(5) !important;
// }
.testMatrixColumn10 {
  position: absolute;
  left: vwFn(2) !important;
  z-index: 1 !important;
}
.testMatrixColumn11 {
  position: absolute;
  left: vwFn(7) !important;
  z-index: 1 !important;
}
.testMatrixColumn12 {
  position: absolute;
  left: vwFn(50) !important;
  z-index: 1 !important;
}
