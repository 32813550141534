.viewport-size-display {
    position: fixed;
    z-index: 99999999;
    top: 0;
    left: 0;
    pointer-events: none;
}

.viewport-size-display--label {
    font-weight: bold;
}

.viewport-size-display--value {}