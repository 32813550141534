@use "../../common/styles/variables.scss" as *;

.familyTree {
  height: vhFn(82) !important;
  position: absolute;
  left: vwFn(14);
  overflow: visible;
  top: vhFn(-68.9);
}

.theme-adult .familyTree {
    height: vhFn(82) !important;
    position: absolute;
    left: vwFn(13);
    overflow: visible;
    top: vhFn(-50);
}

.familyTree--smaller .familyTree--basePart {
  background-image: url(./familyTreeBaseBranches.svg);
}

.familyTree--bigger .familyTree--basePart {
  background-image: url(./familyTreeAllBranches.svg);
  height: 86%;
}

.familyTree--smaller {
  width: vwFn(70);
}

.familyTree--bigger {
  width: vwFn(100);
  height: 96% !important;
}

.familyTree--basePart {
  display: block;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  z-index: 99;
}

.theme-adult .familyTree--basePart {
  height: 111%
}

.familyTree--smaller .familyTree--cow {
  position: absolute;
  left: 13% !important;
}

.familyTree--bigger .familyTree--cow {
  position: absolute;
  left: 7% !important;
}

.familyTree--smaller {
  width: vwFn(63);
}