@use "../../../common/styles/admin_variables.scss" as adminVar;
@use "../../../common/components/SaveChangesButton/SaveChangesButton.scss";

.FarmFeaturesPage {
  margin-left: 70px;
  margin-top: adminVar.$pagesMarginTop;
  height: 90vh;
  overflow: auto;
  padding: 0 19.17vw;
}

.farm-features-page--header {
  font-weight: 400;
  font-size: 26px;
  padding: 0.25vw 1vh;
}

.farm-features-intro {
  font-size: 14px;
  padding: 0vw 1vh;
  width: 30vw;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

.farm-features-mobile {
  position: absolute;
  background-image: url(../../../resources/assets/themes/lely/16x9/adminpanel/farmFeatures/mobile-phone-admin-panel.svg);
  background-repeat: no-repeat;
  right: 160px;
  width: 400px;
  height: 198.6px;
  top: 206px;
}
.farm-features-mobile-astronaut {
  position: absolute;
  background-image: url(../../../resources/assets/themes/lely/16x9/adminpanel/farmFeatureCheckbox/astronaut-checkbox-feature.svg);
  background-repeat: no-repeat;
  background-size: cover;
  top: 40px;
  right: 267px;
  width: 31.22px;
  height: 40.95px;
}
.farm-features-mobile-vector {
  position: absolute;
  background-image: url(../../../resources/assets/themes/lely/16x9/adminpanel/farmFeatureCheckbox/vector-checkbox-feature.svg);
  background-repeat: no-repeat;
  background-size: cover;
  top: 90px;
  right: 185px;
  width: 35.22px;
  height: 40.95px;
} 
.farm-features-mobile-juno {
  position: absolute;
  background-image: url(../../../resources/assets/themes/lely/16x9/adminpanel/farmFeatureCheckbox/juno-radio-button.svg);
  background-repeat: no-repeat;
  background-size: cover;
  top: 90px;
  right: 190px;
  width: 35.22px;
  height: 40.95px;
}
.farm-features-mobile-discovery {
  position: absolute;
  background-image: url(../../../resources/assets/themes/lely/16x9/adminpanel/farmFeatureCheckbox/discovery-mobile-feature.svg);
  background-repeat: no-repeat;
  background-size: cover;
  top: 113px;
  right: 245px;
  width: 23.22px;
  height: 22.95px;
}

.farm-features-mobile-collector {
  position: absolute;
  background-image: url(../../../resources/assets/themes/lely/16x9/adminpanel/farmFeatureCheckbox/discovery-90.svg);
  background-repeat: no-repeat;
  background-size: cover;
  top: 113px;
  right: 245px;
  width: 23.22px;
  height: 22.95px;
}
.farm-features-mobile-icecream {
  position: absolute;
  background-image: url(../../../resources/assets/themes/lely/16x9/actors/icecreamfactory/ice-cream-factory.svg);
  background-repeat: no-repeat;
  background-size: cover;
  top: 62px;
  right: 125px;
  width: 44.22px;
  height: 42.95px;
}
.farm-features-mobile-cheese {
  position: absolute;
  background-image: url(../../../resources/assets/themes/lely/16x9/actors/cheesefactory/cheese-factory.svg);
  background-repeat: no-repeat;
  background-size: cover;
  top: 68px;
  right: 125px;
  width: 46.22px;
  height: 42.95px;
}
.farm-features-mobile-orbiter {
  position: absolute;
  background-image: url(../../../resources/assets/themes/lely/16x9/adminpanel/farmFeatureCheckbox/orbiter-checkbox-feature.svg);
  background-repeat: no-repeat;
  background-size: cover;
  top: 62px;
  right: 125px;
  width: 42.22px;
  height: 42.95px;
}
.farm-features-mobile-dairy {
  position: absolute;
  background-image: url(../../../resources/assets/themes/lely/16x9/actors/dairyfactory/dairy-robot-factory.svg);
  background-repeat: no-repeat;
  background-size: 100%;
  top: 70px;
  right: 115px;
  width: 55.22px;
  height: 50.95px;
}
.farm-features-mobile-grazeway {
  position: absolute;
  background-image: url(../../../resources/assets/themes/lely/16x9/adminpanel/farmFeatureCheckbox/grazeway-checkbox-feature.svg);
  background-repeat: no-repeat;
  background-size: 100%;
  top: 100px;
  right: 280px;
  width: 55.22px;
  height: 53.95px;
}
.farm-features-mobile-luna {
  position: absolute;
  background-image: url(../../../resources/assets/themes/lely/16x9/adminpanel/farmFeatureCheckbox/luna-checkbox-mobile-feature.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  top: 145px;
  right: 130px;
  width: 25.22px;
  height: 25.95px;
  z-index: 10;
}
.FarmFeaturesPage .checkbox-group-selection--label {
  font-family: adminVar.$primaryFont;
  color: #121212;
  font-size: 16px;
  line-height: 18px;
  font-weight: 300;
  margin: 16px 0px;
}
.farmFeaturesSelection {
  background-color: #ffffff;
  margin: 1.25vw 0.5vh;
  width: 29vw;
  padding: 1.25vw;
  border-radius: 0.5vw 0.5vw 0.5vw 0.5vw;
  border: 1px solid #cfcbc3;
}
.farmFeaturesSelection .MuiGrid-grid-xs-12 {
  padding-bottom: 10px;
  border-bottom: 1px groove #cfcbc3;
}

.farm-features-language-block {
  background-color: #ffffff;
  margin: 1.25vw 0.5vh;
  width: 29vw;
  padding: 1.25vw;
  border-radius: 0.5vw 0.5vw 0.5vw 0.5vw;
  border: 1px solid #cfcbc3;
}
.checkbox-group-selection--form-control-astronaut {
  background-image: url(../../../resources/assets/themes/lely/16x9/adminpanel/farmFeatureCheckbox/astronaut-checkbox-feature.svg);
  background-repeat: no-repeat;
}
.checkbox-group-selection--form-control-vector {
  background-image: url(../../../resources/assets/themes/lely/16x9/adminpanel/farmFeatureCheckbox/vector-checkbox-feature.svg);
  background-repeat: no-repeat;
}
.checkbox-group-selection--form-control-discovery {
  background-image: url(../../../resources/assets/themes/lely/16x9/adminpanel/farmFeatureCheckbox/discovery-mobile-feature.svg);
  background-repeat: no-repeat;
}
.checkbox-group-selection--form-control-calm {
  background-image: url(../../../resources/assets/themes/lely/16x9/adminpanel/farmFeatureCheckbox/calm-checkbox-feature.svg);
  background-repeat: no-repeat;
}
.checkbox-group-selection--form-control-grazeway {
  background-image: url(../../../resources/assets/themes/lely/16x9/adminpanel/farmFeatureCheckbox/grazeway-checkbox-feature.svg);
  background-repeat: no-repeat;
}
.checkbox-group-selection--form-control-luna {
  background-image: url(../../../resources/assets/themes/lely/16x9/adminpanel/farmFeatureCheckbox/luna-checkbox-feature.svg);
  background-repeat: no-repeat;
}
.checkbox-group-selection--form-control-astronaut {
  height: 128px;
  width: 100px;
  background-size: auto 100px;
  background-position-y: 5px;
  background-repeat: no-repeat;
  align-items: flex-end !important;
  margin-left: 10px !important;
  flex: 25% 1;
}
.checkbox-group-selection--form-control-vector {
  height: 128px;
  width: 100px;
  background-size: auto 100px;
  background-position-y: 5px;
  background-repeat: no-repeat;
  align-items: flex-end !important;
  margin-left: 10px !important;
  flex: 25% 1;
}
.checkbox-group-selection--form-control-discovery {
  height: 128px;
  width: 100px;
  background-size: auto 100px;
  background-position-y: 5px;
  background-repeat: no-repeat;
  align-items: flex-end !important;
  margin-left: 10px !important;
  flex: 25% 1;
}
.checkbox-group-selection--form-control-calm {
  height: 128px;
  width: 100px;
  background-size: auto 100px;
  background-position-y: 5px;
  background-repeat: no-repeat;
  align-items: flex-end !important;
  margin-left: 10px !important;
  flex: 25% 1;
}
.checkbox-group-selection--form-control-grazeway {
  height: 128px;
  width: 100px;
  background-size: auto 100px;
  background-position-y: 5px;
  background-repeat: no-repeat;
  align-items: flex-end !important;
  margin-left: 10px !important;
  flex: 25% 1;
}
.checkbox-group-selection--form-control-luna {
  height: 128px;
  width: 100px;
  background-size: auto 100px;
  background-position-y: 5px;
  background-repeat: no-repeat;
  align-items: flex-end !important;
  margin-left: 10px !important;
  flex: 25% 1;
}
.checkbox-group-selection--form-control-calm .MuiIconButton-root {
  padding: 0px !important;
}
.checkbox-group-selection--form-control-astronaut .MuiIconButton-root {
  padding: 0px !important;
}
// .checkbox-group-selection--form-control-vector .MuiIconButton-root {
//   padding: 0px !important;
// }
.checkbox-group-selection--form-control-discovery .MuiIconButton-root {
  padding: 0px !important;
}
.checkbox-group-selection--form-control-grazeway .MuiIconButton-root {
  padding: 0px !important;
}
.checkbox-group-selection--form-control-luna .MuiIconButton-root {
  padding: 0px !important;
}
.btn-save-changes {
  display: block !important;
  justify-content: center;
  align-items: center;
  padding: 12px 15px;
  width: 162px;
  height: 40px;
  background: adminVar.$color-LelyRed !important;
  border-radius: 4px;
  font-family: adminVar.$primaryFont;
  font-style: normal;
  font-weight: normal;
  line-height: 16px;
  text-align: center;
  color: #ffffff !important;
  margin-top: 20px !important;
  margin-left: 355px !important;
}
.saved-button {
  margin-top: 0vh !important;
  margin-bottom: 5vh;
  min-height: 4vh;
  position: center;
  background-color: #73bd07 !important;
}
.farm-features-languages {
  display: grid;
  margin-bottom: 5px;
}
.farm-features-languages .labelled-switch--switch-container {
  float: right;
  display: flex;
  text-align: right;
}
.farm-features-languages .labelled-switch--label {
  display: inline-block;
  margin-top: 10px;
}
.farm-features-language-intro {
  margin-top: 20px;
  margin-bottom: 15px;
  text-align: left;
}

.farm-features-tractor-block {
  background-color: #ffffff;
  margin: 1.25vw 0.5vh;
  width: 29vw;
  padding: 1.25vw;
  border-radius: 0.5vw 0.5vw 0.5vw 0.5vw;
  border: 1px solid #cfcbc3;

  .farm-features-do-you-have-a-tractor {
    margin-bottom: 18px;
    width: 169px;
    height: 18px;
    color: adminVar.$font-DefaultFontStyle;
    font-family: adminVar.$primaryFont;
    font-style: adminVar.$font-DefaultFontStyle;
  }

  .farm-features-labelled-text-description-for-tractor {
    width: 100%;
    height: 100px;
    border: 1px #121212 !important;
  }

  .farm-features-tractor-container {
    display: flex;

    .farm-features-tractor-main-color {
      margin-top: 20px;
      margin-bottom: 8px;
      height: 18px;
      font-size: 16px;
      line-height: 18.38px;
      font-family: adminVar.$primaryFont;
      font-style: adminVar.$font-DefaultFontStyle;
    }

    .farm-features-main-color-picker {
      margin-top: 16px;
      margin-bottom: 8px;
      height: 38px;
      font-size: 16px;
      line-height: 18.38px;
      font-family: adminVar.$primaryFont;
      font-style: adminVar.$font-DefaultFontStyle;
      background-color: adminVar.$color-LelyWhite;
      padding: 10px, 16px, 10px, 16px;
      border-radius: 4px;
      align-items: flex-start;
      border: 1px adminVar.$color-gray-light solid;
      box-shadow: inset 0px 2px 5px rgba(222, 219, 214, 0.4);
    }

    .farm-features-tractor-line-color {
      margin-top: 16px;
      margin-bottom: 8px;
      height: 18px;
      font-size: 16px;
      line-height: 18.38px;
      font-family: adminVar.$primaryFont;
      font-style: adminVar.$font-DefaultFontStyle;
    }
  }

  .farm-features-tractor {
    align-self: center;
    width: 143.49px;
    height: 121.24px;
    margin-top: 20px;
    margin-left: 36.26px;
  }

  .farm-features-tractor--dropzone {
    width: 100%;
  }
}

.farm-features-radio-buttons-css {
  align-items: center;
}

.image-radio-selection--form-control-style3 {
  display: contents !important;
}

.farm-features-tractor > div {
  position: static !important;
}
.FarmFeaturesPage .btn-save-changes {
  padding: 12px 15px;
  width: 162px;
  height: 40px;
  background: adminVar.$color-LelyRed !important;
  border-radius: 4px;
  font-family: adminVar.$primaryFont;
  font-style: normal;
  font-weight: normal;
  line-height: 16px;
  color: #ffffff !important;
  margin-top: 20px !important;
  margin-left: 355px !important;
}
.FarmFeaturesPage .btn-already-saved {
  margin-top: 0vh !important;
  margin-left: 18vw;
  background-color: #73bd07 !important;
}
.image-radio-selection--form-control-collector {
  background-image: url(../../../resources/assets/themes/lely/16x9/adminpanel/farmFeatureCheckbox/discovery-90.svg);
  height: 140px;
  width: 113px;
}
.image-radio-selection--form-control-discovery {
  background-image: url(../../../resources/assets/themes/lely/16x9/adminpanel/farmFeatureCheckbox/collector-checkbox-feature.svg);
  height: 140px;
  width: 113px;
}
.image-radio-selection--form-control-no-cleaning-robot {
  background-image: url(../../../resources/assets/themes/lely/16x9/adminpanel/farmFeatureCheckbox/no-robot-feature.svg);
  height: 140px;
  width: 113px;
}
.image-radio-selection--form-control-vector {
  background-image: url(../../../resources/assets/themes/lely/16x9/adminpanel/farmFeatureCheckbox/vector-radio-button.svg);
  height: 140px;
  width: 113px;
}
.image-radio-selection--form-control-juno {
  background-image: url(../../../resources/assets/themes/lely/16x9/adminpanel/farmFeatureCheckbox/juno-radio-button.svg);
  height: 140px;
  width: 113px;
}
.image-radio-selection--form-control-no-feeding-robot {
  background-image: url(../../../resources/assets/themes/lely/16x9/adminpanel/farmFeatureCheckbox/no-robot-feature.svg);
  height: 140px;
  width: 113px;
} 

.FarmFeaturesPage .image-radio-selection--form-control {
  height: 130px;
  width: 110px;
  background-size: auto 100px;
  background-position-y: 5px;
  background-repeat: no-repeat;
  align-items: flex-end !important;
  margin-left: 0 !important;
  flex: 20%;
} 
.image-radio-selection--form-control-juno {
  background-size: auto 90px !important;
  background-position-y: 5px;
} 
.image-radio-selection--form-control-no-cleaning-robot {
  background-position-x: 10px;
} 
.image-radio-selection--form-control-no-feeding-robot {
  background-position-x: 10px;
} 
.farmFeaturesSelection .image-radio-selection--radio-group {
  padding-bottom: 20px;
  border-bottom: 1px groove #cfcbc3;
}
