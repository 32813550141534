@use "../../common/styles/variables.scss" as *;

.animalWelfareStorySecondSlide {
  position: absolute;
  background-size: 100% 100%;
  width: vwFn(100.5);
  height: vhFn(100);
}

.animalWelfareStorySecondSlide--textCaption {
  position: absolute;
  width: vwFn(55.41);
  min-height: vhFn(1);
  top: vhFn(26.266);
  left: vwFn(39.619);
  padding: vhFn(3.2) vwFn(1.48);
  z-index: 2;
}

.animalWelfareStorySecondSlide--text {
  font-family: $primaryFont;
  font-style: $font-DefaultFontStyle;
  font-size: vhFn(3.73);
  line-height: vhFn(6.4);
  color: $color-gray-popup;
  margin: vhFn(1.87) 0;
}



.animalWelfareStorySecondSlide--title {
  font-family: "Chelsea Market";
  font-style: $font-DefaultFontStyle;
  font-weight: 400;
  font-size: vhFn(4.27);
  line-height: vhFn(4.8);
  letter-spacing: vwFn(0.1);
  color: $color-gray-popup;
}

.theme-adult .animalWelfareStorySecondSlide-title {
  font-family: BourtonBase !important;
  padding-bottom: vhFn(2);
  font-weight: 400;
  line-height: vhFn(6.4);
  font-size: vhFn(4.8);
}

@media only screen and (orientation: portrait) {
  .animalWelfareStorySecondSlide--title {
    font-family: "Chelsea Market";
    font-style: $font-DefaultFontStyle;
    font-weight: 400;
    font-size: vhFn(4.27);
    line-height: vhFn(4.8);
    letter-spacing: vwFn(0.1);
    color: $color-gray-popup;
    padding-bottom: vhFn(2);
  }
  .animalWelfareStorySecondSlide--textCaption {
    position: absolute;
    min-width: vwFn(30.6);
    min-height: vhFn(24.26);
    top: vhFn(29.072);
    left: vwFn(38.339);
    padding: vhFn(3.2) vwFn(1.48);
  }
}

.animalWelfareStorySecondSlide-farmer-title {
  padding-bottom: vhFn(1);
  font-weight: 700;
  font-size: vhFn(3.75);
}

.theme-adult .animalWelfareStorySecondSlide-treeAndSmallBush {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowhistoryForAdult/background-layers/elements/treeAndBushSecondSlide.svg);
  background-repeat: no-repeat;
  background-size: 50% 100%;
  position: absolute;
  top: vhFn(45.852);
  left: vwFn(2.365);
  width: vwFn(38.834);
  height: vhFn(35.48);
}

.theme-adult .animalWelfareStorySecondSlide--title {
  //font-family: "Chelsea Market" !important;
  font-weight: 400;
  line-height: vhFn(6.4);
  font-size: vhFn(4.8);
}
.animalWelfareStorySecondSlide--imgCaption {
  position: absolute;
  width: vwFn(29.85);
  height: vhFn(78.93);
  background-color: $color-LelyWhite;
  box-shadow: 0px vwFn(0.25) vwFn(0.7) rgba(18, 18, 18, 0.2);
  padding: vhFn(3.2) vwFn(1.48);
  background-size: cover;
}

.animalWelfareStorySecondSlide--imgCaption {
  transform: rotate(5.184deg);
  left: vwFn(6.6);
  bottom: vhFn(8);
  z-index: 3;
}

// .animalWelfareStorySecondSlide--imgCaption--1 {
//   transform: rotate(-11.35deg);
//   left: vwFn(11.33);
//   bottom: vhFn(14.67);
// }

.animalWelfareStorySecondSlide--imgContainer {
  width: 100%;
  height: vhFn(57.77);
  border-width: vhFn(0.53) vwFn(0.246);
  border-style: solid;
  border-color: $color-GrayDairyDarker;
  object-fit: cover; 
  z-index: 2;
}

.animalWelfareStorySecondSlide--imgText {
  font-family: $secondaryFont;
  font-style: $font-DefaultFontStyle;
  font-weight: $font-regular;
  font-size: vhFn(3.73);
  line-height: vhFn(6.4);
  text-align: center;
}
.theme-adult .animalWelfareStorySecondSlide--imgCaption{
  border-color: #000000; 
  border: solid vhFn(1);
} 
.theme-adult .farmerStoryDynamicSlide--imgContainer {
  border: none;
} 