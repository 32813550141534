@use "../../common/styles/variables.scss" as *;

// .astronautPopup {
//   position: absolute;
//   top: vhFn(17.5);
//   left: vwFn(7.66);
//   background-color: $color-LelyWhite;
//   width: vwFn(67.73);
//   min-height: vhFn(72.27);
//   border-left: vwFn(0.12) solid $color-GrayDairyLight;
//   border-right: vwFn(0.12) solid $color-GrayDairyLight;
//   border-top: vhFn(0.27) solid $color-GrayDairyLight;
//   border-bottom: vhFn(0.27) solid $color-GrayDairyLight;
//   box-sizing: border-box;
//   box-shadow: 0px 2px 5px rgba(222, 219, 214, 0.4);
//   border-radius: vwFn(0.5) vwFn(0.5) vwFn(0.5) vwFn(0.5);
//   padding: vhFn(4) vwFn(2.46);
// }

// .astronautPopup--title {
//   width: vwFn(43.1);
//   font-family: $secondaryFont;
//   font-weight: $font-bold;
//   font-style: $font-DefaultFontStyle;
//   color: $color-gray-popup;
//   font-size: vhFn(6.4);
//   line-height: vhFn(9.33);
//   margin-bottom: vhFn(1.87);
// }

// .astronautPopup--description {
//   width: vwFn(43.1);
//   font-family: $primaryFont;
//   font-weight: $font-regular;
//   font-style: $font-DefaultFontStyle;
//   color: $color-gray-popup;
//   height: vhFn(38.4);
//   font-size: vhFn(3.73);
//   line-height: vhFn(6.4);
//   margin-bottom: vhFn(4);
// }

.astronautPopup--btnCallToAction {
  background-color: $color-LelyRed;
  background-repeat: no-repeat;
  border-radius: vwFn(0.5) vwFn(0.5) vwFn(0.5) vwFn(0.5);
  border-left: vwFn(0.12) solid $color-GrayDairyLight;
  border-right: vwFn(0.12) solid $color-GrayDairyLight;
  border-top: vhFn(0.27) solid $color-GrayDairyLight;
  border-bottom: vhFn(0.27) solid $color-GrayDairyLight;
  background-size: 100% 100%;
  //width: vwFn(19.889);
  height: vhFn(10.66);
  font-size: vwFn(1.5);
  color: $color-LelyWhite;
  top: vhFn(3.86);
  line-height: vhFn(4.266);
  min-width: vwFn(0.18);
  font-size: vwFn(1.5);
  color: $color-LelyWhite;
  padding: vhFn(2) vwFn(2);
  min-width: vwFn(0.18);
  display: inline-block;
}
.astronautPopupLiveData--btnCallToAction {
  background-color: $color-LelyRed;
  background-repeat: no-repeat;
  border-radius: vwFn(0.5) vwFn(0.5) vwFn(0.5) vwFn(0.5);
  border-left: vwFn(0.12) solid $color-GrayDairyLight;
  border-right: vwFn(0.12) solid $color-GrayDairyLight;
  border-top: vhFn(0.27) solid $color-GrayDairyLight;
  border-bottom: vhFn(0.27) solid $color-GrayDairyLight;
  background-size: 100% 100%;
  min-width: vwFn(0.18);
  height: vhFn(10.66);
  font-size: vwFn(1.5);
  color: $color-LelyWhite;
  top: vhFn(3.86);
  margin-left: vwFn(2);
  line-height: vhFn(4.266);
  display: inline-block;
  padding: vhFn(2);
}
// .astronautPopup--btnClosePopup {
//   position: absolute;
//   top: vhFn(-2);
//   left: vwFn(50);
//   background-image: url(../../resources/assets/themes/lely/16x9/ui/popup/close-popup-button.svg);
//   background-repeat: no-repeat;
//   background-size: 100% 100%;
//   width: vwFn(8.5);
//   height: vhFn(11.5);
// }

.astronautPopup--astronaut {
  background-image: url(../../resources/assets/themes/lely/16x9/popups/astronaut/cow--astronaut--robot.svg);
  position: absolute;
  width: vhFn(73.35);
  height: vhFn(73.35);
  background-size: 100% 100%;
  left: vwFn(56);
  top: vhFn(0);
}

.cow-daily--milking--slide .astronautPopup--btnCallToAction {
  display: none;
}

.cow-daily--milking--slide .astronautPopup {
  position: absolute;
  top: vhFn(13.5);
  left: vwFn(7.66);
  background-color: $color-LelyWhite;
  width: vwFn(67.73);
  min-height: vhFn(60.27);
}
.astronautPopup--buttons, .orbiter--buttons {
  display: inline-flex;
  gap: vwFn(3); 
  margin-bottom: vhFn(2);
}
.theme-adult .astronautPopup--astronaut {
  background-image: url(../../resources/assets/themes/lely/16x9/popups/astronaut/astronaut--popup--image.svg);
}
