@use "../../common/styles/variables.scss" as *;

// .sustainabilityPopup {
//   position: absolute;
//   left: vwFn(5.6);
//   top: vhFn(10);
//   width: vwFn(87.43);
//   min-height: vhFn(82);
// }

// .sustainabilityTextInfo {
//   position: absolute;
//   width: vwFn(67.73);
//   min-height: vhFn(0);
//   border-left: vwFn(0.12) solid $color-GrayDairyLight;
//   border-right: vwFn(0.12) solid $color-GrayDairyLight;
//   border-top: vhFn(0.27) solid $color-GrayDairyLight;
//   border-bottom: vhFn(0.27) solid $color-GrayDairyLight;
//   box-sizing: border-box;
//   box-shadow: 0px 2px 5px rgba(222, 219, 214, 0.4);
//   border-radius: vwFn(0.5) vwFn(0.5) vwFn(0.5) vwFn(0.5);
//   top: vhFn(9.86);
//   left: vwFn(0);
//   background-color: $color-LelyWhite;
// }

// .sustainabilityPopup--title {
//   font-family: $secondaryFont;
//   color: $color-LelyBlack;
//   font-weight: 700;
//   font-size: vhFn(6.4);
//   line-height: vhFn(9.333);
//   margin-top: vhFn(3.6232);
//   margin-left: vwFn(2.2322);
//   margin-bottom: vhFn(1.86);
// }

// .sustainabilityPopup--description {
//   font-family: "Ubuntu";
//   font-style: normal;
//   font-weight: 400;
//   font-size: vhFn(3.733);
//   line-height: vhFn(6.4);
//   width: vwFn(47.78);
//   min-height: vhFn(0);
//   margin-left: vwFn(2.23222);
//   margin-top: vhFn(0);
// }

// .sustainabilityPopup--btnClosePopup {
//   position: absolute;
//   top: vhFn(-3);
//   left: vwFn(49.25);
//   background-image: url(../../resources/assets/themes/lely/16x9/ui/popup/close-popup-button.svg);
//   background-repeat: no-repeat;
//   background-size: 100% 100%;
//   width: vwFn(8.5);
//   height: vhFn(11.5);
//   padding: 0;
//   margin: 0;
//   z-index: 1;
// }

.sustainabilityPopup--logo {
  position: absolute;
  background-image: url(../../resources/assets/themes/lely/16x9/actors/sustainabilityFarm/sustainabilityPopupImage.svg);
  background-repeat: no-repeat;
  width: vhFn(71.35);
  height: vhFn(77.05);
  background-size: 100% 100%;
  left: vwFn(55.5);
  //top: vhFn(2);
}

// .theme-adult .sustainabilityPopup--title {
//   margin-left: vwFn(10);
//   letter-spacing: .1cm;
// }
// .is-adult-selected {
//   .sustainabilityPopup--title {
//     font-weight: 400;
//     margin-left: vwFn(7.5);
//     font-family: BourtonBase !important;
//     text-transform: uppercase;
//     letter-spacing: vwFn(0.1);
//   } 
//   .sustainabilityPopup--btnClosePopup {
//     background-image: url(../../resources/assets/themes/lely/16x9/ui/popup/close-popup-button-adult-theme.svg);
//     width: vwFn(6);
//     height: vhFn(12.66);
//     left: vwFn(1.84);
//     top: vhFn(-0.375);
// }
// }

// .sustainabilityPopup--btnCallToAction {
//   background-color: $color-LelyRed;
//   background-repeat: no-repeat;
//   border-radius: vwFn(0.5) vwFn(0.5) vwFn(0.5) vwFn(0.5);
//   border-left: vwFn(0.12) solid $color-GrayDairyLight;
//   border-right: vwFn(0.12) solid $color-GrayDairyLight;
//   border-top: vhFn(0.27) solid $color-GrayDairyLight;
//   border-bottom: vhFn(0.27) solid $color-GrayDairyLight;
//   background-size: 100% 100%;
//   min-width: vwFn(0.1);
//   height: vhFn(10.66);
//   font-size: vwFn(1.5);
//   color: #ffffff;
//   bottom: vhFn(2); 
//   padding: vhFn(2) vwFn(2);
//   line-height: vhFn(4.266);
//   margin: vhFn(3) vwFn(2);  
// }
.sustainabilityPopup--defaultImage {
  background-image: url(../../resources/assets/themes/lely/16x9/actors/sustainabilityFarm/sustModal.png);
}
.sustainabilityPopup--buttons {
  //margin-top: vhFn(5);
  display: inline-flex;
  gap: vwFn(3);
}

.theme-adult .sustainabilityPopup--buttons {
  margin-top: vhFn(0);
  margin-left: vwFn(5);
} 
.sustainabilityPopup--btnCallToActionAnimalWelfare {
  background-color: $color-LelyRed;
  background-repeat: no-repeat;
  border-radius: vwFn(0.5) vwFn(0.5) vwFn(0.5) vwFn(0.5);
  border-left: vwFn(0.12) solid $color-GrayDairyLight;
  border-right: vwFn(0.12) solid $color-GrayDairyLight;
  border-top: vhFn(0.27) solid $color-GrayDairyLight;
  border-bottom: vhFn(0.27) solid $color-GrayDairyLight;
  background-size: 100% 100%;
  //width: vwFn(19.889);
  height: vhFn(10.66);
  font-size: vwFn(1.5);
  color: $color-LelyWhite;
  top: vhFn(3.86);
  line-height: vhFn(4.266);
  min-width: vwFn(0.18);
  font-size: vwFn(1.5);
  color: $color-LelyWhite;
  padding: vhFn(2) vwFn(2);
  min-width: vwFn(0.18);
  display: inline-block;
}