@use "../../common/styles/variables.scss" as *;

.lunaStoryThirdSlide {
  position: absolute;
  background-size: 100% 100%;
  width: vwFn(100.5);
  height: vhFn(100);
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/resting/resting3.svg);
}

.lunaStoryThirdSlide--textCaption {
  position: absolute;
  width: vwFn(55.41);
  min-height: vhFn(0);
  top: vhFn(29.3946);
  left: vwFn(40.39961);
  padding: vhFn(3.2) vwFn(1.48);
  z-index: 2;
}

.lunaStoryThirdSlide--title {
  font-family: $secondaryFont;
  font-style: $font-DefaultFontStyle;
  font-weight: $font-bold;
  font-size: vhFn(4.27);
  line-height: vhFn(4.8);
  letter-spacing: vwFn(0.1);
  color: $color-gray-popup;
}

.lunaStoryThirdSlide--text {
  font-family: $primaryFont;
  font-style: $font-DefaultFontStyle;
  font-size: vhFn(3.73);
  line-height: vhFn(6.4);
  color: $color-gray-popup;
  margin: vhFn(1.87) 0;
}

.lunaStoryThirdSlide--imgContainer {
  width: 100%;
  height: vhFn(57.77);
  border-width: vhFn(0.53) vwFn(0.246);
  border-style: solid;
  border-color: $color-GrayDairyDarker;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/lunarobot/lunaThirdSlide.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-color: $color-GrayDairyDarker;
}

.lunaStoryThirdSlide--imgCaption {
  position: absolute;
  width: vwFn(29.855);
  height: vhFn(78.93);
  background-color: $color-LelyWhite;
  box-shadow: 0px vwFn(0.25) vwFn(0.7) rgba(18, 18, 18, 0.2);
  padding: vhFn(3.2) vwFn(1.48);
  transform: rotate(-15.18deg);
  left: vwFn(9.77);
  top: vhFn(2.592);
}

.lunaStoryThirdSlide .discoveryStory--bottomColorOverlay {
  width: 80%;
}
.theme-adult .lunaStoryThirdSlide {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowDailyForAdult/backgrounds/resting/background-pcd-restingAdult7.svg);
}
.theme-adult .lunaStoryThirdSlide--transitionTree {
  position: absolute;
  background-size: 100% 100%;
  width: vwFn(60);
  left: vwFn(60);
  height: vhFn(100);
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowDailyForAdult/backgrounds/eating/full_tree.png);
  display: block;
  z-index: 1;
}
