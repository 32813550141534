@import "../../common/styles/variables.scss";

.astronautUdder {
  background-color: #000;
  width: vwFn(43.842);
  height: vhFn(89.6);
  position: absolute;
  left: vwFn(52.46);
  top: vhFn(0);
}

.astronautUdder--udder {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/astronautLiveDataStory/udder.svg);
  background-size: contain;
  width: vwFn(14.0394);
  height: vhFn(26.4);
  margin: 0 auto;
}
.astronautUdder--colorsTitle {
  height: vhFn(17.6);
  padding: vhFn(2.5) vwFn(1.25);
  min-width: vwFn(30);
}
.astronautUdder--textHeaderArea {
  height: vhFn(17.6);
  // padding: vhFn(3.2) vwFn(1.48);
  //border: 1px solid #24a148;
  border-radius: 4px;
}

.astronautUdder--animationArea {
  position: relative;
  width: 100%;
  height: vhFn(70);
  border: 1px solid #24a148;
  border-radius: 4px;
}

.astronautUdder--xyzTitle,
.astronautUdder--colorsTitle {
  height: vhFn(17.6);
  padding: vhFn(2.5) vwFn(1.25);
  min-width: vwFn(30);
  background-color: transparent;
  border: 1px solid #24a148;
}

.astronautUdder--bottomNavigationArea {
  position: relative;
  width: 100%;
  height: vhFn(70);
  color: #24a148;
  text-decoration: underline;
}

.astronautUdder--bottomNavigationArea--positionButton {
  position: absolute;
  left: 0;
}

.astronautUdder--bottomNavigationArea--milkColorButton {
  position: absolute;
  right: 0;
}

.astronautUdder--bottomNavigationArea .is-active {
  font-weight: bold;
  font-size: vhFn(3.73);
}
.astronautUdder--bottomNavigationArea .is-not-active {
  font-size: vhFn(3.73);
}

.astronautUdder--bottomNavigationArea--positionButton.is-not-active:before {
  content: "<";
  width: vwFn(2);
}
.astronautUdder--bottomNavigationArea--milkColorButton.is-not-active:after {
  content: ">";
  width: vwFn(2);
}
