@use "../../common/styles/variables.scss" as *;

.cow-daily--eating--slide {
  width: vwFn(100);
  height: vhFn(100);
}

.cow-daily-eating--name {
  position: static;
  width: vwFn(18.22);
  height: vhFn(10.4);
  left: vwFn(1.47);
  top: vhFn(14.66);
  font-family: "Amatic SC", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: vhFn(6);
  line-height: vhFn(10.4);
  margin: vhFn(1.86) 0px;
}

.cow-daily--eating--text {
  position: static;
  width: vwFn(19.33);
  height: vhFn(12.8);
  font-family: $secondaryFont;
  font-weight: bold;
  font-size: vhFn(4);
  line-height: vhFn(6.4);
  color: #766e68;
}

.cow--daily-eating {
  left: vwFn(50) !important;
  top: vhFn(20) !important;
  width: vhFn(60) !important;
  height: vhFn(65) !important;
  position: absolute;
  bottom: 0;
}

.textCaption--cowDailyEating {
  position: absolute;
  left: vwFn(5.73);
  top: vhFn(39.61);
  width: vwFn(29);
  min-height: vhFn(0.26);
  padding: 0 vwFn(0.2);
}
