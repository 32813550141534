@use "../../styles/variables.scss" as *;

.radio-group-selection {
  display: flex;
  flex-wrap: wrap;
  background-color: white;
  width: 100%;
  font-size: 13px;
}

.radio-group-selection .radio-group-selection-label {
  font-size: 13px !important;
  font-family: Ubuntu, sans-serif;
  padding-left: 15px;
}

.radio-group {
  flex-direction: row;
  padding-left: 10px;
}

.radio-item {
  flex-basis: 50%;
}

.switch-container {
  //   position: absolute;
  //   top: 50%;
  //   left: 50%;
  //   -webkit-transform: translate3d(-50%, -50%, 0);
  //   transform: translate3d(-50%, -50%, 0);
}

input[type="checkbox"].switch {
  position: absolute;
  opacity: 0;
}

input[type="checkbox"].switch + div {
  vertical-align: middle;
  width: 40px;
  height: 20px;
  border-radius: 10px;
  background-color: grey;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  -webkit-transition-property: background-color, box-shadow;
  transition-property: background-color, box-shadow;
  cursor: pointer;
}

input[type="checkbox"].switch + div span {
  position: absolute;
  font-size: 1.6rem;
  color: white;
  margin-top: 12px;
}

input[type="checkbox"].switch + div span:nth-child(1) {
  margin-left: 15px;
}

input[type="checkbox"].switch + div span:nth-child(2) {
  margin-left: 55px;
}

input[type="checkbox"].switch:checked + div {
  width: 57px;
  background-position: 0 0;
  background-color: #3b89ec;
}

input[type="checkbox"].switch + div {
  width: 57px;
  height: 26px;
}

input[type="checkbox"].switch:checked + div {
  background-color: #c30a14;
}

input[type="checkbox"].switch + div > div {
  float: left;
  width: 24px;
  height: 24px;
  border-radius: 10px;
  background: #ffffff;
  -webkit-transition-timing-function: cubic-bezier(1, 0, 0, 1);
  transition-timing-function: cubic-bezier(1, 0, 0, 1);
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  -webkit-transition-property: transform, background-color;
  transition-property: transform, background-color;
  pointer-events: none;
  margin-top: 1px;
  margin-left: 1px;
}

input[type="checkbox"].switch:checked + div > div {
  -webkit-transform: translate3d(20px, 0, 0);
  transform: translate3d(20px, 0, 0);
  background-color: #ffffff;
}

input[type="checkbox"].bigswitch.switch + div > div {
  width: 44px;
  height: 44px;
  margin-top: 1px;
}

input[type="checkbox"].switch:checked + div > div {
  -webkit-transform: translate3d(31px, 0, 0);
  transform: translate3d(31px, 0, 0);
}

.radio-group {
  .MuiFormControlLabel-root {
    margin: 0px;
  }
  .MuiButtonBase-root {
    padding: 5px;
  }
  .MuiIconButton-root {
    font-size: 1.2rem;
  }
  .MuiSvgIcon-root {
    font-size: 1rem;
  }
  .MuiFormControlLabel-label {
    font-size: 13px;
    font-weight: 400;
    color: $color-LelyBlack;
    line-height: 19px;
  }
  .MuiRadio-root {
    color: #d8d8d8;
  }
  .MuiRadio-colorSecondary.Mui-checked {
    color: $color-LelyRed;
  }
}
.checkbox-group-selection--container {
  display: flex;
  flex-wrap: wrap;
}
