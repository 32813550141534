@use "../../common/styles/variables.scss" as *;

// .farmerPopup { 
//   position: fixed;
//   top: 50%;
//   left: 50%;
//   /* bring your own prefixes */
//   transform: translate(-50%, -50%);
//   width: vwFn(87.684);
//   height: vhFn(77.6);
// }
// .farmerPopupTextBox { 
//   display: inline;
//   position: fixed;
//   width: vwFn(67.73);
//   min-height: vhFn(0.66);
//   background-color: white;
//   top: 50%;
//   transform: translate(0%, -50%);
//   border-left: vwFn(0.12) solid $color-GrayDairyLight;
//   border-right: vwFn(0.12) solid $color-GrayDairyLight;
//   border-top: vhFn(0.27) solid $color-GrayDairyLight;
//   border-bottom: vhFn(0.27) solid $color-GrayDairyLight;
//   box-sizing: border-box;
//   box-shadow: 0px 2px 5px 5px rgba(222, 219, 214, 0.4);
//   border-radius: vwFn(0.5) vwFn(0.5) vwFn(0.5) vwFn(0.5); 
//   padding: vhFn(2) vwFn(2.5);
//   padding-top: vhFn(1.5);
// } 
// .farmerPopup--title { 
//   position: absolute;
//   top: vhFn(3.2);
//   font-family: $secondaryFont;
//   font-weight: bold;
//   color: $color-LelyBlack;
//   font-size: vhFn(6.4);
//   letter-spacing: vwFn(0.3);
//   max-width: vwFn(43.22);
// } 
// .farmerPopup--btnClosePopup {
//   display: inline;
//   background-image: url(../../resources/assets/themes/lely/16x9/ui/popup/close-popup-button.svg);
//   background-repeat: no-repeat;
//   background-size: 100% 100%;
//   width: vwFn(8.7);
//   height: vhFn(11.5);
//   z-index: 10;
//   margin-top: vhFn(-2); 
//   margin-left: vwFn(40);
// } 
// .labelValue--label {
//   font-size: vhFn(3.73);
//   font-weight: bold;
//   width: vwFn(20);
// }

// .labelValue--value {
//   font-size: vhFn(3.73);
//   font-weight: normal;
// } 
// .farmerPopup--farmerQuote {
//   width: vwFn(50.1);
//   font-size: vhFn(3.73);
//   line-height: vhFn(6.4);
//   font-style: italic;
//   margin-top: 0; 
//   margin-bottom: 0 !important;
// } 
// .farmerPopup--farmerContainer {
//   display: flex;
//   width: vhFn(70.6);
//   height: vhFn(77.6);
//   margin-left: vwFn(55);
//   -webkit-clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
//   clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
// }  
// .farmerPopupTextBox .labelValue--label {
//   padding-bottom: vhFn(0);
// }
// .farmerPopup--frame {
//   display: inline;
//   width: vhFn(70.6);
//   height: vhFn(77.6);
//   background-repeat: no-repeat;
//   background-size: 100% 100%;
//   background-image: url(../../resources/assets/themes/lely/16x9/actors/actorFrame/actorFrame.svg); 
//   z-index: 99999;
// } 
// .farmerPopup--farmer { 
//   position: absolute;
//   width: vhFn(65);
//   height: vhFn(70.5);
//   background-repeat: no-repeat;
//   background-size: 100% 100%;
//   -webkit-clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
//   clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
//   object-fit:contain;
//   z-index: 10;
//   margin-left: vhFn(3.5);
//   margin-top: vhFn(2);
// }


// .theme-adult .farmerPopup--frame {
//   background-image: url(../../resources/assets/themes/lely/16x9/actors/actorFrame/actorFrameAdult.svg);
// }
// .farmerPopup--btnCallToAction {
//   background-color: $color-LelyRed;
//   background-repeat: no-repeat;
//   border-radius: vwFn(0.5) vwFn(0.5) vwFn(0.5) vwFn(0.5);
//   border-left: vwFn(0.12) solid $color-GrayDairyLight;
//   border-right: vwFn(0.12) solid $color-GrayDairyLight;
//   border-top: vhFn(0.27) solid $color-GrayDairyLight;
//   border-bottom: vhFn(0.27) solid $color-GrayDairyLight;
//   background-size: 100% 100%;
//   min-width: vwFn(0.18);
//   font-size: vwFn(1.5);
//   color: #ffffff;
//   //position: absolute;
//   line-height: vhFn(4.266); 
//   display: inline; 
//   padding: vhFn(2) vwFn(2); 
//   //margin-left: vwFn(2);

// }
// .theme-adult .farmerPopup--btnCallToAction--container {
//   margin-bottom: vhFn(2) !important;
// }


// .is-adult-selected {
//   .farmerPopup--title {
//     font-size: vhFn(6.4); 
//     margin-left: vwFn(7.25);
//   } 
//   .labelValue--label {
//     margin-left: vwFn(1.25) !important; 
//   }
//   .farmerPopup--farmerQuote { 
//     margin-left: vwFn(1.25) !important; 
//   }
//   .farmerPopup--btnClosePopup {
//     background-image: url(../../resources/assets/themes/lely/16x9/ui/popup/close-popup-button-adult-theme.svg);
//     width: vwFn(7);
//     height: vhFn(13.9); 
//     margin-left: 0;
//     //margin-top: vhFn(0); 
//     padding-left: 0 !important;
// } 
// .farmerPopupTextBox {
//   padding: vhFn(2) vwFn(1.25);
//   box-shadow: none;
// }
// } 
.farmerPopup--farmer--female {
    background-image: url(../../resources/assets/themes/lely/16x9/popups/default/femaleFarmer.svg);
} 
.farmerPopup--farmer--male {
    background-image: url(../../resources/assets/themes/lely/16x9/popups/default/maleFarmer.svg);
}