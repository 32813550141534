@use "../../common/styles/variables.scss" as *;

.mijnMelkFirstSlide--background {
  position: absolute;
  background-size: 100% 100%;
  width: vwFn(100);
  height: vhFn(100);
  border: none;
}

.mijnMelkFirstSlide--textCaption {
  position: absolute;
  width: vwFn(34.6);
  min-height: vhFn(24.266);
  top: vhFn(37.86);
  left: vwFn(19.95);
  padding: vhFn(1.6) vwFn(1.48);
  z-index: 6;
}

.mijnMelkFirstSlide--robot {
  position: absolute;
  background-size: 100% 100%;
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/actors/mijnmelkactor/mijnMelkPlate.svg);
  width: vwFn(17.619);
  height: vhFn(33.018);
  left: vwFn(49.002);
  top: vhFn(48.59);
  z-index: 7;
}

.mijnMelkFirstSlide--title {
  font-family: $primaryFont;
  font-weight: $font-light;
  font-size: vhFn(4.27);
  line-height: vhFn(4.8);
  color: $color-gray-popup;
}

.mijnMelkFirstSlide--name {
  font-family: $secondaryFont;
  font-weight: $font-bold;
  font-size: vhFn(12.8);
  line-height: vhFn(10.4);
  color: $color-gray-popup;
  margin: vhFn(1.87) 0;
  letter-spacing: vwFn(0.1);
}

.mijnMelkFirstSlide--tree {
  position: absolute;
  background-size: 100% 100%;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/orbiterstory/orbitersixthslide.svg);
  width: vwFn(100);
  height: vhFn(124.61);
  left: vwFn(20);
  top: vhFn(-18.37);
  z-index: 5;
}

.theme-adult .mijnMelkFirstSlide--tree {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowDailyForAdult/backgrounds/eating/full_tree.png);
  width: vwFn(75);
  height: vhFn(118.133);
  left: vwFn(55.70);
}