@use "../../common/styles/variables.scss" as *;

.cheeseFactory {
  position: absolute;
  background-repeat: no-repeat;
  pointer-events: fill;
  width: vhFn(25);
  height: vhFn(22);
  background-image: url(../../resources/assets/themes/lely/16x9/actors/cheesefactory/cheese-factory.svg);
  background-size: 100%;
  background-repeat: no-repeat;
  top: vhFn(45);
  left: vhFn(139);
  z-index: 9;
}

.cheeseFactory--title {
  display: none;
}

.cheeseFactory--bigger {
  width: vwFn(30);
  height: vhFn(65);
  background-size: cover;
}

.cheeseFactory--fixed {
  position: absolute;
  width: vwFn(16);
  height: vhFn(72);
  right: vwFn(55);
  top: vhFn(20);
}
