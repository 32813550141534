@use "../../common/styles/variables.scss" as *;

@keyframes slide_in_show {
  0% {
    bottom: vhFn(-70.512);
  }

  100% {
    bottom: 0;
  }
}

.batchCowListPopup {
  animation-name: slide_in_show !important;
  animation-duration: 0.5s !important;
  position: absolute;
  left: vwFn(16.895);
  bottom: 0;
  background-color: $color-LelyWhite;
  width: vwFn(77.8916);
  height: vhFn(70.512);
  border-left: vwFn(0.12) solid $color-GrayDairyLight;
  border-right: vwFn(0.12) solid $color-GrayDairyLight;
  border-top: vhFn(0.27) solid $color-GrayDairyLight;
  box-sizing: border-box;
  box-shadow: 0px 2px 5px rgba(222, 219, 214, 0.4);
  border-radius: vwFn(0.5) vwFn(0.5) 0px 0px;
  z-index: 1000000;
}

.batchCowListPopup--title {
  // width: 100%;
  margin: auto;
  //width: vwFn(55.5418);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-top: vhFn(3.96266);
  text-align: center;
  font-family: $secondaryFont;
  color: $color-gray-popup;
  font-size: vhFn(6);
  line-height: vhFn(9.6);
  font-weight: $font-regular;
  text-shadow: 0px vhFn(1) vhFn(0.5) rgba(0, 0, 0, 0.25);
}

.batchCowListPopup--description,
.batchCowListPopup--nonBatchCowsListDescription {
  width: 100%;
  text-align: center;
  font-family: $primaryFont;
  color: $color-gray-popup;
  // font-size: 2.4em;
  font-size: vhFn(3.5);
  line-height: vhFn(4.27);
  font-weight: $font-regular;
  text-shadow: 0px vhFn(1) vhFn(0.5) rgba(0, 0, 0, 0.25);
}

.batchCowListPopup--cowListBody {
  height: vhFn(56.7);
  padding-left: vwFn(6.97438);
  //padding-right: vwFn(2.463);
  padding-bottom: vhFn(7.47);
  margin-top: vhFn(3);
  overflow: auto;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.batchCowListPopup--cowListBody::-webkit-scrollbar {
  display: none;
}

.batchCowListPopup--cowContainer {
  display: inline-block;
  width: vwFn(21.5);
  padding-bottom: vhFn(1);
  margin-bottom: vhFn(2);
  margin-right: vhFn(1.9704);
}

.batchCowListPopup--cowContainer--cowName {
  display: inline-block;
  font-family: $secondaryFont;
  color: $color-LelyBlack;
  font-size: vhFn(4.6);
  line-height: vhFn(6.0533);
  vertical-align: top;
  //margin-top: vhFn(4.5);
  margin-left: vwFn(0.9);
  width: vwFn(13);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.batchCowListPopup--cowContainer--cowNameFirstRow {
  letter-spacing: vwFn(0.025);
}
.batchCowListPopup--cowContainer--cowProtein,
.batchCowListPopup--cowContainer--cowLactose,
.batchCowListPopup--cowContainer--cowFat,
.batchCowListPopup--cowContainer--cowMilkWeightInKgs {
  font-weight: $font-regular;
  font-family: $primaryFont;
  width: 100%;
  font-size: vhFn(3.43333);
  line-height: vhFn(5.6);
}
.batchCowListPopup--cowContainer--cowImg {
  display: inline-block;
  width: vwFn(7.4);
  height: vhFn(16);
}
.theme-adult .batchCowListPopup--cowContainer--cowImg {
  background-image: url(../../../src/resources/assets/themes/lely/16x9/actors/cow/popup/cow-list-popup-head-adults.svg);
} 

.batchCowListPopup--nonBatchCowsListDescription {
  margin-top: vhFn(4.27);
  margin-bottom: vhFn(4.27);
  padding: 0;
}

.batchCowListPopup--btnClosePopup {
  position: absolute;
  top: vhFn(-3);
  left: vwFn(29);
  background-image: url(../../resources/assets/themes/lely/16x9/ui/buttons/button-with-arrow-down.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: vwFn(8.5);
  height: vhFn(11.5);
  padding: 0;
  margin: 0;
}
.theme-adult .batchCowListPopup--btnClosePopup {
  background-image: url(../../resources/assets/themes/adult/16x9/ui/sidemenu/close-menu-button.svg);
  width: vwFn(5.5576);
  height: vhFn(10.53);
  left: vwFn(66);
  top: vhFn(-0.2501);
}

.batchCowListPopup--milkBottle {
  position: absolute;
  background-image: url(../../resources/assets/themes/lely/16x9/popups/batchList/milkBottle.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: vwFn(16.133);
  height: vhFn(80.733);
  top: vhFn(-4);
  left: vwFn(-10);
  z-index: 1;
}

.theme-adult .batchCowListPopup--milkBottle {
  position: absolute;
  background-image: url(../../resources/assets/themes/lely/16x9/popups/batchList/milkBottleAdult.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: vwFn(16.133);
  height: vhFn(77.6);
  top: vhFn(-2);
  left: vwFn(-10); 
  z-index: 1;
}

.batchCowListPopup--yoghurtBottle {
  position: absolute;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: vwFn(20);
  height: vhFn(80);
  top: vhFn(-4);
  left: vwFn(-14); 
  z-index: 1;
} 
.horizonBatchListPopup {
  left: vwFn(11.055) !important;
} 
.horizonBatchListPopup .batchCowListPopup--btnClosePopup {
 left: vwFn(36);
} 
.theme-adult .horizonBatchListPopup .batchCowListPopup--btnClosePopup {
 left: vwFn(66);
}

// .theme-adult .batchCowListPopup--milkBottle {
//   position: absolute;
//   background-repeat: no-repeat;
//   background-size: 100% 100%;
//   width: vwFn(20);
//   height: vhFn(77.6);
//   top: vhFn(-2);
//   left: vwFn(-14); 
//   z-index: 1;
// }

.batchCowListPopup--rightArrow {
  position: absolute;
  background-image: url(../../resources/assets/themes/lely/16x9/ui/arrows/arrow-left-curve-down.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: vwFn(4.68);
  height: vhFn(10.67);
  top: vhFn(8.8);
  right: vwFn(12.8);
}

.batchCowListPopup--bottomGradient {
  position: absolute;
  bottom: vhFn(-0.2);
  height: vhFn(9);
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 12.11%,
    #ffffff 100%
  );
  mix-blend-mode: lighten;
  box-shadow: 0px 2px 3px rgba(118, 110, 104, 0.1);
  border-radius: 4px;
}

.batchCowListPopup--holstein-white {
  background-image: url(../../resources/assets/themes/lely/16x9/popups/cowlist/cow-list-popup-head-1.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.batchCowListPopup--holstein-brown {
  background-image: url(../../resources/assets/themes/lely/16x9/popups/cowlist/cow-list-popup-head-2.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.batchCowListPopup--holstein-black {
  background-image: url(../../resources/assets/themes/lely/16x9/popups/cowlist/cow-list-popup-head-3.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.batchCowListPopup--holstein-rainbow {
  background-image: url(../../resources/assets/themes/lely/16x9/popups/cowlist/cow-list-popup-head-4.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.batchCowListPopup--maasrijn-brown {
  background-image: url(../../resources/assets/themes/lely/16x9/popups/cow/cow-head-rijn-one.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.batchCowListPopup--blaarkop-black {
  background-image: url(../../resources/assets/themes/lely/16x9/popups/cow/cow-head-blaarkop-one.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.batchCowListPopup--blaarkop-brown {
  background-image: url(../../resources/assets/themes/lely/16x9/popups/cow/cow-head-blaarkop-two.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.batchCowListPopup--jersey-brown {
  background-image: url(../../resources/assets/themes/lely/16x9/popups/cow/cow-head-jersey-one.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.batchCowListPopup--jersey-white {
  background-image: url(../../resources/assets/themes/lely/16x9/popups/cow/cow-head-jersey-two.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.batchCowListPopup--lakenvelder-black {
  background-image: url(../../resources/assets/themes/lely/16x9/popups/cow/cow-head-lakenvelder-one.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.batchCowListPopup--lakenvelder-brown {
  background-image: url(../../resources/assets/themes/lely/16x9/popups/cow/cow-head-lakenvelder-two.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

//TODO: Add other cow type style img
.batchCowListPopup .cowPopup {
  left: vwFn(0) !important;
  top: vhFn(0) !important;
 }
.batchCowListPopup-batchNumber {
  position: absolute;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/milk/batchNumber.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  min-width: vwFn(29.777);
  height: vhFn(13.51733);
  left: vwFn(-1.88073);
  top: vhFn(-10.986);
  z-index: 1;
  overflow: hidden;
}
.batchCowListPopup-batchNumber--text {
  padding-left: vwFn(12);
  padding-top: vhFn(2);
  width: vwFn(10.5);
  font-size: vhFn(3.2);
  letter-spacing: vwFn(0.1);
  white-space: nowrap;
  font-family: "Arvo", serif;
}
.batchCowListPopup-batchNumber--number {
  padding-left: vwFn(12);
  padding-top: vhFn(1);
  width: vwFn(10);
  font-size: vhFn(4.8);
  line-height: vhFn(5.5);
  font-weight: 700 !important;
  font-family: "Arvo", serif;
  font-style: normal;
}  
.theme-adult {
  .batchCowListPopup-batchNumber {
    background-image: url(../../resources/assets/themes/lely/16x9/stories/milk/batchListAdultVersion.svg);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    min-width: vhFn(31.59);
    height: vhFn(20.266);
    position: absolute;
    top: vhFn(-12);
    left: vwFn(1);
  } 
  
  .batchCowListPopup-batchNumber > div {
    display: block;
    //transform: rotate(14deg);
    font-size: vhFn(3.65);
    padding: vhFn(0.3);
  }
  .batchCowListPopup-batchNumber--text {
    position: relative;
    top: vhFn(4);
    left: vwFn(3);
    font-size: vhFn(3.65);
    transform: rotate(-15deg);
  }
  
  .batchCowListPopup-batchNumber--number {
    position: relative;
    font-weight: bold;
    top: vhFn(3.5);
    left: vwFn(3.5);
    font-size: vhFn(3.65);
    transform: rotate(-15deg);
  }
}
@media only screen and (orientation: portrait) {
  .batchCowListPopup--cowContainer--cowName {
    // display: inline-block;
    // font-family: $secondaryFont;
    // color: $color-LelyBlack;
    font-size: vhFn(4);
    // line-height: vhFn(6.0533);
    // vertical-align: top;
    // //margin-top: vhFn(4.5);
    margin-left: vwFn(0.2);
    width: vwFn(10.5);
    // text-overflow: ellipsis;
    // overflow: hidden;
    // white-space: nowrap;
  }
  .batchCowListPopup--cowContainer--cowProtein,
  .batchCowListPopup--cowContainer--cowLactose,
  .batchCowListPopup--cowContainer--cowFat,
  .batchCowListPopup--cowContainer--cowMilkWeightInKgs {
    font-weight: $font-regular;
    font-family: $primaryFont;
    width: 100%;
    font-size: vhFn(2.53333);
    line-height: vhFn(5.6);
  }
}
.batchCowListPopup .cowPopup-head-adults {
  left: vwFn(46);
  top: vhFn(-23);
} 
.batchCowListPopup .cowPopup,  .batchCowListPopup .cowPopup--cow {
  z-index: 10;
} 
.batchCowListPopup .cowPopup--cow {
left : vwFn(46); 
top: vhFn(-20);
} 
.batchCowListPopup .cowPopup--cow {
  left : vwFn(46); 
  top: vhFn(-20);
  } 
.batchCowListPopup .cowPopup--btnClosePopup {
  margin-left: vwFn(32.5);
} 
.batchCowListPopup {
  .oostdam--yogurt,.oostdam--yogurt--volle, .brosa--yoghurt,.brosa--yoghurt--volle,  .dobblehoeve--yogurt,.dobblehoeve--yogurt--volle {
    position: absolute;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: vwFn(20);
    height: vhFn(77.6);
    top: vhFn(-2);
    left: vwFn(-14); 
    z-index: 1;
}
} 
.batchCowListPopup {
  .dobblehoeve--yogurt--volle {
    background-image: url(../../resources/assets/themes/mijnmelk/16x9/onboarding/adult/yoghurt_guus.png) !important;
  } 
  .oostdam--yogurt--volle {
    background-image: url(../../resources/assets/themes/mijnmelk/16x9/onboarding/adult/yoghurt_yvonne.png) !important;
  }
  .brosa--yoghurt--volle {
    background-image: url(../../resources/assets/themes/mijnmelk/16x9/onboarding/adult/yoghurt_ineke.png) !important;
  }
}

.batchCowListPopup--overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  background-color: rgba(0,0,0,0.25);
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (orientation: portrait) {
  .batchCowListPopup--overlay {
    width: 170%;
 }
}

.batchCowListPopup--cowListBody .cowPopup { 
  animation-name: slide_in_show !important;
  animation-duration: 0.5s !important;
  top: vhFn(16) !important;
  left: vwFn(5) !important;

  .cowPopup--container {
    position: sticky;
  }

  @keyframes slide_in_show {
    0% {
         opacity: 0;
         transform: scale(0)
    }

    100% {
         opacity: 1;
         transform: scale(1)
    }
  }
}