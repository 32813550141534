@use "../../common/styles/variables.scss" as *;

@keyframes disclaimer_popup_slide_in_show {
  0% {
    bottom: vhFn(-70.512);
  }

  100% {
    bottom: 0;
  }
}

@keyframes slide_in_hide {
  0% {
    bottom: 0;
    opacity: 1;
  }

  100% {
    bottom: vhFn(-70.512);
    opacity: 0;
  }
}

.disclaimerPopup.is-hiding {
  animation-name: slide_in_hide !important;
  animation-duration: 0.5s !important;
  animation-fill-mode: forwards;
}

.disclaimerPopup {
  animation-name: cookies_popup_slide_in_show !important;
  animation-duration: 0.5s !important;
  position: absolute;
  left: vwFn(12.5);
  top: vhFn(30);
  background-color: $color-LelyWhite;
  width: vwFn(75);
  height: vhFn(40.512);
  border-left: vwFn(0.12) solid $color-GrayDairyLight;
  border-right: vwFn(0.12) solid $color-GrayDairyLight;
  border-top: vhFn(0.27) solid $color-GrayDairyLight;
  box-sizing: border-box;
  border-radius: vwFn(0.5) vwFn(0.5) 0px 0px;
  z-index: 1000000;
}

.disclaimerPopup--title {
  margin: auto;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-top: vhFn(3.96266);
  text-align: left;
  font-family: "BourtonBase";
  color: $color-gray-popup;
  font-size: vhFn(6);
  line-height: vhFn(9.6);
  font-weight: $font-regular;
  padding-left: vwFn(2);
  top: vhFn(-1);
  position: absolute;
}

.disclaimerPopup--body {
  width: 80%;
  margin: auto;
}

.disclaimerPopup--body--textBeginning {
  width: 120%;
  text-align: left;
  color: $color-gray-popup;
  font-size: vhFn(3.73);
  line-height: vhFn(4.27);
  font-weight: $font-regular;
  font-family: "Roboto";
  display: flex;
  position: relative;
  left: vwFn(-5.3);
  padding-right: 0%;
  top: vhFn(13);

}

.disclaimerPopup--footer--btnCloseDisclaimer {
  color: $color-LelyWhite;
  //vertical-align: middle;
  line-height: vhFn(4.5);
  font-size: vhFn(3.9);
  //left: vwFn(2);
  top: vhFn(3.4);
  text-transform: none;
  font-family: "BourtonBase";
  //position: absolute;
  white-space: nowrap;
  //padding-left: vwFn(8);
  font-weight: 400;  
  background-color: transparent;
}

.disclaimerPopup--footer {
  position: absolute;
  bottom: vhFn(9);
  text-align: center;
  width: 100%;
  height: vhFn(5);
}

.disclaimerPopup--footer--btnCloseDisclaimer--label {
  color: $color-LelyWhite;
  vertical-align: middle;
  line-height: vwFn(2.5);
  font-size: vwFn(2);
  top: vhFn(3.5);
  left: vwFn(32.5);
  position:absolute;
  font-family: "BourtonBase";
  }
  

.disclaimerPopup.is-children .disclaimerPopup--footer--btnCloseDisclaimer {
  background-color: #c30a14;
  color: #fff;
  background-image: none;
  height: vhFn(9);
  min-width: vwFn(1);
  padding-left: vwFn(2);
  padding-right: vwFn(2);
  font-family: "Roboto"; 
  margin: auto;
  display: block; 
  margin-top: vhFn(2);
  border-radius: 4px;
}


.disclaimerPopup.is-children
  .disclaimerPopup--footer--btnCloseDisclaimer--label {
  color: #fff;
  vertical-align: middle;
  line-height: vwFn(2.5);
  position: absolute;
  left: vwFn(34.2);
  top: vhFn(2);
  text-transform: none;
  font-family: "Ubuntu";
  }

.disclaimerPopup.is-children .disclaimerPopup--title {
  font-family: "Chelsea Market";
}


.disclaimerPopup.is-children .disclaimerPopup--body--textBeginning {
  font-family: "Ubuntu";
}