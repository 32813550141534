.samsungPopup {
  position: absolute;
  bottom: 0;
  height: vhFn(20);
  background-color: #000;
  z-index: 100000000000000000003;
  vertical-align: middle;
  line-height: vhFn(20);
  pointer-events: none !important;
  opacity: 1;

  -moz-animation: hideSamsungPopup 2s ease-in 10s forwards;
  /* Firefox */
  -webkit-animation: hideSamsungPopup 2s ease-in 10s forwards;
  /* Safari and Chrome */
  -o-animation: hideSamsungPopup 2s ease-in 10s forwards;
  /* Opera */
  animation: hideSamsungPopup 2s ease-in 10s forwards;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.samsungPopup .samsungPopup--title {
  margin: 0;
  padding: 0;
  width: 100%;
  text-align: center;
  color: #fff;
}

@keyframes hideSamsungPopup {
  from {
    opacity: 1;
    bottom: 0;
  }
  to {
    opacity: 0;
    bottom: vhFn(-20);
  }
}
