@use "../../../common/styles/admin_variables.scss" as adminVar;

.LoginPage {
  position: relative;
  background-image: url(../../../resources/assets/themes/lely/16x9/adminpanel/login/login-page-background.svg);
  background-repeat: no-repeat;
  background-size: 100vw 100vh;
  overflow: visible;
  height: 100vh;
  width: 100vw;
  font-family: adminVar.$primaryFont !important;
}

.LoginPage--heading {
  width: 100%;
  text-align: center;
}

.LoginPage--subheading {
  width: 100%;
  text-align: center;
}
