.MuiFormHelperText-root {
  color: rgba(0, 0, 0, 0.54);
  margin: 0;
  font-size: 0.75rem;
  margin-top: 8px;
  min-height: 1em;
  text-align: left;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1em;
  letter-spacing: 0.03333em;
}

.MuiFormHelperText-root.Mui-error {
  color: #f44336;
}

.MuiPopover-paper {
  position: relative !important;
  max-width: 326px !important;
  margin-left: 65px;
}

.custom-datepicker {
  margin-right: 2.5vw;

  .MuiInputBase-root.MuiOutlinedInput-root {
    position: relative;
    width: 100%;
    border-radius: 4px;
    padding: 0;
    -webkit-appearance: none;
    height: 40px;
    line-height: 40px;
    border: 1px solid #000;
    box-shadow: inset 0 2px 5px 0 rgba(222, 219, 214, 0.4);
    background: #fff;
    margin: 0;
    color: #000;
    font-weight: 400;
    font-size: 14px;
    text-align: left;
  }

  .MuiOutlinedInput-notchedOutline {
    border: 0;
  }

  .MuiIconButton-root {
    padding: 7px;
  }

  label.MuiFormLabel-root {
    position: absolute;
    top: -6px;
    font-size: 0.9rem;
    margin-left: 0vw;
    color: #000;
  }
}
