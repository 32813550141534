@use "../../common/styles/variables.scss" as *;

.btnIcon {
  background-repeat: no-repeat;
  background-color: transparent;
  border: none;
  width: vwFn(3);
  height: vwFn(3);
  margin-bottom: vhFn(1);
  cursor: pointer;
}
