@use "../../styles/variables.scss" as *;

.labelled-text-input--label {
  display: block;
}

.labelled-switch--switch-container {
  display: block;
  top: vhFn(-1);
  position: relative;
}
