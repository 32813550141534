@use "../../styles/variables.scss" as *;

.is-percentage-error {
  border: 1px solid $color-LelyRed;
}

.sum-of-breed-percentages-error-msg {
  color: $color-LelyRed;
}
.cow--breed--selection {
  width: 30vw;
}
.cow--breed--selection .MuiInput-input {
  padding-left: 1vw;
}
.cow--breed--container--block .labelled-text-input--label {
  color: #766e68;
  font-weight: 300;
  font-size: 1vw;
}
.cow--breed--container--block {
  border-bottom: 1px groove #cfcbc3;
}
