@use "../../common/styles/variables.scss" as *;

.orbiterStorySixthSlideYoghurt {
  position: absolute;
  background-size: 100% 100%;
  width: vwFn(101);
  height: vhFn(100);
  background-color: #f7f3f2;
  border: none;
}

.orbiterStorySixthSlideYoghurt--textCaption {
  position: absolute;
  width: vwFn(30.788);
  min-height: vhFn(20.8);
  top: vhFn(6.085);
  left: vwFn(45.57);
  padding: vhFn(1.6) vwFn(1.48);
  z-index: 2;
}
.orbiterStorySixthSlideYoghurt--secondTextCaption {
  position: absolute;
  width: vwFn(30.788);
  min-height: vhFn(20.8);
  top: vhFn(27.26);
  left: vwFn(4.08);
  padding: vhFn(1.6) vwFn(1.48);
  z-index: 2;
}

.orbiterStorySixthSlideYoghurt--milkBottles {
  position: absolute;
  background-size: 100% 100%;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/orbiterstory/milkbottle_empty.svg);
  width: vwFn(18.897);
  height: vhFn(13.333);
  left: vwFn(60.956);
  top: vhFn(39.806);
  z-index: 3;
  display: inline-block;
}

.orbiterStorySixthSlideYoghurt--table {
  position: absolute;
  background-size: 100% 100%;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/orbiterstory/orbiterTableFIfthSlide.svg);
  width: vwFn(36.034);
  height: vhFn(60.453);
  left: vwFn(59.2);
  top: vhFn(19.168);
  z-index: 7;
}

.orbiterStorySixthSlideYoghurt--tableRightPart {
  position: absolute;
  background-size: 100% 100%;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/orbiterstory/orbiterTableSecondPart.svg);
  width: vwFn(19.9654);
  height: vhFn(39.973);
  left: vwFn(79);
  top: vhFn(39.46);
  z-index: 4;
}
.orbiterStorySixthSlideYoghurt--middlePipe {
  position: absolute;
  background-size: 100% 100%;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/orbiterstory/orbiterMiddlePipe.svg);
  width: vwFn(22.5);
  height: vhFn(2.4);
  left: vwFn(95);
  top: vhFn(53);
  z-index: 8;
}
.orbiterStorySixthSlideYoghurt--floor {
  position: absolute;
  background-size: 100% 100%;
  left: vwFn(0);
  top: vhFn(78.5);
  width: vwFn(100);
  height: vhFn(21.5);
  background-color: #cfcbc3;
}

.orbiterStorySixthSlideYoghurt--robot {
  position: absolute;
  background-size: 100% 100%;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/orbiterstory/orbiterRobotFifthSlide.svg);
  width: vwFn(24.305);
  height: vhFn(60.114);
  left: vwFn(3.655);
  top: vhFn(19.168);
  z-index: 3;
}

.orbiterStorySixthSlideYoghurt--text {
  font-family: $primaryFont;
  font-style: $font-DefaultFontStyle;
  font-size: vhFn(3.73);
  line-height: vhFn(6.4);
  color: $color-gray-popup;
  margin: vhFn(1.87) 0; 
  letter-spacing: vwFn(0.03);
}

.orbiterStorySixthSlideYoghurt--pipe {
  position: absolute;
  background-size: 100% 100%;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/orbiterstory/orbiterFifthSlidePipe.svg);
  width: vwFn(100);
  height: vhFn(2.4);
  left: vwFn(0);
  top: vhFn(71.33);
  z-index: 4;
}

.orbiterStorySixthSlideYoghurt--secondPipe {
  position: absolute;
  background-size: 100% 100%;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/orbiterstory/pipefourthslideright.svg);
  width: vwFn(49.47);
  height: vhFn(27.67);
  left: vwFn(53.5);
  top: vhFn(46.13);
}

.orbiterStorySixthSlideYoghurt--arrow {
  position: absolute;
  background-size: 100% 100%;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/orbiterstory/orbiterarrow.svg);
  width: vwFn(2);
  height: vhFn(1.486);
  left: vwFn(35.314);
  top: vhFn(71.74);
  transform: rotate(180deg);
  z-index: 9999;
}
.theme-adult .RobotStoryOrbiter5 .parallax-cow-robot-adults .background-layer--hills1  {
  opacity: 0;
} 
.theme-adult .RobotStoryOrbiter5 .parallax-cow-robot-adults .background-layer--hills2  {
  opacity: 0;
} 
.theme-adult {
  .orbiterStorySixthSlideYoghurt {
    background-color: transparent;
  } 
  .orbiterStorySixthSlideYoghurt--floor {
    display: none;
  } 
  .orbiterStorySixthSlideYoghurt--robot {
    background-image: url(../../resources/assets/themes/lely/16x9/stories/orbiterstory/orbiterStoryFifthSLideRobot.svg); 
    z-index: 5;
  } 
  .orbiterStorySixthSlideYoghurt--pipe {
    background-image: url(../../resources/assets/themes/lely/16x9/stories/orbiterstory/orbiterStoryFifthSlidePipeAdult.svg); 
    top: vhFn(67.8); 
    height: vhFn(3.1);
  } 
  .orbiterStorySixthSlideYoghurt--arrow { 
    top: vhFn(68.74); 
    background-image: url(../../resources/assets/themes/lely/16x9/stories/orbiterstory/orbiterArrowAdult.svg); 
    transform: rotate(0deg);
  } 
  .orbiterStorySixthSlideYoghurt--table {
    background-image: url(../../resources/assets/themes/lely/16x9/stories/orbiterstory/orbiterStoryFifthSlideTableAdult.svg);  
    width: vwFn(43.034);
  height: vhFn(60.453); 
  left: vwFn(56);
  } 
  .orbiterStorySixthSlideYoghurt--milkBottles { 
    background-image: url(../../resources/assets/themes/lely/16x9/stories/orbiterstory/orbiterStoryFifthSlideMilkBottlesAdult.svg); 
  }  
  .orbiterStorySixthSlideYoghurt--tableRightPart { 
    background-image: url(../../resources/assets/themes/lely/16x9/stories/orbiterstory/test.svg); 

  } 
  .orbiterStorySixthSlideYoghurt--middlePipe {
    background-image: url(../../resources/assets/themes/lely/16x9/stories/orbiterstory/orbiterStoryPipeFifthSlideAdultTransition.svg);  
    width: vwFn(21.5);
    height: vhFn(2.8);
    left: vwFn(95);
    top: vhFn(52.5);
  } 
  .orbiterStorySixthSlideYoghurt--temperature {
    background-image: url(../../resources/assets/themes/lely/16x9/stories/orbiterstory/orbiterStoryYoghurtSixthSlideTemp.svg); 
    position: absolute;
    background-size: 100% 100%;
    width: vwFn(11.083);
    height: vhFn(19.8);
    left: vwFn(44.45);
    top: vhFn(45.866);
    z-index: 10; 
    transform: scale(1);
  } 
  .orbiterStorySixthSlideYoghurt--arrowText {
    background-image: url(../../resources/assets/themes/lely/16x9/stories/orbiterstory/orbiterStoryArrowTextAdult.svg);  
    position: absolute;
    background-size: 100% 100%;
    width: vwFn(1.354);
    height: vhFn(6.1333);
    left: vwFn(15.314);
    top: vhFn(81.74);
    z-index: 9999;
  } 
  .orbiterStorySixthSlideYoghurt--arrowTextBottom { 
    position: absolute;
    font-size: vhFn(3.733); 
    line-height: vhFn(6.4);
    font-family: $secondaryFont; 
    top: vhFn(90); 
    left: vwFn(7.05);
  }
} 