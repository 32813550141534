@use "../../common/styles/variables.scss" as *;

.junoStoryStartingPoint {
  position: absolute;
  background-size: 100% 100%;
  width: vwFn(100.5);
  height: vhFn(100);
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/eating/eating2.svg);
}

.junoStoryStartingPoint--textCaption {
  position: absolute;
  min-width: vwFn(34.6);
  min-height: vhFn(24.26);
  top: vhFn(35.072);
  left: vwFn(22.339);
  padding: vhFn(3.2) vwFn(1.48);
}

.junoStoryStartingPoint--robot {
  position: absolute;
  background-size: 100% 100%;
  background-image: url(../../resources/assets/themes/lely/16x9/actors/vector/juno_front_story.svg);
  width: vwFn(15.2709);
  height: vhFn(41.333);
  left: vwFn(50.68);
  top: vhFn(41.2);
}

@media only screen and (orientation: portrait) {
  .junoStoryStartingPoint--robot {
    left: vwFn(57.68);
  }
}

.junoStoryStartingPoint--title {
  font-family: $secondaryFont;
  font-style: $font-DefaultFontStyle;
  font-weight: $font-light;
  font-size: vhFn(4.27);
  line-height: vhFn(4.8);
  color: $color-gray-popup;
}

.junoStoryStartingPoint--name {
  font-family: $secondaryFont;
  font-style: $font-DefaultFontStyle;
  font-weight: $font-bold;
  font-size: vhFn(12.8);
  line-height: vhFn(10.4);
  color: $color-gray-popup;
  margin: vhFn(1.87) 0;
}

.junoStoryStartingPoint--farmName {
  font-family: $secondaryFont;
  font-style: $font-DefaultFontStyle;
  font-weight: $font-light;
  font-size: vhFn(4.27);
  line-height: vhFn(4.8);
  color: $color-gray-popup;
} 
.theme-adult .junoStoryStartingPoint--robot {
  background-image: url(../../resources/assets/themes/lely/16x9/actors/vector/juno_front_story_adult.svg);
  width: vwFn(15.2709);
  height: vhFn(41.333);
}
.theme-adult .junoStoryStartingPoint--title {
  font-family: $primaryFont;
} 