@use "../../common/styles/variables.scss" as *;

.cow-daily--rumination--slide {
  width: vwFn(100);
  height: vhFn(100);
}

.cowDailyRumination {
  position: absolute;
  left: vwFn(20.56);
  top: vhFn(35.6);
  width: vwFn(30.78);
  height: vhFn(33.2);
  padding: vwFn(0.25);
  line-height: vhFn(6.4);
  font-size: vwFn(1.72);
  margin: 0 !important;
}

.cowDailyRumination--date {
  position: static;
  font-family: $secondaryFont;
  font-weight: bold;
  font-size: vwFn(1.9);
  line-height: vhFn(6.4);
  color: black;
  letter-spacing: vwFn(0.2);
  margin: vwFn(0.25);
}

.cowDailyRumination--cowRuminationText {
  font-size: vwFn(1.724);
  line-height: vhFn(6.4);
  margin: vwFn(0.25);
}

.cow-daily--rumination--text {
  position: static;
  width: vwFn(19.33);
  height: vhFn(12.8);
  font-family: $secondaryFont;
  font-weight: bold;
  font-size: vhFn(3.75);
  line-height: vhFn(6.4);
  color: black;
  margin: vwFn(1.25) vhFn(1);
}

.cow-daily--rumination-cow {
  position: absolute;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowdaily/graphics/cow_resting.svg);
  background-size: 100%;
  background-repeat: no-repeat;
  left: vwFn(70.05);
  top: vhFn(55);
  width: vwFn(19.5);
  height: vhFn(24);
  cursor: pointer;
}

.cowDailyRumination--button {
  position: absolute;
  background-image: url(../../resources/assets/themes/lely/16x9/screens/cowCalmRobot/cow-calm-slide-button.svg);
  background-repeat: no-repeat;
  height: vhFn(6.63);
  width: vwFn(3.69001);
  padding: 0;
  margin: 0;
  right: 3px;
  top: 50%;
}

.cow-daily--rumination--arrowText {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  position: absolute;
  width: vwFn(25.39);
  height: vhFn(12.8);
  left: vwFn(50);
  bottom: vhFn(5.5);
}

.cow-daily--rumination--arrow {
  position: absolute;
  background-image: url(../../resources/assets/themes/lely/16x9/ui/arrows/arrow-upward-left.svg);
  background-repeat: no-repeat;
  background-size: cover;
  width: vwFn(4.92);
  height: vhFn(6.7);
  left: vwFn(23);
  top: vhFn(2);
  transform: scale(-1, 1);
}
