@use "../../common/styles/variables.scss" as *;

.sustainabilityStoryStartingPoint {
  position: absolute;
  background-size: 100% 100%;
  width: vwFn(100.5);
  height: vhFn(100);
}

.sustainabilityStory-leftTree {
   background-image: url(../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/background-trees/trees1.svg);
   background-repeat: no-repeat;
   background-size: 100% 100%;
   position: absolute;
   top: vhFn(45.852);
   left: vwFn(2.365);
   width: vwFn(70);
   height: vhFn(35.48);
}
//  .sustainability-intro-house {
//    background-image: url(../../resources/assets/themes/lely/16x9/stories/cowhistory/background-layers/elements/house.svg);
//    background-repeat: no-repeat;
//    background-size: 100% 100%;
//    position: absolute;
//    top: vhFn(41.921);
//    left: vwFn(50.463);
//    width: vwFn(25.23);
//    height: vhFn(39.488);
// }
.sustainability-intro-plate {
  background-image: url(../../resources/assets/themes/adult/16x9/stories/sustainabilityStory/sustainability_plate_child.svg);
  background-repeat: no-repeat;
  background-size: 90% 90%;
  position: absolute;
  top: vhFn(51);
  width: vwFn(38.834);
  height: vhFn(35.48);
}
  
.theme-adult .sustainabilityStory-leftTree {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowhistoryForAdult/background-layers/trees/background_tree_01.svg);
  background-size: 30% 100%;
}
.theme-adult .sustainability-intro-house {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowDailyForAdult/elements/farmHouseStyle1Front.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  top: vhFn(41.921);
  left: vwFn(50.463);
  width: vwFn(25.23);
  height: vhFn(39.488);
}

.theme-adult .sustainability-intro-plate {
  background-image: url(../../resources/assets/themes/adult/16x9/stories/sustainabilityStory/sustainability_plate.svg);
  top: vhFn(48.852);
}

.sustainability-intro-silo {
  background-image: url(../../resources/assets/themes/adult/16x9/stories/sustainabilityStory/sustainability_silo_child.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  top: vhFn(45.852);
  left: vwFn(59);
  width: vwFn(38.834);
  height: vhFn(35.48);
}

.theme-adult .sustainability-intro-silo {
  background-image: url(../../resources/assets/themes/adult/16x9/stories/sustainabilityStory/sustainability-silo.svg);
  left: vwFn(62);
}

.sustainabilityStoryStartingPoint--textCaption {
  position: absolute;
  min-width: vwFn(0);
  min-height: vhFn(0);
  top: vhFn(36.072);
  left: vwFn(26.339);
  padding: vhFn(3.2) vwFn(1.48);
  z-index: 1;
}

.sustainabilityStoryStartingPoint--text {
  font-family: $primaryFont;
  font-weight: $font-light;
  font-size: vhFn(4.27);
  line-height: vhFn(4.8);
  color: $color-gray-popup;
}

.sustainabilityStoryStartingPoint--title {
  font-family: "Chelsea Market";
  font-weight: 400;
  font-size: vhFn(7.2);
  line-height: vhFn(10.4);
  color: $color-gray-popup;
  letter-spacing: vwFn(0.1);
}
.theme-adult .sustainabilityStoryStartingPoint--title {
    font-family: "LumiosMarker";
    font-weight: 400;
    font-size: vhFn(12.8);
    line-height: vhFn(10.4);
    color: $color-gray-popup;
    letter-spacing: vwFn(0.1);
    padding-bottom: vhFn(5);
}
@media only screen and (orientation: portrait) {
  .sustainabilityStoryStartingPoint--title {
    font-family: $secondaryFont;
    font-weight: $font-bold;
    font-size: vhFn(8.8);
    line-height: vhFn(10.4);
    color: $color-gray-popup;
    margin: vhFn(1.87) 0;
    letter-spacing: vhFn(0.01);
  }
  .sustainabilityStoryStartingPoint--textCaption {
    position: absolute;
    min-width: vwFn(30.6);
    min-height: vhFn(24.26);
    top: vhFn(29.072);
    left: vwFn(22.339);
    padding: vhFn(3.2) vwFn(1.48);
  }
}

.theme-adult .sustainabilityStoryStartingPoint--robot {
  width: vwFn(32.88);
  height: vhFn(55.466);
  left: vwFn(51.15);
  top: vhFn(26.2);

}

// .sustainability-intro-smallCow {
//   background-image: url(../../resources/assets/themes/lely/16x9/stories/farmer/grazingCowAndBush.svg);
//   background-repeat: no-repeat;
//   background-size: 100% 100%;
//   position: absolute;
//   top: vhFn(70.666);
//   left: vwFn(33.1366);
//   width: vwFn(16.8719);
//   height: vhFn(10.4);
// }

.theme-adult .sustainability-intro-smallCow {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowhistoryForAdult/background-layers/elements/smallCowAndBush.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  top: vhFn(70.666);
  left: vwFn(33.1366);
  width: vwFn(16.8719);
  height: vhFn(10.4);
}