@use "../../../common/styles/variables.scss" as *;

.CowFirstMilkingSlide {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  width: vwFn(100);
  height: vhFn(100);
}

.textCaption--CowFirstMilkingSlide {
  position: absolute;
  min-height: vhFn(35.8);
  width: vwFn(32);
  left: vwFn(20.5);
  top: vhFn(22.5);
  padding: vhFn(2) vwFn(1.5);
}

.cowFirstMilkingSlide--text {
  position: static;
  width: vwFn(27.84);
  min-height: vhFn(32.5);
  left: vwFn(1.5);
  top: vhFn(3.2);
  font-size: vhFn(4);
  line-height: vhFn(6.4);
  font-weight: 400;
}
