@use "../../common/styles/variables.scss" as *;

.textCaption--mijnMelkAdultOnboarding-slide3-text1 {
  position: absolute;
  top: vhFn(18);
  left: vwFn(6.1576);
  box-shadow: none;
  border: none;
  background: transparent; 
  font-size: vhFn(3.73); 
  line-height: vhFn(5.6);
}

.textCaption--mijnMelkAdultOnboarding-slide3-text2 {
  position: absolute;
  top: vhFn(57.133);
  left: vwFn(30.315);
  box-shadow: none;
  border: none;
  background: transparent; 
  font-size: vhFn(3.73);

}

.textCaption--mijnMelkAdultOnboarding-slide3-text3 {
  position: absolute;
  top: vhFn(65);
  left: vwFn(5);
  box-shadow: none;
  border: none;
  background: transparent; 
  font-size: vhFn(3.73);

}

.textCaption--mijnMelkAdultOnboarding-slide3-text4 {
  position: absolute;
  left: vwFn(30.315);
  top: vhFn(80);
  box-shadow: none;
  border: none;
  background: transparent; 
  font-size: vhFn(3.73);

}
.mijnMelkAdultOnboarding-slide3--fromBottleToLips {
  position: absolute;
  left: vwFn(20.85);
  top: vhFn(54.704);
  width: vwFn(8.251);
  height: vhFn(36.266);
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/onboarding/adult/slide3/fromBottleToLips.svg); 
  background-size: 100% 100%; 
  background-repeat: no-repeat;
} 
.mijnMelkAdultOnboarding-slide3--cow {
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/onboarding/adult/slide3/slide3cow.svg);  
  width: vwFn(15.2770); 
  height: vhFn(35.28); 
  top: vhFn(26.666); 
  left: vwFn(46.428); 
  position: absolute;
  background-size: 100% 100%; 
  background-repeat: no-repeat;
} 
.mijnMelkAdultOnboarding-slide3--chickens {
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/onboarding/adult/slide3/slide3chickens.svg);  
  width: vwFn(16.1145); 
  height: vhFn(30.930); 
  top: vhFn(40.91466); 
  left: vwFn(78.22); 
  position: absolute;
  background-size: 100% 100%; 
  background-repeat: no-repeat;
} 
.mijnMelkAdultOnboarding-slide1--grass {
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/onboarding/adult/slide3/slide3grass.svg);  
  width: vwFn(3.44); 
  height: vhFn(3.6266); 
  top: vhFn(65.91466); 
  left: vwFn(50.866); 
  position: absolute;
  background-size: 100% 100%; 
  background-repeat: no-repeat;
}