@use "../../common/styles/variables.scss" as *;
@use "../../themes/starbust.scss";

.BottleStoryPage {
  background-color: white;
}

.theme-milkbottle .slide:nth-child(2) {
  overflow: hidden !important;
}

.rotating-story-container {
  position: relative;
}

.sunburst {
  position: absolute;
  animation-name: animated-background;
  animation-duration: 40s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  width: vwFn(200);
  height: vhFn(300);
  top: vhFn(-50);
  left: vwFn(-50);
}

.rotated-1 {
  transform: rotate(0deg);
}

.rotated-2 {
  transform: rotate(-90deg);
}

.rotated-3 {
  transform: rotate(-180deg);
}

.rotated-4 {
  transform: rotate(-270deg);
}

.slide1 {
  position: absolute;
  width: vwFn(100);
  /* Fallback for browsers that do not support Custom Properties */
  height: vhFn(100);
  left: calc(50 * var(--vw, 1vw) + 60 * var(--vh, 1vh));
  overflow: hidden;
}

.slide2 {
  position: absolute;
  width: vwFn(100);
  height: vhFn(100);
  left: calc(120 * var(--vh, 1vh) + 200 * var(--vw, 1vw));
  top: calc(50 * var(--vw, 1vw) + 60 * var(--vh, 1vh));
  transform-origin: top left;
  transform: rotate(90deg);
  overflow: hidden;
}

.slide3 {
  position: absolute;
  height: vhFn(100);
  width: vwFn(100);
  bottom: 0;
  left: calc(60 * var(--vh, 1vh) + 50 * var(--vw, 1vw));
  transform: rotate(180deg);
  overflow: hidden;
}

.slide4 {
  position: absolute;
  width: vwFn(100);
  height: vhFn(100);
  left: 0;
  top: calc(60 * var(--vh, 1vh) + 150 * var(--vw, 1vw));
  transform-origin: top left;
  transform: rotate(270deg);
  overflow: hidden;
}

.slide2-calendar {
  position: absolute;
  left: vwFn(60);
  top: vhFn(25.712);
  width: vwFn(20);
  height: vhFn(36);
}
.slide2-milkInfoPlate {
  position: absolute;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/milk/milkingBottleInfo.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  transform: rotate(-30deg);
  width: vwFn(27.59802);
  min-height: vhFn(34.9366);
  left: vwFn(10.9273);
  top: vhFn(25.933);
  z-index: 6;
  //transform: rotate(22.24deg);
  -webkit-transform: translate3d(0, 0, 1px);
  transform: translate3d(0, 0, 1px) rotate(-30deg);
}

.slide2-milkInfo--text {
  transform: rotate(16.244deg);
  color: white;
  width: vwFn(24.3559);
  margin: vhFn(9.5) vwFn(1.5);
  line-height: vhFn(5);
  font-size: vhFn(3.73333);
  text-align: center;
  z-index: 11;
  font-family: "Arvo", serif;
}
@media only screen and (orientation: portrait) {
  .slide2-milkInfoPlate {
    -webkit-transform: translate3d(0, 0, 1px);
    transform: translate3d(0, 0, 1px) rotate(-21deg);
  }
  .slide2-milkInfo--text {
    -webkit-transform: translate3d(0, 0, 1px);
    transform: translate3d(0, 0, 1px) rotate(23.244deg);
    font-size: vhFn(3.23333);
  }
}
.slide2-familyTree {
  position: absolute;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/milk/secondSlideFamilyTree.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  left: vwFn(51.2);
  top: vhFn(-9.78);
  width: vwFn(39.06896);
  height: vhFn(81.25);
  //z-index: 5;
}
.slide2-smallBush {
  position: absolute;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/milk/bush.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  top: vhFn(45.284);
  left: vwFn(30.2);
  width: vwFn(17.2419);
  transform: rotate(-10deg);
  height: vhFn(22.4);
  z-index: 5;
  -webkit-transform: translate3d(0, 0, 1px);
  transform: translate3d(0, 0, 1px);
}
.hotMilk--sun {
  position: absolute;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/sky/sun.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  top: vhFn(1.284);
  left: vwFn(31.2);
  width: vwFn(15.2419);
  height: vhFn(29.4);
}
.slide2-bigBush {
  position: absolute;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/milk/bigBush.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  top: vhFn(52.284);
  left: vwFn(67.5);
  width: vwFn(21.551);
  height: vhFn(24.2933);
  z-index: 10;
  -webkit-transform: translate3d(0, 0, 1px);
  transform: translate3d(0, 0, 1px);
}
.slide2-smallGrass {
  position: absolute;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/milk/grassUnderThePole.svg);
  background-repeat: no-repeat;
  transform: rotate(-15deg);
  background-size: 100% 100%;
  top: vhFn(57.284);
  left: vwFn(60);
  width: vwFn(6.1871);
  height: vhFn(10.8);
  z-index: 11;
  -webkit-transform: translate3d(0, 0, 1px);
  transform: translate3d(0, 0, 1px);
}
.slide2-bigGrass {
  position: absolute;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/milk/bigGrass.svg);
  background-repeat: no-repeat;
  transform: rotate(-15deg);
  background-size: 100% 100%;
  top: vhFn(54.284);
  left: vwFn(60.35);
  width: vwFn(6.1871);
  height: vhFn(10.8);
  z-index: 10;
  -webkit-transform: translate3d(0, 0, 1px);
  transform: translate3d(0, 0, 1px);
}
.slide2-grassUnderThePole {
  position: absolute;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/milk/grassUnderThePole.svg);
  background-repeat: no-repeat;
  transform: rotate(-15deg);
  background-size: 100% 100%;
  top: vhFn(57.284);
  left: vwFn(26);
  width: vwFn(6.1871);
  height: vhFn(10.8);
  z-index: 10;
  -webkit-transform: translate3d(0, 0, 1px);
  transform: translate3d(0, 0, 1px);
}
.slide2-grassLeftSide {
  position: absolute;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/milk/grassUnderThePole.svg);
  background-repeat: no-repeat;
  transform: rotate(-25deg);
  background-size: 100% 100%;
  top: vhFn(58.284);
  left: vwFn(20.5);
  width: vwFn(6.1871);
  height: vhFn(10.8);
  z-index: 10;
  -webkit-transform: translate3d(0, 0, 1px);
  transform: translate3d(0, 0, 1px);
}
.slide2-grassRightSide {
  position: absolute;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/milk/grassUnderThePole.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  top: vhFn(71.384);
  left: vwFn(89.23);
  width: vwFn(5.1871);
  height: vhFn(9.8);
  z-index: 10;
  -webkit-transform: translate3d(0, 0, 1px);
  transform: translate3d(0, 0, 1px);
}
.slide2-calendar-date {
  position: absolute;
  top: 3%;
  transform: rotate(18deg);
  font-size: vwFn(7);
  font-family: $secondaryFont;
  width: 100%;
  text-align: center;
}

.slide2-calendar-month {
  position: absolute;
  top: 2%;
  transform: rotate(18deg);
  font-size: vhFn(3.73);
  line-height: vhFn(6);
  color: #fff;
  width: 100%;
  text-align: center;
  justify-content: center;
  top: vwFn(1);
  margin: vhFn(7) vwFn(1) !important;
}
.slide2-firstPole {
  position: absolute;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-color: #705804;
  transform: rotate(-15deg);
  width: vwFn(1.2315);
  height: vhFn(34.512);
  left: vwFn(25.593);
  top: vhFn(31.5133);
  -webkit-transform: translate3d(0, 0, 1px);
  transform: translate3d(0, 0, 1px);
}
.slide3-contains {
  position: absolute;
  left: vwFn(62.73);
  top: vhFn(17.78);
  font-size: vhFn(4);
  font-family: "Amatic SC", sans-serif;
  background-size: 100% 100%;
}

.slide3-arrow-contains {
  background-size: 100% 100%;
  position: absolute;
  width: vwFn(10.926);
  height: vhFn(6.266);
  left: vwFn(53.5);
  top: vhFn(22.25);
  transform: rotate(73deg);
}

.slide4-house {
  position: absolute;
  left: vwFn(70);
  top: vhFn(50.44);
  transform: rotate(76deg);
  width: vwFn(43.23);
  height: vhFn(31.25);
  background-size: 100% 100%;
}

.slide4-text {
  position: absolute;
  font-size: vhFn(4.8);
  line-height: vhFn(8.4);
  font-family: $primaryFont;
  text-align: center;
  letter-spacing: vwFn(0);
  top: vhFn(4.73);
  left: vwFn(5);
  width: vwFn(40.07);
  height: vhFn(19.2);
  font-weight: 700;
  z-index: 10;
  font-family: "Arvo", serif !important;
}

.slide1-sign-text {
  width: 100%;
  height: 100%;
  font-size: vhFn(3.733);
  line-height: vhFn(6.4);
  font-family: $primaryFont;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
}
.slide3-milkInfoPlate {
  position: absolute;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/milk/milkingBottleInfo.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  //transform: rotate(15deg);
  width: vwFn(27.59802);
  height: vhFn(34.9366);
  left: vwFn(63.9273);
  top: vhFn(0.933);
  z-index: 4;
  //transform: rotate(22.24deg);
}
.slide3-milkInfo--text {
  transform: rotate(15.24deg);
  color: white;
  width: vwFn(22.3559);
  margin-top: vhFn(9.5);
  line-height: vhFn(5);
  margin-left: vwFn(2);
  font-size: vhFn(3.73333);
  text-align: center;
  z-index: 5;
}
.slide4-milkInfoPlate {
  position: absolute;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/milk/milkingBottleInfo.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  //transform: rotate(15deg);
  width: vwFn(27.59802);
  height: vhFn(34.9366);
  left: vwFn(63.9273);
  top: vhFn(0.933);
  z-index: 4;
  //transform: rotate(22.24deg);
}
.slide4--farmerName {
  font-size: vhFn(9.6);
  letter-spacing: 0;
}
.slide4-cow {
  position: absolute;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/milk/small_cow.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  left: vwFn(78.2);
  top: vhFn(66);
  width: vwFn(8.389);
  height: vhFn(12.8);
  z-index: 5;
}
.milkBottleStory--farmerName {
  font-family: $secondaryFont;
  font-size: vhFn(3.73);
  letter-spacing: vwFn(0.2);
  text-align: center;
  margin-top: vhFn(2);
}
.slide4-map {
  position: absolute;
  left: vwFn(24.6637);
  top: vhFn(22.34);
  transform: rotate(-18deg);
  width: vwFn(21.551);
  height: vhFn(53.8666);
}

.slide1-text {
  //font-family: $primaryFont;
  font-family: "Arvo", serif;
  position: absolute;
  top: vhFn(7.5);
  font-size: vhFn(4.8);
  line-height: vhFn(5.86);
  margin: 0 !important;
  text-align: center;
  left: vwFn(11.663);
}

.slide1-subtext {
  font-family: $primaryFont;
  position: absolute;
  letter-spacing: -0.2vw;
  top: vhFn(14.26);
  font-size: vhFn(9.6);
  margin: 0 !important;
  left: vwFn(11.563);
  text-align: center;
}
.slide1-batchNumber {
  position: relative;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/milk/batchNumber.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  //transform: rotate(15deg);
  width: vwFn(47.66);
  height: vhFn(24.26);
  left: vwFn(48.8073);
  top: vhFn(8.986);
  //transform: rotate(-2.24deg);
  z-index: 99999;
  overflow: hidden;
  // -webkit-transform: translate3d(0, 0, 1px);
  // transform: translate3d(0, 0, 1px);
}
.slide1-grassUnderThePole {
  position: absolute;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/milk/grassUnderThePole.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  top: vhFn(59.284);
  left: vwFn(72);
  width: vwFn(5.1871);
  height: vhFn(9.8);
  z-index: 10;
}

.slide1-grass2 {
  position: absolute;
  top: vhFn(60.86);
  transform: rotate(-31deg);
  left: vwFn(20);
  width: vwFn(3.22);
  height: vhFn(7.106);
}
.slide2-batchNumber {
  position: relative;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/milk/batchNumber.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  //transform: rotate(15deg);
  width: vwFn(47.66);
  height: vhFn(24.26);
  left: vwFn(48.8073);
  top: vhFn(8.986);
  padding: 0 vwFn(3);
  z-index: 99999;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 1px);
  transform: translate3d(0, 0, 1px);
}
.slide2-batchNumber--text {
  padding-left: vwFn(17);
  padding-top: vhFn(4);
  width: vwFn(10.5);
  font-size: vhFn(3.73333);
  letter-spacing: vwFn(0.1);
  white-space: nowrap;
  font-family: "Arvo", serif;
}
.slide2-batchNumber--number {
  padding-left: vwFn(17);
  padding-top: vhFn(1);
  width: vwFn(10);
  height: vhFn(10);
  font-size: vhFn(6.4);
  line-height: vhFn(9.6);
  font-weight: 700;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: "Arvo", serif;
}
.slide3-batchNumberPlate {
  position: absolute;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/milk/batchNumberPlate.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  //transform: rotate(15deg);
  width: vwFn(21.798022);
  height: vhFn(26.93);
  left: vwFn(65.9273);
  top: vhFn(10.933);
  z-index: 4;
  //transform: rotate(22.24deg);
}
.slide3-batchNumberPlate--text {
  transform: rotate(15.24deg);
  color: white;
  width: vwFn(17.4211);
  margin-top: vhFn(7);
  margin-left: vwFn(2);
  font-size: vhFn(3.733333);
  text-align: center;
  z-index: 5;
  font-family: "Arvo", serif;
}
.slide3-topThreeCows {
  position: absolute;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/milk/topThreeCowsPlate.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  //transform: rotate(15deg);
  width: vwFn(24.3559);
  height: vhFn(26.93);
  left: vwFn(61.9273);
  top: vhFn(28.933);
  z-index: 4;
  //transform: rotate(22.24deg);
}
.slide3-grassUnderThePole {
  position: absolute;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/milk/grassUnderThePole.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  top: vhFn(59.284);
  left: vwFn(72);
  width: vwFn(4.1871);
  height: vhFn(8.8);
  z-index: 8;
}
.slide3-bush {
  position: absolute;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/milk/bush.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  top: vhFn(46.284);
  left: vwFn(56);
  width: vwFn(17.2419);
  height: vhFn(22.4);
}
.slide3-topThreeCows--text {
  transform: rotate(15.24deg);
  color: white;
  width: vwFn(20.3559);
  margin-top: vhFn(8.5);
  margin-left: vwFn(2);
  font-size: vhFn(3.73333);
  text-align: center;
  z-index: 5;
}
.slide4-milkInfo--text {
  transform: rotate(15.24deg);
  color: white;
  width: vwFn(23);
  margin-top: vhFn(9.5);
  line-height: vhFn(5);
  margin-left: vwFn(2);
  font-size: vhFn(3.73333);
  text-align: center;
  z-index: 5;
  font-family: "Arvo", serif;
}
.slide4--farmerName {
  font-family: $primaryFont;
}
.slide4-farmPlate {
  position: absolute;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/milk/farmPlate.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  transform: rotate(15deg);
  width: vwFn(23.768);
  height: vhFn(23.7333);
  left: vwFn(64.3004);
  top: vhFn(43.5133);
  transform: rotate(4.24deg);
  z-index: 4;
}
.slide3-pole {
  position: absolute;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-color: #705804;
  transform: rotate(15deg);
  width: vwFn(1.2315);
  height: vhFn(53.064);
  left: vwFn(75.0751);
  top: vhFn(14.1133);
  z-index: 2;
}

.slide1-batchNumber--text {
  padding-left: vwFn(20);
  padding-top: vhFn(4);
  width: vwFn(10.5);
  font-size: vhFn(3.73333);
  letter-spacing: vwFn(0.1);
  white-space: nowrap;
  font-family: "Arvo", serif;
}
.slide1-batchNumber--number {
  padding-left: vwFn(20);
  padding-top: vhFn(1);
  width: vwFn(10);
  font-size: vhFn(6.4);
  line-height: vhFn(9.6);
  font-weight: 700 !important;
  font-family: "Arvo", serif;
  font-style: normal;
}
.slide1-mijnMelkPlate {
  position: absolute;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/milk/mijnMelkPlate.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  transform: rotate(15deg);
  width: vwFn(17.12192);
  height: vhFn(32.088);
  left: vwFn(7.3004);
  top: vhFn(45.5133);
  transform: rotate(4.24deg);
}
.slide1-pole {
  position: absolute;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-color: #766e68;
  transform: rotate(15deg);
  width: vwFn(1.2315);
  height: vhFn(25);
  left: vwFn(75.0751);
  top: vhFn(42.1133);
}
.slide1-road--sign {
  position: absolute;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/vectorstory/vectorlastslidesign.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-size: 100% 100%;
  width: vwFn(27.12);
  min-height: vhFn(20.333);
  left: vwFn(64);
  top: vhFn(42.018);
  z-index: 10;
  // text-align: center;
  // padding: vhFn(1) 0;
  display: flex;
  //padding: vhFn(5) vwFn(6);
  text-align: center;
  justify-content: center;
  transform: rotate(15deg);
}
.slide1-road--sign--text {
  font-size: vhFn(3.733);
  padding: 0 vwFn(3);
  line-height: vhFn(4.5);
  font-family: "Arvo", serif;
  font-weight: 400;
  color: white;
  text-align: center;
  margin: auto auto;
  justify-content: center;
  align-items: center;
}
.sign-text-text {
  line-height: vhFn(4.75);
  font-family: $primaryFont;
  transform: rotate(-7deg);
  font-size: vhFn(3.73);
}
.slide1-swipe--gesture {
  position: absolute;
  width: vwFn(6.8965);
  height: vhFn(16);
  left: vwFn(91.871);
  top: vhFn(71.3333);
  background-image: url(../../resources/assets/themes/lely/16x9/stories/milk/swipingGesture.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -webkit-transform: translate3d(0, 0, 1px);
  transform: translate3d(0, 0, 1px);
}
.rotating-story-swipe-symbol {
  position: absolute;
  left: vwFn(80);
  bottom: vhFn(25);
  height: vhFn(20);
  width: vwFn(30);
  -webkit-transform: translate3d(0, 0, 1px);
  transform: translate3d(0, 0, 1px);
}

.story-milk-bottle {
  width: vwFn(40);
  height: vhFn(40);
  bottom: vhFn(15);
  position: absolute;
  left: vwFn(30);
}

.milk-story-animated-background {
  position: absolute;
  animation-name: animated-background;
  animation-duration: 50s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  width: 100%;
  height: 200%;
  border-radius: 50%;
  top: 50%;
}

.slide2-special {
  position: absolute;
  top: vwFn(21);
  left: vwFn(25);
  font-size: vhFn(3);
  width: 10%;
  font-family: $secondaryFont;
}

.planet {
  position: absolute;
  transform-origin: 50% 50%;
  top: vhFn(60);
  left: vhFn(60);
  width: vwFn(200);
  height: vwFn(200);
}

.planet circle {
  cx: vwFn(100);
  cy: vwFn(100);
  r: vwFn(100);
}

.rotated-area-container {
  position: absolute;
  left: calc(-50 * var(--vw, 1vw) - 60 * var(--vh, 1vh));
  transition: all;
  transform-origin: center center;
  transition-duration: 3s;
  width: calc(var(--vh, 1vh) * 120 + var(--vw, 1vw) * 200);
  height: calc(var(--vh, 1vh) * 120 + var(--vw, 1vw) * 200);
}

@keyframes animated-background {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.bottle {
  display: none;
}

.bottle.staticMilkBottle {
  position: absolute;
  display: block;
  width: vwFn(16.13);
  height: vhFn(71.73);
  top: vhFn(13.28);
  left: vwFn(41);
}

.is-ingredients-displayed ~ .bottle {
  display: block;
}

.is-ingredients-displayed ~ .staticMilkBottle {
  display: none;
}

.milkBottleStory--imgCaption {
  position: absolute;
  width: vwFn(23.167);
  height: vhFn(61.272);
  top: vhFn(23.95);
  left: vwFn(6.9572);
  background-color: $color-LelyWhite;
  box-shadow: 0px vwFn(0.25) vwFn(0.7) rgba(18, 18, 18, 0.2);
  padding: vhFn(3.2) vwFn(1.48);
  transform: rotate(-15deg);
}

.milkBottleStory--imgContainer {
  width: 100%;
  height: vhFn(44.8426);
  border-width: vhFn(0.53) vwFn(0.246);
  border-style: solid;
  border-color: $color-GrayDairyDarker;
}

.cow--christmas {
  top: vhFn(50);
  left: vwFn(34);
  width: vwFn(10.71);
  height: vhFn(25.33);
}

.christmas--whistle {
  top: vhFn(64);
  left: vwFn(33);
  height: vhFn(10);
  position: absolute;
}

.christmas--hat {
  top: vhFn(44);
  left: vwFn(37);
  height: vhFn(10);
  position: absolute;
}
.slide4-sign {
  position: absolute;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/vectorstory/vectorlastslidesign.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-size: 100% 100%;
  width: vwFn(25.12);
  height: vhFn(14.833);
  left: vwFn(68);
  top: vhFn(33.018);
  z-index: 10;
  // text-align: center;
  // padding: vhFn(1) 0;
  display: flex;
  //padding: vhFn(5) vwFn(6);
  text-align: center;
  justify-content: center;
  transform: rotate(17deg);
  cursor: pointer;
}
.slide4-sign-text {
  font-size: vhFn(3.733);
  padding: 0 vhFn(5);
  line-height: vhFn(5);
  font-weight: 400;
  color: white;
  text-align: center;
  margin: auto auto;
  justify-content: center;
  align-items: center;
  font-family: "Arvo", serif;
}
// .slide4-sign {
//   position: absolute;
//   transform: rotate(0deg);
//   position: absolute;
//   left: vwFn(69);
//   top: vhFn(26.2);
//   width: vwFn(24.507);
//   position: absolute;
//   background-image: url(../../resources/assets/themes/lely/16x9/stories/vectorstory/vectorlastslidesign.svg);
//   background-repeat: no-repeat;
//   background-size: 100% 100%;
//   height: vhFn(33.066);
//   //padding: vhFn(2.5) vwFn(4.2);
//   z-index: 5;
// }

// .slide4-sign-text {
//   width: 100%;
//   margin: vhFn(12.5) 0;
//   margin-left: vwFn(0.8);
//   font-size: vhFn(3.733);
//   line-height: vhFn(6.4);
//   font-family: $primaryFont;
//   font-weight: 400;
//   color: #ffffff;
//   text-align: center;
//   //transform: rotate(20deg);
// }

.slide4-firstPole {
  position: absolute;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-color: #705804;
  transform: rotate(15deg);
  width: vwFn(1.2315);
  height: vhFn(61.309333);
  left: vwFn(75.0751);
  top: vhFn(5.5133);
}
.slide4-secondPole {
  position: absolute;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-color: #705804;
  transform: rotate(15deg);
  width: vwFn(1.2315);
  height: vhFn(37.5);
  left: vwFn(78.0751);
  top: vhFn(32.133);
}
.slide4--house--Style3 {
  position: absolute;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/milk/americanBarn.svg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: vwFn(20.75);
  height: vhFn(30.9146);
  left: vwFn(84.969);
  top: vhFn(54.51);
  z-index: 5;
  transform: rotate(30deg);
}
.slide4--house--Style2 {
  position: absolute;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/milk/dutchBarnSecondType.svg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: vwFn(19.75);
  height: vhFn(30.9146);
  left: vwFn(85.969);
  top: vhFn(54.51);
  z-index: 5;
  transform: rotate(30deg);
}
.slide4--house--Style1 {
  position: absolute;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/milk/dutchBarn.svg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: vwFn(19.75);
  height: vhFn(30.9146);
  left: vwFn(85.969);
  top: vhFn(54.51);
  z-index: 5;
  transform: rotate(30deg);
}
.slide4--image--dobblehoeve {
  position: absolute;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/milk/deDobbelhoeve.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: vwFn(19.131);
  height: vhFn(15.469);
  margin-top: vhFn(4);
  margin-left: vwFn(2.5);
  z-index: 99999;
}
.slide4--image--oostdam {
  position: absolute;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/milk/oostdamImage.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: vwFn(12.25);
  height: vhFn(15.469);
  margin: vhFn(4) vwFn(6);
  z-index: 999999;
}
.slide4--image--rotgans {
  position: absolute;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/milk/rotgans.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: vwFn(12.25);
  height: vhFn(15.469);
  margin: vhFn(4) vwFn(6);
  z-index: 99999;
}
.slide4--image--schutte {
  position: absolute;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/milk/boerKees.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: vwFn(12.26);
  height: vhFn(15.469);
  margin: vhFn(4) vwFn(6);
  z-index: 99999;
}
.slide2-text {
  font-family: "Arvo", serif;
  position: absolute;
  top: vhFn(7.5);
  font-size: vhFn(4.8);
  line-height: vhFn(5.86);
  margin: 0 !important;
  text-align: center;
  left: vwFn(8.463);
  justify-content: center;
  flex-wrap: wrap;
  -webkit-transform: translate3d(0, 0, 1px);
  transform: translate3d(0, 0, 1px);
}

.slide2-subtext {
  font-family: $primaryFont;
  letter-spacing: -0.4vw;
  width: vwFn(40);
  // top: vhFn(14.26);
  font-size: vhFn(9.6);
  margin: 0 !important;
  // left: vwFn(5.563);
  // height: vhFn(20);
  line-height: vhFn(8);
  text-transform: uppercase;
  text-align: center;
}
.slide4-smallGrass {
  position: absolute;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/milk/grassUnderThePole.svg);
  background-repeat: no-repeat;
  transform: rotate(-15deg);
  background-size: 100% 100%;
  top: vhFn(57.284);
  left: vwFn(70.21);
  width: vwFn(6.1871);
  height: vhFn(10.8);
  z-index: 11;
}
.slide4-bigGrass {
  position: absolute;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/milk/bigGrass.svg);
  background-repeat: no-repeat;
  transform: rotate(-15deg);
  background-size: 100% 100%;
  top: vhFn(61.884);
  left: vwFn(73.35);
  width: vwFn(5.1871);
  height: vhFn(8.8);
  z-index: 10;
}
/* Hide bottle story animation for performance purposes
   once we have seen it */
.is-onboarding-over .BottleStoryPage {
  display: none;
}
