@use "../../common/styles/variables.scss" as *;

.mijnMelkBottle--container {
  position: absolute;
  width: vwFn(35);
  height: vhFn(100);
  transition: 2s transform;
}

.mijnMelkBottle {
  position: absolute;
  background-repeat: no-repeat;
  background-size: contain;
  left: vwFn(-2);
  background-position: center;
  width: 100%;
  height: 80%;
  bottom: vhFn(-5);
  z-index: 1;
}

.youghurtBottle {
  position: absolute;
  background-repeat: no-repeat;
  background-size: contain;
  left: vwFn(5);
  background-position: center;
  width: 100%;
  height: 80%;
  bottom: vhFn(-6);
  z-index: 1;
}

.mijnMelkBottle--grass {
  position: absolute;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 30%;
  bottom: 0;
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/onboarding/adult/grass.png);
}

.is-shifted-left {
  transform: translateX(vwFn(15));
}

.is-shifted-right {
  transform: translateX(vwFn(52.35));
}

.is-full-left {
  transform: translateX(vwFn(0));
}

.yoghurt-is-shifted-right {
  transform: translateX(vwFn(55.35));
}

.yoghurt-is-full-left {
  transform: translateX(vwFn(4));
}
