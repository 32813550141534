@use "../../common/styles/variables.scss" as *;

.header {
  height: 4.1667vw;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.header--top {
  $self: &;
  display: flex;
  justify-content: space-between;
  height: 4.1667vw;
  background-color: $color-LelyWhite;
  align-items: center;
  padding: 10px 0px;
  width: 100%;
  padding: 0 20px;
  box-shadow: 0 2px 5px 0 rgba(222, 219, 214, 0.4);

  box-sizing: border-box;
  position: static;
  top: 0;
  left: 0;
  z-index: 10000;

  .header--top--logo {
    background-image: url(../../resources/images/lely_logo_white_border.png);
    background-repeat: no-repeat;
    background-size: contain;
    width: 2vw;
    height: 2.8vh;
    left: 0.95vw;
    top: 2.8vh;
  }

  .header--top--logotext {
    white-space: nowrap;
    font-size: 1.11vw;
    line-height: 1.25vw;
    margin-left: 2vw;
    color: #c30a14;
    font-weight: 500;
    letter-spacing: 0.15vw;
    position: absolute;
    width: 92px;
    height: 1.25vw;
    left: 3.5vw;
    top: 1.3vw;
  }

  &__btns {
    display: flex;
    justify-content: flex-end;
  }

  .button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 11px 15px;
    gap: 5px;
    width: 91px;
    height: 42px;
    background: #FFFFFF;
    border: 1px solid #CFCBC3;
    box-shadow: 0px 2px 3px rgba(222, 219, 214, 0.4);
    border-radius: 4px;
    flex: none;
    order: 0;
    flex-grow: 0;
    cursor: pointer;
  }

}

.shareButton--text {
  font-size: 12px;
  display: inline-flex;
}

.shareButton {
  background-image: url(../../resources/images/shareButton.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: inline-flex;
  width: 20px;
  height: 20px;
}