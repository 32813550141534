@use "../../../common/styles/admin_variables.scss" as adminVar;
@use "../../../common/components/SaveChangesButton/SaveChangesButton.scss";

.AnimalsPage {
  margin-left: adminVar.$sideMenuWidth;
  margin-top: adminVar.$pagesMarginTop;
  height: 90vh;
  overflow: auto;
  padding: 0 19.17vw;
}

.AnimalsPage .btn-save-changes {
  margin-left: calc(100% - 170px) !important;
  width: 170px;
}

.AnimalsPage .btn-already-saved {
  margin-left: calc(100% - 270px) !important;
  width: 270px;
  margin-top: 20px !important;
}

.AnimalsPage legend {
  font-family: Ubuntu, sans-serif;
  font-size: 16px;
  color: #121212;
}

.animals--otherAnimals {
  padding-top: 16px;
}

.animals--header {
  font-weight: 400;
  font-size: 26px;
}

.animals--intro {
  font-size: 14px;
  padding-bottom: 2vh;
}

.save-button-style {
  margin-top: 2vh !important;
  margin-bottom: 5vh;
  min-height: 4vh;
  position: center;
  background-color: #73bd07 !important;
  padding: 6px 8px;
}

.animals--animalsAreaContainer {
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #cfcbc3;
  padding-left: 10px;
}
