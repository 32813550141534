@import "../../common/styles/variables.scss";

.udderRGBInfo {
  position: absolute;
  left: vwFn(-3);
}

.udderRGBInfo--red {
  color: #f45643;
}

.udderRGBInfo--green {
  color: #24a148;
}

.udderRGBInfo--blue {
  color: #0f92e7;
}

.udderInfo--1 .udderRGBInfo,
.udderInfo--2 .udderRGBInfo {
  left: vwFn(-11);
}

.udderInfo--3 .udderRGBInfo {
  left: vwFn(3);
}

.udderInfo--4 .udderRGBInfo {
  left: vwFn(5);
}

.udderInfo--1 .udderRGBInfo,
.udderInfo--4 .udderRGBInfo {
  top: vhFn(-2);
}

.udderInfo--2 .udderRGBInfo,
.udderInfo--3 .udderRGBInfo {
  top: vhFn(0);
}
.udderInfo--1 .udderRGBInfo {
  left: vwFn(-11);
  top: vhFn(-10);
  font-size: vhFn(3.733);
  line-height: vhFn(5.5);
}
.udderInfo--2 .udderRGBInfo {
  left: vwFn(-7.5);
  top: vhFn(15);
  font-size: vhFn(3.733);
  line-height: vhFn(5.5);
}
.udderInfo--3 .udderRGBInfo {
  font-size: vhFn(3.733);
  left: vwFn(0.5);
  top: vhFn(15);
  line-height: vhFn(5.5);
}
.udderRGBInfo--value {
  margin-left: vwFn(0.25);
}
.udderInfo--4 .udderRGBInfo {
  font-size: vhFn(3.733);
  left: vwFn(5.5);
  top: vhFn(-9);
  line-height: vhFn(5.5);
}
