@use "../../common/styles/variables.scss" as *;

// .dogPopup {
//   position: absolute;
//   top: vhFn(13.5);
//   left: vwFn(7.66);
//   background-color: $color-LelyWhite;
//   width: vwFn(67.73);
//   min-height: vhFn(70.5);
//   border-left: vwFn(0.12) solid $color-GrayDairyLight;
//   border-right: vwFn(0.12) solid $color-GrayDairyLight;
//   border-top: vhFn(0.27) solid $color-GrayDairyLight;
//   border-bottom: vhFn(0.27) solid $color-GrayDairyLight;
//   box-sizing: border-box;
//   box-shadow: 0px vhFn(0.52) vwFn(0.65) rgba(222, 219, 214, 0.4);
//   border-radius: vwFn(0.5) vwFn(0.5) vwFn(0.5) vwFn(0.5);
//   background-color: $color-LelyWhite;
//   padding: vhFn(2) vwFn(1);
// }

// .dogPopup--name {
//   width: 100%;
//   font-family: $secondaryFont;
//   letter-spacing: vwFn(0.25);
//   color: $color-LelyBlack;
//   //font-weight: bold;
//   font-size: vwFn(3);
//   line-height: vhFn(9.333);
//   margin-top: 0 !important;
//   padding-top: vhFn(4);
//   margin-bottom: 0;
//   padding-left: vwFn(2.5);
//   font-weight: 400;
// }

// .dogPopup--description {
//   width: vwFn(43.1);
//   min-height: vhFn(30.1);
//   left: vwFn(2.46);
//   top: vhFn(1.86);
//   margin: 0;
//   margin-left: vwFn(2.46);
//   padding-bottom: vhFn(2.14);
//   font-family: $primaryFont;
//   font-size: vhFn(3.73);
//   line-height: vhFn(6.4);
//   font-weight: normal;
//   overflow: hidden;
// }

// .dogPopup--btnClosePopup {
//   position: absolute;
//   top: vhFn(-2);
//   left: vwFn(50);
//   background-image: url(../../resources/assets/themes/lely/16x9/ui/popup/close-popup-button.svg);
//   background-repeat: no-repeat;
//   background-size: 100% 100%;
//   width: vwFn(8.5);
//   height: vhFn(11.5);
//   z-index: 10;
// }

// .dogPopup--dog {
//   position: absolute;
//   top: vhFn(0);
//   left: vwFn(53.5);
//   width: vhFn(73.35);
//   height: vhFn(73.35);
// }

// .dogPopup--dog--image {
//   position: relative;
//   top: vhFn(4);
//   left: vwFn(-3.5);
//   width: vhFn(63.2);
//   height: vhFn(65.2);
//   background-repeat: no-repeat;
//   background-size: 100% 100%;
//   -webkit-clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
//   clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
//   object-fit: cover;
// }

// .dogPopup--frame {
//   position: absolute;
//   top: vhFn(0.2);
//   left: vwFn(-6);
//   width: vhFn(73.333);
//   height: vhFn(73.333);
//   background-repeat: no-repeat;
//   background-size: 100% 100%;
//   background-image: url(../../resources/assets/themes/lely/16x9/actors/actorFrame/actorFrame.svg);
// }
// .theme-adult .dogPopup--frame {
//   background-image: url(../../resources/assets/themes/lely/16x9/actors/actorFrame/actorFrameAdult.svg);
// }
//  .theme-adult .dogPopup--name {
//   margin-left: vwFn(6)
// } 
.animalPopup--image {
    width: vhFn(68);
   height: vhFn(77);
   background-repeat: no-repeat;
   background-size: 100% 100%;
   -webkit-clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
   clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
   object-fit:cover;
   z-index: 10;
   margin-left: vhFn(4);
   //margin-top: vhFn(1);
} 
.donkey {
    background-image: url(../../resources/assets/themes/lely/16x9/popups/default/defaultDonkey.svg);
}
.sheep {
    background-image: url(../../resources/assets/themes/lely/16x9/popups/default/defaultSheep.svg);
}
.chicken {
    background-image: url(../../resources/assets/themes/lely/16x9/popups/default/defaultChicken.svg);
}
.horse {
    background-image: url(../../resources/assets/themes/lely/16x9/popups/default/defaultHorse.svg);
}
.goat {
    background-image: url(../../resources/assets/themes/lely/16x9/popups/default/defaultGoat.svg);
}
.alpaca {
    background-image: url(../../resources/assets/themes/lely/16x9/popups/default/defaultAlpaca.svg);
}
.pony {
    background-image: url(../../resources/assets/themes/lely/16x9/popups/default/defaultPony.svg);
}
.llama {
    background-image: url(../../resources/assets/themes/lely/16x9/popups/default/defaultLama.svg);
}
.cat {
    background-image: url(../../resources/assets/themes/lely/16x9/popups/default/defaultCat.svg);
}
.bigDog {
    background-image: url(../../resources/assets/themes/lely/16x9/popups/default/defaultBigDog.svg);
}
.smallDog {
    background-image: url(../../resources/assets/themes/lely/16x9/popups/default/defaultSmallDog.svg);
}
.mediumDog {
    background-image: url(../../resources/assets/themes/lely/16x9/popups/default/defaultMediumDog.svg);
}