@use "../../common/styles/variables.scss" as *;

.pet {
  position: absolute;
  background-repeat: no-repeat;
  pointer-events: fill;
  background-size: 100% 100%;
  // width: calc(var(--vh, 1vh) * 9);
  // height: calc(var(--vh, 1vh) * 10);
  // left: calc(var(--vh, 1vh) * 60);
  // top: calc(var(--vh, 1vh) * 20);
  pointer-events: fill;
  z-index: 18;
  transform: translate3d(0, 0, 1px);
}

.pet--smalldog {
  background-image: url(../../resources/assets/themes/lely/16x9/actors/pet/pet--smallDog.svg);
  width: vwFn(2.4778) !important;
  height: vhFn(5.576) !important;
  background-size: 100% 100% !important;
}

.pet--mediumdog {
  background-image: url(../../resources/assets/themes/lely/16x9/actors/pet/pet--mediumDog.svg);
  width: vwFn(2.4778) !important;
  height: vhFn(7.576) !important;
  background-size: 100% 100% !important;
}

.pet--bigdog {
  background-image: url(../../resources/assets/themes/lely/16x9/actors/pet/pet--bigDog.svg);
  width: vwFn(4.4778) !important;
  height: vhFn(8.576) !important;
  background-size: 100% 100% !important;
}

.pet--chicken {
  background-image: url(../../resources/assets/themes/lely/16x9/actors/pet/pet--chicken.svg);
}

.pet--pony {
  background-image: url(../../resources/assets/themes/lely/16x9/actors/pet/pet--pony.svg);
  width: vwFn(3.99014) !important;
  height: vhFn(6.688) !important;
  background-size: 100% 100%;
}

.pet--horse {
  background-image: url(../../resources/assets/themes/lely/16x9/actors/pet/pet--horse.svg);
  width: vwFn(6.2807) !important;
  height: vhFn(11.592) !important;
  // left: vhFn(173.5) !important;
  // top: vhFn(57) !important;
  background-size: 100% 100%;
  //position: fixed !important;
  transform: translate3d(0, 0, 1px);
}

.pet--llama {
  background-image: url(../../resources/assets/themes/lely/16x9/actors/pet/pet--llama.svg);
  width: vwFn(3.4448275) !important;
  height: vhFn(11.064) !important;
  background-size: 100% 100% !important;
  transform: scaleX(-1);
}

.pet--alpaca {
  background-image: url(../../resources/assets/themes/lely/16x9/actors/pet/pet--alpaca.svg);
  width: vwFn(3.9408) !important;
  height: vhFn(9.554666) !important;
  background-size: 100% 100%;
}

.pet--donkey {
  background-image: url(../../resources/assets/themes/lely/16x9/actors/pet/pet--donkey.svg);
  width: vwFn(3.571463) !important;
  height: vhFn(8.8509) !important;
  background-size: 100% 100%;
}

.pet--sheep {
  background-image: url(../../resources/assets/themes/lely/16x9/actors/pet/pet--sheep.svg);
  width: vwFn(2.58263) !important;
  height: vhFn(5.416) !important;
  background-size: 100% 100%;
}

.pet--goat {
  background-image: url(../../resources/assets/themes/lely/16x9/actors/pet/pet--goat.svg);
  width: vwFn(3.263) !important;
  height: vhFn(6.509) !important;
  background-size: 100% 100%;
}

.pet--chicken {
  background-image: url(../../resources/assets/themes/lely/16x9/actors/pet/pet--chicken.svg);
  width: vwFn(1.7263) !important;
  height: vhFn(4.2) !important;
  background-size: 100% 100%;
}

.pet--no {
  opacity: 0;
  display: none;
}

.pet--title {
  opacity: 0;
}
