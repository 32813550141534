@use "../../common/styles/variables.scss" as *;

.slide {
  position: absolute;
  top: 0;
  overflow: visible;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  width: vwFn(100);
  /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
}
