@import "../../common/styles/variables.scss";

.udderInfo {
  background-repeat: no-repeat;
}

.is-animation-enabled .udderInfo {
  opacity: 0;
  animation: fadeInUdderInfo 1s forwards;
}

.udderInfo .udderInfoLine {
  display: block;
  background-size: 100% 100%;
}

.udderInfo--1 {
  position: absolute;
  top: vhFn(25);
  left: vwFn(12);
  animation-delay: 1.5s !important;
}

.udderInfo--2 {
  position: absolute;
  top: vhFn(26);
  left: vwFn(18.5);
  animation-delay: 2s !important;
}

.udderInfo--3 {
  position: absolute;
  top: vhFn(26);
  left: vwFn(22.5);
  animation-delay: 2.5s !important;
}

.udderInfo--4 {
  position: absolute;
  top: vhFn(25);
  left: vwFn(26);
  animation-delay: 3s !important;
}

.udderInfo--1 .udderInfoLine {
  width: vwFn(4.681);
  height: vhFn(4.5706);
  background-image: url(../../resources/assets/themes/lely/16x9/stories/astronautLiveDataStory/udder_line_1.svg);
}

.udderInfo--2 .udderInfoLine {
  width: vwFn(1.575);
  height: vhFn(12.13);
  background-image: url(../../resources/assets/themes/lely/16x9/stories/astronautLiveDataStory/udder_line_2.svg);
}

.udderInfo--3 .udderInfoLine {
  width: vwFn(1.575);
  height: vhFn(12.13);
  background-image: url(../../resources/assets/themes/lely/16x9/stories/astronautLiveDataStory/udder_line_3.svg);
}

.udderInfo--4 .udderInfoLine {
  width: vwFn(4.681);
  height: vhFn(4.5706);
  background-image: url(../../resources/assets/themes/lely/16x9/stories/astronautLiveDataStory/udder_line_4.svg);
}

@keyframes fadeInUdderInfo {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 100;
  }
}
