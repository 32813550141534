@use "../../../common/styles/admin_variables.scss" as adminVar;
@use "../../../common/components/SaveChangesButton/SaveChangesButton.scss";

.TellMoreAboutMe {
  margin-left: adminVar.$sideMenuWidth;
  margin-top: adminVar.$pagesMarginTop;
  height: 90vh;
  overflow: auto;
  padding: 0 19.17vw;
}
.more-about-you--header {
  font-weight: 400;
  font-size: 26px;
}
.more-about-you--intro {
  font-size: 14px;
}

.btn-add-text-block {
  margin-top: 2.5vh;
  margin-bottom: 2.5vh;
}

.more-about-you--textField {
  width: 78.74%;
}

.more-about-you--textArea {
  width: 100%;
}
.save-button-more-about-me {
  margin-top: 0vh !important;
  margin-bottom: 5vh;
  min-height: 4vh;
  position: center;
  background-color: #73bd07 !important;
}
