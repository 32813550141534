@import "../../common/styles/variables.scss";

.liveDataIndicator {
  position: absolute;
  top: vhFn(-2);
  left: vwFn(3);
  min-width: vwFn(38.4);
  height: vhFn(15.4666);
  background-image: url(../../resources/assets/themes/lely/16x9/ui/live-data-indicator/background.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.liveDataIndicator--cowHead {
  width: vwFn(7.389);
  height: vhFn(15.466);
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  background-size: 100% 100%;
  top: vhFn(-12);
  left: vwFn(35);
}

.liveDataIndicator--liveIcon {
  background-image: url(../../resources/assets/themes/lely/16x9/popups/livedata/liveIcon.svg);
  background-color: transparent;
  width: vwFn(7.1777);
  height: vhFn(9.941);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  left: vwFn(-1);
  display: inline-block;
  position: relative;
  top: vhFn(-4);
}

.liveDataIndicator--title {
  font-family: $primaryFont;
  color: $color-LelyBlack;
  font-size: vhFn(3.733);
  padding-left: vwFn(0.25);
  //padding-top: vhFn(1);
  letter-spacing: vhFn(0.075);
  display: inline-block;
  position: relative;
  top: vhFn(-2);
  font-weight: 500;
  left: vwFn(-5);
}

.theme-adult .liveDataIndicator--liveIcon {
  background-image: url(../../resources/assets/themes/lely/16x9/popups/livedata/liveIconAdults.svg);
  top: vhFn(-6);
}

.theme-adult .liveDataIndicator--cowHead {
  width: vwFn(6.222);
  height: vhFn(12.7466);
  position: absolute;
  top: vhFn(3);
  left: vwFn(13);
  background-size: 100% 100%;
  margin-top: vhFn(0.25);
  margin-right: vhFn(0.25);
  background-image: url(../../resources/assets/themes/lely/16x9/actors/cow/popup/cow-list-popup-head-adults.svg);
}

.theme-adult .liveDataIndicator {
  position: absolute;
  background-color: $color-LelyWhite;
  border-radius: vwFn(0.2) vwFn(0.2) vwFn(0.2) vwFn(0.2);
  // box-sizing: border-box;
  // background-repeat: no-repeat;
  width: vwFn(20.15);
  height: vhFn(16.73);
  // box-sizing: border-box;
  // border-width: 0.25px;
  padding: vhFn(0.25) vwFn(0.12);
  background-image: none;
  left: vwFn(56);
  z-index: 1 ;
  min-width: vwFn(0);
  top: vhFn(-3);
}

.theme-adult .liveDataIndicator--title {
  width: vwFn(12.7466);
  left: vwFn(1);
  top: vhFn(-6);
  font-size: vhFn(3.33);
  font-family: $secondaryFont !important;
  font-weight: 700;
}

.theme-adult .liveDataIndicator--arrow {
  width: vwFn(3.6);
  height: vhFn(14.832);
  //display: block;
  background-repeat: no-repeat;
  position: absolute;
  bottom: vhFn(-12);
  right: vwFn(0.1);
  background-size: 100% 100%;
  background-image: url(../../resources/assets/themes/lely/16x9/popups/livedata/liveDataArrowAdults.svg);
}