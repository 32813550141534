@use "../common/styles/variables.scss" as *;

.theme-mijnmelkpicnic {
  .vehicle--tractor {
    background-image: url(../resources/assets/themes/mijnmelk/16x9/actors/tractor/green_tractor.svg);
  }

  .virtualFarm {
    background-image: url(../resources/assets/themes/mijnmelk/16x9/screens/virtualFarm/background_farm_overview_812x375.svg);
    background-size: 100% 100%;
  }

  .virtualFarm--bus {
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: absolute;
    background-image: url(../resources/assets/themes/mijnmelk/16x9/screens/virtualFarm/background_bus_label_picnic.svg);
    width: vhFn(23.10066);
    height: vhFn(22.53066666666667);
    left: vhFn(165.65066);
    top: vhFn(36.928);
    z-index: 2;
  }

  .brand {
    display: none;
  }

  .mijnMelk {
    position: fixed;
    background-repeat: no-repeat;
    pointer-events: fill;
    width: vhFn(15);
    height: vhFn(16);
    top: vhFn(4);
    left: vwFn(11);
    background-size: 100% 100%;
    background-image: url(../resources/assets/themes/mijnmelk/16x9/actors/mijnmelkactor/mijnmelkactor.svg);
    cursor: pointer;
  }

  .ribbonTitle--is-clickable {
    background-image: url(../resources/assets/themes/mijnmelk/16x9/ui/ribbon/ribbon.svg);
  }
}
