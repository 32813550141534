@use "../../common/styles/variables.scss" as *;

.lelyStoryStartingSlide {
  position: absolute;
  background-size: 100% 100%;
  width: vwFn(100.5);
  height: vhFn(100);
  background-color: $color-GrayDairyLighter;
}

.lelyStoryStartingSlide--textCaption {
  position: absolute;
  min-width: vwFn(34.605);
  min-height: vhFn(24.26);
  top: vhFn(28.9733);
  left: vwFn(20.096);
  padding: vhFn(3.2) vwFn(1.48);
}

.lelyStoryStartingSlide--line {
  position: absolute;
  background-color: #766e68;
  width: vwFn(20.096);
  height: vhFn(0.53);
  top: vhFn(50.29);
}

.lelyStoryStartingSlide--building {
  position: absolute;
  background-size: 100% 100%;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/lelystory/building.svg);
  width: vwFn(29.18);
  height: vhFn(61.525);
  left: vwFn(54.184);
  top: vhFn(19.237);
}

.lelyStoryStartingSlide--trees {
  position: absolute;
  background-size: 100% 100%;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/lelystory/trees.svg);
  width: vwFn(9.1071);
  height: vhFn(12.4);
  left: vwFn(6.286);
  top: vhFn(38.424);
}

.lelyStoryStartingSlide--title {
  font-family: $secondaryFont;
  font-style: $font-DefaultFontStyle;
  font-weight: $font-bold;
  font-size: vhFn(4.27);
  line-height: vhFn(4.8);
  color: $color-gray-popup;
}

.lelyStoryStartingSlide--name {
  font-family: $secondaryFont;
  font-style: $font-DefaultFontStyle;
  font-weight: $font-bold;
  font-size: vhFn(12.8);
  line-height: vhFn(10.4);
  color: $color-gray-popup;
  margin: vhFn(1.87) 0;
}

.lelyStoryStartingSlide--farmName {
  font-family: $secondaryFont;
  font-style: $font-DefaultFontStyle;
  font-weight: $font-light;
  font-size: vhFn(4.27);
  line-height: vhFn(4.8);
  color: $color-gray-popup;
}
