@use "../../common/styles/variables.scss" as *;

.dobblehoeve--milk {
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/onboarding/adult/bottle_guus.png) !important;
}

.rotgans--milk {
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/onboarding/adult/bottle_reijer.png) !important;
}

.oostdam--milk {
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/onboarding/adult/bottle_yvonne.png) !important;
}

.brosa--milk {
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/onboarding/adult/bottle_ineke.png) !important;
}
.dobblehoeve--yogurt--volle {
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/onboarding/adult/yoghurt_guus.png) !important;
  width: 55%;
  height: 75%;
}
.dobblehoeve--yogurt {
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/onboarding/adult/Packshot_MijnMelk_Yoghurt_500ml_Guus_nolabel_small.png) !important;
  width: 55%;
  height: 75%;
}
.brosa--yoghurt--volle {
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/onboarding/adult/yoghurt_ineke.png) !important;
  width: 55%;
  height: 75%;
}
.brosa--yoghurt {
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/onboarding/adult/Packshot_MijnMelk_Yoghurt_500ml_Ineke_nolabel_small.png) !important;
  width: 55%;
  height: 75%;
}
.oostdam--yogurt--volle {
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/onboarding/adult/yoghurt_yvonne.png) !important; 
  width: 55%;
  height: 75%;
} 
.oostdam--yogurt {
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/onboarding/adult/Packshot_MijnMelk_Yoghurt_500ml_Yvonne_nolabel_small.png) !important; 
  width: 55%;
  height: 75%;
} 
.mijnMelkOnboardingAdult--layer--milkBottle {
  .oostdam--yogurt, .brosa--yoghurt, .dobblehoeve--yogurt  {
    left: vwFn(5.75);
  }
}