@use "../../../common/styles/variables.scss" as *;

.cowBirthFirstPage {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  width: vwFn(100);
  height: vhFn(100);
}

.textCaption--cow--cowBirthFirstPage {
  position: absolute;
  height: vhFn(17.6);
  min-width: vwFn(25);
  top: vhFn(41);
  left: vwFn(13.05);
  background: #ffffff;
  border: 1px solid #efedea;
  box-sizing: border-box;
  box-shadow: 0px 2px 5px rgba(18, 18, 18, 0.2);
  border-radius: vhFn(3.7);
  padding: vhFn(3);
  display: flex;
  justify-content: center;
  text-align: center;
}

.cow-cowBirthFirstPage--text {
  position: static;
  min-width: vwFn(30.09);
  height: vhFn(10.4);
  left: vwFn(1.5);
  top: vhFn(3.2);
  font-family: $secondaryFont !important;
  font-size: vhFn(14);
  font-weight: bold;
  line-height: vhFn(10.4);
  margin: vhFn(1);
  text-align: center;
}
