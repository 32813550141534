@use "../../common/styles/variables.scss" as *;

.milkBatchTemperatureIcon {
  height: vhFn(12);
}

.milkBatchTemperatureIcon--icon {
  display: inline-block;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: vwFn(4);
  height: vhFn(12);
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/onboarding/adult/weatherIcons/temperature_icon.svg);
}

.milkBatchTemperatureIcon--temperatureText {
  display: inline-block;
  font-family: "Ubuntu Mono";
  font-weight: bold;
  font-size: vhFn(5);
  height: vhFn(12);
  vertical-align: middle;
}
