@use "../../common/styles/variables.scss" as *;

.windmill {
  position: absolute;
  width: vhFn(25);
  height: vhFn(60);
}

.windmill-1 {
  left: vwFn(25);
  bottom: vhFn(19);
}

.windmill-2 {
  left: vwFn(50);
  bottom: vhFn(19);
}

.windmill-3 {
  left: vwFn(15);
  bottom: vhFn(19);
}

.windmill-4 {
  left: vwFn(60);
  bottom: vhFn(19);
}

.windmill--body,
.windmill--fin {
  position: absolute;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.windmill--body {
  width: vhFn(5.86);
  height: vhFn(44);
  bottom: 0;
  left: vhFn(9.57); /* (25 - 5.86) / 2 */ /*1.93 = 3.86/2*/
  background-image: url(../../resources/assets/themes/lely/16x9/ui/windmill/windmill-body.svg);
}

.windmill--fin {
  width: vhFn(25);
  height: vhFn(25);
  transform-origin: 50% 50%;
  background-size: 100% 100%;
  bottom: vhFn(30.5);
  left: 0;
  background-image: url(../../resources/assets/themes/lely/16x9/ui/windmill/windmill-fin.svg);
}

:root {
  --windmillRotationSpeed: 2000ms;
}

@keyframes windmillRotating {
  from {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
  to {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

.windmill--fin.is-rotating {
  -webkit-animation: windmillRotating var(--windmillRotationSpeed) linear
    infinite;
  animation: windmillRotating var(--windmillRotationSpeed) linear infinite;
}

.parallax-cow-history .windmill {
  bottom: vhFn(20) !important;
}
