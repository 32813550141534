@use "../../../../common/styles/variables.scss" as *;

.FinalOnboardingSlide {
  background-color: $color-GrayDairyLighter;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  width: vwFn(100);
  height: vhFn(100);
}

.FinalOnboardingSlide--background {
  position: absolute;
  bottom: vhFn(25);
  left: vhFn(11);
  width: vwFn(100);
  height: vhFn(62.5);
  background-image: url(../../../../resources/assets/themes/mijnmelk/16x9/onboarding/finalOnboardingSite/ending-point-screen.svg);
  background-repeat: no-repeat;
  background-size: cover;
}

.FinalOnboardingSlide--backButton {
  position: absolute;
  top: vhFn(55.67);
  right: vwFn(12.46);
  width: vwFn(22.66);
  height: vhFn(12);
  background-image: url(../../../../resources/assets/themes/mijnmelk/16x9/onboarding/finalOnboardingSite/ending-point-button.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  z-index: 2;
  color: $color-LelyWhite;
  font-size: vhFn(3.73);
  line-height: vhFn(4.6);
  text-align: center;
  padding-top: vhFn(3.5);
}

.FinalOnboardingSlide--revertButton {
  position: absolute;
  top: vhFn(40.67);
  right: vwFn(14.46);
  width: vwFn(27.21);
  height: vhFn(11.73);
  background-image: url(../../../../resources/assets/themes/mijnmelk/16x9/onboarding/finalOnboardingSite/ending-point-revert-button.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  z-index: 2;
  color: $color-LelyWhite;
  font-size: vhFn(3.73);
  line-height: vhFn(4.6);
  text-align: center;
  padding-top: vhFn(3);
}

.FinalOnboardingSlide--chooseDayButton {
  position: absolute;
  top: vhFn(27.67);
  right: vwFn(12.46);
  width: vwFn(22.66);
  height: vhFn(12);
  background-image: url(../../../../resources/assets/themes/mijnmelk/16x9/onboarding/finalOnboardingSite/ending-point-back-button.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  z-index: 2;
  color: $color-LelyWhite;
  font-size: vhFn(3.73);
  line-height: vhFn(4.6);
  text-align: center;
  padding-top: vhFn(3.5);
}

.farmerStoryEndingPoint--backButton--text {
  position: absolute;
  width: vwFn(14.46);
  bottom: vhFn(35);
  left: vwFn(48.5);
  word-break: break-word;
  font-family: $secondaryFont;
  font-style: $font-DefaultFontStyle;
  font-weight: $font-bold;
  font-size: vhFn(4.8);
  line-height: vhFn(6.4);
  color: $color-gray-popup;
  text-align: center;
  z-index: 2;
}

.textCaption--FinalOnboardingSlide {
  position: absolute;
  min-width: vwFn(20.07);
  max-width: vwFn(24);
  height: vhFn(19.2);
  left: vwFn(12.31);
  top: vhFn(40.4);
  padding: vwFn(1.4) vwFn(1.4) vwFn(1.4) vwFn(1.4);
  z-index: 1;
}

.FinalOnboardingSlide--text {
  position: static;
  font-size: vwFn(1.72);
  line-height: vhFn(6.4);
  margin: 0px;
}

.FinalOnboardingSlide--farmer {
  position: absolute;
  left: vwFn(30.21);
  top: vhFn(34.56);
  width: vwFn(18.47);
  height: vhFn(45.54);
  background-image: url(../../../../resources/assets/themes/mijnmelk/16x9/onboarding/farmerPresentation/farmer.svg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  z-index: 1;
}

.FinalOnboardingSlide--bottle {
  position: absolute;
  left: vwFn(20.44);
  bottom: vhFn(24.6);
  width: vwFn(2.709);
  height: vhFn(12.8);
  background-image: url(../../../../resources/assets/themes/mijnmelk/16x9/onboarding/finalOnboardingSite/bottle.svg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.FinalOnboardingSlide--cup {
  position: absolute;
  left: vwFn(24.13);
  bottom: vhFn(24.6);
  width: vwFn(2.33);
  height: vhFn(7.2);
  background-image: url(../../../../resources/assets/themes/mijnmelk/16x9/onboarding/finalOnboardingSite/cup-milk.svg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.farmerStoryEndingPoint--chooseDayButton--text {
  position: absolute;
  width: vwFn(14.46);
  top: vhFn(25);
  left: vwFn(45.5);
  word-break: break-word;
  font-family: $secondaryFont;
  font-style: $font-DefaultFontStyle;
  font-weight: $font-bold;
  font-size: vhFn(4.8);
  line-height: vhFn(6.4);
  color: $color-gray-popup;
  text-align: center;
  z-index: 2;
}

.farmerStoryEndingPoint--chooseDayButton--arrow {
  position: absolute;
  transform: rotate(-40deg);
  left: vwFn(61.92);
  top: vhFn(28.712);
  width: vwFn(3.2);
  height: vhFn(11.62);
  background-image: url(../../../../resources/assets/themes/lely/16x9/ui/arrows/arrow-left-curve-down.svg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.arrowFinalSlideMelk {
  position: absolute;
  background-repeat: no-repeat;
  background-color: gray;
  background-size: 100% 100%;
  border: vhFn(0.2) solid $color-GrayDairyDarker;
  width: 100%;
  height: vhFn(0.25);
  top: vhFn(74.7);
  left: vwFn(0);
}
