@use "../../../common/styles/variables.scss" as *;
.popup-container > div {
    animation-name: fade_in_show;
    animation-duration: 0.5s;
}

@keyframes fade_in_show {
    0% {
         opacity: 0;
         transform: scale(0)
    }

    100% {
         opacity: 1;
         transform: scale(1)
    }
} 
.shopPopup, .vehiclePopup, .iceCreamFactoryPopup, .cheeseFactoryPopup, .dairyPopup, .calmRobotPopup, .solarPanel, .cowPopup, .orbiterPopup, .discoveryPopup, .astronautPopup, .grazewayPopup, .lunaPopup, .farmPopup, .farmerPopup, .animalPopup, .sustainabilityPopup, .mijnMelkPopup, .vectorPopup, .junoPopup {
   position: absolute;
   top: vhFn(11.2);
   left: vwFn(6.159);
   // /* bring your own prefixes */
   // transform: translate(-50%, -50%);
   width: vwFn(87.684);
   height: vhFn(77.6);
 }
 .shopPopup--container, .vehiclePopup--container, .iceCreamFactoryPopup--container, .cheeseFactoryPopup--container, .dairyPopup--container, .calmRobotPopup--container, .solarPanelTextInfo, .cowPopup--container, .orbiterPopup--container, .discoveryPopup--container, .astronautPopup--container, .grazewayPopup--container, .lunaPopup--container, .farmPopup--container, .farmerPopup--container, .dogPopup, .sustainabilityPopup--container, .mijnMelkPopup--container, .vectorPopup--container, .junoPopup--container {
   //display: inline;
   position: fixed;
   width: vwFn(70.73);
   min-height: vhFn(30.66);
   background-color: white;
   top: 50%;
   transform: translate(0%, -50%);
   border-left: vwFn(0.12) solid $color-GrayDairyLight;
   border-right: vwFn(0.12) solid $color-GrayDairyLight;
   border-top: vhFn(0.27) solid $color-GrayDairyLight;
   border-bottom: vhFn(0.27) solid $color-GrayDairyLight;
   box-sizing: border-box;
   //box-shadow: 0px 2px 5px 5px rgba(222, 219, 214, 0.4);
   border-radius: vwFn(0.5) vwFn(0.5) vwFn(0.5) vwFn(0.5); 
   padding: vhFn(3.25) vwFn(2.5);
   //padding-top: vhFn(1.5);
 }
 .shopPopup--frame, .vehiclePopup--frame, .farmPopup--frame, .farmerPopup--frame, .dogPopup--frame {
   position: absolute;
   top: vhFn(0.2);
   left: vwFn(55.3546);
   width: vhFn(70.6);
   height: vhFn(77.6);
   background-repeat: no-repeat;
   background-size: 100% 100%; 
   background-repeat: no-repeat;
   width: vhFn(71.35);
   height: vhFn(77.05);
   background-size: 100% 100%;
   left: vwFn(55.5);
   background-image: url(../../../resources/assets/themes/lely/16x9/actors/actorFrame/actorFrame.svg); 
   z-index: 99999;
 }
 .theme-adult .shopPopup--frame, .theme-adult .farmPopup--frame, .theme-adult .vehiclePopup--frame,  .theme-adult .farmerPopup--frame,  .theme-adult .dogPopup--frame {
   background-image: url(../../../resources/assets/themes/lely/16x9/actors/actorFrame/actorFrameAdult.svg);
 }
 .shopPopup--title, .vehiclePopup--title, .iceCreamFactoryPopup--title, .cheeseFactoryPopup--title, .dairyPopup--title, .calmRobotPopup--title, .solarPanel--title, .cowPopup--title, .orbiterPopup--title, .discoveryPopup--title, .astronautPopup--title, .grazewayPopup--title, .lunaPopup--title, .farmPopup--title, .farmerPopup--title, .dogPopup--name, .sustainabilityPopup--title, .mijnMelkPopup--title, .vectorPopup--title, .junoPopup--title {
  position: absolute;
   top: vhFn(3.2);
   font-family: $secondaryFont;
   font-weight: bold;
   color: $color-LelyBlack;
   font-size: vhFn(6.4);
   letter-spacing: vwFn(0.3);
   max-width: vwFn(43.22);
 }
 
 .shopPopup--description, .vehiclePopup--description, .iceCreamFactoryPopup--description, .cheeseFactoryPopup--description, .dairyPopup--description, .calmRobotPopup--robotDescriptionContainer, .solarPanel--description, .orbiterPopup--descriptionValue, .discoveryPopup--description, .astronautPopup--description, .grazewayPopup--description, .lunaPopup--description, .farmPopup--description, .farmerPopup--farmerQuote, .dogPopup--description, .sustainabilityPopup--description, .mijnMelkPopup--description, .vectorPopup--description, .junoPopup--description {
   width: vwFn(50.1);
   font-size: vhFn(3.73);
   line-height: vhFn(6.4);
   //font-style: italic;
   //margin-top: vhFn(1); 
   margin-bottom: 0 !important;
   overflow-wrap: break-word;
 }
 
 .shopPopup--btnClosePopup, .vehiclePopup--btnClosePopup, .iceCreamFactoryPopup--btnClosePopup, .cheeseFactoryPopup--btnClosePopup, .dairyPopup--btnClosePopup, .calmRobotPopup--btnClosePopup, .solarPanel--btnClosePopup, .cowPopup--btnClosePopup, .orbiterPopup--btnClosePopup, .discoveryPopup--btnClosePopup, .astronautPopup--btnClosePopup, .grazewayPopup--btnClosePopup, .lunaPopup--btnClosePopup, .farmPopup--btnClosePopup, .farmerPopup--btnClosePopup, .dogPopup--btnClosePopup, .sustainabilityPopup--btnClosePopup, .mijnMelkPopup--btnClosePopup, .vectorPopup--btnClosePopup, .junoPopup--btnClosePopup {
    display: inline;
   background-image: url(../../../resources/assets/themes/lely/16x9/ui/popup/close-popup-button.svg);
   background-repeat: no-repeat;
   background-size: 100% 100%;
   width: vwFn(8.7);
   height: vhFn(11.5);
   z-index: 10;
   margin-top: vhFn(-3.5); 
   margin-left: vwFn(40);
 }
 
 .shopPopup--image-wrapper, .vehiclePopup--image-wrapper, .farmPopup--farmImageContainer, .farmerPopup--farmerContainer, .dogPopup--dog { 
   // position: absolute; 
   // left: vwFn(60.96);
   // top: vhFn(11.2);
   width: vhFn(70.6);
   height: vhFn(77.6);
   margin-left: vwFn(55);
   -webkit-clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
   clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
 }
 
 .shopPopup--image, .vehiclePopup--image, .farmPopup--farmImage, .farmerPopup--farmer, .dogPopup--dog--image, .farmerPopup--farmer--female, .farmerPopup--farmer--male, .farmPopup--farmDefaultImage, .sustainabilityPopup--defaultImage {
   //position: absolute;
   width: vhFn(68);
   height: vhFn(77);
   background-repeat: no-repeat;
   background-size: 100% 100%;
   -webkit-clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
   clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
   object-fit:cover;
   z-index: 10;
   margin-left: vhFn(4);
   //margin-top: vhFn(1);
 }
 .theme-adult {
  .shopPopup--title, .vehiclePopup--title, .iceCreamFactoryPopup--title, .cheeseFactoryPopup--title, .dairyPopup--title, .calmRobotPopup--title, .solarPanel--title, .cowPopup--title, .orbiterPopup--title, .discoveryPopup--title, .astronautPopup--title, .grazewayPopup--title, .lunaPopup--title, .vectorPopup--title, .farmPopup--title,.farmerPopup--title, .dogPopup--name, .sustainabilityPopup--title, .mijnMelkPopup--title, .junoPopup--title  {
     font-size: vhFn(6.4); 
     margin-left: vwFn(7.25); 
   } 
   .shopPopup--description, .vehiclePopup--description, .iceCreamFactoryPopup--description, .cheeseFactoryPopup--description, .dairyPopup--description, .calmRobotPopup--robotDescriptionContainer, .solarPanel--description, .orbiterPopup--descriptionValue, .discoveryPopup--description, .astronautPopup--description, .grazewayPopup--description, .lunaPopup--description, .vectorPopup--description, .farmPopup--description, .farmerPopup--farmerQuote, .dogPopup--description, .sustainabilityPopup--description, .mijnMelkPopup--description, .junoPopup--description { 
     margin-left: vwFn(1.25) !important; 
     margin-top: 0 !important;
   }
   .shopPopup--btnClosePopup, .vehiclePopup--btnClosePopup, .iceCreamFactoryPopup--btnClosePopup, .cheeseFactoryPopup--btnClosePopup, .dairyPopup--btnClosePopup, .calmRobotPopup--btnClosePopup, .solarPanel--btnClosePopup, .cowPopup--btnClosePopup, .orbiterPopup--btnClosePopup, .discoveryPopup--btnClosePopup, .astronautPopup--btnClosePopup, .grazewayPopup--btnClosePopup, .lunaPopup--btnClosePopup, .vectorPopup--btnClosePopup, .farmPopup--btnClosePopup, .farmerPopup--btnClosePopup, .dogPopup--btnClosePopup, .sustainabilityPopup--btnClosePopup, .mijnMelkPopup--btnClosePopup, .junoPopup--btnClosePopup {
     background-image: url(../../../resources/assets/themes/lely/16x9/ui/popup/close-popup-button-adult-theme.svg);
     width: vwFn(7);
     height: vhFn(13.9); 
     margin-left: 0 !important;
     //margin-top: vhFn(0); 
     padding-left: 0 !important;
 } 
 .shopPopup--container, .vehiclePopup--container, .iceCreamFactoryPopup--container, .cheeseFactoryPopup--container, .dairyPopup--container, .calmRobotPopup--container, .solarPanelTextInfo, .cowPopup--container, .orbiterPopup--container, .discoveryPopup--container, .astronautPopup--container, .grazewayPopup--container, .lunaPopup--container, .vectorPopup--container, .farmPopup--container, .farmerPopup--container, .dogPopup, .sustainabilityPopup--container, .mijnMelkPopup--container, .junoPopup--container {
   padding: vhFn(3.25) vwFn(2.25);
   box-shadow: none;
 }
 }   
 .orbiterPopup--btnCallToAction, .discoveryPopup--btnCallToAction, .lunaPopup--btnCallToAction, .vectorPopup-callToActionButton, .farmerPopup--btnCallToAction, .sustainabilityPopup--btnCallToAction, .junoPopup--btnCallToAction {
  background-color: $color-LelyRed;
  background-repeat: no-repeat;
  border-radius: vwFn(0.5) vwFn(0.5) vwFn(0.5) vwFn(0.5);
  border-left: vwFn(0.12) solid $color-GrayDairyLight;
  border-right: vwFn(0.12) solid $color-GrayDairyLight;
  border-top: vhFn(0.27) solid $color-GrayDairyLight;
  border-bottom: vhFn(0.27) solid $color-GrayDairyLight;
  background-size: 100% 100%;
  min-width: vwFn(0.18);
  font-size: vwFn(1.5);
  color: #ffffff;
  //position: absolute;
  line-height: vhFn(4.266); 
  display: inline; 
  padding: vhFn(2) vwFn(2); 
  //margin-left: vwFn(2);
 } 
//  .theme-adult .lunaPopup--btnCallToAction, .theme-adult .vectorPopup-callToActionButton, .theme-adult .farmerPopup--btnCallToAction--container, .theme-adult .sustainabilityPopup--btnCallToAction--container {
//   margin-bottom: vhFn(2) !important;
// }
.is-simple-mode-activated > 
  .shopPopup--description, .vehiclePopup--description, .iceCreamFactoryPopup--description, .cheeseFactoryPopup--description, .dairyPopup--description, .solarPanel--description, .orbiterPopup--descriptionValue, .discoveryPopup--description, .astronautPopup--description, .grazewayPopup--description, .lunaPopup--description, .farmPopup--description, .farmerPopup--farmerQuote, .dogPopup--description, .sustainabilityPopup--description, .mijnMelkPopup--description, .vectorPopup--description, .junoPopup--description {
    margin-top: vhFn(2.25);
    margin-bottom: vhFn(2.25);

}