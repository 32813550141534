@use "../../styles/variables.scss" as *;

.labelled-text-input-for-language {
  position: relative;
  margin-bottom: 5px;
}

.labelled-text-input-for-language--label {
  display: block;
  font-family: Ubuntu, sans-serif;
  font-size: 16px;
  padding-top: 1px;
  padding-bottom: 1vh;
}

.labelled-text-input-for-language .labelled-text-input--textInput {
  border: 1px solid $color-gray-light;
  box-shadow: inset 0px 2px 5px rgba(222, 219, 214, 0.4);
  border-radius: 4px;
  padding: 4px;
  padding-top: 15px;
  height: 32px;
  font-size: 14px;
  color: $color-gray-popup;
}

.labelled-text-input-for-language .tooltip-padding {
  padding-right: 28px;
}

.labelled-text-input-for-language .labelled-text-input--tooltip {
  position: absolute;
  right: 7px;
  bottom: 6px;
  cursor: pointer;
}
