@use "../../../common/styles/variables.scss" as *;

.cow--firstBirthdaySlide {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-color: #f7f6f4;
  position: absolute;
  width: vwFn(100);
  height: vhFn(100);
}

.textCaption--cow--firstBirthdaySlide {
  position: absolute;
  min-height: vhFn(1);
  max-width: vwFn(26.52);
  left: vwFn(37.0394);
  top: vhFn(10.8);
  padding: vwFn(1);
  background: #ffffff;
  border: 1px solid #efedea;
  box-sizing: border-box;
  box-shadow: 0px 2px 5px rgba(18, 18, 18, 0.2);
  border-radius: vhFn(4);
  z-index: 6;
}

.cow--firstBirthdaySlide--text {
  font-size: vhFn(4.8);
  font-family: $secondaryFont !important;
  font-weight: bold;
  letter-spacing: vwFn(0.1);
  max-width: vwFn(20.92);
  min-height: vhFn(26);
  text-transform: uppercase;
}

.cow--firstBirthdaySlide--whistle {
  position: absolute;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-image: url(../../../resources/assets/themes/lely/16x9/screens/cowFirstBirthday/cow-first-birthday-slide-whistle.svg);
  width: vwFn(8.94);
  height: vhFn(13.33);
  left: vwFn(62.5);
  top: vhFn(68);
}

.cow--firstBirthdaySlide--arrow {
  position: absolute;
  width: vwFn(100);
  background-color: #766e68;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: vhFn(0.4);
  top: vhFn(80);
  border: vhFn(0.4);
}

.cow--firstBirthdaySlide--firstYear {
  position: absolute;
  width: vwFn(6.5);
  height: vhFn(6.4);
  left: vwFn(84.5);
  top: vhFn(21);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  font-family: $secondaryFont;
  font-style: normal;
  font-weight: bold;
  font-size: vhFn(7);
  line-height: vhFn(6.4);
  display: flex;
  align-items: center;
}

.cow-firstBirthdaySlide--date {
  position: static;
  width: vwFn(27.83);
  height: vhFn(6.4);
  left: vwFn(1.477);
  top: vhFn(3.2);
  font-family: $secondaryFont;
  font-style: normal;
  font-size: vhFn(5);
  line-height: vhFn(6.4);
  margin: vwFn(1) 0;
}

.cow-firstBirthdaySlide--text {
  position: static;
  width: vwFn(27.83);
  height: vhFn(19.2);
  left: vwFn(1.477);
  top: vhFn(12.26);
  font-family: Ubuntu;
  font-style: normal;
  font-weight: normal;
  font-size: vhFn(3.75);
  line-height: vhFn(6.4);
}

.cow-firstBirthdaySlide--calendar {
  position: absolute;
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistory/background-layers/firstYear/calendar.svg);
  background-repeat: no-repeat;
  width: vwFn(15.4);
  height: vhFn(31.821);
  left: vwFn(11.674);
  top: vhFn(29.0053);
  z-index: 5;
}

.cow-firstBirthdaySlide--calendarMonth {
  position: fixed;
  margin-top: vhFn(6);
  text-align: center;
  color: #ffffff;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  font-size: vhFn(3.73);
  line-height: vhFn(6.4);
  width: vwFn(15.394);
}

.cow-firstBirthdaySlide--calendarDay {
  position: fixed;
  margin-top: vhFn(12);
  text-align: center;
  color: black;
  font-family: $secondaryFont;
  font-style: normal;
  font-weight: 700;
  font-size: vhFn(12.8);
  line-height: vhFn(14.4);
  width: vwFn(15.394);
}

.textCaption--firstBirthdaySlide--year {
  position: absolute;
  padding: vwFn(1.5);
  min-height: vhFn(11.2);
  min-width: vwFn(1);
  top: vhFn(42.6);
  left: vwFn(26.257);
  z-index: 4;
  font-weight: 300;
  font-size: vhFn(4.266);
  line-height: vhFn(4.8);
}

.cow--firstBirthdaySlide--box {
  position: absolute;
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistory/background-layers/firstYear/presentBox.svg);
  background-repeat: no-repeat;
  background-size: contain;
  width: vwFn(8);
  height: vhFn(22.058);
  left: vwFn(29.081);
  top: vhFn(59.1413);
  z-index: 5;
}

.cow--firstBirthdaySlide--bigPartyFlag {
  position: absolute;
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistory/background-layers/firstYear/bigPartyFlag.svg);
  background-repeat: no-repeat;
  width: vwFn(41.7);
  height: vhFn(33.602);
  left: vwFn(33.518);
  top: vhFn(0);
  z-index: 5;
}

.cow--firstBirthdaySlide--smallPartyFlag {
  position: absolute;
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistory/background-layers/firstYear/smallPartyFlag.svg);
  background-repeat: no-repeat;
  width: vwFn(23.992);
  height: vhFn(22.797);
  left: vwFn(75.052);
  top: vhFn(0);
  z-index: 5;
}
