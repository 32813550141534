@use "../../../common/styles/admin_variables.scss" as adminVar;
@use "../../../common/components/SaveChangesButton/SaveChangesButton.scss";

.AboutSustainabilityPage {
  margin-left: 5vw;
  margin-top: adminVar.$pagesMarginTop;
  height: 90vh;
  overflow: auto;
  padding: 0 19.17vw;
}
.adminPanel--sustainability, .adminPanel--animalWellfare, .adminPanel--sustainabilityModal {
    margin-top: 10px;
    border-radius: 4px;
    background-color: #fff;
    border: 1px solid #CFCBC3;
    padding-left: 10px;
  }
.about-you--header {
  font-weight: 400;
  font-size: 26px;
}

.sustainability--intro {
  font-size: 14px;
}
.adminPanel--sustainability .image-radio-selection--form-control-style1, .adminPanel--sustainabilityModal {
  background-position-y: -10px;
}
.adminPanel--sustainability .image-radio-selection--form-control-style2, .adminPanel--sustainabilityModal {
  background-position-y: -10px;
}
.about-you--textField {
  width: 100%;
}

.about-you--language {
  width: 100%;
}

.about-you--textArea {
  width: 100%;
}

/* Farmer style radio buttons */
.image-radio-selection--form-control-style1 {
  background-image: url(../../../resources/assets/themes/lely/16x9/adminpanel/farmerStyle/radio-farmer-male.svg);
  background-size: 100%;
  height: 200px;
}

.image-radio-selection--form-control-style2 {
  background-image: url(../../../resources/assets/themes/lely/16x9/adminpanel/farmerStyle/radio-farmer-female.svg);
  background-size: 100%;
  height: 200px;
}

/* Farmer pet radio buttons */
// .image-radio-selection--form-control-dogNone {
// }

.image-radio-selection--form-control-dogSmall {
  background-image: url(../../../resources/assets/themes/lely/16x9/adminpanel/dogSize/radio-small-dog.svg);
}

.image-radio-selection--form-control-dogMedium {
  background-image: url(../../../resources/assets/themes/lely/16x9/adminpanel/dogSize/radio-medium-dog.svg);
}

.image-radio-selection--form-control-dogLarge {
  background-image: url(../../../resources/assets/themes/lely/16x9/adminpanel/dogSize/radio-large-dog.svg);
}

/* Farmer other animals radio buttons */
// .image-radio-selection--form-control-otherAnimalsNone {
// }

.image-radio-selection--form-control-otherAnimalsCat {
  background-image: url(../../../resources/assets/themes/lely/16x9/adminpanel/otherAnimal/radio-cat.svg);
}

.image-radio-selection--form-control-otherAnimalsPony {
  background-image: url(../../../resources/assets/themes/lely/16x9/adminpanel/otherAnimal/radio-pony.svg);
}

.image-radio-selection--form-control-otherAnimalsHorse {
  background-image: url(../../../resources/assets/themes/lely/16x9/adminpanel/otherAnimal/radio-horse.svg);
}

.image-radio-selection--form-control-otherAnimalsLlama {
  background-image: url(../../../resources/assets/themes/lely/16x9/adminpanel/otherAnimal/radio-llama.svg);
}

.image-radio-selection--form-control-otherAnimalsAlpaca {
  background-image: url(../../../resources/assets/themes/lely/16x9/adminpanel/otherAnimal/radio-alpaca.svg);
}

.image-radio-selection--form-control-otherAnimalsDonkey {
  background-image: url(../../../resources/assets/themes/lely/16x9/adminpanel/otherAnimal/radio-donkey.svg);
}

.image-radio-selection--form-control-otherAnimalsSheep {
  background-image: url(../../../resources/assets/themes/lely/16x9/adminpanel/otherAnimal/radio-sheep.svg);
}

.image-radio-selection--form-control-otherAnimalsGoat {
  background-image: url(../../../resources/assets/themes/lely/16x9/adminpanel/otherAnimal/radio-goat.svg);
}

.image-radio-selection--form-control-otherAnimalsChicken {
  background-image: url(../../../resources/assets/themes/lely/16x9/adminpanel/otherAnimal/radio-chicken.svg);
}

.AboutYouPage .btn-save-changes {
  margin-left: calc(100% - 170px) !important;
  width: 170px;
}

.AboutYouPage .btn-already-saved {
  margin-left: calc(100% - 270px) !important;
  width: 270px;
  margin-top: 20px !important;
}
.AboutYouPage .MuiTypography-body1 {
    font-size: 14px;
} 
.sustainabilityPage--milkyield {
 font-weight: 700;
 font-size: 14px;
} 
.sustainabilityPage--milkyield--question, .sustainability--carbonDioxideQuestion, .sustainability--concentratesQuestion, .sustainability--fertilizerQuestion, .sustainability--renewableEnergyQuestion, .sustainability--roughageProductionQuestion, .animalWelfare--grazingQuestion {
  width: 98%;
  margin-bottom: 2vh !important;
  padding-top: 0vh !important;
} 
.AboutYouPage .labelled-text-input--label {
  font-size: 14px;
} 
.AboutYouPage .labelled-text-input--textInput {
   font-size: 14px; 
   padding-left: 15px;
} 
.about-the-farm--payment-methods {
    border-top: 1px groove #cfcbc3;
    padding-top: 10px;
    margin-top: 0px;
} 
.sustainability--carbonDioxide, .sustainability--concentrates, .sustainability--cowAndCalf, .sustainability--ageBreedInfo, .sustainability--livingEnvironment, .sustainability--grazing  {
    border-top: 1px groove #cfcbc3;
    padding-top: 10px;
    margin-top: 0px;
} 
.sustainability--carbonDioxide, .sustainability--concentrates, .sustainability--cowAndCalf, .sustainability--ageBreedInfo, .sustainability--livingEnvironment, .sustainability--grazing {
    //margin-top: 20px;
  
    .checkbox-group-selection--label {
      color: adminVar.$color-LelyBlack;
    }
    .checkbox-group-selection--checkbox-item {
        height: 35px;
    }
    .checkbox-group-selection--form-control {
      display: flex;
      align-items: center !important;
      width: 200px;
      height: fit-content;
      margin-left: 0 !important;
      margin-bottom: 10px;
  
      .MuiIconButton-root {
        padding-left: 0;
      }
    }
  } 
  .sustainability--carbonDioxideQuestion, .sustainability--concentratesQuestion, .multiLanguageTextArea--label, .sustainability--fertilizerQuestion, .sustainability--renewableEnergyQuestion, .sustainability--roughageProductionQuestion, .animalWelfare--grazingQuestion {
    font-size: 14px !important;
  } 
  .multiLanguageTextArea{
    // /border-bottom: 1px groove #cfcbc3;
    // padding-bottom: 5px;
    margin-bottom: 10px;
  }  
  .AboutSustainabilityPage .MuiCheckbox-root {
    color: #c30a14;
  } 
  .AboutSustainabilityPage .btn-save-changes {
    left: 20vw;
  } 
  .sustainability--carbonDioxide .MuiFormControlLabel-label, 
  .sustainability--concentrates .MuiFormControlLabel-label, 
  .sustainability--cowAndCalf .MuiFormControlLabel-label, 
  .sustainability--ageBreedInfo .MuiFormControlLabel-label, 
  .sustainability--livingEnvironment .MuiFormControlLabel-label, 
  .sustainability--grazing .MuiFormControlLabel-label {
    font-weight: 700;
    text-wrap: nowrap;
  } 
  
  .AboutSustainabilityPage .checkbox-group-selection--form-control {
    width: 200px;
  }