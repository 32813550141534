@use "../../common/styles/variables.scss" as *;

// .calmRobotPopup {
//   position: absolute;
//   background-color: $color-LelyWhite;
//   width: vwFn(67.73);
//   height: vhFn(72.27);
//   border-left: vwFn(0.12) solid $color-GrayDairyLight;
//   border-right: vwFn(0.12) solid $color-GrayDairyLight;
//   border-top: vhFn(0.27) solid $color-GrayDairyLight;
//   box-sizing: border-box;
//   box-shadow: 0px 2px 5px rgba(222, 219, 214, 0.4);
//   border-radius: vwFn(0.5) vwFn(0.5) vwFn(0.5) vwFn(0.5);
//   padding: vhFn(2) vwFn(2);
//   top: vhFn(8);
//   left: vwFn(7);
//   z-index: 100; //could be lower, just higher than the calm slide overlay
// }

// .calmRobotPopup--title {
//   width: 100%;
//   padding-top: vhFn(1);
//   font-family: $secondaryFont;
//   color: $color-LelyBlack;
//   font-weight: bold;
//   font-size: vhFn(6.25);
//   margin-left: vwFn(1);
// }

.calmRobotPopup--robotNameContainer {
  margin-bottom: vhFn(2);
  margin-top: vhFn(1.5);
}

.calmRobotPopup--nameHeader {
  display: inline-block;
  padding: 0px;
  font-weight: bold;
  //margin-left: vwFn(1);
  font-size: vwFn(1.75);
  width: vwFn(14.3);
}

.calmRobotPopup--nameValue {
  display: inline-block;
  font-weight: 400;
  font-size: vhFn(4);
}

.calmRobotPopup--descriptionHeader {
  display: inline-block;
  vertical-align: text-top;
  padding: 0px;
  font-weight: bold;
  //margin-left: vwFn(1);
  font-size: vhFn(4);
  width: vwFn(14.3);
  vertical-align: top;
}

// .calmRobotPopup--descriptionValue {
//   display: inline-block;
//   width: vwFn(33.5);
//   font-weight: 400;
//   margin-bottom: vhFn(2);
//   font-size: vhFn(4);
//   line-height: vhFn(6.4);
// }

.calmRobotPopup--logo {
  position: absolute;
  top: vhFn(-1);
  left: vwFn(52.5);
  width: vhFn(74);
  height: vhFn(74);
  background-image: url(../../resources/assets/themes/lely/16x9/popups/calmrobot/calm-robot.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

// .calmRobotPopup--btnClosePopup {
//   position: absolute;
//   top: vhFn(-2.8);
//   left: vwFn(40);
//   background-image: url(../../resources/assets/themes/lely/16x9/ui/popup/close-popup-button.svg);
//   background-repeat: no-repeat;
//   background-size: 100% 100%;
//   width: vwFn(8.5);
//   height: vhFn(11.5);
// }
.adult-theme, .calmRobotPopup--title {
  margin-top: vhFn(1);
} 
.theme-adult .calmRobotPopup--logo {
  background-image: url(../../resources/assets/themes/lely/16x9/popups/calmrobot/calm--popup--image.svg); 
}