@use "../../common/styles/variables.scss" as *;

.cow-daily--resting--slide {
  width: vwFn(100);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  /* Fallback for browsers that do not support Custom Properties */
  height: vhFn(100);
}

.cowDailyResting {
  position: absolute;
  left: vwFn(20.56);
  top: vhFn(35.6);
  width: vwFn(30.78);
  min-height: vhFn(32);
  padding: vwFn(0.2);
  line-height: vhFn(6.4);
  font-size: vhFn(4);
  margin: 0 !important;
}

.cowDailyResting--date {
  position: static;
  font-family: $secondaryFont;
  font-weight: bold;
  font-size: vhFn(4);
  line-height: vhFn(6.4);
  color: black;
  letter-spacing: vwFn(0.2);
  margin: vwFn(0.25);
}

.cow-daily--resting--text {
  position: static;
  width: vwFn(19.33);
  height: vhFn(12.8);
  font-family: $secondaryFont;
  font-weight: bold;
  font-size: vhFn(3.75);
  line-height: vhFn(6.4);
  color: black;
  margin: vwFn(1.25) vhFn(1);
}

.cow-daily--resting-cow {
  position: absolute;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowdaily/graphics/cow_resting.svg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  left: vwFn(53.2);
  top: vhFn(24);
  width: vwFn(19);
  height: vhFn(23);
  bottom: vhFn(40);
}

.cowDailyResting--button {
  position: absolute;
  background-image: url(../../resources/assets/themes/lely/16x9/screens/cowCalmRobot/cow-calm-slide-button.svg);
  background-repeat: no-repeat;
  height: vhFn(6.63);
  width: vwFn(3.69);
  padding: 0;
  margin: 0;
  right: 3px;
  top: 50%;
}

.cow-daily--resting--arrowText {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  position: absolute;
  width: vwFn(25.39);
  height: vhFn(12.8);
  left: vwFn(62.78);
  top: vhFn(18);
}

.cow-daily--resting--arrow {
  position: absolute;
  background-image: url(../../resources/assets/themes/lely/16x9/ui/arrows/arrow-left-curve-down-black.svg);
  background-repeat: no-repeat;
  background-size: cover;
  width: vwFn(4.92);
  height: vhFn(5.62);
  left: vwFn(-5);
  top: vhFn(14);
  transform: scale(1) rotate(-115deg);
}

.cowDailyResting--text {
  font-size: vhFn(3.75);
  line-height: vhFn(6.4);
  margin: vwFn(1.25) vhFn(1);
}
