@import "../../common/styles/variables.scss";

.astronautNumberSlide {
  position: absolute;
  background-size: 100% 100%;
  width: vwFn(101);
  height: vhFn(100);
  background-image: url(../../resources/assets/themes/lely/16x9/stories/astronautLiveDataStory/backgroundAstronautLiveData.svg);
  border: none;
}
.astronautNumberSlide--matrixElements {
  position: absolute;
  background-size: 100% 100%;
  width: vwFn(50);
  left: vwFn(50.25);
  height: vhFn(100);
  background-image: url(../../resources/assets/themes/lely/16x9/stories/astronautLiveDataStory/backgroundMixBetweenMilkAndMatrix.svg);
  border: none;
}
.astronautNumberSlide--textCaption {
  position: absolute;
  width: vwFn(30.7881);
  min-height: vhFn(24.46);
  top: vhFn(37.2);
  left: vwFn(7.88);
  padding: vhFn(3.2) vwFn(1.48);
  z-index: 2;
}

.astronautNumberSlide--title {
  font-family: $secondaryFont;
  font-style: $font-DefaultFontStyle;
  font-weight: $font-light;
  font-size: vhFn(4.27);
  line-height: vhFn(4.8);
  letter-spacing: vwFn(0.1);
  color: $color-gray-popup;
}

.astronautNumberSlide--text {
  font-family: $primaryFont;
  font-style: $font-DefaultFontStyle;
  font-size: vhFn(3.73);
  line-height: vhFn(6.4);
  color: $color-gray-popup;
}

.astronautNumberSlide--imgContainer {
  width: 100%;
  height: vhFn(57.77);
  border-width: vhFn(0.53) vwFn(0.246);
  border-style: solid;
  border-color: $color-GrayDairyDarker;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/astronautstory/astronaut.jpg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-color: $color-GrayDairyDarker;
}

.astronautNumberSlide--imgCaption {
  position: absolute;
  width: vwFn(29.85);
  height: vhFn(78.93);
  background-color: $color-LelyWhite;
  box-shadow: 0px vwFn(0.25) vwFn(0.7) rgba(18, 18, 18, 0.2);
  padding: vhFn(3.2) vwFn(1.48);
  transform: rotate(5.18deg);
  left: vwFn(8.08);
  bottom: vhFn(8);
}
.theme-adult {
  .astronautNumberSlide {
    background-image: url(../../resources/assets/themes/lely/16x9/stories/astronautLiveDataStory/astronautSecondSlideAdultVersion.svg);
  } 
  // .astronautNumberSlide--matrixElements {
  //   display: none;
  // }
}