@use "../../common/styles/variables.scss" as *;

.orbiterStoryFourthSlide {
  position: absolute;
  background-size: 100% 100%;
  width: vwFn(101);
  height: vhFn(100);
  background-color: #f7f3f2;
  border: none;
}

.orbiterStoryFourthSlide--textCaption {
  position: absolute;
  width: vwFn(30.788);
  min-height: vhFn(20.8);
  top: vhFn(23.39);
  left: vwFn(65.26);
  padding: vhFn(1.6) vwFn(1.48);
  z-index: 2;
}

.orbiterStoryFourthSlide--floor {
  position: absolute;
  background-size: 100% 100%;
  left: vwFn(0);
  top: vhFn(78.5);
  width: vwFn(100);
  height: vhFn(21.5);
  background-color: #cfcbc3;
}

.orbiterStoryFourthSlide--robot {
  position: absolute;
  background-size: 100% 100%;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/orbiterstory/orbiterFourthSlideRobot.svg);
  width: vwFn(39.65);
  height: vhFn(65.6);
  left: vwFn(17.28);
  top: vhFn(12.88);
  z-index: 3;
}

.orbiterStoryFourthSlide--text {
  font-family: $primaryFont;
  font-style: $font-DefaultFontStyle;
  font-size: vhFn(3.73);
  line-height: vhFn(6.4);
  color: $color-gray-popup;
  margin: vhFn(1.87) 0; 
  letter-spacing: vwFn(0.03);
}

.orbiterStoryFourthSlide--pipe {
  position: absolute;
  background-size: 100% 100%;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/orbiterstory/pipefourthslide.svg);
  width: vwFn(20.39);
  height: vhFn(56.84);
  left: vwFn(0);
  top: vhFn(17.13);
  z-index: 2;
}

.orbiterStoryFourthSlide--secondPipe {
  position: absolute;
  background-size: 100% 100%;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/orbiterstory/pipefourthslideright.svg);
  width: vwFn(49.47);
  height: vhFn(27.67);
  left: vwFn(53.5);
  top: vhFn(46.13);
}

.orbiterStoryFourthSlide--temperature {
  position: absolute;
  background-size: 100% 100%;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/orbiterstory/orbiterTemperature.svg);
  width: vwFn(2.212);
  height: vhFn(7.773);
  left: vwFn(7.665);
  top: vhFn(8.128);
}

.orbiterStoryFourthSlide--temperatureAfter {
  position: absolute;
  background-size: 100% 100%;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/orbiterstory/orbiterTemperature.svg);
  width: vwFn(2.212);
  height: vhFn(7.773);
  left: vwFn(57.79);
  top: vhFn(33.594);
}

.orbiterStoryFourthSlide--temperatureAfterText {
  position: absolute;
  width: vwFn(21.05);
  min-height: vhFn(9.33);
  left: vwFn(60.59);
  top: vhFn(34.06);
  font-size: vhFn(4.23);
  line-height: vhFn(6.4);
  font-family: $secondaryFont;
}

.orbiterStoryFourthSlide--temperatureText {
  position: absolute;
  width: vwFn(21.05);
  min-height: vhFn(9.33);
  left: vwFn(10.59);
  top: vhFn(8.06);
  font-size: vhFn(4.23);
  line-height: vhFn(6.4);
  font-family: $secondaryFont;
}

.orbiterStoryFourthSlide--thirdArrow {
  position: absolute;
  background-size: 100% 100%;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/orbiterstory/orbiterarrow.svg);
  width: vwFn(2);
  height: vhFn(1.486);
  left: vwFn(59.314);
  top: vhFn(11.344);
  transform: rotate(0deg);
}

.orbiterStoryFourthSlide--arrow {
  position: absolute;
  background-size: 100% 100%;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/orbiterstory/orbiterarrow.svg);
  width: vwFn(2);
  height: vhFn(1.486);
  left: vwFn(63.314);
  top: vhFn(71.74);
  transform: rotate(180deg);
  z-index: 9999;
}

.orbiterStoryFourthSlide--secondArrow {
  position: absolute;
  background-size: 100% 100%;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/orbiterstory/orbiterarrow.svg);
  width: vwFn(2);
  height: vhFn(1.486);
  left: vwFn(84.314);
  top: vhFn(72.14);
  transform: rotate(180deg);
  z-index: 9999;
}
.theme-adult .RobotStoryOrbiter4 .parallax-cow-robot-adults .background-layer--hills1  {
  opacity: 0;
} 
.theme-adult .RobotStoryOrbiter4 .parallax-cow-robot-adults .background-layer--hills2  {
  opacity: 0;
} 
.orbiterStoryFourthSlide--temperatureContainerAdultText  {
  display: none;
}
.theme-adult {
  .orbiterStoryFourthSlide {
    background-color: transparent;
  } 
  .orbiterStoryFourthSlide--floor {
    display: none;
  } 
  .orbiterStoryFourthSlide--pipe {
    background-image: url(../../resources/assets/themes/lely/16x9/stories/orbiterstory/orbiterFourthSlideAdult.svg); 
    width: vwFn(16.39);
  } 
  .orbiterStoryFourthSlide--robot {
    background-image: url(../../resources/assets/themes/lely/16x9/stories/orbiterstory/orbiterStoryFourthSlideRobotAdult.svg); 
    left: vwFn(15.28);

  } 
  .orbiterStoryFourthSlide--secondPipe {
    background-image: url(../../resources/assets/themes/lely/16x9/stories/orbiterstory/orbiterStoryFourthSlidePipe.svg); 
    height: vhFn(24.67);
  } 
  .orbiterStoryFourthSlide--temperatureContainerAdultBefore {
    background-image: url(../../resources/assets/themes/lely/16x9/stories/orbiterstory/orbiterStoryFourthSlideTempContainer.svg); 
    position: absolute;
    background-size: 100% 100%;
    width: vwFn(14.0381);
    height: vhFn(15.2);
    left: vwFn(5.469);
    top: vhFn(13.693); 
    z-index: 3;
  }  
  .orbiterStoryFourthSlide--temperatureContainerAdult {
    background-image: url(../../resources/assets/themes/lely/16x9/stories/orbiterstory/orbiterStoryFourthSlideTempAfter.svg); 
    position: absolute;
    background-size: 100% 100%;
    width: vwFn(14.0381);
    height: vhFn(15.2);
    left: vwFn(50.469);
    top: vhFn(40.693); 
    z-index: 3; 
    transform: scale(1);
  }  
  .orbiterStoryFourthSlide--temperatureContainerAdultText {
    position: absolute;
    top: vhFn(5.4);
    left: vwFn(7);
    font-family: "Roboto";
    font-size: vhFn(3.75);
    display: block;
  }
  .orbiterStoryFourthSlide--arrow {
    top: vhFn(68.5); 
    background-image: url(../../resources/assets/themes/lely/16x9/stories/orbiterstory/orbiterArrowAdult.svg); 
    transform: scale(1);

  }
  .orbiterStoryFourthSlide--temperature, 
  .orbiterStoryFourthSlide--temperatureAfter {
    display: none;
  } 
  .orbiterStoryFourthSlide--temperatureText, 
  .orbiterStoryFourthSlide--temperatureAfterText {
    display: none;
  } 
  .orbiterStoryFourthSlide--textCaption {
    top: vhFn(30); 
    left: vwFn(66.5);
  }

} 