@import "../../common/styles/variables.scss";

.udderXYZInfo {
  position: absolute;
  color: #24a148;
}

.udderInfo--1 .udderXYZInfo,
.udderInfo--2 .udderXYZInfo {
  left: vwFn(-3);
}

.udderInfo--3 .udderXYZInfo {
  left: vwFn(3);
}

.udderInfo--4 .udderXYZInfo {
  left: vwFn(5);
}

.udderInfo--1 .udderXYZInfo,
.udderInfo--4 .udderXYZInfo {
  top: vhFn(-2);
}

.udderInfo--2 .udderXYZInfo,
.udderInfo--3 .udderXYZInfo {
  top: vhFn(4);
}
.udderInfo--1 .udderXYZInfo {
  left: vwFn(-6);
  top: vhFn(-10);
  font-size: vhFn(3.733);
  line-height: vhFn(5.5);
}
.udderXYZInfo--value {
  margin-left: vwFn(0.5);
}
.udderInfo--2 .udderXYZInfo {
  left: vwFn(-5);
  top: vhFn(11);
  font-size: vhFn(3.733);
  line-height: vhFn(5.5);
}
.udderInfo--3 .udderXYZInfo {
  font-size: vhFn(3.733);
  left: vwFn(2.5);
  top: vhFn(11);
  line-height: vhFn(5.5);
}
.udderInfo--4 .udderXYZInfo {
  font-size: vhFn(3.733);
  left: vwFn(5.5);
  top: vhFn(-10);
  line-height: vhFn(5.5);
}
