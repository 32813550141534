@use "../../common/styles/variables.scss" as *;

.grazewayFeature {
  position: absolute;
  background-repeat: no-repeat;
  pointer-events: fill;
  width: vhFn(20);
  height: vhFn(20);
  background-image: url(../../resources/assets/themes/lely/16x9/actors/grazeway/grazeway-feature.svg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  top: vhFn(61.7);
  left: vhFn(69);
  z-index: 9;
}

.grazewayFeature--door {
  background-image: url(../../resources/assets/themes/lely/16x9/actors/grazeway/grazeway-door.svg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  top: vhFn(65.9);
  left: vhFn(88.3);
  position: fixed;
  background-repeat: no-repeat;
  pointer-events: fill;
  width: vhFn(9.2);
  height: vhFn(8);
}

.grazewayFeature--title {
  display: none;
}

.grazewayFeature--bigger {
  width: vwFn(30);
  height: vhFn(65);
  background-size: cover;
}

.grazewayFeature--fixed {
  position: absolute;
  width: vwFn(16);
  height: vhFn(72);
  right: vwFn(55);
  top: vhFn(20);
}
