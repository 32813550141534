@use "../../common/styles/variables.scss" as *;

.cow--ruminationPopup {
  position: absolute;
  bottom: vhFn(9.6);
  left: vwFn(5.66);
  background-color: $color-LelyWhite;
  width: vwFn(67.73);
  min-height: vhFn(0);
  border-left: vwFn(0.12) solid $color-GrayDairyLight;
  border-right: vwFn(0.12) solid $color-GrayDairyLight;
  border-top: vhFn(0.27) solid $color-GrayDairyLight;
  box-sizing: border-box;
  box-shadow: 0px 2px 5px rgba(222, 219, 214, 0.4);
  border-radius: vwFn(0.5) vwFn(0.5) vwFn(0.5) vwFn(0.5);
  padding: vhFn(3.25) vwFn(2.5);
}

.cow--ruminationPopup--title {
  width: vwFn(43.1);
  font-family: $secondaryFont;
  font-weight: $font-bold;
  font-style: $font-DefaultFontStyle;
  color: $color-gray-popup;
  font-size: vhFn(6.4);
  line-height: vhFn(9.33);
  margin-bottom: vhFn(1.87);
}

.cow--ruminationPopup--description {
  width: vwFn(40.5);
  font-family: $font-DefaultFont;
  font-weight: $font-regular;
  font-style: $font-DefaultFontStyle;
  color: $color-gray-popup;
  height: vhFn(56.8);
  font-size: vhFn(3.73);
  line-height: vhFn(6);
  margin-bottom: vhFn(7.49);
}

.cow--ruminationPopup--btnClosePopup {
  position: absolute;
  top: vhFn(-2);
  left: vwFn(50);
  background-image: url(../../resources/assets/themes/lely/16x9/ui/popup/close-popup-button.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: vwFn(8.5);
  height: vhFn(11.5);
}

.cow--ruminationPopup--rumination {
  background-image: url(../../resources/assets/themes/lely/16x9/popups/rumination/cow--rumination.svg);
  position: absolute;
  height: vhFn(62.67);
  width: vwFn(52.71);
  background-size: 100%;
  left: vwFn(41.5);
  bottom: vhFn(-1);
}

//Iphone 12 Pro Max
@media only screen and (min-device-height: 428px) and (max-device-width: 896px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
  .cow--ruminationPopup {
    bottom: vhFn(13.5) !important;
  }
}

//Iphone 13 Pro Max
@media only screen and (min-device-height: 428px) and (max-device-width: 926px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
  .cow--ruminationPopup {
    bottom: vhFn(13.5) !important;
  }
}
.theme-adult .cow--ruminationPopup--rumination {
  background-image: url(../../resources/assets/themes/lely/16x9/popups/rumination/rumination--popup--image.svg);
}