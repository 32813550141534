@use "../../common/styles/variables.scss" as *;

.textCaption--mijnMelkAdultOnboarding-slide4-text1 {
  position: absolute;
  top: vhFn(15);
  left: vwFn(27.463);
  box-shadow: none;
  border: none;
  background: transparent;
  font-size: vhFn(3.73);
  font-family: "Roboto", "Ubuntu" !important;
  width: vwFn(16);
  //letter-spacing: vwFn(0.1);
}

.textCaption--mijnMelkAdultOnboarding-slide4-text2 {
  position: absolute;
  top: vhFn(35.104);
  left: vwFn(52.07);
  box-shadow: none;
  border: none;
  background: transparent;
  font-size: vhFn(3.73);
  font-family: "Roboto", "Ubuntu" !important;
}

@media only screen and (orientation: portrait) {
  .textCaption--mijnMelkAdultOnboarding-slide4-text2 {
    top: vhFn(26.104);
   }
}

.textCaption--mijnMelkAdultOnboarding-slide4-text3 {
  position: absolute;
  top: vhFn(55);
  left: vwFn(27.463);
  box-shadow: none;
  border: none;
  background: transparent;
  font-size: vhFn(3.73);
  font-family: "Roboto", "Ubuntu" !important;
}

.textCaption--mijnMelkAdultOnboarding-slide4-text4 {
  position: absolute;
  top: vhFn(71.5);
  left: vwFn(52.07);
  box-shadow: none;
  border: none;
  background: transparent;
  font-size: vhFn(3.73);
  font-family: "Roboto", "Ubuntu" !important;
}

.mijnMelkAdultOnboarding-slide4--toTheFarmButton {
  position: absolute;
  top: vhFn(42.7653);
  left: vwFn(70);
  width: vwFn(26.6);
  padding: vhFn(5) vwFn(5);
  min-height: vhFn(18.666);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/onboarding/adult/slide4/toTheFarmButton.svg);
}

.mijnMelkAdultOnboarding-slide4--toTheFarmButton > span {
  display: block;
  color: #fff;
}

.mijnMelkAdultOnboarding-slide4--toTheFarmButton--label {
  position: relative;
  top: vhFn(-2);
  left: vwFn(-3);
  font-size: vhFn(3.73);
}

.mijnMelkAdultOnboarding-slide4--toTheFarmButton--farmName {
  text-transform: uppercase;
  font-size: vhFn(5.3333);
  position: relative;
  top: vhFn(0);
  left: vwFn(-3);
  width: vwFn(25);
  padding-right: vwFn(1);
}

.mijnMelkAdultOnboarding-slide4--tapHandIcon {
  position: absolute;
  top: vhFn(28.362);
  left: vwFn(88);
  width: vwFn(8.403);
  height: vhFn(20);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/onboarding/adult/slide4/tapHandIcon.svg);
}

.mijnMelkAdultOnboarding-slide4--farmerFence {
  position: absolute;
  top: vhFn(85.333);
  width: vwFn(40.965);
  left: vwFn(69.458);
  height: vhFn(8.8);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/onboarding/adult/slide4/farmerAndFence.svg);
}

.mijnMelkAdultOnboarding-slide4--farmer {
  position: absolute;
  top: vhFn(72.33);
  width: vwFn(10);
  right: vhFn(32);
  height: vhFn(20);
  background-repeat: no-repeat;
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/actors/farmer/farmer-male.svg);
  background-size: 100% 100%;
  z-index: 3;
}

@media only screen and (orientation: portrait) {
  .mijnMelkAdultOnboarding-slide4--farmer {
    right: vhFn(21);
  }
}

.mijnMelkAdultOnboarding-slide4--farmer--male {
  background-image: url(../../resources/assets/themes/adult/16x9/actors/farmer/farmer-male.svg);
}

.mijnMelkAdultOnboarding-slide4--farmer--female {
  background-image: url(../../resources/assets/themes/adult/16x9/actors/farmer/farmer-female.svg);
}

.mijnMelkAdultOnboarding-slide4--farmHouse {
  position: absolute;
  top: vhFn(54.59);
  width: vwFn(25.25);
  left: vwFn(86.69);
  height: vhFn(39.46666);
}

.mijnMelkAdultOnboarding-slide4--farmHouse--style1 {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowhistoryForAdult/elements/farmHouseStyle1Front.svg);
}

.mijnMelkAdultOnboarding-slide4--farmHouse--style2 {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowhistoryForAdult/elements/farmHouseStyle2Front.svg);
}

.mijnMelkAdultOnboarding-slide4--farmHouse--style3 {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowhistoryForAdult/elements/farmHouseStyle3Front.svg);
}
.mijnMelkAdultOnboarding-slide4--grass {
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/onboarding/adult/slide4/slide4grass.svg);
  width: vwFn(6.773399);
  height: vhFn(6.666);
  top: vhFn(58.1333);
  left: vwFn(2.709);
  position: absolute;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.mijnMelkAdultOnboarding-slide4--fromPinToBottle {
  position: absolute;
  left: vwFn(41.5517);
  top: vhFn(20.533);
  width: vwFn(8.251);
  height: vhFn(62.93);
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/onboarding/adult/slide4/fromPinToBottle.svg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.mijnMelkOnboardingAdult--layer
  .mijnMelkOnboardingAdult--layer--slides
  .mijnMelkOnboardingAdult--slide
  .mijnMelkOnboardingAdult--slide4
  .is-faded-in
  + .mijnMelkOnboardingAdult--swipeHand {
  display: none;
}
