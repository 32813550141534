@use "../../../../common/styles/variables.scss" as *;

.OrbiterScreen {
  position: relative;
  background-image: url(../../../../resources/assets/themes/lely/16x9/screens/cowIntro/cow-intro-page.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  width: vwFn(100);
  height: vhFn(100);
}

.textCaption--OrbiterScreen {
  position: absolute;
  width: vwFn(30.78);
  max-height: vhFn(38.4);
  left: vwFn(47.98);
  top: vhFn(30.8);
  padding: vwFn(1.4) vwFn(1.4) vwFn(1.8);
  z-index: 1;
}

.OrbiterScreen--text {
  position: static;
  width: vwFn(28.78);
  max-height: vhFn(38.4);
  font-size: vwFn(1.72);
  line-height: vhFn(6.4);
  margin: 0px;
}

.OrbiterScreen--farmer {
  position: absolute;
  right: vwFn(1.97);
  top: vhFn(34.56);
  width: vwFn(18.47);
  height: vhFn(45.54);
  background-image: url(../../../../resources/assets/themes/mijnmelk/16x9/onboarding/farmerPresentation/farmer.svg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  z-index: 1;
}

.OrbiterScreen--astronaut {
  position: absolute;
  left: vwFn(15.43);
  bottom: vhFn(12.936);
  width: vhFn(60.33);
  height: vhFn(60.66);
  background-image: url(../../../../resources/assets/themes/lely/16x9/actors/milkfactory/milk-factory.svg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  z-index: 1;
}
