@use "../../common/styles/variables.scss" as *;

.mist--container {
  width: 100%;
  height: 100%;
}

.mist-overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #ffffff;
  transition: 1s opacity;
  opacity: 0;
}

.is-mist-active {
  opacity: 0.3;
}

.mist-cloud {
  position: absolute;
  background-size: contain;
  background-repeat: no-repeat;
  transition: 1s;
}

.mist-cloud--small {
  width: vwFn(20);
  height: vhFn(20);
}

.mist-cloud-1 {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/mist/background_weather_mist_01.svg);
}

.mist-cloud-2 {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/mist/background_weather_mist_02.svg);
}

.mist-cloud-3 {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/mist/background_weather_mist_03.svg);
}

.mist-cloud-4 {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/mist/background_weather_mist_04.svg);
}

.mist-cloud-5 {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/mist/background_weather_mist_05.svg);
}

.mist-cloud-6 {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/mist/background_weather_mist_06.svg);
}

.mist-cloud-7 {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/mist/background_weather_mist_07.svg);
}
