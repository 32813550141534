@use "../../common/styles/variables.scss" as *;

.vehicle {
  position: absolute;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  pointer-events: fill;
  // width: calc(var(--vh, 1vh) * 25);
  // height: calc(var(--vh, 1vh) * 29);
  // height: calc(var(--vh, 1vh) * 24.93);
  // top: calc(var(--vh, 1vh) * 8);
  // left: calc(var(--vh, 1vh) * 150);
  z-index: 9;
}

.vehicleLeft {
  // position: absolute;
  // top: calc(var(--vh, 1vh) * 61);
  // left: calc(var(--vh, 1vh) * 169);
}
.vehicle--tractor {
  background-image: url(../../resources/assets/themes/lely/16x9/actors/tractor/tractor.svg);
}
.tractorLeft {
  position: absolute;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  pointer-events: fill;
}
