@use "../../common/styles/variables.scss" as *;

.vectorFeature {
  position: absolute !important;
  pointer-events: fill;
  width: vhFn(16.5);
  height: vhFn(18);
  background-image: url(../../resources/assets/themes/lely/16x9/actors/vector/vector.svg);
  background-repeat: no-repeat;
  background-size: 100%;
  z-index: 9;
  cursor: pointer;
  //  top: vhFn(15.701);
  //  left: vhFn(43.873);
}


.vectorFeature--front {
  background-image: url(../../resources/assets/themes/lely/16x9/actors/vector/vector.svg);
}

.vectorFeature--back {
  background-image: url(../../resources/assets/themes/lely/16x9/actors/vector/vector_back.svg);
}


@keyframes vectorMovement {
  0% {
    left: vhFn(95);
    top: vhFn(34);
  }

  50% {
    left: vhFn(150);
    top: vhFn(65);
  }

  100% {
    left: vhFn(95);
    top: vhFn(34);
  }
}

.vectorFeature--title {
  display: none;
}

.vectorFeature--bigger {
  width: vwFn(30);
  height: vhFn(65);
  background-size: cover;
}

.vectorFeature--fixed {
  position: absolute;
  width: vwFn(16);
  height: vhFn(72);
  right: vwFn(55);
  top: vhFn(20);
}

.is-virtual-farm-tour-active .vectorFeature {
  transition: 0s all;
}
