@use "../../../common/styles/variables.scss" as *;

.cow-first-year--slide {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  width: vwFn(100);
  height: vhFn(100);
}

.textCaption--cow-first--year {
  position: absolute;
  left: vwFn(20.566);
  top: vhFn(41.2);
  min-width: vwFn(32.05);
  min-height: vhFn(17.6);
  padding: vhFn(2.5);
}

.textCaption--cow-first--year--text {
  font-family: $secondaryFont;
  font-weight: bold;
  line-height: vhFn(10.4);
  font-size: vwFn(5.911);
  min-width: vwFn(35.09);
  height: vhFn(10.4);
  left: vwFn(1.477);
  top: vhFn(3.2);
  margin: vwFn(1.23) 0;
  letter-spacing: vwFn(0.45);
}

.calf {
  position: absolute;
  right: vwFn(13.54);
  top: vhFn(45.66);
  width: vwFn(23.47);
  height: vhFn(32.8);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.calf--jersey-white {
  background-image: url(../../../resources/assets/themes/lely/16x9/actors/calf/calf-jersey-white.svg);
  background-size: 100% 100%;
}

.calf--jersey-brown {
  background-image: url(../../../resources/assets/themes/lely/16x9/actors/calf/calf-jersey-brown.svg);
  background-size: 100% 100%;
}

.calf--lakenvelder-black {
  background-image: url(../../../resources/assets/themes/lely/16x9/actors/calf/calf-lakenvelder-black.svg);
}

.calf--lakenvelder-brown {
  background-image: url(../../../resources/assets/themes/lely/16x9/actors/calf/calf-lakenvelder-brown.svg);
}

.calf--holstein-white {
  background-image: url(../../../resources/assets/themes/lely/16x9/actors/calf/calf-holstein-white.svg);
}

.calf--holstein-black {
  background-image: url(../../../resources/assets/themes/lely/16x9/actors/calf/calf-holstein-black.svg);
}

.calf--holstein-brown {
  background-image: url(../../../resources/assets/themes/lely/16x9/actors/calf/calf-holstein-brown.svg);
}

.calf--holstein-rainbow {
  background-image: url(../../../resources/assets/themes/lely/16x9/actors/calf/calf-holstein-rainbow.svg);
}

.calf--blaarkop-black {
  background-image: url(../../../resources/assets/themes/lely/16x9/actors/calf/calf-blaarkop-black.svg);
}

.calf--blaarkop-brown {
  background-image: url(../../../resources/assets/themes/lely/16x9/actors/calf/calf-blaarkop-brown.svg);
}

.calf--maasRijn-brown {
  background-image: url(../../../resources/assets/themes/lely/16x9/actors/calf/calf-maas-rijn-brown.svg);
}
