@import "../../common/styles/variables.scss";

.astronautAmountOfSeconds {
  position: absolute;
  background-size: 100% 100%;
  width: vwFn(101);
  height: vhFn(100);
  background-color: #000;
  border: none;
}

.astronautAmountOfSeconds--leftTextCaption {
  position: absolute;
  width: vwFn(30.7881);
  min-height: vhFn(30.66);
  top: vhFn(30.66);
  left: vwFn(8.96619);
  padding: vhFn(3.2) vwFn(1.48);
  z-index: 2;
  background-color: #121212;
  border-color: #43bf66;
}
.astronautAmountOfSeconds--middleTextCaption {
  position: absolute;
  width: vwFn(30.7881);
  min-height: vhFn(17.6);
  top: vhFn(41.896);
  left: vwFn(65.964);
  padding: vhFn(3.2) vwFn(1.48);
  z-index: 2;
  background-color: #121212;
  border-color: #43bf66;
}
.astronautAmountOfSeconds--hourTextCaption {
  position: absolute;
  min-width: vwFn(10.7881);
  height: vhFn(11.86);
  top: vhFn(15.896);
  left: vwFn(75.964);
  padding: vhFn(3.2) vwFn(1.48);
  z-index: 2;
  background-color: #121212;
  border-color: #43bf66;
  border-radius: 0;
}
.astronautAmountOfSeconds--text {
  font-family: "Ubuntu Mono";
  font-style: $font-DefaultFontStyle;
  font-size: vhFn(3.6);
  line-height: vhFn(6.4);
  color: #43bf66;
}
.astronautAmountOfSeconds--seconds {
  border-color: #43bf66;
  border-radius: 0;
  width: vwFn(19.5);
  height: vhFn(4);
  border: 1px solid #43bf66;
  padding: vhFn(0.6) vhFn(0.6);
  box-sizing: border-box;
  white-space: nowrap;
}
.astronautAmountOfSeconds--hourText {
  font-family: "Digital-7 Italic", sans-serif;
  font-style: $font-DefaultFontStyle;
  font-size: vhFn(9.6);
  line-height: vhFn(6.4);
  color: #43bf66;
}
.astronautAmountOfSeconds--arrow {
  position: absolute;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/astronautLiveDataStory/arrow.svg);
  width: vwFn(26.038);
  height: vhFn(1.86);
  top: vhFn(50.08);
  left: vwFn(39.7561);
  z-index: 2;
  transform: translate3d(0, 0, 1px);
}
.astronautAmountOfSeconds--arrowBetweenSeconds {
  position: absolute;
  background-color: #43bf66;
  width: vwFn(0.15);
  height: vhFn(18.2);
  top: vhFn(27.6);
  left: vwFn(83.7561);
  z-index: 3;
}
.testMatrixColumn {
  height: vhFn(100);
  //width: vwFn(2) !important;
  left: vwFn(1.25) !important;
  position: absolute;
  z-index: 1 !important;
  transform: translate3d(0, 0, 1px);
}
.testMatrixColumn2 {
  position: absolute;
  left: vwFn(6) !important;
  z-index: 1 !important;
  transform: translate3d(0, 0, 1px);
}
.testMatrixColumn3 {
  position: absolute;
  left: vwFn(45) !important;
  z-index: 1 !important;
  transform: translate3d(0, 0, 1px);
}
.testMatrixColumn4 {
  position: absolute;
  left: vwFn(52) !important;
  z-index: 1 !important;
  transform: translate3d(0, 0, 1px);
}
.testMatrixColumn5 {
  position: absolute;
  left: vwFn(60) !important;
  z-index: 1 !important;
  transform: translate3d(0, 0, 1px);
}
.theme-adult {
  .astronautAmountOfSeconds--arrowBetweenSeconds {
    display: none;
  } 
  .astronautAmountOfSeconds--hourTextCaption {
    display: none;
  } 
  .astronautAmountOfSeconds--seconds {
    border: none;
  }
}