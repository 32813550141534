@use "../../common/styles/variables.scss" as *;

.AnimalBlock .labelled-text-area--label {
  position: absolute;
  top: 0;
  color: #766e68;
  font-size: 12px;
  padding-top: 5px;
  padding-left: 5px;
}

.AnimalBlock .labelled-text-area {
  position: relative;
  width: 48%;
  margin-right: 2%;
}

.AnimalBlock .labelled-text-input,
.story-input--label {
  margin-bottom: 2%;
}

.AnimalBlock textarea {
  padding-top: 20px;
  padding-left: 5px;
  resize: none;
}

.labelled-text-area--charactersLeft {
  display: block;
  color: #766e68;
  position: absolute;
  right: 10px;
  bottom: 10px;
  font-size: 10px;
}

.btn-delete-animal-block {
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
}

.btn-delete-animal-block .lely-icon-trash {
  font-size: 16px;
  color: #766e68;
  position: relative;
  top: 2px;
}

.btn-delete-animal-block--label {
  text-decoration: underline;
  color: #766e68;
  font-size: 14px;
  margin: 0;
}

.AnimalBlock {
  border-bottom: 1px solid #cfcbc3;
}

.AnimalBlock--title {
  color: #121212;
  font-size: 16px;
}
