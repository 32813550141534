@import "../../common/styles/variables.scss";

.scanBar {
  width: 100%;
  height: vhFn(72);
  //margin: 20px;
  outline: 2px solid;
  outline-offset: 10px;
  position: absolute;
}

.scanBar::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 20px;
  background: #c30a14;
  box-shadow: 0 0 70px 20px #c30a14;
  clip-path: inset(0);
}

.is-animation-enabled .scanBar::before {
  animation: x 0.5s ease-in-out infinite alternate, y 1s ease-in-out infinite;
  animation-iteration-count: 1 !important;
}

.is-animation-enabled .scanBar {
  animation: scanBarOpacity 0.5s forwards;
  animation-iteration-count: 1 !important;
}

@keyframes x {
  to {
    transform: translateX(-100%);
    left: 100%;
  }
}

@keyframes y {
  33% {
    clip-path: inset(0 0 0 -100px);
  }
  50% {
    clip-path: inset(0 0 0 0);
  }
  83% {
    clip-path: inset(0 -100px 0 0);
  }
}

@keyframes scanBarOpacity {
  from {
    opacity: 1;
  }
  95% {
    opacity: 1;
  }
  96% {
    opacity: 0;
  }
  to {
    opacity: 0;
  }
}
