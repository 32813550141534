@import "../../common/styles/variables.scss";

.astronautTimeMilked {
  position: absolute;
  background-size: 100% 100%;
  width: vwFn(100);
  height: vhFn(100);
  background-image: url(../../resources/assets/themes/lely/16x9/stories/astronautLiveDataStory/astronautLiveDataTimeMilkedBackground.svg);
  border: none;
}
.astronautTimeMilked--blackBackground {
  //position: absolute;
  display: inline-flex;
  background-color: #000;
  width: vwFn(36.9458);
  height: vhFn(100);
  background-size: 100% 100%;
}
.astronautTimeMilked--blackBoxes {
  //position: absolute;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/astronautLiveDataStory/astronautLiveDataTimeMilkedMixedBackground.svg);
  display: inline-flex;
  width: vwFn(46.1822);
  height: vhFn(100);
  background-size: 100% 100%;
}
.astronautTimeMilked--textCaption {
  position: absolute;
  top: vhFn(38.528);
  left: vwFn(4.9261);
  width: vwFn(30.7881);
  min-height: vhFn(0);
  border: 1px solid;
  box-sizing: border-box;
  padding: vhFn(3.2) vwFn(1.48);
  z-index: 4;
  background-color: #121212;
  border-color: #43bf66;
  border-radius: vwFn(0.5);
  transform: translate3d(0, 0, 1px);
}
.astronautTimeMilked--text {
  position: relative;
  font-family: "Ubuntu Mono";
  font-style: $font-DefaultFontStyle;
  font-size: vhFn(3.733);
  line-height: vhFn(6.4);
  color: #43bf66;
}
.astronautTimeMilked--whiteTextCaption {
  position: absolute;
  left: vwFn(64.0394);
  top: vhFn(40.48);
  width: vwFn(30.7881);
  min-height: vhFn(0);
  // border-color: #efedea;
  background-color: #ffffff;
  //border: 1px solid;
  box-sizing: border-box;
  padding: vhFn(3.2) vwFn(1.48);
  z-index: 4;
  border-radius: vwFn(0.5);
}
.astronautTimeMilked--whiteTextCaptionText {
  font-family: $primaryFont;
  font-weight: 400;
  font-size: vhFn(3.73);
  line-height: vhFn(6.4);
  color: $color-gray-popup;
}
.astronautTimeMilked--arrowConnectingTextBoxes {
  position: absolute;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/astronautLiveDataStory/arrowConnectingText.svg);
  width: vwFn(28.028);
  height: vhFn(1.75);
  top: vhFn(49.128);
  left: vwFn(35.667);
  z-index: 2;
}
.astronautTimeMilked--arrowConnectingSlides {
  position: absolute;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/astronautLiveDataStory/arrowBetweenSlides.svg);
  width: vwFn(9.999);
  height: vhFn(1.75);
  top: vhFn(49.128);
  left: vwFn(94.82758);
  z-index: 2;
}
.testMatrixColumn14 {
  position: absolute;
  left: vwFn(30) !important;
  z-index: 1 !important;
  transform: translate3d(0, 0, 1px);
} 
.theme-adult {
  .astronautTimeMilked {
    background-image: url(../../resources/assets/themes/lely/16x9/stories/astronautstory/milkingRobotStory.svg);

  }
}
