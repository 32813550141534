@use "../../styles/variables.scss" as *;

.labelled-slider--label {
  display: block;
}

.labelled-slider--slider-container {
  display: block;
  width: 20%;
}

.MuiSlider-root {
  color: #c30a14 !important;
}

.MuiSlider-rail {
  color: #efedea !important;
  opacity: 1 !important;
}
