@import "../../common/styles/variables.scss";

// .solarPanel {
//   position: absolute;
//   left: vwFn(5.6);
//   top: vhFn(10);
//   width: vwFn(87.43);
//   min-height: vhFn(80);
// }

// .solarPanelTextInfo {
//   position: absolute;
//   width: vwFn(67.73);
//   min-height: vhFn(0);
//   border-left: vwFn(0.12) solid $color-GrayDairyLight;
//   border-right: vwFn(0.12) solid $color-GrayDairyLight;
//   border-top: vhFn(0.27) solid $color-GrayDairyLight;
//   border-bottom: vhFn(0.27) solid $color-GrayDairyLight;
//   box-sizing: border-box;
//   box-shadow: 0px 2px 5px rgba(222, 219, 214, 0.4);
//   border-radius: vwFn(0.5) vwFn(0.5) vwFn(0.5) vwFn(0.5);
//   top: vhFn(20.866);
//   left: vwFn(0);
//   padding-top: vhFn(4);
//   padding-bottom: vhFn(4);
//   padding-left: vhFn(5.25);
//   background-color: $color-LelyWhite;
// }

// .solarPanel--title {
//   font-family: $secondaryFont;
//   color: $color-LelyBlack;
//   font-weight: 700;
//   font-size: vhFn(6.4);
//   line-height: vhFn(9.333);
//   margin-top: vhFn(0);
//   // margin-left: vwFn(2.2322);
//   //margin-bottom: vhFn(2);
// }

// .solarPanel--description {
//   font-family: "Ubuntu";
//   font-style: normal;
//   font-weight: 400;
//   font-size: vhFn(3.733);
//   line-height: vhFn(6.4);
//   width: vwFn(47.78);
//   //height: vhFn(38.4);
//   margin-top: vhFn(0);
//   margin-bottom: vhFn(0) !important;
// }

// .solarPanel--btnClosePopup {
//   position: absolute;
//   top: vhFn(-2);
//   left: vwFn(45.25);
//   background-image: url(../../resources/assets/themes/lely/16x9/ui/popup/close-popup-button.svg);
//   background-repeat: no-repeat;
//   background-size: 100% 100%;
//   width: vwFn(8.5);
//   height: vhFn(11.5);
//   padding: 0;
//   margin: 0;
//   z-index: 1;
// }

.solarPanel--logo {
  position: absolute;
  background-image: url(../../resources/assets/themes/lely/16x9/actors/solarPanel/solarPanelPopupImage.svg);
  background-repeat: no-repeat;
  background-repeat: no-repeat;
  width: vhFn(71.35);
  height: vhFn(77.05);
  background-size: 100% 100%;
  left: vwFn(55.5);
}
