@use "../../common/styles/variables.scss" as *;

.labelValue {
  width: 100%;
}

.labelValue--label {
  display: inline-block;
  width: 50%;
  font-weight: bold;
}

.labelValue--value {
  display: inline-block;
  width: 50%;
}
