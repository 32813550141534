@use "../../../common/styles/variables.scss" as *;

.theme-adult .vectorStoryStartingPoint, .theme-adult .junoStoryStartingPoint {
    background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowDailyForAdult/backgrounds/eating/background-pcd-eatingAdult1.svg);
} 
.theme-adult .vectorStorySecondSlide, .theme-adult .junoStorySecondSlide {
    background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowDailyForAdult/backgrounds/eating/background-pcd-eatingAdult2.svg);
}
/**/
.theme-adult .vectorStoryThirdSlide, .theme-adult .junoStoryThirdSlide {
    background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowDailyForAdult/backgrounds/eating/background-pcd-eatingAdult1.svg);
} 
.theme-adult .vectorStoryFourthSlide, .theme-adult .junoStoryFourthSlide {
    background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowDailyForAdult/backgrounds/eating/background-pcd-eatingAdultVector3.svg);
}
