@use "../../common/styles/variables.scss" as *;

.familyMember {
  position: relative;
  background-repeat: no-repeat;
  width: calc(var(--vh, 1vh) * 20) !important;
  height: calc(var(--vh, 1vh) * 20) !important;
  background-size: contain;
}

.familyMember--holstein-black {
  background-image: url(../../resources/assets/themes/lely/16x9/actors/cow/heads/cow-list-popup-head-3.svg);
}

.familyMember--holstein-white {
  background-image: url(../../resources/assets/themes/lely/16x9/actors/cow/heads/cow-list-popup-head-1.svg);
}

.familyMember--holstein-brown {
  background-image: url(../../resources/assets/themes/lely/16x9/actors/cow/heads/cow-list-popup-head-2.svg);
}

.familyMember--holstein-rainbow {
  background-image: url(../../resources/assets/themes/lely/16x9/actors/cow/heads/cow-list-popup-head-4.svg);
}

.familyMember--jersey-brown {
  background-image: url(../../resources/assets/themes/lely/16x9/actors/cow/heads/cow-list-popup-head-5.svg);
}

.familyMember--jersey-white {
  background-image: url(../../resources/assets/themes/lely/16x9/actors/cow/heads/cow-list-popup-head-11.svg);
}

.familyMember--blaarkop-brown {
  background-image: url(../../resources/assets/themes/lely/16x9/actors/cow/heads/cow-list-popup-head-6.svg);
}

.familyMember--blaarkop-black {
  background-image: url(../../resources/assets/themes/lely/16x9/actors/cow/heads/cow-list-popup-head-7.svg);
}

.familyMember--lakenvelder-black {
  background-image: url(../../resources/assets/themes/lely/16x9/actors/cow/heads/cow-list-popup-head-8.svg);
}

.familyMember--lakenvelder-brown {
  background-image: url(../../resources/assets/themes/lely/16x9/actors/cow/heads/cow-list-popup-head-9.svg);
}

.familyMember--maasRijn-brown {
  background-image: url(../../resources/assets/themes/lely/16x9/actors/cow/heads/cow-list-popup-head-10.svg);
}

.familyMember--mother {
  position: absolute;
  left: 18%;
  top: 41%;
}

.familyMember--grandmother {
  position: absolute;
  left: 33%;
  top: 11%;
}

.familyMember--sister-0 {
  position: absolute;
  left: 7%;
  top: 11%;
}

.familyMember--sister-1 {
  position: absolute;
  left: 31%;
  top: 36%;
}

.familyMember--relative-0 {
  position: absolute;
  left: 53%;
  top: 21%;
}

.familyMember--relative-1 {
  position: absolute;
  left: 67%;
  top: 23%;
}

.familyMember--relative-2 {
  position: absolute;
  left: 78%;
  top: 12%;
}

.familyMember--relative-3 {
  position: absolute;
  left: 90%;
  top: 17%;
}

.familyTree--smaller .familyMember--mother {
  position: absolute;
  left: 24%;
  top: 41%;
}

.familyTree--smaller .familyMember--sister-1 {
  position: absolute;
  left: 37%;
  top: 36%;
}

.familyMember--name {
  position: absolute;
  right: 0;
  font-family: "Amatic SC", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: calc(var(--vh, 1vh) * 6.4);
  color: black;
  text-shadow: 0px 10px 0.02px white, 9.8px 2.1px 0.02px white,
    4.2px -9.1px 0.02px white, -8px -6px 0.02px white, -7.6px 6.5px 0.02px white,
    4.8px 8.8px 0.02px white, 9.6px -2.8px 0.02px white,
    -0.7px -10px 0.02px white, -9.9px -1.5px 0.02px white,
    -3.5px 9.4px 0.02px white, 8.4px 5.4px 0.02px white, 7.1px -7px 0.02px white,
    -5.4px -8.4px 0.02px white, -9.4px 3.5px 0.02px white,
    1.4px 9.9px 0.02px white, 10px 0.8px 0.02px white, 2.9px -9.6px 0.02px white,
    -8.7px -4.8px 0.02px white, -6.6px 7.5px 0.02px white,
    5.9px 8px 0.02px white, 9.1px -4.1px 0.02px white,
    -2.1px -9.8px 0.02px white, -10px -0.1px 0.02px white,
    -2.2px 9.8px 0.02px white, 9.1px 4.2px 0.02px white, 6.1px -8px 0.02px white,
    -6.5px -7.6px 0.02px white, -8.8px 4.7px 0.02px white,
    2.7px 9.6px 0.02px white, 10px -0.6px 0.02px white,
    1.5px -9.9px 0.02px white, -9.3px -3.6px 0.02px white,
    -5.5px 8.4px 0.02px white, 7px 7.2px 0.02px white, 8.5px -5.3px 0.02px white,
    -3.4px -9.4px 0.02px white, -9.9px 1.3px 0.02px white,
    -0.8px 10px 0.02px white, 9.6px 2.9px 0.02px white,
    4.9px -8.7px 0.02px white, -7.5px -6.7px 0.02px white,
    -8.1px 5.9px 0.02px white, 4px 9.2px 0.02px white, 9.8px -2px 0.02px white,
    0.2px -10px 0.02px white, -9.7px -2.3px 0.02px white,
    -4.3px 9px 0.02px white, 7.9px 6.1px 0.02px white;
}

.familyMember--sister-0 .familyMember--name {
  transform: rotate(25deg);
  bottom: -32%;
  left: 0;
}

.familyMember--sister-1 .familyMember--name {
  transform: rotate(-45deg);
  bottom: 0;
  right: 0;
}

.familyMember--mother .familyMember--name {
  transform: rotate(25deg);
  bottom: -32%;
  left: 0;
}

.familyMember--grandmother .familyMember--name {
  transform: rotate(25deg);
  bottom: -32%;
  left: 0;
}

.familyMember--relative-0 .familyMember--name {
  transform: rotate(25deg);
  top: 95%;
  left: -0%;
}

.familyMember--relative-1 .familyMember--name {
  transform: rotate(25deg);
  top: 100%;
  left: 0%;
}

.familyMember--relative-2 .familyMember--name {
  transform: rotate(-25deg);
  top: 95%;
  left: 25%;
}

.familyMember--relative-3 .familyMember--name {
  transform: rotate(-25deg);
  top: 95%;
  left: 25%;
}
