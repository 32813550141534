/* PARALLAX EFFECT CODE GOES BELOW */
$parallax-perspective: 1000px;

@function parallax-scale-factor($z-distance) {
  @return ($parallax-perspective - $z-distance) / $parallax-perspective;
}

.slidesContainer-main {
}

.parallax {
    perspective: 0;
    height: 150vh;
    overflow-x: visible !important;
    overflow-y: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-x: scroll;
    background-color: #C2EAF2;
}

.background-layer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.background-layer--mountains {
    transform: translateZ(0);
} 
.background-layer--mountainsAdult {
    transform: translateZ(0);
}

.background-layer--hills1 {
    transform: translateZ(0);
} 
.background-layer--hillsAdult {
    transform: translateZ(0);
}

.background-layer--hills2 {
    transform: translateZ(0);
}

.background-layer--forrest1 {
    transform: translateZ(0);
}
.background-layer--forrestAdult {
    transform: translateZ(0);
}
.background-layer--forrest2 {
    transform: translateZ(0);
}

.background-layer--grass {
    transform: translateZ(0);
}

.parallax__cover{
    background: #2D112B;
    display: block;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    height: 2000px;
    z-index: 2;
  }