@use "../../common/styles//variables.scss" as *;

// .discoveryPopup {
//   position: absolute;
//   top: vhFn(15);
//   left: vwFn(10.5);
//   background-color: $color-LelyWhite;
//   width: vwFn(67.73);
//   min-height: vhFn(35.27);
//   border-left: vwFn(0.12) solid $color-GrayDairyLight;
//   border-right: vwFn(0.12) solid $color-GrayDairyLight;
//   border-top: vhFn(0.27) solid $color-GrayDairyLight;
//   box-sizing: border-box;
//   box-shadow: 0px 2px 5px rgba(222, 219, 214, 0.4);
//   border-radius: vwFn(0.5) vwFn(0.5) vwFn(0.5) vwFn(0.5);
//   padding: vhFn(4) vwFn(2.46);
// }

// .discoveryPopup--title {
//   width: vwFn(43.1);
//   font-family: $secondaryFont;
//   font-weight: $font-bold;
//   font-style: $font-DefaultFontStyle;
//   color: $color-gray-popup;
//   font-size: vhFn(6.4);
//   line-height: vhFn(9.33);
//   margin-bottom: vhFn(1.87);
// }

// .discoveryPopup--description {
//   width: vwFn(43.1);
//   font-family: $primaryFont;
//   font-weight: $font-regular;
//   font-style: $font-DefaultFontStyle;
//   color: $color-gray-popup;
//   min-height: vhFn(35);
//   font-size: vhFn(3.73);
//   line-height: vhFn(6.4);
//   margin-bottom: vhFn(4);
// }

.discoveryPopup--btnCallToAction {
  background-color: $color-LelyRed;
  background-repeat: no-repeat;
  border-radius: vwFn(0.5) vwFn(0.5) vwFn(0.5) vwFn(0.5);
  border-left: vwFn(0.12) solid $color-GrayDairyLight;
  border-right: vwFn(0.12) solid $color-GrayDairyLight;
  border-top: vhFn(0.27) solid $color-GrayDairyLight;
  border-bottom: vhFn(0.27) solid $color-GrayDairyLight;
  background-size: 100% 100%;
  padding: vhFn(2) vwFn(2); 
  min-width: vwFn(0.18);
  height: vhFn(10.66);
  font-size: vwFn(1.5);
  color: #ffffff;
  //position: absolute;
  bottom: vhFn(2);
  line-height: vhFn(4.266);
}

// .discoveryPopup--btnClosePopup {
//   position: absolute;
//   top: vhFn(-2);
//   left: vwFn(42.5);
//   background-image: url(../../resources/assets/themes/lely/16x9/ui/popup/close-popup-button.svg);
//   background-repeat: no-repeat;
//   background-size: 100% 100%;
//   width: vwFn(8.5);
//   height: vhFn(11.5);
// }

.discoveryPopup--discovery {
  background-image: url(../../resources/assets/themes/lely/16x9/popups/discovery/discovery-popup-image.svg);
  position: absolute;
  width: vhFn(73.35);
  height: vhFn(73.35);
  background-size: 100% 100%;
  left: vwFn(53);
  //top: vhFn(-5);
}
.discoveryPopup--collector {
  background-image: url(../../resources/assets/themes/lely/16x9/popups/discoverySW/discoverySW.svg);
  position: absolute;
  width: vhFn(73.35);
  height: vhFn(73.35);
  background-size: 100% 100%;
  left: vwFn(53);
  //top: vhFn(-5);
} 
.theme-adult .discoveryPopup--collector {
  background-image: url(../../resources/assets/themes/lely/16x9/popups/discoverySW/discovery90--popup--image.svg);

}
