@use "../../common/styles/variables.scss" as *;

.orbiterStorySeventhSlide {
  position: absolute;
  background-size: 100% 100%;
  width: vwFn(80);
  height: vhFn(100);
  background-color: #f7f3f2;
  border: none;
}

.orbiterStorySeventhSlide--textCaption {
  position: absolute;
  width: vwFn(55.18);
  min-height: vhFn(20.4);
  top: vhFn(5.442);
  left: vwFn(40.513);
  padding: vhFn(1) vwFn(1);
  z-index: 10;
  font-weight: 400;
}

.orbiterStorySeventhSlide--milkBottles {
  position: absolute;
  background-size: 100% 100%;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/orbiterstory/milkBottles.svg);
  width: vwFn(18.897);
  height: vhFn(13.333);
  left: vwFn(60.956);
  top: vhFn(39.806);
  z-index: 3;
}

.orbiterStorySeventhSlide--table {
  position: absolute;
  background-size: 100% 100%;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/orbiterstory/orbiterTableSixthSlide.svg);
  width: vwFn(80.344);
  height: vhFn(60.52);
  left: vwFn(-0.5);
  top: vhFn(19.168);
  z-index: 4;
}

.orbiterStorySeventhSlide--tree {
  position: absolute;
  background-size: 100% 100%;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/orbiterstory/orbitersixthslide.svg);
  width: vwFn(100);
  height: vhFn(124.61);
  left: vwFn(0);
  top: vhFn(-18.37);
  z-index: 5;
}

.orbiterStorySeventhSlide--floor {
  position: absolute;
  background-size: 100% 100%;
  left: vwFn(0);
  top: vhFn(78.5);
  width: vwFn(80);
  height: vhFn(21.5);
  background-color: #cfcbc3;
}

.orbiterStorySeventhSlide--text {
  font-family: $primaryFont;
  font-style: $font-DefaultFontStyle;
  font-size: vhFn(3.73);
  line-height: vhFn(5.6);
  color: $color-gray-popup;
  margin: vhFn(1.87) 0; 
  letter-spacing: vwFn(0.03);
}

.orbiterStorySeventhSlide--pipe {
  position: absolute;
  background-size: 100% 100%;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/orbiterstory/orbiterSixthSlidePipe.svg);
  width: vwFn(20.252);
  height: vhFn(15.597);
  left: vwFn(-1);
  top: vhFn(58.12);
  z-index: 3;
}

.orbiterStorySeventhSlide--secondPipe {
  position: absolute;
  background-size: 100% 100%;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/orbiterstory/pipefourthslideright.svg);
  width: vwFn(49.47);
  height: vhFn(27.67);
  left: vwFn(53.5);
  top: vhFn(46.13);
}

.orbiterStorySeventhSlide--arrow {
  position: absolute;
  background-size: 100% 100%;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/orbiterstory/orbiterarrow.svg);
  width: vwFn(2);
  height: vhFn(1.486);
  left: vwFn(5.314);
  top: vhFn(71.74);
  transform: rotate(180deg);
  z-index: 9999;
}
.theme-adult .RobotStoryOrbiter6 .parallax-cow-robot-adults .background-layer--hills1  {
  opacity: 0;
} 
.theme-adult .RobotStoryOrbiter6 .parallax-cow-robot-adults .background-layer--hills2  {
  opacity: 0;
} 
.theme-adult {
  .orbiterStorySeventhSlide {
    background-color: white;
    height: vhFn(80);
  } 
  .orbiterStorySeventhSlide--floor {
    display: none;
  } 
  .orbiterStorySeventhSlide--table {
    background-image: url(../../resources/assets/themes/lely/16x9/stories/orbiterstory/orbiterSixthSlideTableAdult.svg);
  }  
  .orbiterStorySeventhSlide--arrow {
    top: vhFn(68.5); 
    background-image: url(../../resources/assets/themes/lely/16x9/stories/orbiterstory/orbiterArrowAdult.svg); 
    transform: scale(1);

  }
  .orbiterStorySeventhSlide--pipe { 
    background-image: url(../../resources/assets/themes/lely/16x9/stories/orbiterstory/orbiterStoryFifthSlideMiddlePipeAdult.svg);  
    width: vwFn(20.252);
  height: vhFn(12.597);
  left: vwFn(-1);
  top: vhFn(58.12);
  }
}  
.theme-adult .orbiterStorySeventhSlide--tree {
  position: absolute;
  background-size: 100% 100%;
  width: vwFn(60);
  left: vwFn(60);
  height: vhFn(100);
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowDailyForAdult/backgrounds/eating/full_tree.png);
  display: block;
  z-index: 4; 
  top: 0; 
  
}