@use "../../common/styles/variables.scss" as *;

.vectorStoryStartingPoint {
  position: absolute;
  background-size: 100% 100%;
  width: vwFn(100.5);
  height: vhFn(100);
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/eating/eating2.svg);
}

.vectorStoryStartingPoint--textCaption {
  position: absolute;
  min-width: vwFn(34.6);
  min-height: vhFn(24.26);
  top: vhFn(29.072);
  left: vwFn(22.339);
  padding: vhFn(3.2) vwFn(1.48);
}

.vectorStoryStartingPoint--robot {
  position: absolute;
  background-size: 100% 100%;
  background-image: url(../../resources/assets/themes/lely/16x9/actors/vector/vector_front.png);
  width: vwFn(35.61);
  height: vhFn(60.49);
  left: vwFn(50.68);
  top: vhFn(23.2);
}

@media only screen and (orientation: portrait) {
  .vectorStoryStartingPoint--robot {
    left: vwFn(57.68);
  }
}

.vectorStoryStartingPoint--title {
  font-family: $secondaryFont;
  font-style: $font-DefaultFontStyle;
  font-weight: $font-light;
  font-size: vhFn(4.27);
  line-height: vhFn(4.8);
  color: $color-gray-popup;
}

.vectorStoryStartingPoint--name {
  font-family: $secondaryFont;
  font-style: $font-DefaultFontStyle;
  font-weight: $font-bold;
  font-size: vhFn(12.8);
  line-height: vhFn(10.4);
  color: $color-gray-popup;
  margin: vhFn(1.87) 0;
}

.vectorStoryStartingPoint--farmName {
  font-family: $secondaryFont;
  font-style: $font-DefaultFontStyle;
  font-weight: $font-light;
  font-size: vhFn(4.27);
  line-height: vhFn(4.8);
  color: $color-gray-popup;
} 
.theme-adult .vectorStoryStartingPoint--robot {
  background-image: url(../../resources/assets/themes/adult/16x9/stories/vectorstory/vectorRobotAdultVersion1.svg); 
  width: vwFn(32.88);
  height: vhFn(57.3333);
}
.theme-adult .vectorStoryStartingPoint--title {
  font-family: $primaryFont;
} 