@use "../../../common/styles/variables.scss" as *;

/* Background trees */
.background-pcd-trees1 {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/background-trees/trees1.svg);
}

.theme-adult .virtualFarm--actors-container .cow.virtualFarm--interactable {
  background-image: none !important;
}

// .background-pcd-trees33 {
//   background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/background-trees/trees3.svg);
// }

.background-pcd-trees55 {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/background-trees/trees5.svg);
}

.background-pcd-trees1,
.background-pcd-trees2,
.background-pcd-trees3 {
  background-position-y: 66%;
}

/**/

/* Background clouds */
.background-pcd-clouds1 {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/clouds/clouds1.svg);
}

.background-pcd-clouds2 {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/clouds/clouds2.svg);
}

.background-pcd-clouds3 {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/clouds/clouds3.svg);
}

/**/

/* Background eating, resting, milking and rumination */
.background-layer--rumination .background-layer--slide {
  background-size: cover;
  overflow: visible;
}

// .CowDailyEating1Slide,
// .CowDailyEating3Slide,
// .CowDailyResting1Slide,
// .CowDailyResting3Slide,
// .CowDailyMilking2Slide {
//   opacity: 0;
// }

.CowDailyOutroSlide .background-layer--trees {
  opacity: 1;
}

.background-pcd-eating1 {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/eating-improved/eating_left.svg);
}

.background-pcd-eating2 {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/eating/eating2.svg);
}

.background-pcd-eating3 {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/eating-improved/eating_last.svg);
  background-size: cover;
}

.background-pcd-resting1 {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/resting/resting_left.svg);
}

.background-pcd-resting2 {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/resting/resting2.svg);
}

.background-pcd-resting3 {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/resting/resting_last.svg);
}

.background-pcd-milking1 {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/milking/milking_left.svg);
  background-size: cover;
}

.background-pcd-milking2 {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/milking/milking_last.svg);
}

.background-pcd-rumination {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/rumination/rumination_1.svg);
}

/**/

/* Background forefront tree */
.background-pcd-forefronttree {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/forefront-tree/forefronttree2.svg);
  background-position: 0% 0%;
}

/**/

/* Background grass */
.background-pcd-grass3-history {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/eating-improved/grass_without_tree.svg);
  background-size: 100%;
}

.background-pcd-grass5 {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/eating-improved/grass_without_tree.svg);
  background-size: auto;
}

.background-pcd-grass6 {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/eating-improved/grass_new.svg);
  background-size: 100%;
}

.background-pcd-firstSlideCalm {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistory/background-layers/firstYear/barnSecondSlide.svg);
  background-size: auto;
}

.background-pcd-secondSlideCalm {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistory/background-layers/firstYear/secondSlideCalmVersion.svg);
  background-size: auto;
}

/**/
.background-pcd-firstCalfSlide {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistory/background-layers/firstYear/firstCalfSlide.svg);
  background-size: auto;
}

.background-pcd-firstCalfSecondSlide {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistory/background-layers/firstYear/firstCalfSecondSlide.svg);
  background-size: auto;
}

.background-pcd-firstCalfThirdSlide {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistory/background-layers/firstYear/firstCalfThirdSlide.svg);
  background-size: auto;
}

.background-pcd-birthday {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistory/background-layers/firstYear/birthdaySlide.svg);
  background-size: auto;
}

/* Sun and birds */
.background-pcd-sky1 {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/sky/sun.svg);
}

.background-pcd-sky2 {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/sky/birds.svg);
}

/**/

.parallax-cow-history {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  width: vwFn(100);
  /* Fallback for browsers that do not support Custom Properties */
  height: vhFn(100);
}

.is-cow-history-currently-shown .parallax-cow-history {
  opacity: 1;
}

.parallax-cow-history.is-dusk {
  background-color: rgb(7, 6, 51);
}

.parallax-cow-history .background-layer--stars {
  background-image: linear-gradient(10deg, #000746, #00104c);
}

.background-pcd-stars {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/stars/starry_sky.svg);
}

.parallax-cow-history .background-layer--sky .background-layer--slide {
  background-size: 25% 25%;
  background-position: top;
}

.parallax-cow-history.is-dusk .background-layer {
  filter: brightness(0.5) sepia(1) hue-rotate(180deg) saturate(5);
}

.theme-adult .familyTree--smaller .familyTree--basePart {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistoryForAdult/elements/familyTreeBaseBranchesForAdult.svg);
}

.theme-adult .familyTree--bigger .familyTree--basePart {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistoryForAdult/elements/familyTreeAllBranchesForAdult.svg);
}

.theme-adult .cow-intro--name,
.theme-adult .cow-full--name,
.theme-adult .familyMember--name {
  // color: white;
  // text-shadow: 0px 10px 0.02px black, 9.8px 2.1px 0.02px black,
  //   4.2px -9.1px 0.02px black, -8px -6px 0.02px black, -7.6px 6.5px 0.02px black,
  //   4.8px 8.8px 0.02px black, 9.6px -2.8px 0.02px black,
  //   -0.7px -10px 0.02px black, -9.9px -1.5px 0.02px black,
  //   -3.5px 9.4px 0.02px black, 8.4px 5.4px 0.02px black, 7.1px -7px 0.02px black,
  //   -5.4px -8.4px 0.02px black, -9.4px 3.5px 0.02px black,
  //   1.4px 9.9px 0.02px black, 10px 0.8px 0.02px black, 2.9px -9.6px 0.02px black,
  //   -8.7px -4.8px 0.02px black, -6.6px 7.5px 0.02px black,
  //   5.9px 8px 0.02px black, 9.1px -4.1px 0.02px black,
  //   -2.1px -9.8px 0.02px black, -10px -0.1px 0.02px black,
  //   -2.2px 9.8px 0.02px black, 9.1px 4.2px 0.02px black, 6.1px -8px 0.02px black,
  //   -6.5px -7.6px 0.02px black, -8.8px 4.7px 0.02px black,
  //   2.7px 9.6px 0.02px black, 10px -0.6px 0.02px black,
  //   1.5px -9.9px 0.02px black, -9.3px -3.6px 0.02px black,
  //   -5.5px 8.4px 0.02px black, 7px 7.2px 0.02px black, 8.5px -5.3px 0.02px black,
  //   -3.4px -9.4px 0.02px black, -9.9px 1.3px 0.02px black,
  //   -0.8px 10px 0.02px black, 9.6px 2.9px 0.02px black,
  //   4.9px -8.7px 0.02px black, -7.5px -6.7px 0.02px black,
  //   -8.1px 5.9px 0.02px black, 4px 9.2px 0.02px black, 9.8px -2px 0.02px black,
  //   0.2px -10px 0.02px black, -9.7px -2.3px 0.02px black,
  //   -4.3px 9px 0.02px black, 7.9px 6.1px 0.02px black;
}

.theme-adult .cow--firstBirthdaySlide--box {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistoryForAdult/elements/presentBox.svg);
}

.theme-adult .cow-firstBirthdaySlide--calendar,
.theme-adult .cow-cowBirthday--calendar,
.theme-adult .cow-CowGaveBirthFirstSlide--calendar {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistoryForAdult/elements/calendar.svg);
}

.theme-adult .cow--firstBirthdaySlide--bigPartyFlag,
.theme-adult .cow--birthdayCakeSlide--bigPartyFlag {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistoryForAdult/elements/bigPartyFlag.svg);
}

.theme-adult .cow--firstBirthdaySlide--smallPartyFlag {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistoryForAdult/elements/smallPartyFlag.svg);
}

.theme-adult .birthdayPartyHat {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistoryForAdult/elements/birthdayPartyHat.svg);
  width: vwFn(7.0197);
  height: vhFn(22.666);
}

.theme-adult .birthdayPartyFlute {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistoryForAdult/elements/birthdayPartyFlute.svg);
}

.theme-adult .birthdayCake--cake {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistoryForAdult/elements/birthdayPartyCake.svg);
  top: vhFn(21.9573);
  width: vwFn(19.4581);
  height: vhFn(61.333);
}

.theme-adult .cow--birthdayCakeSlide--bigBox {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistoryForAdult/elements/birthdayPartyBigBox.svg);
  width: vwFn(17.9802);
  height: vhFn(33.066);
  left: vwFn(61.96422);
  top: vhFn(53.9573);
}

.theme-adult .cow--birthdayCakeSlide--box {
  display: none;
}

.theme-adult .cow-birthdayCakeSlide--temperature,
.theme-adult .cow-cowBirthdayPage--temperature,
.theme-adult .cow-CowGaveBirthSecondSlide--temperature {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistoryForAdult/elements/termometer.svg);
}

.theme-adult .cow--fixed {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistoryForAdult/elements/cow.svg);
}

.theme-adult .cow-intro-leftTree {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistoryForAdult/background-layers/trees/background_tree_01.svg);
  width: vwFn(23.0295);
  height: vhFn(37.3333);
}

.theme-adult .cow-daily-intro--house--style1 {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistoryForAdult/elements/farmHouseStyle1Front.svg);
  left: vwFn(49.5);
}

.theme-adult .lastSlideRobotStory--house--style1 {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistoryForAdult/elements/storyEndHouseStyle1.svg);
}

.theme-adult .cow-daily-intro--house--style2 {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistoryForAdult/elements/farmHouseStyle2Front.svg);
  left: vwFn(49.5);
}

.theme-adult .lastSlideRobotStory--house--style2 {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistoryForAdult/elements/storyEndHouseStyle2.svg);
}

.theme-adult .cow-daily-intro--house--style3 {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistoryForAdult/elements/farmHouseStyle3Front.svg);
  left: vwFn(49.5);
}

.theme-adult .lastSlideRobotStory--house--style3 {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistoryForAdult/elements/storyEndHouseStyle3.svg);
}

.theme-adult .vectorStoryLastSlide--sign {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistoryForAdult/elements/vectorLastSlideSign.svg);
  min-height: vhFn(0);
  min-width: vwFn(0);
}
// .theme-adult .vectorStoryLastSlide--text {
//   padding: vhFn(3);
// }
.theme-adult .lastSlideRobotStory--moreInfo {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistoryForAdult/elements/moreInfoPlate.svg);
  height: vhFn(7.533);
  padding: vhFn(0.85) vwFn(1.25);
  top: vhFn(35);
}

.theme-adult .lastSlideRobotStory--moreInfo--text {
  color: black;
}

.theme-adult .lastSlideRobotStory--poleSharing--share {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistoryForAdult/elements/sharingShare.svg);
}

.theme-adult .lastSlideRobotStory--poleSharing--share--text {
  color: black;
}

.theme-adult .lastSlideRobotStory--poleSharing--facebook {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistoryForAdult/elements/sharingFacebook.svg);
}

.theme-adult .lastSlideRobotStory--poleSharing--linkedin {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistoryForAdult/elements/sharingLinkedIn.svg);
}

.theme-adult .lastSlideRobotStory--poleSharing--twitter {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistoryForAdult/elements/sharingTwitter.svg);
}

.theme-adult .familyMember {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistoryForAdult/elements/familyMemberAvatar.svg);
}

.theme-adult .cow-calm--robot--slide .calf,
.theme-adult .CowGaveBirthSecondSlide .calfLeftSide {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistoryForAdult/elements/calf.svg);
}

.theme-adult .cow-calm--robot--secondSlide .cow-calm--robot--milkRobot {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistoryForAdult/elements/calmRobot.svg);
}

.theme-adult .cow-calm--robot--secondSlide .cowCalmRobot--button {
  left: 0;
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistoryForAdult/elements/calmRobotSideButton.svg);
}

.parallax-cow-history {
  background-color: white;
}

.background-forrestAdult1 {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistoryForAdult/background-layers/forestLeafage/background_forrest_leafage_01.svg);
}

.background-forrestAdult2 {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistoryForAdult/background-layers/forestLeafage/background_forrest_leafage_02.svg);
}

.background-forrestAdult3 {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistoryForAdult/background-layers/forestLeafage/background_forrest_leafage_03.svg);
}

.background-forrestAdult4 {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistoryForAdult/background-layers/forestLeafage/background_forrest_leafage_04.svg);
}

.background-forrestAdult5 {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistoryForAdult/background-layers/forestLeafage/background_forrest_leafage_05.svg);
}

.theme-adult .background-layer--slide {
  width: vwFn(100.25); 
  background-size: 100% 100%;
}
// .theme-adult .cow-calm--robot--tree {
//   display: none;
// }
.theme-adult .cow--firstBirthdaySlide--arrow {
  display: none;
}

.theme-adult .mijnMelkLastSlide--sign {
  position: absolute;
  line-height: vhFn(6.4);
  font-family: $secondaryFont;
  font-weight: 700;
  text-align: center;
  letter-spacing: vwFn(0.1);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  //width: vwFn(27.12);
  min-height: vhFn(12.333);
  left: vwFn(39);
  top: vhFn(60.018);
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistoryForAdult/elements/toMijnMelkWebSiteButton.svg);
  z-index: 7;
  display: block; 
  //margin: auto;
}

@media only screen and (orientation: portrait) {
  .theme-adult .mijnMelkLastSlide--sign {
    left: vwFn(36);
  }
}

.theme-adult .mijnMelkLastSlide--sign--text {
  width: 100%;
  height: 100%;
  white-space: nowrap;
  font-size: vhFn(3.733);
  line-height: vhFn(6.4);
  //font-family: $primaryFont;
  font-weight: 400;
  text-align: center;
  display:inline-flex;
  //padding-top: vhFn(4);
  color: #ff684d;
  //font-family: $primaryFont;
  font-weight: normal;
  font-size: vhFn(3.73333); 
  margin: vhFn(2.5) vwFn(3);
  top: vhFn(0.9);
  left: vwFn(-0.8);
  position: relative;
  padding: vhFn(0.7) vwFn(0.5);
}

.theme-adult .lastSlideRobotStory--poleSharing {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistoryForAdult/elements/finalSlideSharingPole.svg);
}

.theme-adult .lastSlideRobotStory--pole {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistoryForAdult/elements/finalSlideFirstPole.svg);
  width: vwFn(3.25);
  left: vwFn(53.5);
}

.theme-adult .cow--firstBirthdaySlide--text {
  //font-family: "Chelsea Market" !important;
  font-style: normal;
  font-weight: 400;
  font-size: vhFn(4.8);
  text-transform: none;
}

.theme-adult .textCaption--cow--firstBirthdaySlide {
  min-height: vhFn(18);
  top: vhFn(38);
} 
.theme-adult .cow--firstBirthdaySlide {
  background-color: white; 
  height: vhFn(80);
}
.theme-adult .cow--birthdayCakeSlide--arrow {
  display: none;
}
.theme-adult .cow--birthdayCakeSlide {
  background-color: white; 
  //height: vhFn(80);
  //background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistoryForAdult/background-layers/trees/tree_on_the_right.png);
  background-image: none; 
  height: vhFn(80);
}
.theme-adult .cow-intro-smallCow {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistoryForAdult/background-layers/elements/smallCowAndBush.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  top: vhFn(70.666);
  left: vwFn(33.1366);
  width: vwFn(16.8719);
  height: vhFn(10.4);
} 
.theme-adult .cow--birthdayCakeSlide--treeAdultVersion { 
  position: absolute;
  background-size: 100% 100%;
  width: vwFn(60);
  left: vwFn(68);
  height: vhFn(100);
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistoryForAdult/background-layers/trees/full_tree.png);
  display: block;
  z-index: 1;

} 
.theme-adult .cow--cowBirthFirstPage--treeAdultVersion {
  position: absolute;
  background-size: 100% 100%;
  width: vwFn(60);
  left: vwFn(62);
  height: vhFn(100);
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistoryForAdult/background-layers/trees/full_tree.png);
  display: block;
  z-index: 1;

}
.theme-adult .CowParentsPage-leftTree {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistoryForAdult/background-layers/elements/treeAndBushSecondSlide.svg);
  width: vwFn(17.610837);
  height: vhFn(38.9333);
  left: vhFn(7.84729);
}
.theme-adult .cow-cowBirthdayPage--temperature {
  left: vwFn(46.923);
}
.theme-adult .cow-cowBirthdayPage--temperature--degrees {
  left: vwFn(50.923);
}
.theme-adult .textCaption--cowBirthdayPage {
  left: vwFn(55);
  top: vhFn(38.5);
}
.theme-adult .textCaption--cow-calm--robot {
  left: vwFn(30.55);
}
.theme-adult .cow-firstBirthdaySlide--calendar {
  top: vhFn(25);
}
.theme-adult .cowOutroPage--bigTree {
  background-image: none;
  width: vwFn(100);
}
.theme-adult .lastSlideRobotStory--trees {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistoryForAdult/background-layers/elements/treeAndBushSecondSlide.svg);
  width: vwFn(17.610837);
  height: vhFn(38.9333);
  left: vhFn(15.84729);
}
.theme-adult .lastSlideRobotStory--moreInfo--text,
.theme-adult .lastSlideRobotStory--poleSharing--share--text {
  font-family: "Roboto" !important;
}

/* Background panels for cow history adult version  */ 
// sibling screen
.theme-adult .background-pcd-siblingSlide {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistoryForAdult/backgrounds/background--siblingSlide.svg);
} 
//screen after the sibling slide with transition tree
.background-pcd-firstYearAdult {
  //background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistoryForAdult/background-layers/trees/tree_on_the_right.png);
}  
.theme-adult .cow-cowBirthdayPage--leftTreeAdultVersion {
  position: absolute;
  background-size: 100% 100%;
  width: vwFn(60);
  left: vwFn(-32);
  height: vhFn(100);
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistoryForAdult/background-layers/trees/full_tree.png);
  display: block;
  z-index: 1;
}
//first screen in the barn with transition tree in the right part of the screen 
.background-pcd-firstYearSecondSlideAdult {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistoryForAdult/backgrounds/background-pcd-firstYearSecondSlide.svg);
  background-size: 100% 100%;
} 
// screen in the barn with calves without transition tree //
.background-pcd-firstSlideCalmAdult {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistoryForAdult/background-layers/firstYear/barnSecondSlideAdult.svg);
  background-size: 100% 100%;
} 
// ending panel of the calm story with transition tree // 
.background-pcd-secondSlideCalmAdult {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistoryForAdult/background-layers/firstYear/transitionSlideCalm.svg);
  background-size: auto;
} 
// first screen for the calf with transition tree //
.background-pcd-firstCalfSlideAdult {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistoryForAdult/background-layers/firstYear/firstCalfAdultFirstSlide.svg);
  background-size: auto;
}
.background-pcd-firstCalfSecondSlideAdult {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistoryForAdult/background-layers/firstYear/firstCalfSecondSlideAdult.svg);
}
.background-pcd-firstCalfThirdSlideAdult {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistoryForAdult/background-layers/firstYear/calfing_pen_transition_back.svg);
} 
.theme-adult .cow-cowBirthdayPage--tree {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistoryForAdult/background-layers/trees/full_tree.png);
  width: vwFn(60);
  left: vwFn(75)
} 
.theme-adult .RobotStoryMijnMelk1 .background-layer--slide, 
.theme-adult .RobotStoryMijnMelk2 .background-layer--slide,
.theme-adult .RobotStoryMijnMelk3 .background-layer--slide, 
.theme-adult .RobotStoryMijnMelk4 .background-layer--slide{
  background-size: 100%;
} 
.theme-adult .lastSlideMijnMelk--redirectionLely {
  display:none;
} 
.theme-adult .orbiterStoryLastSlide .lastSlideRobotStory--trees{
  display: none;
}