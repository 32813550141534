@use "../../common/styles/variables.scss" as *;

.pet.pet--cat {
  position: absolute;
  background-repeat: no-repeat;
  pointer-events: fill;
  width: vwFn(2.7241);
  height: vhFn(5.82666);
  pointer-events: fill;
  // background-size: 100% 100%;
}

@keyframes catDisappearWithPoof {
  from {
    background-position: left;
  }
  to {
    background-position: right;
  }
}

@keyframes catAppearWithPoof {
  from {
    background-position: left;
  }
  to {
    background-position: right;
  }
}

.pet--cat {
  opacity: 1;
  //  background-image: url(../../resources/assets/themes/lely/16x9/actors/pet/pet--cat.svg)
  background-size: 1100% 100%;
}

.pet--no {
  opacity: 0;
}

.pet--title {
  opacity: 0;
}

.is-disappearing {
  background-image: url(../../resources/assets/themes/lely/16x9/actors/cat/cat_disappearing.svg);
  animation: catDisappearWithPoof 1s steps(10) 1;
  animation-fill-mode: forwards;
}

.is-appearing {
  background-image: url(../../resources/assets/themes/lely/16x9/actors/cat/cat_appearing.svg);
  animation: catAppearWithPoof 1s steps(10) 1;
  animation-fill-mode: forwards;
}
