@use "../../common/styles/variables.scss" as *;

.astronautFactory {
  position: absolute;
  background-repeat: no-repeat;
  pointer-events: fill;
  width: vhFn(16);
  height: vhFn(16);
  background-image: url(../../resources/assets/themes/lely/16x9/actors/astronautfactory/astronaut-landing-page.svg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  top: vhFn(38);
  left: vhFn(76.25066);
  z-index: 11;
}

.astronautFactory--title {
  display: none;
}

.astronautFactory--bigger {
  width: vwFn(30);
  height: vhFn(65);
  background-size: cover;
}

.astronautFactory--fixed {
  position: absolute;
  width: vwFn(16);
  height: vhFn(16);
  right: vwFn(55);
  top: vhFn(20);
}
