@use "../../common/styles/variables.scss" as *;

// .orbiterPopup {
//   position: absolute;
//   top: vhFn(15);
//   left: vwFn(10.5);
//   background-color: $color-LelyWhite;
//   width: vwFn(67.73);
//   min-height: vhFn(70.93);
//   max-height: vhFn(85.93);
//   border-left: vwFn(0.12) solid $color-GrayDairyLight;
//   border-right: vwFn(0.12) solid $color-GrayDairyLight;
//   border-top: vhFn(0.27) solid $color-GrayDairyLight;
//   border-bottom: vhFn(0.27) solid $color-GrayDairyLight;
//   box-sizing: border-box;
//   box-shadow: 0px 2px 5px rgba(222, 219, 214, 0.4);
//   border-radius: vwFn(0.5) vwFn(0.5) vwFn(0.5) vwFn(0.5);
//   padding: vhFn(2) vwFn(2.46);
// }

// .orbiterPopup--title {
//   width: 100%;
//   font-family: $secondaryFont;
//   letter-spacing: vwFn(0.2);
//   color: $color-LelyBlack;
//   font-weight: bold;
//   font-size: vwFn(3);
//   line-height: vhFn(9.33);
//   margin-top: 0 !important;
//   margin-bottom: 0;
// }

// .orbiterPopup--descriptionValue {
//   width: vwFn(47.78);
//   min-height: vhFn(47.93);
//   font-family: $primaryFont;
//   font-weight: $font-regular;
//   font-style: $font-DefaultFontStyle;
//   color: $color-gray-popup;
//   font-size: vhFn(3.73);
//   line-height: vhFn(6.4);
//   padding-bottom: vhFn(2.13);
//   margin-top: vhFn(1);
// }

.orbiterPopup--logo {
  position: absolute;
  background-image: url(../../resources/assets/themes/lely/16x9/popups/orbiter/orbiter-popup-image.svg);
  background-repeat: no-repeat;
  width: vhFn(73.35);
  height: vhFn(73.35);
  background-size: 100% 100%;
  left: vwFn(56);
  top: vhFn(0);
}

// .orbiterPopup--btnClosePopup {
//   position: absolute;
//   top: vhFn(-2);
//   left: 70%;
//   background-image: url(../../resources/assets/themes/lely/16x9/ui/popup/close-popup-button.svg);
//   background-repeat: no-repeat;
//   background-size: 100% 100%;
//   width: vwFn(8.5);
//   height: vhFn(11.5);
//   margin: 0;
// }

.orbiterPopup--btnCallToAction, .orbiterPopup--btnCallToActionMilkStory {
  background-color: $color-LelyRed;
  background-repeat: no-repeat;
  border-radius: vwFn(0.5) vwFn(0.5) vwFn(0.5) vwFn(0.5);
  border-left: vwFn(0.12) solid $color-GrayDairyLight;
  border-right: vwFn(0.12) solid $color-GrayDairyLight;
  border-top: vhFn(0.27) solid $color-GrayDairyLight;
  border-bottom: vhFn(0.27) solid $color-GrayDairyLight;
  background-size: 100% 100%;
  padding: vhFn(2) vwFn(2); 
  min-width: vwFn(0.18);
  height: vhFn(10.66);
  font-size: vwFn(1.5);
  color: $color-LelyWhite;
  top: vhFn(3.86);
  line-height: vhFn(4.266);
}
.theme-adult .orbiterPopup--logo {
  background-image: url(../../resources/assets/themes/lely/16x9/popups/orbiter/orbiter--popup--image.svg);
}