@use "../../../common/styles/admin_variables.scss" as adminVar;
@use "../../../common/components/SaveChangesButton/SaveChangesButton.scss";

.AboutYouPage {
  margin-left: adminVar.$sideMenuWidth;
  margin-top: adminVar.$pagesMarginTop;
  height: 90vh;
  overflow: auto;
  padding: 0 19.17vw;
}

.about-you--header {
  font-weight: 400;
  font-size: 26px;
}

.about-you--intro {
  font-size: 14px;
}
.adminPanel--section .image-radio-selection--form-control-style1 {
  background-position-y: -10px;
}
.adminPanel--section .image-radio-selection--form-control-style2 {
  background-position-y: -10px;
}
.about-you--textField {
  width: 100%;
}

.about-you--language {
  width: 100%;
}

.about-you--textArea {
  width: 100%;
}

/* Farmer style radio buttons */
.image-radio-selection--form-control-style1 {
  background-image: url(../../../resources/assets/themes/lely/16x9/adminpanel/farmerStyle/radio-farmer-male.svg);
  background-size: 100%;
  height: 200px;
}

.image-radio-selection--form-control-style2 {
  background-image: url(../../../resources/assets/themes/lely/16x9/adminpanel/farmerStyle/radio-farmer-female.svg);
  background-size: 100%;
  height: 200px;
}

/* Farmer pet radio buttons */
.image-radio-selection--form-control-dogNone {
}

.image-radio-selection--form-control-dogSmall {
  background-image: url(../../../resources/assets/themes/lely/16x9/adminpanel/dogSize/radio-small-dog.svg);
}

.image-radio-selection--form-control-dogMedium {
  background-image: url(../../../resources/assets/themes/lely/16x9/adminpanel/dogSize/radio-medium-dog.svg);
}

.image-radio-selection--form-control-dogLarge {
  background-image: url(../../../resources/assets/themes/lely/16x9/adminpanel/dogSize/radio-large-dog.svg);
}

/* Farmer other animals radio buttons */
.image-radio-selection--form-control-otherAnimalsNone {
}

.image-radio-selection--form-control-otherAnimalsCat {
  background-image: url(../../../resources/assets/themes/lely/16x9/adminpanel/otherAnimal/radio-cat.svg);
}

.image-radio-selection--form-control-otherAnimalsPony {
  background-image: url(../../../resources/assets/themes/lely/16x9/adminpanel/otherAnimal/radio-pony.svg);
}

.image-radio-selection--form-control-otherAnimalsHorse {
  background-image: url(../../../resources/assets/themes/lely/16x9/adminpanel/otherAnimal/radio-horse.svg);
}

.image-radio-selection--form-control-otherAnimalsLlama {
  background-image: url(../../../resources/assets/themes/lely/16x9/adminpanel/otherAnimal/radio-llama.svg);
}

.image-radio-selection--form-control-otherAnimalsAlpaca {
  background-image: url(../../../resources/assets/themes/lely/16x9/adminpanel/otherAnimal/radio-alpaca.svg);
}

.image-radio-selection--form-control-otherAnimalsDonkey {
  background-image: url(../../../resources/assets/themes/lely/16x9/adminpanel/otherAnimal/radio-donkey.svg);
}

.image-radio-selection--form-control-otherAnimalsSheep {
  background-image: url(../../../resources/assets/themes/lely/16x9/adminpanel/otherAnimal/radio-sheep.svg);
}

.image-radio-selection--form-control-otherAnimalsGoat {
  background-image: url(../../../resources/assets/themes/lely/16x9/adminpanel/otherAnimal/radio-goat.svg);
}

.image-radio-selection--form-control-otherAnimalsChicken {
  background-image: url(../../../resources/assets/themes/lely/16x9/adminpanel/otherAnimal/radio-chicken.svg);
}

.AboutYouPage .btn-save-changes {
  margin-left: calc(100% - 170px) !important;
  width: 170px;
}

.AboutYouPage .btn-already-saved {
  margin-left: calc(100% - 270px) !important;
  width: 270px;
  margin-top: 20px !important;
}
