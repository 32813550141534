@use "../../common/styles/admin_variables.scss" as adminVar;

.time-input--container {
  display: flex;
  align-items: center;

  .time-input--label {
    padding-right: 12px;
  }

  .MuiFormControl-root {
    width: 80px;

    input.MuiInputBase-input {
      padding: 8px 10px;
      border: 1px solid adminVar.$color-gray-light;
      border-radius: 4px;
      box-shadow: inset 0px 2px 5px rgba(222, 219, 214, 0.4);
    }
  }

  &.invalid .MuiFormControl-root {
    input.MuiInputBase-input {
      border-color: #ff0000;
    }
  }

  &.empty .MuiFormControl-root {
    input.MuiInputBase-input {
      border-color: #ff0000;
      color: adminVar.$color-GrayDairyDarker;
    }
  }
}
