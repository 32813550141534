@use "../../common/styles/variables.scss" as *;

.shop {
  position: absolute;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  pointer-events: fill;
  width: calc(var(--vh, 1vh) * 18);
  height: calc(var(--vh, 1vh) * 22);
  top: calc(var(--vh, 1vh) * 15);
  //left: calc(var(--vh, 1vh) * 165);
  background-image: url(../../resources/assets/themes/lely/16x9/actors/shop/shop.svg);
  z-index: 10;
}
