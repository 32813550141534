@use "../../common/styles/variables.scss" as *;

.virtualFarm {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  width: vwFn(100);
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/screens/virtualFarm/background_farm_overview_812x375.svg);
  height: vhFn(100);
  background-color: #dedbd6;
}

.virtualFarm--hill {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/screens/virtualFarm/background_farm_hill.svg);
  width: vwFn(100);
  height: vhFn(100);
  // top: vhFn(7.28);
}

.virtualFarm--animal--fence {
  pointer-events: none !important;
  z-index: 15; // in order for the tractor/etc. actors to look like they are behind the fence
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/screens/virtualFarm/background_animal_fence.svg);
  -webkit-transform: translate3d(0, 0, 1px) rotateY(-40deg);
  transform: translate3d(0, 0, 1px) rotateY(-40deg);
  opacity: 1;
}

.virtualFarm--actors-container {
  z-index: 15;
  -webkit-transform: translate3d(0, 0, 1px);
  transform: translate3d(0, 0, 1px);
}

.virtualFarm--house--style1 {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/screens/virtualFarm/background_farm_house.svg);
}
.virtualFarm--house--style2 {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/screens/virtualFarm/background_farm_house_style2.svg);
}
.virtualFarm--house--style3 {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/screens/virtualFarm/background_farm_house_style3.svg);
}
.virtualFarm--left--barn {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/screens/virtualFarm/background_barn_left_part.svg);
  z-index: 3;
}

.virtualFarm--right--production {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/screens/virtualFarm/background_barn_production.svg);
  z-index: 2;
}

.virtualFarm--right--street {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/screens/virtualFarm/background_farm_right_street.svg);
  width: vhFn(48.984);
  height: vhFn(52.778);
  top: vhFn(12.8666666666667);
  //z-index: 1;
}

.virtualFarm--bus {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/screens/virtualFarm/background_bus.svg);
  width: vhFn(19.10066);
  height: vhFn(16.530666666666667);
  top: vhFn(39.927);
  z-index: 2;
}

.virtualFarm--grass1 {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/screens/virtualFarm/grassType1.svg);
  z-index: 2;
}
.virtualFarm--grass2 {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/screens/virtualFarm/grassType1.svg);
  z-index: 2;
  transform: scaleX(-1);
}
.virtualFarm--grass3 {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/screens/virtualFarm/grassType1.svg);
  z-index: 2;
  transform: scaleX(-1);
}
.virtualFarm--grass4 {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/screens/virtualFarm/grassType1.svg);
  z-index: 2;
  transform: scaleX(-1);
}
.virtualFarm--grass5 {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/screens/virtualFarm/grassType1.svg);
  z-index: 2;
  transform: scaleX(1);
}
.virtualFarm--grass6 {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/screens/virtualFarm/grassType1.svg);
  z-index: 2;
  transform: scaleX(1);
}
.virtualFarm--grass7 {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/screens/virtualFarm/grassType1.svg);
  z-index: 2;
  transform: scaleX(-1);
}
.virtualFarm--grass8 {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/screens/virtualFarm/grassType1.svg);
  z-index: 2;
  transform: scaleX(-1);
}
.virtualFarm--grass9 {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/screens/virtualFarm/grassType1.svg);
  z-index: 10;
  transform: scaleX(-1);
}
.virtualFarm--grass10 {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/screens/virtualFarm/grassType1.svg);
  z-index: 2;
  transform: scaleX(1);
}
.virtualFarm--grass11 {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/screens/virtualFarm/grassType1.svg);
  z-index: 2;
  transform: scaleX(1);
}
.virtualFarm--grass12 {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/screens/virtualFarm/grassType1.svg);
  z-index: 2;
  transform: scaleX(-1);
}
.fence {
  pointer-events: none;
  background-image: url(../../resources/assets/themes/lely/16x9/screens/virtualFarm/fence.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  width: vhFn(66.25066);
  height: vhFn(42.352);
  top: vhFn(43.58133333333333);
  z-index: 10;
}

.batchCowListButton {
  width: vwFn(6.19) !important;
  height: vhFn(11.52) !important;
  left: vwFn(47.5);
  bottom: vhFn(0);
  margin-bottom: vhFn(-2) !important;
  background-image: url(../../resources/assets/themes/lely/16x9/ui/buttons/button-with-arrow-up.svg);
  position: absolute;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 0 !important;
  z-index: 10;
  -webkit-transform: translate3d(0, 0, 1px);
  transform: translate3d(0, 0, 1px);
}

.sustainabilityPlate {
  width: vhFn(6.61) !important;
  height: vhFn(10.61) !important;
  left: vhFn(50.61) !important;
  top: vhFn(6);
  position: absolute;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 0 !important;
  background-image: url(../../resources/assets/themes/lely/16x9/actors/sustainabilityFarm/sustainability.svg);
}

.virtualFarm--interactable {
  background-image: none !important;
}

.slide-virtual-farm .parallax-cow-daily {
  opacity: 0;
}

.virtualFarm--background-overlay {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: vwFn(100);
  height: 100%;
  background-color: #121212;
  opacity: 0.4;
  pointer-events: fill;
}

.virtualFarm--background-overlay.is-virtual-farm-displayed {
  display: block;
}
