@use "../../common/styles/variables.scss" as *;
.theme-adult {
  .virtualFarm--actors-container .cow {
    background-image: url(../resources/assets/themes/adult/16x9/actors/cow/virtualFarmAvatars/adultThemeCow.svg) !important;
  }
  .grazewayFeature {
    position: absolute;
    background-repeat: no-repeat;
    pointer-events: fill;
    width: vhFn(20);
    height: vhFn(20);
    //background-image: url(../../resources/assets/themes/adult/16x9/actors/grazeway/grazeway-feature.svg);
    background-image: url(../resources/assets/themes/adult/16x9/actors/grazeway/grazeway-feature.svg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    top: vhFn(61.7);
    left: vhFn(69);
    z-index: 9;
  }

  .lunaFeature {
    position: absolute;
    background-repeat: no-repeat;
    pointer-events: fill;
    background-image: url(../resources/assets/themes/adult/16x9/actors/luna/lunaRobot.svg);
    background-size: 100% 100%;
    z-index: 11;
    width: vwFn(3.3) !important;
  }
  .vectorFeature--front {
    background-image: url(../resources/assets/themes/adult/16x9/actors/vector/vector.svg);
  }

  .vectorFeature--back {
    background-image: url(../resources/assets/themes/adult/16x9/actors/vector/vector_back.svg);
  }
  .junoFeature--front {
    background-image: url(../resources/assets/themes/adult/16x9/actors/juno/juno_front.svg);
  }

  .junoFeature--back {
    background-image: url(../resources/assets/themes/adult/16x9/actors/juno/juno_back.svg);
  }
  .discoveryFeaturesSW {
    background-image: url(../resources/assets/themes/adult/16x9/actors/discoveryssw90/discoveryRobot_moveLeftEdge.svg);
  }
  .discoveryFeaturesSW.moveAlongLeftEdge {
    background-image: url(../resources/assets/themes/adult/16x9/actors/discoveryssw90/discoveryRobot_moveLeftEdge.svg);
    //background-image: url(../../resources/assets/themes/lely/16x9/actors/discovery/discoveryRobotSprite.svg#moveLeftEdge);
  }

  .discoveryFeaturesSW.moveAlongBottomEdge {
    background-image: url(../resources/assets/themes/adult/16x9/actors/discoveryssw90/discoveryRobot_moveBottomEdge.svg);
    //background-image: url(../../resources/assets/themes/lely/16x9/actors/discovery/discoveryRobotSprite.svg#moveBottomEdge);
  }

  .discoveryFeaturesSW.moveAlongRightEdge {
    background-image: url(../resources/assets/themes/adult/16x9/actors/discoveryssw90/discoveryRobot_moveRightEdge.svg);
    //background-image: url(../../resources/assets/themes/lely/16x9/actors/discovery/discoveryRobotSprite.svg#moveRightEdge);
  }

  .discoveryFeaturesSW.moveAlongTopEdge {
    background-image: url(../resources/assets/themes/adult/16x9/actors/discoveryssw90/discoveryRobot_moveTopEdge.svg);
    //background-image: url(../../resources/assets/themes/lely/16x9/actors/discovery/discoveryRobotSprite.svg#moveTopEdge);
  }
  .vehicle {
    background-image: url(../resources/assets/themes/adult/16x9/actors/tractor/tractor.svg) !important;
  }
  .virtualFarm--animal--fence {
    pointer-events: none !important;
    z-index: 15; // in order for the tractor/etc. actors to look like they are behind the fence
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: absolute;
    background-image: url(../resources/assets/themes/adult/16x9/screens/virtualFarm/background_animal_fence.svg);
    transform: translate3d(0, 0, 1px) rotateY(-40deg);
    -webkit-transform: translate3d(0, 0, 1px) rotateY(-40deg);
    opacity: 1;
  }
  .virtualFarm--left--barn {
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: absolute;
    background-image: url(../resources/assets/themes/adult/16x9/screens/virtualFarm/background_barn_left_part.svg);
    z-index: 3;
  }
  .virtualFarm--right--production {
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: absolute;
    background-image: url(../resources/assets/themes/adult/16x9/screens/virtualFarm/background_barn_production.svg);
    z-index: 2;
  }
  .virtualFarm--bus {
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: absolute;
    background-image: url(../resources/assets/themes/adult/16x9/screens/virtualFarm/background_bus.svg);
    width: vhFn(19.10066);
    height: vhFn(16.530666666666667);
    top: vhFn(39.927);
    z-index: 2;
  }
  .virtualFarm--hill {
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: absolute;
    background-image: url(../resources/assets/themes/adult/16x9/screens/virtualFarm/background_farm_hill.svg);
    width: vwFn(100);
    height: vhFn(100);
    // top: vhFn(7.28);
  }
  .virtualFarm {
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: absolute;
    width: vwFn(100);
    background-image: url(../resources/assets/themes/adult/16x9/screens/virtualFarm/background_farm_overview_812x375.svg);
    height: vhFn(100);
    background-color: white;
  }

  .pet--smalldog {
    background-image: url(../resources/assets/themes/adult/16x9/actors/pet/pet--adult--smallDog.svg);
  }

  .pet--mediumdog {
    background-image: url(../resources/assets/themes/adult/16x9/actors/pet/pet--adult--mediumDog.svg);
  }

  .pet--bigdog {
    background-image: url(../resources/assets/themes/adult/16x9/actors/pet/pet--adult--bigDog.svg);
  }

  .pet--chicken {
    background-image: url(../resources/assets/themes/adult/16x9/actors/pet/pet--adult--chicken.svg);
  }

  .pet--cat {
    background-image: url(../resources/assets/themes/adult/16x9/actors/pet/pet--adult--cat.svg);
    background-size: 100% 100%;
  }

  .virtualFarm--grass1,
  .virtualFarm--grass2,
  .virtualFarm--grass3,
  .virtualFarm--grass4,
  .virtualFarm--grass5,
  .virtualFarm--grass6,
  .virtualFarm--grass7,
  .virtualFarm--grass8,
  .virtualFarm--grass9,
  .virtualFarm--grass10,
  .virtualFarm--grass11,
  .virtualFarm--grass12 {
    display: none;
  }
  .excrement {
    display: none;
  }
  .grazewayFeature--door {
    background-image: url(../resources/assets/themes/adult/16x9/actors/grazeway/grazeway-door.svg);
  }
  .virtualFarm--right--street {
    // background-repeat: no-repeat;
    // background-size: 100% 100%;
    // position: absolute;
    background-image: url(../resources/assets/themes/adult/16x9/screens/virtualFarm/background_farm_right_street2.svg);
    // width: vhFn(48.984);
    // height: vhFn(55.778) !important;
    // top: vhFn(4.8666666666667) !important;
  }
  .solarpanel {
    background-image: url(../resources/assets/themes/adult/16x9/actors/solarPanel/solarPanel.svg);
  }
  .virtualFarm--house--style1 {
    background-image: url(../resources/assets/themes/adult/16x9/screens/virtualFarm/background_farm_house.svg);
  }
  .virtualFarm--house--style2 {
    background-image: url(../resources/assets/themes/adult/16x9/screens/virtualFarm/background_farm_house_style2.svg);
  }
  .virtualFarm--house--style3 {
    background-image: url(../resources/assets/themes/adult/16x9/screens/virtualFarm/background_farm_house_style3.svg);
  }
  .fence {
    background-image: url(../resources/assets/themes/adult/16x9/screens/virtualFarm/fence.svg);
    //left: vhFn(82) !important;
  }
  .sustainabilityPlate {
    background-image: url(../resources/assets/themes/adult/16x9/actors/sustainabilityFarm/sustainability.svg);
  }
  .theme-adult {
    #exEUen0FNv4190_to,
    #exEUen0FNv4192_to,
    #exEUen0FNv4201_to,
    #exEUen0FNv4210_to,
    #exEUen0FNv4220_to,
    #exEUen0FNv4230_to,
    #exEUen0FNv4240_to,
    #exEUen0FNv4250_to,
    #exEUen0FNv4280_to,
    #exEUen0FNv4290_to,
    #exEUen0FNv4290,
    #exEUen0FNv4270_to,
    #exEUen0FNv4260_to {
      animation: none !important;
    }
  }
  .milkFactory {
    width: vhFn(26) !important;
    height: vhFn(27) !important;
  }
  .mijnMelk {
    background-image: url(../resources/assets/themes/adult/16x9/actors/mijnMelk/mijnMelk.svg);
    position: fixed;
    background-repeat: no-repeat;
    pointer-events: fill;
    width: vhFn(16);
    height: vhFn(15);
    top: vhFn(4);
    left: vhFn(20);
    background-size: 100% 100%;
    cursor: pointer;
  }

  .brand {
    display: none;
  }
  .ribbonTitle--is-clickable {
    background-image: url(../resources/assets/themes/mijnmelk/16x9/ui/ribbon/ribbon.svg);
  }
  .btnIcon--showMenu {
    background-image: url(../resources/assets/themes/adult/16x9/ui/sidemenu/menu-icon.svg);
  }
  .sideMenu--mainMenu--btnCloseMenu {
    background-image: url(../resources/assets/themes/adult/16x9/ui/sidemenu/close-menu-button.svg);
    transform: scale(-1);
    width: vwFn(7.27);
    height: vhFn(12);
    left: vwFn(1);
    bottom: vhFn(-1.5);
  }
  .batchCowListButton {
    background-image: url(../resources/assets/themes/adult/16x9/ui/buttons/batchCowListButton.svg);
  }
  #applyTexture {
    fill: url(#texturePattern);
  }
  .discoveryFeature.moveAlongLeftEdge {
    background-image: url(../resources/assets/themes/adult/16x9/actors/discovery/discoveryRobot_adult_moveLeftEdge.svg);
  }
  .lunaPopup--btnCallToAction--container,
  .vectorPopup-callToActionButton--container,
  .discoveryPopup--btnCallToAction--container,
  .astronautPopupLiveData--btnCallToAction--container,
  .astronautPopup--btnCallToAction--container,
  .farmerPopup--btnCallToAction--container,
  .brandPopup--btnCallToAction--container,
  .orbiterPopup--btnCallToAction--container,
  .iceCreamFactoryPopup--btnCallToAction--container,
  .dairyPopup--btnCallToAction--container,
  .cheeseFactoryPopup--btnCallToAction--container,
  .mijnMelkPopup--btnCallToAction--container,
  .grazewayPopup--btnCallToAction--container,
  .mijnMelkPopup--secondBtnCallToAction--container,
  .cowPopup--btnCallToAction--container,
  .cowPopup--secondBtnCallToAction--container,
  .shopPopup--btnCallToAction--container,
  .shopPopup--btnCallToActionSecondary--container,
  .sustainabilityPopup--btnCallToAction--container,
  .orbiterPopup--btnCallToActionMilkStory--container,
  .junoPopup-callToActionButton--container,
  .sustainabilityPopup--btnCallToActionAnimalWelfare--container {
    min-width: vwFn(0);
    height: vhFn(6.4);
    display: flex;
    flex-direction: row;
    align-items: center;
    left: vwFn(27);
    top: vhFn(40);
    //position: absolute;
    justify-content: unset;
    margin-left: vwFn(27.5);
  }
  .cowPopup--btnCallToAction--container,
  .cowPopup--secondBtnCallToAction--container {
    margin-left: vwFn(3);
  }
  .lunaPopup--btnCallToAction,
  .vectorPopup-callToActionButton,
  .discoveryPopup--btnCallToAction,
  .astronautPopupLiveData--btnCallToAction,
  .astronautPopup--btnCallToAction,
  .farmerPopup--btnCallToAction,
  .brandPopup--btnCallToAction,
  .orbiterPopup--btnCallToAction,
  .iceCreamFactoryPopup--btnCallToAction,
  .dairyPopup--btnCallToAction,
  .cheeseFactoryPopup--btnCallToAction,
  .mijnMelkPopup--btnCallToAction,
  .grazewayPopup--btnCallToAction,
  .mijnMelkPopup--secondBtnCallToAction,
  .cowPopup--btnCallToAction,
  .cowPopup--secondBtnCallToAction,
  .shopPopup--btnCallToAction,
  .shopPopup--btnCallToActionSecondary,
  .sustainabilityPopup--btnCallToAction,
  .orbiterPopup--btnCallToActionMilkStory,
  .sustainabilityPopup--btnCallToActionAnimalWelfare {
    background-color: transparent;
    border-color: transparent;
    color: #ff684d;
    min-height: 0;
    min-width: vwFn(1);
    order: 0;
    font-size: vhFn(3.73);
    margin-right: 0;
    background-image: none;
  }
  .lunaPopup--btnCallToAction--arrows,
  .vectorPopup-callToActionButton--arrows,
  .discoveryPopup--btnCallToAction--arrows,
  .astronautPopupLiveData--btnCallToAction--arrows,
  .astronautPopup--btnCallToAction--arrows,
  .farmerPopup--btnCallToAction--arrows,
  .brandPopup--btnCallToAction--arrows,
  .orbiterPopup--btnCallToAction--arrows,
  .iceCreamFactoryPopup--btnCallToAction--arrows,
  .dairyPopup--btnCallToAction--arrows,
  .cheeseFactoryPopup--btnCallToAction--arrows,
  .mijnMelkPopup--btnCallToAction--arrows,
  .grazewayPopup--btnCallToAction--arrows,
  .mijnMelkPopup--secondBtnCallToAction--arrows,
  .cowPopup--btnCallToAction--arrows,
  .cowPopup--secondBtnCallToAction--arrows,
  .shopPopup--btnCallToAction--arrows,
  .shopPopup--btnCallToActionSecondary--arrows,
  .cookieStatementPopup--footer--btnAcceptCookies--arrows,
  .sustainabilityPopup--btnCallToAction--arrows,
  .orbiterPopup--btnCallToActionMilkStory--arrows,
  .junoPopup-callToActionButton--arrows,
  .sustainabilityPopup--btnCallToActionAnimalWelfare--arrows {
    background-image: url(../resources/assets/themes/lely/16x9/ui/popup/orange_button_arrows.svg);
    width: vwFn(1.7);
    height: vhFn(4);
    background-repeat: no-repeat;
    order: 1;
  }

  .astronautPopupLiveData--btnCallToAction--container {
    left: vwFn(2);
    top: vhFn(55);
  }
  .astronautPopup--btnCallToAction--container,
  .sustainabilityPopup--btnCallToActionAnimalWelfare--container,
  .farmerPopup--btnCallToAction--container {
    top: vhFn(55);
  }

  .mijnMelkPopup--secondBtnCallToAction--container //{, .grazewayPopup--btnCallToAction--container, .vectorPopup-callToActionButton--container }
  {
    top: vhFn(55);
  }
  .mijnMelkPopup--btnCallToAction--container,
  .shopPopup--btnCallToAction {
    top: vhFn(55);
    left: vwFn(2);
  }
  .shopPopup--btnCallToAction,
  .shopPopup--btnCallToActionSecondary {
    background-color: transparent !important;
    background-image: none !important;
    color: #ff684d !important;
    font-size: vhFn(3) !important;
  }
  .shopPopup--btnCallToAction-disabled--container {
    display: none;
  }
  .shopPopup--btnCallToAction--container {
    margin-left: vwFn(2);
  }

  .iceCreamFactoryPopup--btnClosePopup,
  .cheeseFactoryPopup--btnClosePopup,
  .calmRobotPopup--btnClosePopup,
  .brandPopup--btnClosePopup,
  .dogPopup--btnClosePopup,
  .farmPopup--btnClosePopup,
  .shopPopup--btnClosePopup,
  .sustainabilityPopup--btnClosePopup,
  .grazewayPopup--btnClosePopup,
  .vectorPopup--btnClosePopup,
  .orbiterPopup--btnClosePopup,
  .astronautPopup--btnClosePopup,
  .discoveryPopup--btnClosePopup,
  .solarPanel--btnClosePopup,
  .vehiclePopup--btnClosePopup,
  .lunaPopup--btnClosePopup,
  .mijnMelkPopup--btnClosePopup,
  .farmerPopup--btnClosePopup,
  .dairyPopup--btnClosePopup,
  .cow--ruminationPopup--btnClosePopup,
  .cowPopup--btnClosePopup {
    background-image: url(../resources/assets/themes/lely/16x9/ui/popup/close-popup-button-adult-theme.svg);
    width: vwFn(6);
    height: vhFn(12.66);
    left: vwFn(1.84);
    top: vhFn(-0.375);
  }
  .calmRobotPopup--title,
  .farmPopup--title,
  .farmerPopup--title,
  .brandPopup--title,
  .orbiterPopup--title,
  .iceCreamFactoryPopup--title,
  .dairyPopup--title,
  .cheeseFactoryPopup--title,
  .vehiclePopup--title,
  .shopPopup--title,
  .mijnMelkPopup--title,
  .astronautPopup--title,
  .vectorPopup--title,
  .grazewayPopup--title,
  .discoveryPopup--title,
  .lunaPopup--title,
  .solarPanel--title,
  .cow--ruminationPopup--title,
  .cowPopup--title,
  .vehiclePopup--title,
  .sustainabilityPopup--title,
  .junoPopup--title {
    font-weight: 400;
    margin-left: vwFn(7.5);
    font-family: BourtonBase !important;
    text-transform: uppercase;
    letter-spacing: vwFn(0.1);
  }
  .farmPopup--title,
  .farmerPopup--title,
  .iceCreamFactoryPopup--title {
    font-size: vhFn(5);
  }
  .discoveryFeature {
    background-image: url(../resources/assets/themes/adult/16x9/actors/discovery/discoveryRobot_adult_moveLeftEdge.svg);
  }

  .discoveryFeature.moveAlongBottomEdge {
    background-image: url(../resources/assets/themes/adult/16x9/actors/discovery/discoveryRobot_adult_moveBottomEdge.svg);
  }

  .discoveryFeature.moveAlongRightEdge {
    background-image: url(../resources/assets/themes/adult/16x9/actors/discovery/discoveryRobot_adult_moveRightEdge.svg);
  }
  .cowPopup--hasNameBeenSelected .cowPopup--btnClosePopup {
    top: vhFn(-0.375);
  }
  .cowPopup--chosen-name-party-hat {
    left: vwFn(69.5);
    top: vhFn(-7);
  }
  .cowPopup--chosen-name-party-flute {
    left: vwFn(64);
    top: vhFn(45.5);
  }
  // .cowPopup--hasNameBeenSelected .cowPopup--chosen-name-party-flute {
  //   right: vwFn(9);
  //   top: vhFn(30);
  // }
  .discoveryFeature.moveAlongTopEdge {
    background-image: url(../resources/assets/themes/adult/16x9/actors/discovery/discoveryRobot_adult_moveTopEdge.svg);
  }

  .shop {
    background-image: url(../resources/assets/themes/adult/16x9/actors/shop/shop_adult.svg);
  }
  .mijnMelkPopup--secondBtnCallToAction--container,
  .shopPopup--btnCallToActionSecondary--container {
    width: vwFn(22.5);
    margin-left: vwFn(3);
  }
  .shopPopup--btnCallToActionSecondary {
    border-color: transparent !important;
  }
  .mijnMelkPopup--secondBtnCallToAction {
    width: vwFn(20.5);
  }

  .shopPopup--btnCallToActionSecondary,
  .shopPopup--btnCallToAction {
    border: none !important;
    box-shadow: none !important;
  }
  .astronautPopupLiveData--btnCallToAction--container {
    width: vwFn(19.5);
    margin-left: vwFn(3);
  }
  .astronautPopupLiveData--btnCallToAction {
    width: vwFn(20.5);
  }
  .astronautPopup--btnCallToAction--container,
  .sustainabilityPopup--btnCallToAction--container,
  .sustainabilityPopup--btnCallToActionAnimalWelfare--container {
    margin-left: 0;
    width: vwFn(19.5) !important;
  }
  .mijnMelkPopup--btnCallToAction--container,
  .orbiterPopup--btnCallToActionMilkStory--container {
    margin-left: vwFn(1);
  }
  .is-cow-daily-currently-shown .grazewayPopup--btnCallToAction--container {
    display: none;
  }
  .grazewayPopup--btnCallToAction {
    //width: 0;
    min-width: vwFn(1);
  }
  .discoveryStoryStartingSlide--name,
  .cow-daily--astronaut--text,
  .cow-daily--rumination--text,
  .astronautStoryStartingPoint--name,
  .lunaStoryStartingSlide--name,
  .orbiterStoryStartingPoint--name,
  .vectorStoryStartingPoint--name,
  .cow-intro--name,
  .familyMember--name,
  .cow-calm--robot--textDrinking,
  .batchCowListPopup--cowContainer--cowNameFirstRow {
    font-family: LumiosMarker !important;
    font-weight: 400 !important;
  }
  .discoveryStorySecondSlide--title,
  .discoveryStoryThirdSlide--title,
  .vectorStoryLastSlide--text,
  .mijnMelkLastSlide--sign--text,
  .astronautPopup--title,
  .astronautStorySecondSlide--title,
  .astronautStoryThirdSlide--title,
  .astronautStoryFourthSlide--title,
  .grazewayPopup--title,
  .lunaPopup--title,
  .lunaStorySecondSlide--title,
  .lunaStoryThirdSlide--title,
  .solarPanel--title,
  .mijnMelkPopup--title,
  .orbiterPopup--title,
  .farmerPopup--title,
  .vehiclePopup--title,
  .sustainabilityPopup--title,
  .farmerStoryDynamicSlide--title,
  .farmPopup--title,
  .farmerStory--text,
  .dogPopup--name,
  .cowPopup--title,
  .cow--firstBirthdaySlide--text,
  .batchCowListPopup--title,
  .vectorStorySecondSlide--title,
  .vectorStoryThirdSlide--title,
  .vectorStoryFourthSlide--title,
  .junoStorySecondSlide--title,
  .junoStoryThirdSlide--title,
  .junoStoryFourthSlide--title {
    font-family: BourtonBase !important;
  }
  .batchCowListPopup--title {
    font-size: vhFn(3.8);
  }
  .cow-daily--astronaut--text,
  .cow-daily--rumination--text {
    font-size: vhFn(4.333);
  }
  .discoveryPopup--btnCallToAction {
    margin-left: vwFn(-7);
  }
}

.AgeSelectionPage--welcome--man-answer {
  font-family: BourtonBase !important;
}
.batchCowListPopup--title {
  text-shadow: none !important;
}
.cookieStatementPopup--footer--btnAcceptCookies--container,
.disclaimerPopup--footer--btnCloseDisclaimer--container {
  min-width: vwFn(0);
  height: vhFn(6.4);
  display: table;
  padding: vhFn(1.5) vwFn(1);
  flex-direction: row;
  align-items: center;
  left: vwFn(15);
  top: vhFn(40);
  justify-content: unset;
  margin: vhFn(5) auto;
  background-color: #ff684d;
  border-radius: vhFn(1);
}
.theme-adult .cookieStatementPopup--footer--btnAcceptCookies--arrows,
.cookieStatementPopup--footer--btnAcceptCookies--arrows,
.disclaimerPopup--footer--btnCloseDisclaimer--arrows {
  background-image: url(../resources/assets/themes/adult/16x9/ui/cookies/arrow_right.svg);
  display: inline-block;
  width: vwFn(1.9);
  height: vhFn(3.7);
  vertical-align: vhFn(-0.5);
}
.theme-adult
  .virtual-farm-tour-slide-3
  .virtual-farm-tour-container
  .btn-top-close,
.theme-adult .virtual-farm-tour-container .btn-side-right-arrow {
  background-image: url(../resources/assets/themes/adult/16x9/ui/buttons/virtualFarmTourCloseButton.svg);
  transform: rotate(0);
  left: vwFn(0.5);
  top: vhFn(-0.5);
}

.theme-adult
  .is-virtual-farm-tour-active
  .virtualFarm--highlighted
  path:first-of-type {
  fill: white;
  stroke: black;
}

.theme-adult .is-virtual-farm-tour-active .virtual-farm-tour--overlay {
  transform: translate3d(0, 0, 1px);
  -webkit-transform: translate3d(0, 0, 1px);
  z-index: 5;
}

.theme-adult .is-virtual-farm-tour-active .virtualFarm--actors-container {
  z-index: 15;
}

.theme-adult .is-virtual-farm-tour-active .virtualFarm--actors-container * {
  z-index: 3;
}

.theme-adult .is-virtual-farm-tour-active .virtualFarm--highlighted {
  z-index: 10;
}

.theme-adult .windmill--body {
  background-image: url(../resources/assets/themes/adult/16x9/ui/windmill/windmill-body.svg);
}

.theme-adult .windmill--fin {
  background-image: url(../resources/assets/themes/adult/16x9/ui/windmill/windmill-fin.svg);
  bottom: vhFn(30.5);
}

.theme-adult .btn-social {
  width: vwFn(3.875);
  height: vwFn(3.875);
}

// .sustainabilityPopup--btnCallToAction--container {
//   padding-bottom: vhFn(10);
// }
@media only screen and (orientation: portrait) {
  .theme-adult .cowPopup--btnCallToAction {
    max-width: vwFn(22);
  }
}

.theme-adult .vectorStoryLastSlide--text {
  padding: vhFn(3) vwFn(3);
}

.theme-adult .cowPopup--container {
  z-index: 100;
}
.theme-adult {
  .orbiterPopup--btnCallToAction--container {
    margin-left: vwFn(2);
    width: vwFn(22);
  }
}
.orbiterPopup--btnCallToActionMilkStory--container {
  width: vwFn(23);
}
.theme-adult .junoPopup-callToActionButton--container {
  margin-left: vwFn(20);
}
