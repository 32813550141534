@use "../../common/styles/variables.scss" as *;

.brandPopup {
  position: absolute;
  left: vwFn(5.603);
  top: vhFn(17.0666);
  background-color: $color-LelyWhite;
  width: vwFn(67.73);
  height: vhFn(65.86);
  border-left: vwFn(0.12) solid $color-GrayDairyLight;
  border-right: vwFn(0.12) solid $color-GrayDairyLight;
  border-top: vhFn(0.27) solid $color-GrayDairyLight;
  border-bottom: vhFn(0.27) solid $color-GrayDairyLight;
  box-sizing: border-box;
  box-shadow: 0px 2px 5px rgba(222, 219, 214, 0.4);
  border-radius: vwFn(0.5) vwFn(0.5) vwFn(0.5) vwFn(0.5);
  padding: vhFn(3.25) vwFn(2.463);
}

.brandPopup--title {
  width: vwFn(43.103);
  font-family: $secondaryFont;
  color: $color-LelyBlack;
  font-weight: 700;
  font-size: vhFn(6.4);
  line-height: vhFn(9.33);
  letter-spacing: vwFn(0.2);
}

.brandPopup--brand {
  position: relative;
  width: vwFn(10);
  height: vhFn(10);
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(../../resources/images/lely_logo_white_border.png);
}

.brandPopup--description {
  width: vwFn(47.78);
  min-height: vhFn(32);
  font-family: $primaryFont;
  font-weight: 400;
  font-size: vhFn(3.733);
  line-height: vhFn(6.4);
  margin-bottom: vhFn(3.25);
}

.brandPopup--btnClosePopup {
  position: absolute;
  top: vhFn(-2.5);
  left: vwFn(48.5);
  background-image: url(../../resources/assets/themes/lely/16x9/ui/popup/close-popup-button.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: vwFn(8.5);
  height: vhFn(11.5);
  z-index: 2;
}

.brandPopup--logo {
  background-image: url(../../resources/assets/themes/lely/16x9/popups/lelyBrand/lelyBrandLogo.svg);
  position: absolute;
  top: vhFn(-3.5);
  left: vwFn(53);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: vhFn(73.35);
  height: vhFn(73.35);
}

.brandPopup--btnCallToAction {
  background-color: $color-LelyRed;
  background-repeat: no-repeat;
  border-radius: vwFn(0.5) vwFn(0.5) vwFn(0.5) vwFn(0.5);
  border-left: vwFn(0.12) solid $color-GrayDairyLight;
  border-right: vwFn(0.12) solid $color-GrayDairyLight;
  border-top: vhFn(0.27) solid $color-GrayDairyLight;
  border-bottom: vhFn(0.27) solid $color-GrayDairyLight;
  background-size: 100% 100%;
  width: vwFn(19.889);
  height: vhFn(10.66);
  font-size: vwFn(1.5);
  color: $color-LelyWhite;
  top: vhFn(3.86);
  line-height: vhFn(4.266);
}
