@use "../../common/styles/variables.scss" as *;

.btnIcon {
  background-repeat: no-repeat;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.btnCallToAction {
  border: none;
  cursor: pointer;
}
