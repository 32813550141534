@use "../../../common/styles/variables.scss" as *;

.CowGaveBirthSecondSlide {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  width: vwFn(100);
  height: vhFn(100);
}

.textCaption--CowGaveBirthSecondSlide {
  position: absolute;
  min-height: vhFn(1.1);
  width: vwFn(30.8);
  left: vwFn(20.5);
  top: vhFn(31.3);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: vhFn(2);
}

.calfLeftSide {
  position: absolute;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: vwFn(15.64);
  height: vhFn(21.066);
  left: vwFn(51.73);
  top: vhFn(64);
}

.cow-CowGaveBirthSecondSlide--text {
  position: static;
  font-size: vhFn(3.5);
  line-height: vhFn(6.4);
  font-weight: 400;
  margin: vhFn(2.7) 0px;
  width: vwFn(27.9);
  // min-height: vhFn(24.5);
  left: vwFn(1.47);
  top: vhFn(12.26);
}

.cow-CowGaveBirthSecondSlide--date {
  position: static;
  font-size: vhFn(4.5);
  font-weight: bold;
  line-height: vhFn(6.4);
  display: flex;
  left: vwFn(1.5);
  top: vhFn(3.2);
  align-items: center;
  font-family: $secondaryFont !important;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin-top: vhFn(2.7);
  margin-bottom: 0;
}

.cow-CowGaveBirthSecondSlide--temperature {
  position: absolute;
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistory/background-layers/firstYear/thermometer.svg);
  background-repeat: no-repeat;
  width: vwFn(5);
  height: vhFn(15.2);
  left: vwFn(6.28571);
  top: vhFn(20.5);
  z-index: 5;
}

.textCaption--CowGaveBirthSecondSlide--degrees {
  position: absolute;
  padding: vwFn(1.5);
  min-height: vhFn(11.2);
  min-width: vwFn(1);
  top: vhFn(21.853);
  left: vwFn(10.1576);
  z-index: 4;
  font-weight: 300;
  font-size: vhFn(4.266);
  line-height: vhFn(4.8);
}
