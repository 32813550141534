@use "./variables.scss" as *;

.table {
  border-collapse: separate; //needed for borders
  border-spacing: 0 1px;
  position: relative; //relative is needed for btn-collapse position in tables to create bounding box
  width: 100%;
  margin-bottom: 1rem;
  color: $color-LelyBlack;
  font-size: 14px;

  &.farm-list,
  &.robot-list {
    cursor: pointer;
  }

  .table-level {
    font-family: $font-DefaultFont;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;
    display: flex;
    align-items: self-end;
    text-align: center;
    color: $color-LelyWhite;

    border-radius: 10px;
    padding: 0 10px;
    box-sizing: border-box;
    text-transform: capitalize;
    i {
      padding: 0 5px 0 0;
    }
  }
  .table-level-warning {
    background: $color-BrandWarning;
  }

  .table-level-alarm,
  .table-level-error,
  .table-level-critical,
  .table-level-emergency {
    background: $color-BrandDanger;
  }

  .table-level-info,
  .table-level-information {
    background: $color-LelyPetrol;
  }

  .col-date-and-type {
    color: $color-gray-lighter;
  }

  // for mobile; hide table header any time
  thead {
    th[sortable] {
      cursor: pointer;
    }

    th.checkbox-cell {
      padding-top: 0;

      .custom-control-label {
        position: absolute;
        top: 0;
        width: 175px;

        // @include media-breakpoint-up($grid-breakpoints: ms) {
        //   span {
        //     display: none;
        //   }
        // }
      }
    }

    th:not(.checkbox-cell) {
      display: table-cell;
      padding-top: 0;
      padding-bottom: 0;
      font-weight: 300;
      vertical-align: top;
      text-align: inherit;
      padding: 0.75rem;
      min-width: 100px;

      // reset the following selector in forms.scss
      // select + .btn, select.custom-select + .btn, select.form-control + .btn
      .btn {
        min-width: initial;
        margin-left: 0;

        &.btn-icon {
          top: -4px;
        }
      }

      //   @include media-breakpoint-up(md) {
      //     display: table-cell;
      //   }
      //override some default styles
      .btn-sort {
        display: inline-block;
        margin-left: 5px;

        &:before,
        &:after {
          display: block;
        }
      }

      &:not(.desc):before {
        opacity: 1;
      }

      &.asc {
        .btn-sort:before {
          opacity: 1;
        }
      }

      &.desc {
        .btn-sort:after {
          opacity: 1;
        }
      }
    }
  }

  tr,
  thead {
    -webkit-transition: 0.4s cubic-bezier(0.36, 0.03, 0.28, 0.99);
    transition: all 0.4s cubic-bezier(0.36, 0.03, 0.28, 0.99);
    td,
    th {
      //Automaticly wrap text in cells
      white-space: pre-wrap;
      min-height: 60px;
      vertical-align: middle;
      border: 0px;
      //Modifier class for text on one line
      &.nowrap,
      [nowrap] {
        white-space: nowrap;
      }

      &.checkbox-cell {
        padding-right: 0;
        width: 24px;
      }

      &.input-cell {
        padding-top: 10px;
        padding-bottom: 10px;
        height: 60px;
      }

      &.options-cell {
        width: 20px;

        // @include media-breakpoint-up(md) {
        //   padding-left: 0;
        //   width: auto;
        // }
      }

      &:first-child {
        padding-left: 20px;
      }

      &:last-child {
        padding-right: 13px;
      }

      &.sticky-end {
        position: sticky;
        display: inline-block;
        right: 0;
        width: 20px;
        z-index: 1;
        box-shadow: -4px 0px 4px $color-GrayDairyLighter;
        width: 40px;
        padding-left: 0px;
      }

      &.th__colspan {
        padding-left: 0px;
        padding-right: 0px;
        position: relative;

        &:before,
        &:after {
          content: "";
          width: 1px;
          height: 5px;
          position: absolute;
          background: $color-GrayDairy;
          left: 0;
          top: 6px;
        }

        &:after {
          right: 0;
          left: auto;
        }

        span {
          // @extend h5;
          color: $color-GrayDairyDarker;
          text-align: center;
          display: flex;

          &:before,
          &:after {
            content: "";
            height: 1px;
            background: $color-GrayDairy;
            flex: 1;
            position: relative;
            width: 100%;
            display: block;
            transform: translateY(8px);
          }

          &:before {
            margin-right: 8px;
          }

          &:after {
            margin-left: 8px;
          }
        }
      }

      .btn {
        background: 0 0;
        border-radius: 0;
        padding: 0;
        box-shadow: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        height: 40px;
        line-height: 40px;
        font-size: 14px;
        font-weight: 400;
        border: 0;
        vertical-align: sub;
        i {
          color: $color-gray-arrow;
        }
      }

      .btn:not(.btn-circle) {
        i[class^="lely-icon-"],
        i[class*=" lely-icon-"] {
          position: static;
          line-height: 38px;
        }
      }

      i[class^="lely-icon-"],
      i[class*=" lely-icon-"] {
        line-height: 18px;
        position: relative;
        transform: translateY(2px);
        -webkit-transform: translateY(3px);
        -moz-transform: translateY(1px);
      }

      .custom-control.custom-checkbox--star {
        align-items: center;
      }
      //spacing between icon and range slider on Health Report
      .icon {
        float: left;
        margin-right: 10px;
      }

      a {
        color: $color-text;
        text-decoration: none; // todo: discuss; remove text decoration for all links?
      }

      .label + .label {
        //e.g used in report overview
        margin-left: 10px;
      }

      .cell-meta {
        display: flex;
        flex-wrap: wrap;
        color: $color-GrayDairyDarker;
        font-weight: $font-regular;
        font-size: 12px;
        line-height: 14px;
        margin: 1px 0 0;

        // @include media-breakpoint-up(md) {
        //   display: none;
        // }

        &__text {
          display: flex;
          align-items: center;
        }

        .rangeslider {
          margin-top: 15px;
          padding-top: 0;
          border: none;
        }
      }

      .cell-meta__text {
        &:after {
          content: "•";
          color: $color-GrayDairyLight;
          display: inline-block;
          margin: 0 5px;
        }

        &:last-of-type:after {
          content: none;
        }
      }
    }

    td {
      background: $color-LelyWhite;
      position: relative;
      height: 60px;
      padding: 0 0.75rem;
      box-sizing: border-box;
      max-width: 130px;

      &.d-md-table-cell {
        display: table-cell;
      }

      &:first-child {
        border-top-left-radius: $border-radius;
        border-bottom-left-radius: $border-radius;
      }

      &:last-of-type {
        //use last-of-type instead of last-child. Sometimes last child is an btn (see Manage feed)
        border-top-right-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
      }

      &.checkbox-cell {
        padding-right: 0;
        width: 24px;
        padding-top: 10px;
        padding-bottom: 10px;

        .custom-checkbox {
          margin: 6px 0px; //position to middle of row
        }
      }

      &.sticky-end {
        background-color: $color-LelyWhite;
      }

      input + span {
        margin-left: 10px;
      }

      span + input {
        margin-left: 10px;
      }

      strong + span {
        margin-left: 10px;
      }

      .tr-status {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        border-top-left-radius: $border-radius;
        border-bottom-left-radius: $border-radius;

        &--critical {
          border-left: 5px solid $color-BrandDanger;
        }

        &--warning {
          border-left: 5px solid $color-BrandWarning;
        }

        &--advice {
          border-left: 5px solid $color-BrandInfo;
        }
      }
    }

    th {
      height: 30px;
      padding-bottom: 5px;

      &.sticky-end {
        background-color: $color-GrayDairyLighter;
      }
    }

    &:not(.individual-task) {
      td:last-child {
        .table--cell {
          padding-left: 13px;

          //   @include media-breakpoint-up(md) {
          //     padding-left: 0;
          //   }

          &:before {
            content: "";
            position: absolute;
            width: 1px;
            top: 15px;
            bottom: 15px;
            left: 0;
            background: $color-GrayDairy;

            // @include media-breakpoint-up(md) {
            //   display: none;
            // }
          }
        }
      }
    }

    &.table__divider {
      box-shadow: none;

      td {
        padding-left: 0px;
        padding-right: 0px;
        position: relative;
        background: transparent;
      }
    }
  }

  tbody {
    tr {
      border-radius: $border-radius; // do not remove this; necessary for using box shadow
      box-shadow: $box-shadow;
      -webkit-transition: 0.4s cubic-bezier(0.36, 0.03, 0.28, 0.99);
      transition: all 0.4s cubic-bezier(0.36, 0.03, 0.28, 0.99);

      &.tr-spacer {
        box-shadow: none;

        td {
          border: 0;
        }
      }
    }
    //Styling for collapsing table rows (see Manage Feed)
    &.tr-collapse-row {
      tr {
        td:not(:first-child) {
          padding-left: 35px;
        }
      }
    }

    &.tr-collapse-row,
    &.tr-collapse-body {
      tr {
        position: relative;

        &.actions-row {
          .actions {
            margin-right: 10px;
          }
        }

        .btn-collapse {
          position: absolute;
          z-index: 1;
          left: auto;
          right: 20px;
          transform: translateY(15px);
        }

        td {
          //Dont position absolute when collapse btn is in table cell
          .btn-collapse {
            position: relative;
            top: auto;
            right: auto;
            transform: none;
          }
        }
      }

      + tbody tr.table__divider td {
        height: 15px;
        padding: 0;
      }
    }

    &.tr-collapse-body {
      //Create indenting
      transform: translateX(5px);

      //   @include media-breakpoint-up(md) {
      //     transform: translateX(20px);
      //   }

      tr {
        box-shadow: none; // remove shadow for now because of the indenting (strange behaviour)
        td:last-of-type:not([colspan]) {
          //Remove extra width from last table cell for indenting
          width: calc(100% - 10px);
          display: block;

          //   @include media-breakpoint-up(md) {
          //     width: calc(100% - 20px);
          //   }
        }
        //All strong tags in the indented table rows have a medium weight.
        strong {
          font-weight: $font-medium;
        }

        .btn-collapse {
          top: 0;
          right: 40px;
        }
      }
    }
    //Create indenting for second level (see Feed table)
    + .tr-collapse-body--second {
      transform: translateX(40px);

      tr {
        td:last-of-type {
          //Remove extra width from last table cell for indenting
          width: calc(100% - 40px);
        }
      }
    }
  }
}

.MuiTablePagination-root {
  color: $color-GrayDairyDarker;
  .MuiIconButton-root {
    padding: 0px;
  }
  .MuiTablePagination-toolbar {
    min-height: 20px;
  }
  .MuiTablePagination-actions {
    margin-left: 10px;
  }
  .MuiTablePagination-selectRoot {
    margin-left: 0px;
    margin-right: 15px;
  }
}

.MuiButton-root {
  &.action-button {
    margin-right: 10px;
  }
  &.MuiContainer-maxWidthLg {
    max-width: 100%;
  }
}

.table-responsive {
  .table {
    position: static; //overwrite, relative on default breaks the sticky columns
    //Automaticly wrap text in table responsive cells
    td,
    th {
      white-space: nowrap;
    }
  }

  $sticky-column-width: 200px;

  .sticky-column {
    position: absolute;
    display: inline-block;
    background-color: $color-LelyWhite;
    width: $sticky-column-width;
    z-index: 1;
    box-shadow: 4px 0px 4px $color-GrayDairyLighter;
    padding-top: 20px;
    padding-bottom: 2px; // To hide the border
  }

  th.sticky-column {
    background-color: $color-GrayDairyLighter;
  }

  .sticky-end {
    position: sticky;
    display: inline-block;
    right: 0;
    background-color: $color-LelyWhite;
    width: 20px;
    z-index: 1;
    box-shadow: -4px 0px 4px $color-GrayDairyLighter;
    padding-top: 20px;
  }

  .sticky-column + td,
  .sticky-column + th {
    padding-left: calc(#{$sticky-column-width} + 20px);
  }
}
