@use "../../common/styles/variables.scss" as *;

.brand {
  position: fixed;
  background-repeat: no-repeat;
  pointer-events: fill;
  width: calc(var(--vh, 1vh) * 31);
  height: calc(var(--vh, 1vh) * 28);
  top: calc(-3.5 * var(--vh, 1vh));
  left: calc(var(--vh, 1vh) * 4);
  background-size: 100% 100%;
}

.brand--lely {
  background-image: url(../../resources/assets/themes/lely/16x9/actors/brand/brand-logo.svg);
}
