@use "../../common/styles/variables.scss" as *;

.astronautStorySecondSlide {
  position: absolute;
  background-size: 100% 100%;
  width: vwFn(101);
  height: vhFn(100);
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/milking/milking_last.svg);
  border: none;
}

.astronautStorySecondSlide--textCaption {
  position: absolute;
  width: vwFn(55.41);
  min-height: vhFn(47.46);
  top: vhFn(26.266);
  left: vwFn(38.819);
  padding: vhFn(3.2) vwFn(1.48);
  z-index: 2;
}

.astronautStorySecondSlide--title {
  font-family: $secondaryFont;
  font-style: $font-DefaultFontStyle;
  font-weight: $font-light;
  font-size: vhFn(4.27);
  line-height: vhFn(4.8);
  letter-spacing: vwFn(0.1);
  color: $color-gray-popup;
}

.astronautStorySecondSlide--text {
  font-family: $primaryFont;
  font-style: $font-DefaultFontStyle;
  font-size: vhFn(3.73);
  line-height: vhFn(6.4);
  color: $color-gray-popup;
  margin: vhFn(1.87) 0;
}

.astronautStorySecondSlide--imgContainer {
  width: 100%;
  height: vhFn(57.77);
  border-width: vhFn(0.53) vwFn(0.246);
  border-style: solid;
  border-color: $color-GrayDairyDarker;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/astronautstory/astronaut.jpg);
  background-image: url(../../resources/assets/themes/lely/16x9/stories/astronautstory/astronautsecond.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-color: $color-GrayDairyDarker;
}

.astronautStorySecondSlide--imgCaption {
  position: absolute;
  width: vwFn(29.85);
  height: vhFn(78.93);
  background-color: $color-LelyWhite;
  box-shadow: 0px vwFn(0.25) vwFn(0.7) rgba(18, 18, 18, 0.2);
  padding: vhFn(3.2) vwFn(1.48);
  transform: rotate(5.18deg);
  left: vwFn(8.08);
  bottom: vhFn(8);
}
.theme-adult .astronautStorySecondSlide {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/astronautstory/milkingRobotStorySecondSlide.svg);
}
.theme-adult .astronautStorySecondSlide--transitionTree {
  position: absolute;
  background-size: 100% 100%;
  width: vwFn(60);
  left: vwFn(70);
  height: vhFn(100);
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowDailyForAdult/backgrounds/eating/full_tree.png);
  display: block;
  z-index: 1;
}
.theme-adult {
  .astronautStorySecondSlide--imgCaption,
  .astronautStoryThirdSlide--imgCaption,
  .astronautStoryFourthSlide--imgCaption,
  .astronautStoryFifthSlide--imgCaption {
    border-color: #000000;
    border: solid vhFn(1);
  }
  .astronautStorySecondSlide--title,
  .astronautStoryThirdSlide--title,
  .astronautStoryFourthSlide--title,
  .astronautStoryFifthSlide--title {
    //font-family: "Chelsea Market" !important;
    font-weight: 400;
    line-height: vhFn(6.4);
    font-size: vhFn(4.8);
  }
}
