@use "../../styles/variables.scss" as *;

:root {
  --admin-image-dropzone-size-px: 150px;
}

.labelled-image-drop-zone--label {
  display: block;
  font-size: 16px !important;
  padding-bottom: 1vh;
}

.labelled-image-drop-zone--preview-image {
  max-width: 20vw;
  max-height: 20vh;
}

.labelled-image-drop-zone .MuiDropzoneArea-root {
  position: absolute;
  left: 0;
  width: calc(100% - var(--admin-image-dropzone-size-px));
  opacity: 0;
  min-height: var(--admin-image-dropzone-size-px) !important;
  height: var(--admin-image-dropzone-size-px) !important;
}

.labelled-image-drop-zone--userFacingContents {
  position: absolute;
  left: 0;
  width: calc(100% - var(--admin-image-dropzone-size-px));
  height: 100%;
  border-radius: 4px;
  border: 1px dashed var(--color-border-cfcbc-3-color-border, #cfcbc3);
  background-color: white;
  box-shadow: 0px 2px 5px 0px rgba(222, 219, 214, 0.4) inset;
  background-image: url(../../../resources/assets/themes/lely/16x9/adminpanel/imageDropZone/uploadIcon.svg);
  background-position: center center;
  background-repeat: no-repeat;
  pointer-events: none;
}

.labelled-image-drop-zone--userFacingContents h3 {
  font-family: Ubuntu;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 100%;
  text-align: center;
  position: absolute;
  top: 55%;
  color: #766e78;
}

.labelled-image-drop-zone--userFacingContents h4 {
  width: 100%;
  text-align: center;
  position: absolute;
  top: 65%;
  font-family: Ubuntu;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  color: #766e78;
}

.labelled-image-drop-zone {
  position: relative;
}

.labelled-image-drop-zone--container {
  position: relative;
  width: 100%;
  height: var(--admin-image-dropzone-size-px);
}
