@use "../../common/styles/variables.scss" as *;

.textCaption--mijnMelkAdultOnboarding-slide2-text1 {
  position: absolute;
  top: vhFn(20.53);
  left: vwFn(47.1096);
  box-shadow: none;
  border: none;
  background: transparent;
  font-size: vhFn(3.73);
}

.textCaption--mijnMelkAdultOnboarding-slide2-text2 {
  position: absolute;
  top: vhFn(31.60);
  left: vwFn(75.2290); 
  width: vwFn(14);
  box-shadow: none;
  border: none;
  background: transparent;
  font-size: vhFn(3.73);

}

.textCaption--mijnMelkAdultOnboarding-slide2-text3 {
  position: absolute;
  top: vhFn(49.077);
  left: vwFn(47.109); 
  width: vwFn(14);
  box-shadow: none;
  border: none;
  background: transparent; 
  font-size: vhFn(3.73);

}

.textCaption--mijnMelkAdultOnboarding-slide2-text4 {
  position: absolute;
  left: vwFn(75.2290); 
  top: vhFn(68);
  width: vwFn(17);
  box-shadow: none;
  border: none;
  background: transparent; 
  font-size: vhFn(3.73);

}
.mijnMelkAdultOnboarding-slide1--fromCowToBus {
  position: absolute;
  left: vwFn(62.5837);
  top: vhFn(20.533);
  width: vwFn(8.374);
  height: vhFn(62.93);
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/onboarding/adult/slide2/fromCowToBus.svg); 
  background-size: 100% 100%; 
  background-repeat: no-repeat;
} 
.mijnMelkAdultOnboarding-slide1--leftCow {
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/onboarding/adult/slide2/leftCow.svg);  
  width: vwFn(12.31527); 
  height: vhFn(28.95); 
  top: vhFn(23.73); 
  left: vwFn(6.1600); 
  position: absolute;
  background-size: 100% 100%; 
  background-repeat: no-repeat;
} 
.mijnMelkAdultOnboarding-slide1--grain {
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/onboarding/adult/slide2/grain.svg);  
  width: vwFn(5.7881); 
  height: vhFn(14.66); 
  top: vhFn(65.741); 
  left: vwFn(9.431); 
  position: absolute;
  background-size: 100% 100%; 
  background-repeat: no-repeat;
} 
.mijnMelkAdultOnboarding-slide1--lyingCow {
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/onboarding/adult/slide2/holsteinLying.svg);  
  width: vwFn(12.3152); 
  height: vhFn(20.266); 
  top: vhFn(73.08); 
  left: vwFn(44.7179); 
  position: absolute;
  background-size: 100% 100%; 
  background-repeat: no-repeat;
}