@use "../../styles/variables.scss" as *;

.search-wrapper {
  margin: 0px 0 10px 0px;
}

.search-container {
  display: inline-block;
  margin-top: 19px;
  width: $search-input-width;
  height: $search-input-height;
  line-height: 39px;
  color: $color-LelyWhite;
  font-weight: $font-regular;
  font-size: $search-input-font-size;
  text-align: left;

  input[type="search"] {
    outline: none;
    height: $search-input-height;
    width: $search-input-width;
    padding: 0 19px;
    font-weight: $font-regular;
    border: 1px solid $color-GrayDairy;
    box-shadow: inset 0 2px 5px 0 rgba(222, 219, 214, 0.4);
    background-color: $color-LelyWhite;
    margin: 0;
    color: $color-RecommendedTextColor;
    border-radius: $search-input-border-radius;
  }

  .btn-search {
    position: absolute;
    right: 0;
    top: 0;
    border-radius: 0 $search-input-border-radius $search-input-border-radius 0;
    width: $search-input-height;
    height: $search-input-height;
    padding: 0;
    text-align: center;
    z-index: 1;
    background-color: $color-LelyRed;
    border: $color-search-border;
    color: $color-LelyWhite;
    text-decoration: none;
    font-size: 18px;
  }
}
.search-error-message {
  text-align: center;
  flex-wrap: wrap;
  display: flex;
  max-width: 330px;
  font-size: 12px;
  padding: 0px 10px;
  color: #f44336;
  font-weight: 500;
}
