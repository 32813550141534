@use "../../../common/styles/variables.scss" as *;

.CowFirstMilkingSecondSlide {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  width: vwFn(100);
  height: vhFn(100);
}

.textCaption--CowFirstMilkingSecondSlide {
  position: absolute;
  height: vhFn(19.9);
  width: vwFn(30.79);
  left: vwFn(20.5);
  top: vhFn(40.312);
  padding: vhFn(2.5) vwFn(1.5);
  flex-wrap: nowrap;
  align-items: center;
}

.CowFirstMilkingSecondSlide--text {
  width: vwFn(27.9);
  height: vhFn(12.8);
  left: vwFn(1.5);
  top: vhFn(3.2);
  font-size: vhFn(4);
  line-height: vhFn(6.5);
  font-weight: 400;
}
