@use "../../common/styles/variables.scss" as *;

// .cowPopup {
//   position: absolute;
//   top: vhFn(22.4);
//   left: vwFn(10.66);
//   background-color: $color-LelyWhite;
//   width: vwFn(67.73);
//   min-height: vhFn(50.08);
//   border-left: vwFn(0.12) solid $color-GrayDairyLight;
//   border-right: vwFn(0.12) solid $color-GrayDairyLight;
//   border-top: vhFn(0.27) solid $color-GrayDairyLight;
//   box-sizing: border-box;
//   box-shadow: 0px 2px 5px rgba(222, 219, 214, 0.4);
//   border-radius: vwFn(0.5) vwFn(0.5) vwFn(0.5) vwFn(0.5);
//   padding: vhFn(3.5) vwFn(2.5);
//   z-index: 1;
// }

// .cowPopup--title {
//   width: vwFn(43.1);
//   font-family: $secondaryFont;
//   font-weight: bold;
//   color: $color-LelyBlack;
//   font-size: vwFn(2.95);
//   line-height: vhFn(9.33);
//   letter-spacing: vwFn(0.3);
//   position: static;
//   height: vhFn(9.33);
//   margin: 0;
//   //top: vhFn(4);
//   //margin: vwFn(2.5) 0;
// }

.labelValue--label {
  font-size: vwFn(1.72);
  line-height: vhFn(6.4);
  font-weight: bold;
  width: vwFn(20);
  padding-bottom: vhFn(1);
}

.labelValue--value {
  font-size: vwFn(1.72);
  line-height: vhFn(6.4);
  font-weight: normal;
}

.cowPopup--cow {
  position: absolute;
  top: vhFn(0);
  left: vwFn(31);
  width: vwFn(35);
  height: vhFn(40);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.cowPopup--btnCallToAction {
  background-color: $color-LelyRed;
  background-repeat: no-repeat;
  border-radius: vwFn(0.5) vwFn(0.5) vwFn(0.5) vwFn(0.5);
  border-left: vwFn(0.12) solid $color-GrayDairyLight;
  border-right: vwFn(0.12) solid $color-GrayDairyLight;
  border-top: vhFn(0.27) solid $color-GrayDairyLight;
  border-bottom: vhFn(0.27) solid $color-GrayDairyLight;
  background-size: 100% 100%;
  min-width: vwFn(19.889);
  min-height: vhFn(10.66);
  font-size: vwFn(1.5);
  color: $color-LelyWhite;
  top: vhFn(3.86);
  line-height: vhFn(4.266);
}

// .cowPopup--btnClosePopup {
//   position: absolute;
//   top: vhFn(-2);
//   left: vwFn(40);
//   background-image: url(../../resources/assets/themes/lely/16x9/ui/popup/close-popup-button.svg);
//   background-repeat: no-repeat;
//   background-size: 100% 100%;
//   width: vwFn(8.5);
//   height: vhFn(11.5);
// }

.cowPopup--btnCallToAction.is-disabled {
  opacity: 0.2;
  //background-color: transparent;
}

.cowPopup--cow {
  background-size: 100% 100%;
  width: vhFn(73.35);
  height: vhFn(73.35);
  left: vwFn(50) !important;
  //top: vhFn(-12);
  z-index: 2;
}

.cowPopup--cow .cowPopup--cow--jersey-brown {
  background-size: 100% 100%;
  width: vhFn(73.35);
  height: vhFn(73.35);
  left: vwFn(50);
  top: vhFn(-10);
}

// .cowPopup--chosen-name-party-hat {
//   background-image: url(../../resources/assets/themes/lely/16x9/popups/cow/namePollPartyHat.svg);
//   background-repeat: no-repeat;
//   background-size: 100% 100%;
//   width: vwFn(7.05788);
//   height: vhFn(21.45);
//   position: absolute;
//   left: vwFn(65);
//   top: vhFn(-10);
//   z-index: 10;
// }

.cowPopup--chosen-name-party-hat {
  background-image: url(../../resources/assets/themes/lely/16x9/popups/cow/namePollPartyHat-2.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: vhFn(19.15788);
  height: vhFn(25.54);
  position: absolute;
  left: vwFn(64.5);
  top: vhFn(-11);
  z-index: 10;
}
.theme-adult .cowPopup--chosen-name-party-hat {
  background-image: url(../../resources/assets/themes/lely/16x9/popups/cow/namePollPartyHat-adults.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: vhFn(19.15788);
  height: vhFn(25.54);
  position: absolute;
  left: vwFn(70);
  top: vhFn(-9);
  z-index: 999;
}
@media only screen and (orientation: portrait) {
  .theme-adult .cowPopup--chosen-name-party-hat {
    left: vwFn(71);
    top: vhFn(-8);
    width: vhFn(18.15788);
  }
  .cowPopup--chosen-name-party-hat {
    left: vwFn(67);
    top: vhFn(-11);
    width: vhFn(18.15788);
  }
}

@media only screen and (max-device-width: 600px) and (orientation: landscape) {
  .theme-adult .cowPopup--chosen-name-party-hat {
    left: vwFn(68);
  }
  .cowPopup--chosen-name-party-hat {
    left: vwFn(63);
  }
}

.cowPopup--chosen-name-party-flute {
  background-image: url(../../resources/assets/themes/lely/16x9/popups/cow/namePollPartyFlute.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: vhFn(16.3522);
  height: vhFn(17.19);
  position: absolute;
  left: vwFn(55);
  top: vhFn(52);
  z-index: 10;
}

.theme-adult .cowPopup--chosen-name-party-flute {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowhistory/background-layers/firstYear/partyFlutes/partyFlute_2.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: vhFn(20.3522);
  height: vhFn(15.19);
  position: absolute;
  left: vwFn(62);
  top: vhFn(45);
  z-index: 999;
  animation: none !important;
}

.cowPopup--secondBtnCallToAction {
  background-color: $color-LelyRed;
  background-repeat: no-repeat;
  border-radius: vwFn(0.5) vwFn(0.5) vwFn(0.5) vwFn(0.5);
  border-left: vwFn(0.12) solid $color-GrayDairyLight;
  border-right: vwFn(0.12) solid $color-GrayDairyLight;
  border-top: vhFn(0.27) solid $color-GrayDairyLight;
  border-bottom: vhFn(0.27) solid $color-GrayDairyLight;
  background-size: 100% 100%;
  min-width: vwFn(19.889);
  min-height: vhFn(10.66);
  font-size: vwFn(1.5);
  color: $color-LelyWhite;
  top: vhFn(3.86);
  line-height: vhFn(4.266);
}
#mouthOpen {
  animation: spinnnn 2s ease-in infinite;
}

#mouthMove {
  animation: moveMouth 2s ease-in-out infinite;
}

@keyframes spinnnn {
  0% {
    transform: translateY(1%);
  }

  50% {
    transform: translateY(0%) rotate(1.5deg);
  }

  100% {
    transform: translateY(1%);
  }
}

@keyframes moveMouth {
  0% {
    transform: translateY(0%);
  }

  50% {
    transform: translateY(0.5%) scaleY(1.001) rotate(1.5deg);
  }

  100% {
    transform: translateY(0%);
  }
}

.cowPopup--hasNameBeenSelected .cowPopup--title {
  // position: relative;
  // top: 30px;
}

.cowPopup--namePolling .cowPopup--btnClosePopup {
  top: vhFn(-12.85);
}
.cowPopup--buttons {
  //margin-top: vhFn(5);
  display: inline-flex;
  gap: vwFn(3);
}
.theme-adult .cowPopup .labelValue--label {
  padding-bottom: vhFn(2);
  width: vwFn(16);
}
.theme-adult .cowPopup--buttons {
  margin-top: vhFn(0);
  margin-left: vwFn(5);
}
.cowPopup-head-adults {
  position: absolute;
  //top: vhFn(-8);
  background-repeat: no-repeat;
  width: vhFn(71.35);
  height: vhFn(77.05);
  background-size: 100% 100%;
  left: vwFn(55.5);
  background-image: url(../../resources/assets/themes/lely/16x9/actors/cow/popup/cow-list-popup-head-adults.svg);
  z-index: 101;
}
.theme-adult .labelValue--label {
  margin-left: vwFn(1.25) !important;
}
