@use "../../common/styles/variables.scss" as *;

.UnavailablePage {
    background-image: url(../../resources/assets/themes/lely/16x9/screens/unavailablePage/unavailable-page-background.svg);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: absolute;
    width: vwFn(100);
    /*  Fallback for browsers that do not support Custom Properties */
    height: vhFn(100);
}
.UnavailavlePage--fourOfour--message {
  text-align: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  top: vhFn(5);
  position: absolute;
  left: vwFn(45);
  font-weight: 700;
  font-size: vhFn(19.2);
  font-family: $secondaryFont;
}
  
.UnavailavlePage--message {
  text-align: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  top: vhFn(44);
  position: absolute;
  left: vwFn(45);
  font-size: vhFn(3.73);
}

.UnavailavlePage--second--message {
  text-align: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  top: vhFn(50);
  position: absolute;
  left: vwFn(30);
  font-size: vhFn(3.73);
}

.unavailablePage--homepage--btnCallToAction {
  background-color: $color-LelyRed;
  background-repeat: no-repeat;
  border-radius: vwFn(0.5) vwFn(0.5) vwFn(0.5) vwFn(0.5);
  background-size: 100% 100%;
  //width: vwFn(19.889);
  height: vhFn(10.66);
  font-size: vwFn(1.5);
  color: $color-LelyWhite;
  top: vhFn(3.86);
  line-height: vhFn(5);
  min-width: vwFn(0.18);
  font-size: vwFn(1.5);
  color: $color-LelyWhite;
  padding: vhFn(2) vwFn(2);
  min-width: vwFn(0.18);
  display: inline-block;
  text-decoration: none;
}

.unavailablePage--contactUs--btnCallToAction {
  background-color: #ffffff;
  background-repeat: no-repeat;
  border-radius: vwFn(0.5) vwFn(0.5) vwFn(0.5) vwFn(0.5);
  border-left: vwFn(0.12) solid $color-GrayDairyLight;
  border-right: vwFn(0.12) solid $color-GrayDairyLight;
  border-top: vhFn(0.27) solid $color-GrayDairyLight;
  border-bottom: vhFn(0.27) solid $color-GrayDairyLight;
  border-color: #CFCBC3;
  background-size: 100% 100%;
  min-width: vwFn(0.18);
  height: vhFn(10.66);
  font-size: vwFn(1.5);
  color: #121212;
  top: vhFn(3.99);
  margin-left: vwFn(2);
  line-height: vhFn(5);
  display: inline-block;
  padding: vhFn(2);
  text-decoration: none;
}

.unavailablePage--buttons{
  text-align: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  top: vhFn(63.86);
  position: relative;
  padding: 0;
}

@media only screen and (orientation: portrait) {
  .UnavailablePage {
    width: 100%;
    height: vhFn(50);
    top: vhFn(50);
  }
  .UnavailavlePage--fourOfour--message {
    font-size: vhFn(12);
    margin:auto;
    left: vwFn(95)
  }
  .UnavailavlePage--message  {
    top: vhFn(15);
    left: vwFn(90);

  }
  .UnavailavlePage--second--message {
    top: vhFn(20);
    left: vwFn(36);
    font-size: vhFn(3);
  }
  .unavailablePage--buttons {
    top: vhFn(30);
  }
  .unavailablePage--contactUs--btnCallToAction, .unavailablePage--homepage--btnCallToAction {
    background-repeat: no-repeat;
    border-radius: vwFn(1);
    border-left: vwFn(0.12) solid $color-GrayDairyLight;
    border-right: vwFn(0.12) solid $color-GrayDairyLight;
    border-top: vhFn(0.27) solid $color-GrayDairyLight;
    border-bottom: vhFn(0.27) solid $color-GrayDairyLight;
    background-size: 100% 100%;
    min-width: vwFn(0.18);
    line-height: vhFn(5);
    height: vhFn(6.3);
    font-size: vhFn(2);
    line-height: vhFn(1.8);
  }
}
