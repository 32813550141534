@use "../../common/styles/variables.scss" as *;

.thunderstorm--overlay {
  position: absolute;
  width: vwFn(100);
  height: 100%;
  background-color: #ffffff;
  animation-iteration-count: 1;
  animation-name: thunderstrike;
  animation-duration: 1s;
  animation-direction: alternate;
  opacity: 0;
}

.thunderstorm--container {
  width: vwFn(100);
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.thunder {
  position: absolute;
  background-repeat: no-repeat;
  background-size: contain;
  width: vwFn(10);
  height: 80%;
}

.thunder-1 {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/weather/lightnings/background_weather_lightning_01.svg);
}

.thunder-2 {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/weather/lightnings/background_weather_lightning_02.svg);
}

.thunder-3 {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/weather/lightnings/background_weather_lightning_03.svg);
}

.thunder-4 {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/weather/lightnings/background_weather_lightning_04.svg);
}

.thunder-5 {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/weather/lightnings/background_weather_lightning_05.svg);
}

.thunder-6 {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/weather/lightnings/background_weather_lightning_06.svg);
}

@keyframes thunderstrike {
  0% {
    opacity: 0;
  }

  30% {
    opacity: 0.5;
  }

  60% {
    opacity: 0;
  }

  100% {
    opacity: 0.8;
  }
}
