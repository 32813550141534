@import "../../styles/variables.scss";

.astronautCowDropdownMenu .MuiSelect-selectMenu {
  padding-left: vhFn(6) !important;
  padding-right: vhFn(6) !important;
  height: vhFn(8) !important;
  vertical-align: middle !important;
  line-height: vhFn(8) !important;
  margin-top: 0 !important;
  position: relative !important;
  top: vhFn(3.5) !important;
  background-color: black !important;
  border-radius: 4px;
}

.astronautCowDropdownMenu.dropdown-text-input--textInput {
  font-family: "Ubuntu Mono";
  box-shadow: none !important;
  background-color: black !important;
  border: none !important;
}

.astronautCowDropdownMenu .MuiInputBase-root {
  font-family: "Ubuntu Mono" !important;
  background-color: black !important;
  box-shadow: inset 0px 2px 5px rgba(222, 219, 214, 0.4);
  border-radius: 4px;
  padding: 2px;
  height: vhFn(6.4);
  font-size: vhFn(3.733) !important;
  color: #43bf66;
  background-color: black !important;
}

.astronautCowDropdownMenu .MuiSelect-icon {
  color: #43bf66;
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/astronautLiveDataStory/astronautLiveDataStorySelectionTriangle.svg);
  width: vwFn(0.85);
  height: vhFn(1.5366);
  margin-left: vhFn(2.5);
  margin-right: vhFn(3.5);
  margin-top: vhFn(4) !important;
  z-index: 2;
  display: block;
}

.dropdown-text-input--udder {
  font-family: "Ubuntu Mono" !important;
  background-color: #000 !important;
  color: #43bf66 !important;
}

.dropdown-text-input--udder.MuiList-root {
  color: #43bf66 !important;
  background-color: #000 !important;
  position: fixed;
}

.dropdown-text-input--udder.Mui-selected:hover,
.dropdown-text-input--udder:hover {
  background-color: #333 !important;
  color: #43bf66 !important;
}

.astronautCowDropdownMenu.MuiPaper-rounded {
  width: vwFn(2) !important;
}
