@use "../../../common/styles/variables.scss" as *;

.lastSlideRobotStory--poleSharing {
  position: absolute;
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/vectorstory/sharePlateImage.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: vwFn(1.635);
  height: vhFn(68.668);
  left: vwFn(92.4);
  top: vhFn(14.5133);
  z-index: 6;
}
.lastSlideRobotStory--poleSharing--share {
  position: absolute;
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/vectorstory/sharePlateImage.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: vwFn(7.4815);
  height: vhFn(6.6666);
  left: vwFn(89.278);
  top: vhFn(15.285);
  padding: vhFn(0.25) vwFn(1);
  z-index: 7;
}
.lastSlideRobotStory--poleSharing--share--text {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  font-size: vhFn(3.733);
  line-height: vhFn(6.4);
  font-family: $primaryFont;
  font-weight: 400;
  text-align: center;
  color: #ffffff;
  z-index: 7;
}
.lastSlideRobotStory--poleSharing--facebook {
  position: absolute;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: vwFn(11.699);
  height: vhFn(22.13);
  left: vwFn(87.278);
  top: vhFn(21.285);
  padding: vhFn(0.25) vwFn(1);
  z-index: 7;
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/vectorstory/facebookPlate.svg);
}
.lastSlideRobotStory--poleSharing--twitter {
  position: absolute;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: vwFn(11.699);
  height: vhFn(22.13);
  left: vwFn(87.278);
  top: vhFn(40.485);
  padding: vhFn(0.25) vwFn(1);
  z-index: 7;
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/vectorstory/twitterPlate.svg);
}
.lastSlideRobotStory--poleSharing--linkedin {
  position: absolute;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: vwFn(10.699);
  height: vhFn(18.13);
  left: vwFn(87.678);
  top: vhFn(60.185);
  padding: vhFn(0.25) vwFn(1);
  z-index: 7;
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/vectorstory/linkedinPlate.svg);
}
.lastSlideRobotStory--poleSharing--facebook .btn-social {
  position: relative;
  width: vwFn(11.699);
  height: vhFn(20.13);
  left: vwFn(-2vw);
  opacity: 0;
}
.lastSlideRobotStory--poleSharing--twitter .btn-social {
  position: relative;
  width: vwFn(11.699);
  height: vhFn(20.13);
  left: vwFn(-2vw);
  opacity: 0;
}
.lastSlideRobotStory--poleSharing--linkedin .btn-social {
  position: relative;
  width: vwFn(11.699);
  height: vhFn(20.13);
  left: vwFn(-2vw);
  opacity: 0;
}
.lastSlideRobotStory--pole {
  position: absolute;
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowdaily/graphics/endingSlidePole.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: vwFn(2.311);
  height: vhFn(44.608);
  left: vwFn(54.24);
  top: vhFn(36.5133);
  z-index: 6;
}
.lastSlideRobotStory--trees {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/background-trees/trees3.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  top: vhFn(45.5);
  left: vwFn(2.365);
  width: vwFn(38.834);
  height: vhFn(35.48);
}
.vectorStoryLastSlide--sign {
  position: absolute;
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/vectorstory/vectorlastslidesign.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  min-height: vhFn(17.333);
  width: vwFn(28);
  left: vwFn(41);
  top: vhFn(46.018);
  //padding: vhFn(5) vwFn(6);
  z-index: 7;
}
.lastSlideRobotStory--moreInfo {
  position: absolute;
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/vectorstory/moreInfoPlate.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: vwFn(12.684);
  height: vhFn(12.533);
  left: vwFn(49.24);
  top: vhFn(32.285);
  padding: vhFn(5) vwFn(1);
  z-index: 7;
}
.lastSlideRobotStory--moreInfo--text {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  font-size: vhFn(3.733);
  line-height: vhFn(6.4);
  font-family: $primaryFont;
  font-weight: 400;
  text-align: center;
  color: #ffffff;
  z-index: 7;
}
.vectorStoryLastSlide--text {
  width: 100%;
  height: 100%;
  white-space: nowrap;
  font-size: vhFn(3.733);
  line-height: vhFn(6.4);
  font-family: $primaryFont;
  font-weight: 400;
  text-align: center;
  color: #ffffff;
  display: inline-block;
  padding: vhFn(4) vwFn(3);
  top: vhFn(0.4);
  left: vwFn(0.5);
  position: relative;
}

@media only screen and (orientation: portrait) {
.vectorStoryLastSlide--text {
  top: vhFn(0.6);
}
.vectorStoryLastSlide--sign {
  width: vwFn(34);
  left: vwFn(38);
}
.lastSlideRobotStory--moreInfo {
  width: vwFn(15);
}
}
.lastSlideRobotStory--house--style1 {
  position: absolute;
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/vectorstory/dutchBarnEndingImage.svg);
  background-repeat: no-repeat;
  width: vwFn(45.448);
  height: vhFn(39.488);
  left: vwFn(54.969);
  top: vhFn(41.51);
  z-index: 5;
}
.lastSlideRobotStory--house--style3 {
  position: absolute;
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/vectorstory/americanBarnEndingImage.svg);
  background-repeat: no-repeat;
  width: vwFn(45.448);
  height: vhFn(39.488);
  left: vwFn(54.969);
  top: vhFn(41.51);
  z-index: 5;
}
.lastSlideRobotStory--house--style2 {
  position: absolute;
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/vectorstory/dutchBarnSecondTypeEndingImage.svg);
  background-repeat: no-repeat;
  width: vwFn(45.448);
  height: vhFn(39.488);
  left: vwFn(54.969);
  top: vhFn(41.51);
  z-index: 5;
}
.vectorStoryLastSlide--aboutTheFarm {
  position: absolute;
  font-size: vhFn(4.8);
  line-height: vhFn(6.4);
  font-family: $secondaryFont;
  font-weight: 700;
  text-align: center;
  letter-spacing: vwFn(0.1);
  left: vwFn(30.826);
  top: vhFn(64.28533);
  max-width: vwFn(20);
}

.vectorStoryLastSlide--arrow {
  position: relative;
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/vectorstory/robotstoryarrow.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: vwFn(4.926);
  height: vhFn(5.626);
  left: vwFn(52.45);
  top: vhFn(63.283);
}

.mijnMelkLastSlide--sign {
  display: none;
}
