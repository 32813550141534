@use "../../common/styles/variables.scss" as *;

.farmerStoryDynamicSlide {
  position: absolute;
  top: 0;
  background-size: 100% 100%;
  width: vwFn(100);
  height: vhFn(100);
  //background-color: $color-GrayDairyLighter;
}

.farmerStoryDynamicSlide--textCaption {
  position: absolute;
  top: vhFn(20.13);
  right: vwFn(2.46);
  padding: vhFn(3.2) vwFn(1.48);
  z-index: 3; 
  width: vwFn(52.25);
}

.farmerStoryDynamicSlide--title {
  font-family: $secondaryFont;
  font-style: $font-DefaultFontStyle;
  font-weight: $font-bold;
  font-size: vhFn(4.8);
  line-height: vhFn(6.4);
  color: $color-gray-popup;
  margin-bottom: vhFn(2.67);
}

.farmerStoryDynamicSlide--description {
  font-family: $primaryFont;
  font-style: $font-DefaultFontStyle;
  font-weight: $font-regular;
  font-size: vhFn(3.73);
  line-height: vhFn(6.4);
  color: $color-gray-popup;
  word-break: normal;
}

.farmerStoryDynamicSlide--imgCaption--0,
.farmerStoryDynamicSlide--imgCaption--1 {
  position: absolute;
  width: vwFn(29.85);
  height: vhFn(78.93);
  background-color: $color-LelyWhite;
  box-shadow: 0px vwFn(0.25) vwFn(0.7) rgba(18, 18, 18, 0.2);
  padding: vhFn(3.2) vwFn(1.48);
  background-size: cover;
}

.farmerStoryDynamicSlide--imgCaption--0 {
  transform: rotate(15deg);
  left: vwFn(6.6);
  bottom: vhFn(8);
}

.farmerStoryDynamicSlide--imgCaption--1 {
  transform: rotate(-11.35deg);
  left: vwFn(11.33);
  bottom: vhFn(14.67);
}

.farmerStoryDynamicSlide--imgContainer {
  width: 100%;
  height: vhFn(57.77);
  border-width: vhFn(0.53) vwFn(0.246);
  border-style: solid;
  border-color: $color-GrayDairyDarker;
  object-fit: cover;
}

.farmerStoryDynamicSlide--imgText {
  font-family: $secondaryFont;
  font-style: $font-DefaultFontStyle;
  font-weight: $font-regular;
  font-size: vhFn(3.73);
  line-height: vhFn(6.4);
  text-align: center;
}
.theme-adult .farmerStoryDynamicSlide--imgCaption--0,.theme-adult .farmerStoryDynamicSlide--imgCaption--1 {
  border-color: #000000; 
  border: solid vhFn(1);
} 
.theme-adult .farmerStoryDynamicSlide--imgContainer {
  border: none;
} 