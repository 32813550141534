.sustainabilityStoryLastSlide {
    position: absolute;
    background-size: 101% 100%;
    width: vwFn(101);
    left: vwFn(-0.1);
    height: vhFn(100);
}

.sustainabilityStoryLastSlide .vectorStoryLastSlide .mijnMelkLastSlide--sign {
    display: none !important;
}