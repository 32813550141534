@use "../../common/styles/variables.scss" as *;

.farmerStoryEndingPoint {
  position: absolute;
  top: 0;
  background-size: 100% 100%;
  width: vwFn(100);
  height: vhFn(100);
  //background-color: $color-GrayDairyLighter;
}

.farmerStoryEndingPoint--background {
  position: absolute;
  bottom: vhFn(24.27);
  right: 0;
  width: vwFn(84.98);
  height: vhFn(54.27);
  //background-image: url(../../resources/assets/themes/lely/16x9/stories/farmer/ending-point-background.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  z-index: 1;
}

.farmerStoryEndingPoint--backButton {
  position: absolute;
  top: vhFn(51.67);
  left: vwFn(66.46);
  min-width: vwFn(22.66);
  max-width: vwFn(30);
  min-height: vhFn(8);
  background-image: url(../../resources/assets/themes/lely/16x9/stories/farmer/ending-point-button.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  z-index: 2;
  color: $color-LelyWhite;
  font-size: vhFn(3.73);
  line-height: vhFn(4.6);
  text-align: center;
  padding: vhFn(3.5) vwFn(4);
}

.farmerStoryEndingPoint--goToTheFarm {
  width: 100%;
  font-size: vhFn(3.733);
  line-height: vhFn(6.4);
  font-family: $primaryFont;
  font-weight: 400;
  color: #ffffff;
}

.farmerStoryEndingPoint--backButton--text {
  width: 100%;
  bottom: vhFn(35);
  left: vwFn(40);
  word-break: break-word;
  font-family: $secondaryFont;
  font-style: $font-DefaultFontStyle;
  font-weight: $font-bold;
  font-size: vhFn(4.8);
  line-height: vhFn(6.4);
  color: $color-gray-popup;
  text-align: center;
  z-index: 2;
}

.farmerStoryEndingPoint--website {
  position: absolute;
  top: vhFn(31);
  right: vwFn(52);
  font-family: $primaryFont;
  font-style: $font-DefaultFontStyle;
  font-weight: $font-regular;
  font-size: vhFn(3.73);
  line-height: vhFn(6.4);
  color: $color-gray-popup;
  text-decoration: underline;
  z-index: 2;
}
.farmerStoryEndingPoint .mijnMelkLastSlide--sign {
  display: none !important;
} 
.theme-adult .farmerStory--signToTheWebsite {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowhistoryForAdult/elements/toMijnMelkWebSiteButton.svg); 
}
.farmerStory--signToTheWebsite {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/lelystory/lelyRedirectionButton.svg);
  //margin: auto;
  position: absolute;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: vwFn(25.12);
  min-height: vhFn(13.333);
  left: vwFn(43);
  top: vhFn(62.018);
  z-index: 7;
} 
.farmerStory--text {
    font-size: vhFn(3.733);
    line-height: vhFn(6.4);
    //font-family: $primaryFont;
    font-weight: 400;
    text-align: center;
    color:#766E68;
    display:inline-flex;
    //padding-top: vhFn(4);
    //font-family: $primaryFont;
    font-weight: normal;
    font-size: vhFn(3.73333); 
    margin: vhFn(2.5) vwFn(3);
} 
.theme-adult .farmerStory--text {
  color: #ff684d;
}