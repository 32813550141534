@use "../../common/styles/variables.scss" as *;

.slidesContainer {
  position: relative;
  transition: 0.5s ease-in;
}

.is-swipe-in-progress .slidesContainer {
  transition: none;
}

.is-swipe-in-progress .background-layer {
  transition: none;
}

// transitions logic goes below:
.are-slides-container-transitions-disabled .slidesContainer {
  transition: none;
}

.are-slides-container-transitions-disabled .parallax-cow-history {
}

.are-slides-container-transitions-disabled .background-layer--sky {
  animation: none !important;
}

.are-slides-container-transitions-disabled .slide.is-active {
  z-index: -100 !important;
  animation: $transitionAnimationDurationInSeconds linear
    $transitionAnimationDelayInSeconds 1 forwards fadeScaleOutSlideAnimation !important;
}

.are-slides-container-transitions-disabled .sky--container {
  animation: none;
}

.are-slides-container-transitions-disabled .background-layer--slide {
  opacity: 0;
  animation: $transitionAnimationDurationInSeconds linear
    $transitionAnimationDelayInSeconds 1 forwards fadeScaleInSlideAnimation !important;
}

.is-transition-to-0-in-progress.flow-container-Cow-slide-CowHistoryRelativesPage
  .cow--fixed,
.is-transition-to-0-in-progress.flow-container-Cow-slide-CowHistoryIntroPage
  .cow--fixed,
.is-transition-to-0-in-progress.flow-container-Cow-slide-CowDailyIntroPage
  .cow--fixed,
.is-transition-to-1-in-progress.flow-container-Cow-slide-CowHistoryRelativesPage
  .cow--fixed,
.is-transition-to-1-in-progress.flow-container-Cow-slide-CowHistoryIntroPage
  .cow--fixed,
.is-transition-to-1-in-progress.flow-container-Cow-slide-CowDailyIntroPage
  .cow--fixed,
.is-transition-to-2-in-progress.flow-container-Cow-slide-CowHistoryRelativesPage
  .cow--fixed,
.is-transition-to-2-in-progress.flow-container-Cow-slide-CowHistoryIntroPage
  .cow--fixed,
.is-transition-to-2-in-progress.flow-container-Cow-slide-CowDailyIntroPage
  .cow--fixed,
.is-transition-to-3-in-progress.flow-container-Cow-slide-CowHistoryRelativesPage
  .cow--fixed,
.is-transition-to-3-in-progress.flow-container-Cow-slide-CowHistoryIntroPage
  .cow--fixed,
.is-transition-to-3-in-progress.flow-container-Cow-slide-CowDailyIntroPage
  .cow--fixed,
.is-transition-to-4-in-progress.flow-container-Cow-slide-CowHistoryRelativesPage
  .cow--fixed,
.is-transition-to-4-in-progress.flow-container-Cow-slide-CowHistoryIntroPage
  .cow--fixed,
.is-transition-to-4-in-progress.flow-container-Cow-slide-CowDailyIntroPage
  .cow--fixed,
.is-transition-to-5-in-progress.flow-container-Cow-slide-CowHistoryRelativesPage
  .cow--fixed,
.is-transition-to-5-in-progress.flow-container-Cow-slide-CowHistoryIntroPage
  .cow--fixed,
.is-transition-to-5-in-progress.flow-container-Cow-slide-CowDailyIntroPage
  .cow--fixed,
.is-transition-to-6-in-progress.flow-container-Cow-slide-CowHistoryRelativesPage
  .cow--fixed,
.is-transition-to-6-in-progress.flow-container-Cow-slide-CowHistoryIntroPage
  .cow--fixed,
.is-transition-to-6-in-progress.flow-container-Cow-slide-CowDailyIntroPage
  .cow--fixed,
.is-transition-to-7-in-progress.flow-container-Cow-slide-CowHistoryRelativesPage
  .cow--fixed,
.is-transition-to-7-in-progress.flow-container-Cow-slide-CowHistoryIntroPage
  .cow--fixed,
.is-transition-to-7-in-progress.flow-container-Cow-slide-CowDailyIntroPage
  .cow--fixed,
.is-transition-to-8-in-progress.flow-container-Cow-slide-CowHistoryRelativesPage
  .cow--fixed,
.is-transition-to-8-in-progress.flow-container-Cow-slide-CowHistoryIntroPage
  .cow--fixed,
.is-transition-to-8-in-progress.flow-container-Cow-slide-CowDailyIntroPage
  .cow--fixed,
.is-transition-to-9-in-progress.flow-container-Cow-slide-CowHistoryRelativesPage
  .cow--fixed,
.is-transition-to-9-in-progress.flow-container-Cow-slide-CowHistoryIntroPage
  .cow--fixed,
.is-transition-to-9-in-progress.flow-container-Cow-slide-CowDailyIntroPage
  .cow--fixed,
.is-transition-to-10-in-progress.flow-container-Cow-slide-CowHistoryRelativesPage
  .cow--fixed,
.is-transition-to-10-in-progress.flow-container-Cow-slide-CowHistoryIntroPage
  .cow--fixed,
.is-transition-to-10-in-progress.flow-container-Cow-slide-CowDailyIntroPage
  .cow--fixed,
.is-transition-to-11-in-progress.flow-container-Cow-slide-CowHistoryRelativesPage
  .cow--fixed,
.is-transition-to-11-in-progress.flow-container-Cow-slide-CowHistoryIntroPage
  .cow--fixed,
.is-transition-to-11-in-progress.flow-container-Cow-slide-CowDailyIntroPage
  .cow--fixed,
.is-transition-to-12-in-progress.flow-container-Cow-slide-CowHistoryRelativesPage
  .cow--fixed,
.is-transition-to-12-in-progress.flow-container-Cow-slide-CowHistoryIntroPage
  .cow--fixed,
.is-transition-to-12-in-progress.flow-container-Cow-slide-CowDailyIntroPage
  .cow--fixed {
  opacity: 0;
  animation: $transitionAnimationDurationInSeconds linear
    $transitionAnimationDelayInSeconds 1 forwards fadeScaleInSlideAnimation !important;
}

.is-transition-to-0-in-progress .slide.is-active {
  left: 0 !important;
}

.is-transition-to-0-in-progress .slide-0 {
  opacity: 0;
  animation: $transitionAnimationDurationInSeconds linear
    $transitionAnimationDelayInSeconds 1 forwards fadeScaleInSlideAnimation;
}

.is-transition-to-1-in-progress .slide.is-active {
  left: vwFn(100) !important;
}

.is-transition-to-1-in-progress .slide-1 {
  opacity: 0;
  animation: $transitionAnimationDurationInSeconds linear
    $transitionAnimationDelayInSeconds 1 forwards fadeScaleInSlideAnimation;
}

.is-transition-to-2-in-progress .slide.is-active {
  left: vwFn(200) !important;
}

.is-transition-to-2-in-progress .slide-2 {
  opacity: 0;
  animation: $transitionAnimationDurationInSeconds linear
    $transitionAnimationDelayInSeconds 1 forwards fadeScaleInSlideAnimation;
}

.is-transition-to-3-in-progress .slide.is-active {
  left: vwFn(300) !important;
}

.is-transition-to-3-in-progress .slide-3 {
  opacity: 0;
  animation: $transitionAnimationDurationInSeconds linear
    $transitionAnimationDelayInSeconds 1 forwards fadeScaleInSlideAnimation;
}

.is-transition-to-4-in-progress .slide.is-active {
  left: vwFn(400) !important;
}

.is-transition-to-4-in-progress .slide-4 {
  opacity: 0;
  animation: $transitionAnimationDurationInSeconds linear
    $transitionAnimationDelayInSeconds 1 forwards fadeScaleInSlideAnimation;
}

.is-transition-to-5-in-progress .slide.is-active {
  left: vwFn(500) !important;
}

.is-transition-to-5-in-progress .slide-5 {
  opacity: 0;
  animation: $transitionAnimationDurationInSeconds linear
    $transitionAnimationDelayInSeconds 1 forwards fadeScaleInSlideAnimation;
}

.is-transition-to-6-in-progress .slide.is-active {
  left: vwFn(600) !important;
}

.is-transition-to-6-in-progress .slide-6 {
  opacity: 0;
  animation: $transitionAnimationDurationInSeconds linear
    $transitionAnimationDelayInSeconds 1 forwards fadeScaleInSlideAnimation;
}

.is-transition-to-7-in-progress .slide.is-active {
  left: vwFn(700) !important;
}

.is-transition-to-7-in-progress .slide-7 {
  opacity: 0;
  animation: $transitionAnimationDurationInSeconds linear
    $transitionAnimationDelayInSeconds 1 forwards fadeScaleInSlideAnimation;
}

.is-transition-to-8-in-progress .slide.is-active {
  left: vwFn(800) !important;
}

.is-transition-to-8-in-progress .slide-8 {
  opacity: 0;
  animation: $transitionAnimationDurationInSeconds linear
    $transitionAnimationDelayInSeconds 1 forwards fadeScaleInSlideAnimation;
}

.is-transition-to-9-in-progress .slide.is-active {
  left: vwFn(900) !important;
}

.is-transition-to-9-in-progress .slide-9 {
  opacity: 0;
  animation: $transitionAnimationDurationInSeconds linear
    $transitionAnimationDelayInSeconds 1 forwards fadeScaleInSlideAnimation;
}

.is-transition-to-10-in-progress .slide.is-active {
  left: vwFn(1000) !important;
}

.is-transition-to-10-in-progress .slide-10 {
  opacity: 0;
  animation: $transitionAnimationDurationInSeconds linear
    $transitionAnimationDelayInSeconds 1 forwards fadeScaleInSlideAnimation;
}

@keyframes fadeScaleInSlideAnimation {
  0% {
    opacity: 0;
    scale: 0;
  }

  100% {
    opacity: 1;
    scale: 1;
  }
}

@keyframes fadeScaleOutSlideAnimation {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
