@use "../../common/styles/variables.scss" as *;

.mijnMelkThirdSlide {
  position: absolute;
  background-size: 100% 100%;
  width: vwFn(80);
  height: vhFn(100);
  background-color: #f7f3f2;
  border: none;
}

.mijnMelkThirdSlide--textCaption {
  position: absolute;
  width: vwFn(55.418);
  min-height: vhFn(49.86);
  top: vhFn(16.992);
  left: vwFn(39.915);
  padding: vhFn(1) vwFn(1);
  z-index: 10;
  font-weight: 400;
}

.mijnMelkThirdSlide--title {
  font-family: $secondaryFont;
  font-style: $font-DefaultFontStyle;
  font-weight: $font-bold;
  font-size: vhFn(4.27);
  line-height: vhFn(4.8);
  letter-spacing: vwFn(0.1);
  color: $color-gray-popup;
}

.mijnMelkThirdSlide--text {
  font-family: $primaryFont;
  font-style: $font-DefaultFontStyle;
  font-size: vhFn(3.73);
  line-height: vhFn(6.4);
  color: $color-gray-popup;
  margin: vhFn(1.87) 0;
}

.mijnMelkThirdSlide--tree {
  position: absolute;
  background-size: 100% 100%;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/orbiterstory/orbitersixthslide.svg);
  width: vwFn(100);
  height: vhFn(124.61);
  left: vwFn(5);
  top: vhFn(-18.37);
  z-index: 5;
}

.mijnMelkThirdSlide--imgContainer {
  width: 100%;
  height: vhFn(57.77);
  border-width: vhFn(0.53) vwFn(0.246);
  border-style: solid;
  border-color: $color-GrayDairyDarker;
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/actors/mijnmelkactor/mijnmelkthirdslide.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-color: $color-GrayDairyDarker;
}

.mijnMelkThirdSlide--imgCaption {
  position: absolute;
  width: vwFn(29.85);
  height: vhFn(78.93);
  background-color: $color-LelyWhite;
  box-shadow: 0px vwFn(0.25) vwFn(0.7) rgba(18, 18, 18, 0.2);
  padding: vhFn(3.2) vwFn(1.48);
  transform: rotate(-15.18deg);
  left: vwFn(9.77);
  top: vhFn(5.992);
}


.theme-adult .mijnMelkThirdSlide--tree {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowDailyForAdult/backgrounds/eating/full_tree.png);
  width: vwFn(75);
  height: vhFn(118.133);
  left: vwFn(35.70);
}

.theme-adult .mijnMelkThirdSlide--imgCaption {
  border-color: #000000; 
  border: solid vhFn(1);
} 

.theme-adult .mijnMelkThirdSlide--title {
font-family: "Chelsea Market" !important;
font-weight: 400; 
line-height: vhFn(6.4);
font-size: vhFn(4.8);
}