@use "../../common/styles/variables.scss" as *;

.virtualFarmFooter {
  position: fixed;
  z-index: 999;
  -webkit-transform: translate3d(0, 0, 1px);
  transform: translate3d(0, 0, 1px);
  bottom: 0;
  width: vwFn(100);
  height: vhFn(20);
  pointer-events: none;
  display: none;
}

.virtualFarmFooter > div {
  background-size: 100% 100%;
}

.virtualFarmFooter--grassLeft {
  background-image: url(../../resources/assets/themes/adult/16x9/ui/virtual-farm-footer/grass-aligned-left.png);
  background-repeat: no-repeat;
  background-position: left bottom;
  pointer-events: none;
  flex-basis: vwFn(20);
}

.virtualFarmFooter--grassCenter {
  background-image: url(../../resources/assets/themes/adult/16x9/ui/virtual-farm-footer/grass-aligned-center.png);
  background-repeat: no-repeat;
  background-position: center bottom;
  pointer-events: none;
  flex-basis: vwFn(10);
}

.virtualFarmFooter--grassRight {
  background-repeat: no-repeat;
  background-image: url(../../resources/assets/themes/adult/16x9/ui/virtual-farm-footer/grass-aligned-right.png);
  background-position: right bottom;
  pointer-events: none;
  flex-basis: vwFn(20);
}

.theme-adult .virtualFarmFooter {
  display: flex;
  justify-content: space-between;
  pointer-events: none;
}
