@use "../../../../common/styles/variables.scss" as *;

.FinalOnboardingSlideKool {
  background-color: $color-GrayDairyLighter;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  width: vwFn(100);
  height: vhFn(100);
}

.FinalOnboardingSlideKool--background {
  position: absolute;
  bottom: vhFn(24);
  left: vhFn(15);
  width: vwFn(100);
  height: vhFn(62.5);
  background-image: url(../../../../resources/assets/themes/mijnmelk/16x9/onboarding/finalOnboardingSite/ending-point-screen.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.FinalOnboardingSlideKool--backButton {
  position: absolute;
  top: vhFn(55.67);
  right: vwFn(9.46);
  width: vwFn(22.66);
  height: vhFn(12);
  background-image: url(../../../../resources/assets/themes/mijnmelk/16x9/onboarding/finalOnboardingSite/ending-point-button.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  z-index: 2;
  color: $color-LelyWhite;
  font-size: vhFn(3.73);
  line-height: vhFn(4.6);
  text-align: center;
  padding-top: vhFn(3.5);
}

.FinalOnboardingSlideKool--revertButton {
  position: absolute;
  top: vhFn(40.67);
  right: vwFn(14.46);
  width: vwFn(27.21);
  height: vhFn(11.73);
  background-image: url(../../../../resources/assets/themes/mijnmelk/16x9/onboarding/finalOnboardingSite/ending-point-revert-button.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  z-index: 2;
  color: $color-LelyWhite;
  font-size: vhFn(3.73);
  line-height: vhFn(4.6);
  text-align: center;
  padding-top: vhFn(3);
}

.FinalOnboardingSlideKool--chooseDayButton {
  position: absolute;
  top: vhFn(27.67);
  right: vwFn(12.46);
  width: vwFn(22.66);
  height: vhFn(12);
  background-image: url(../../../../resources/assets/themes/mijnmelk/16x9/onboarding/finalOnboardingSite/ending-point-back-button.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  z-index: 2;
  color: $color-LelyWhite;
  font-size: vhFn(3.73);
  line-height: vhFn(4.6);
  text-align: center;
  padding-top: vhFn(3.5);
}

.FinalOnboardingSlideKool--backButton--text {
  position: absolute;
  width: vwFn(14.46);
  bottom: vhFn(35);
  left: vwFn(48.5);
  word-break: break-word;
  font-family: $secondaryFont;
  font-style: $font-DefaultFontStyle;
  font-weight: $font-bold;
  font-size: vhFn(4.8);
  line-height: vhFn(6.4);
  color: $color-gray-popup;
  text-align: center;
  z-index: 2;
}

.textCaption--FinalOnboardingSlideKool {
  position: absolute;
  min-width: vwFn(19.07);
  max-width: vwFn(22);
  height: vhFn(24.6);
  left: vwFn(9.31);
  top: vhFn(40.4);
  padding: vwFn(1.5) vwFn(1.5) vwFn(1.5) vwFn(1.5);
  z-index: 1;
}

.FinalOnboardingSlideKool--text {
  position: static;
  font-size: vwFn(1.72);
  line-height: vhFn(6.4);
  margin: 0px;
}

.FinalOnboardingSlideKool--farmer {
  position: absolute;
  left: vwFn(30.21);
  top: vhFn(34.56);
  width: vwFn(18.47);
  height: vhFn(45.54);
  background-image: url(../../../../resources/assets/themes/kaasfarm/16x9/onboarding/welcomeScreenKaas/farmer-image.svg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  z-index: 1;
}

.FinalOnboardingSlideKool--cheese {
  position: absolute;
  left: vwFn(20.93);
  bottom: vhFn(16.06);
  width: vwFn(9.852);
  height: vhFn(16);
  background-image: url(../../../../resources/assets/themes/kaasfarm/16x9/onboarding/welcomeScreenKaas/cheese-image.svg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  z-index: 1;
}

.FinalOnboardingSlideKool--cuttedCheese {
  position: absolute;
  left: vwFn(13.5);
  bottom: vhFn(9.884);
  width: vwFn(10.221);
  height: vhFn(19.072);
  background-image: url(../../../../resources/assets/themes/kaasfarm/16x9/onboarding/welcomeScreenKaas/cutted-cheese-image.svg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.FinalOnboardingSlideKool--chooseDayButton--text {
  position: absolute;
  width: vwFn(14.46);
  top: vhFn(25);
  left: vwFn(45.5);
  word-break: break-word;
  font-family: $secondaryFont;
  font-style: $font-DefaultFontStyle;
  font-weight: $font-bold;
  font-size: vhFn(4.8);
  line-height: vhFn(6.4);
  color: $color-gray-popup;
  text-align: center;
  z-index: 2;
}

.FinalOnboardingSlideKool--chooseDayButton--arrow {
  position: absolute;
  transform: rotate(-40deg);
  left: vwFn(61.92);
  top: vhFn(28.712);
  width: vwFn(3.2);
  height: vhFn(11.62);
  background-image: url(../../../../resources/assets/themes/lely/16x9/ui/arrows/arrow-left-curve-down.svg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.arrowFinalSlideKool {
  position: absolute;
  background-repeat: no-repeat;
  background-color: gray;
  background-size: 100% 100%;
  border: vhFn(0.2) solid $color-GrayDairyDarker;
  width: 100%;
  height: vhFn(0.25);
  top: vhFn(74.7);
  left: vwFn(0);
}

.is-onboarding-over .WelcomeScreenKool,
.is-onboarding-over .FarmerPresentationKool,
.is-onboarding-over .RobotsOnboardingKool,
.is-onboarding-over .CheeseFactoryScreen,
.is-onboarding-over .FinalOnboardingSlideKool {
  display: none;
}
