.multiLanguageTextInput .labelled-text-input-for-language--label {
  position: absolute;
  top: 0;
  color: #766e68;
  font-size: 12px;
  padding-top: 1px;
  padding-left: 5px;
}

.multiLanguageTextInput .labelled-text-input-for-language {
  width: 100%;
}

.multiLanguageTextInput
  .labelled-text-input-for-language
  .labelled-text-input--textInput {
  width: 100%;
}

.multiLanguageTextInput .multiLanguageTextInput--label {
  display: block;
  font-family: Ubuntu, sans-serif;
  font-size: 16px;
  padding-top: 2vh;
  padding-bottom: 1vh;
}
