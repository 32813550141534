.theme-themagroen {
  $color-StarbucksGreen: #006241;
  $color-StarbucksGreenSecondary: #004830;
  $color-StarbucksGreenLight: #3d7a66;

  .ribbonTitle--is-clickable {
    background-image: url(../resources/assets/themes/themagroen/16x9/ui/ribbon/ribbon.svg);
  }

  .btnIcon--showMenu {
    background-image: url(../resources/assets/themes/themagroen/16x9/ui/sidemenu/menu-icon.svg);
  }

  .sideMenu--mainMenu--btnCloseMenu {
    background-image: url(../resources/assets/themes/themagroen/16x9/ui/sidemenu/close-menu-button.svg);
  }

  .batchCowListButton {
    background-image: url(../resources/assets/themes/themagroen/16x9/ui/buttons/button-with-arrow-up.svg);
  }

  .batchCowListPopup--btnClosePopup {
    background-image: url(../resources/assets/themes/themagroen/16x9/ui/buttons/button-with-arrow-down.svg);
  }

  .cowPopup--btnClosePopup {
    background-image: url(../resources/assets/themes/themagroen/16x9/ui/popup/close-popup-button.svg);
  }

  .cowPopup--btnCallToAction, .cowPopup--secondBtnCallToAction {
    background-image: url(../resources/assets/themes/themagroen/16x9/ui/buttons/red-button.svg);
    background-color: transparent;
  }

  .farmerPopup--btnClosePopup {
    background-image: url(../resources/assets/themes/themagroen/16x9/ui/popup/close-popup-button.svg);
  }
  .dairyPopup--btnClosePopup {
    background-image: url(../resources/assets/themes/themagroen/16x9/ui/popup/close-popup-button.svg);
  }
  .farmerPopup--btnCallToAction {
    background-image: url(../resources/assets/themes/themagroen/16x9/ui/buttons/red-button-popup-farmer.svg);
  }
  .cow-daily--outro--backButton,
  .orbiterStoryLastSlide--sign,
  .vectorStoryLastSlide--sign,
  .lelyStoryLastSlide--sign,
  .farmerStoryEndingPoint--backButton {
    background-image: url(../resources/assets/themes/themagroen/16x9/stories/farmer/ending-point-button.svg);
  }
  .cowDailyRumination--button,
  .cowDailyAstronaut--button,
  .cowDailyGrazeway--button,
  .cowCalmRobot--button {
    background-image: url(../resources/assets/themes/themagroen/16x9/screens/cowCalmRobot/cow-calm-slide-button.svg);
  }

  .leftArrowButton {
    background-image: url(../resources/assets/themes/themagroen/16x9/screens/cowOutro/leftButtonCowOutroPage.svg);
  }

  .rightArrowButton {
    background-image: url(../resources/assets/themes/themagroen/16x9/screens/cowOutro/rightButtonCowOutroPage.svg);
  }

  .shopPopup--btnCallToAction,
  .orbiterPopup--btnCallToAction,
  .vectorPopup-callToActionButton,
  .astronautPopup--btnCallToAction,
  .astronautPopupLiveData--btnCallToAction,
  .discoveryPopup--btnCallToAction,
  .brandPopup--btnCallToAction,
  .lunaPopup--btnCallToAction,
  .sustainabilityPopup--btnCallToActionAnimalWelfare {
    background-color: #006241;
  }
  .iceCreamFactoryPopup--btnCallToAction,
  .cheeseFactoryPopup--btnCallToAction,
  .grazewayPopup--btnCallToAction,
  .dairyPopup--btnCallToAction {
    background-color: #006241;
    opacity: 0.2;
  }
  .shopPopup--action-buttons
    .shopPopup--btnCallToAction.shopPopup--btnCallToAction-disabled {
    background-color: #006241;
    opacity: 0.2;
  }
  .shopPopup--action-buttons
    .shopPopup--btnCallToAction.shopPopup--btnCallToAction {
    background-color: #006241;
  }
  .iceCreamFactoryPopup--btnClosePopup,
  .cheeseFactoryPopup--btnClosePopup,
  .calmRobotPopup--btnClosePopup,
  .brandPopup--btnClosePopup,
  .dogPopup--btnClosePopup,
  .farmPopup--btnClosePopup,
  .shopPopup--btnClosePopup,
  .sustainabilityPopup--btnClosePopup,
  .grazewayPopup--btnClosePopup,
  .vectorPopup--btnClosePopup,
  .orbiterPopup--btnClosePopup,
  .astronautPopup--btnClosePopup,
  .discoveryPopup--btnClosePopup,
  .solarPanel--btnClosePopup,
  .vehiclePopup--btnClosePopup,
  .lunaPopup--btnClosePopup {
    background-image: url(../resources/assets/themes/themagroen/16x9/ui/popup/close-popup-button.svg);
  }

  .flow-container-Cow-slide-CowRelativesPage .cowParentPage--mother {
    background-image: url(../resources/assets/themes/themagroen/16x9/screens/cowParent/cow-mother-info.svg);
  }

  .flow-container-Cow-slide-CowRelativesPage .cowParentPage--father {
    background-image: url(../resources/assets/themes/themagroen/16x9/screens/cowParent/cow-father-info.svg);
  }

  .AgeSelectionPage--welcome--kid {
    background-image: url(../resources/assets/themes/themagroen/16x9/screens/ageSelection/age-selection-under-10.svg);
  }

  .AgeSelectionPage--welcome--man {
    background-image: url(../resources/assets/themes/themagroen/16x9/screens/ageSelection/age-selection-above-10.svg);
  }

  .btn-social.theme-btn-social circle {
    fill: #006241 !important;
  }
}
