@use "../../common/styles/variables.scss" as *;

@keyframes cookies_popup_slide_in_show {
  0% {
    bottom: vhFn(-70.512);
  }

  100% {
    bottom: 0;
  }
}

@keyframes slide_in_hide {
  0% {
    bottom: 0;
    opacity: 1;
  }

  100% {
    bottom: vhFn(-70.512);
    opacity: 0;
  }
}

.cookiesPopup.is-hiding {
  animation-name: slide_in_hide !important;
  animation-duration: 0.5s !important;
  animation-fill-mode: forwards;
}

.cookiesPopup {
  animation-name: cookies_popup_slide_in_show !important;
  animation-duration: 0.5s !important;
  position: absolute;
  left: vwFn(11);
  top: vhFn(26.088);
  background-color: $color-LelyWhite;
  width: vwFn(73.89);
  height: vhFn(50.512);
  border-left: vwFn(0.12) solid $color-GrayDairyLight;
  border-right: vwFn(0.12) solid $color-GrayDairyLight;
  border-top: vhFn(0.27) solid $color-GrayDairyLight;
  box-sizing: border-box;
  border-radius: vwFn(0.5) vwFn(0.5) 0px 0px;
  z-index: 1000000;
}

.cookiesPopup--title {
  // width: 100%;
  margin: auto;
  //width: vwFn(55.5418);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-top: vhFn(2);
  padding-left: vwFn(2);

  font-family: "BourtonBase";
  color: $color-gray-popup;
  font-size: vhFn(6);
  line-height: vhFn(9.6);
  font-weight: $font-regular;
}

.cookiesPopup--body {
  width: 80%;
  margin: auto;
}

.cookiesPopup-body-paragraph{
  position: relative;
  left: vwFn(-12.5);
  width: 125%;
  height: vhFn(57);
  padding-left: vwFn(6.97438);
  padding-right: vwFn(6.97438);
}

.cookiesPopup--body--textBeginning {
  width: 100%;
  text-align: left;
  color: $color-gray-popup;
  // font-size: 2.4em;
  font-family: $primaryFont;
  font-size: vhFn(3.5);
  line-height: vhFn(4.27);
  font-weight: $font-regular;
}

.cookiesPopup--footer--btnAcceptCookies {
  background-repeat: no-repeat;
  background-position: right;
  padding-right: vwFn(2);
  background-image: url(../../resources/assets/themes/adult/16x9/ui/cookies/close_button_with_arrows.svg);
  width: 32%;
  height: vhFn(4);
  margin: auto;
  display: block;
  cursor: pointer;
}

.cookiesPopup--footer--btnAcceptCookies--label {
  color: #ff684d;
  font-size: vhFn(2.5);
}

.cookiesPopup--body--btnReadMore {
  white-space:pre;
  text-decoration: underline;
  cursor: pointer;
  font-family: $primaryFont;
  font-size: vhFn(3.5);
}

.cookiesPopup--footer {
  position: absolute;
  bottom: vhFn(12);
  text-align: center;
  width: 100%;
  height: vhFn(5);
}


.cookiesPopup--image {
  position: absolute;
  left: vwFn(60);
  background-repeat: no-repeat;
  background-position: 100% 100%;
  width: vwFn(28.07142);
  height: vhFn(51.6);
  background-image: url(../../resources/assets/themes/adult/16x9/ui/cookies/popup_image.svg);
}

//#region CHILDREN VERSION STYLES
.cookiesPopup.is-children .cookiesPopup--image {
  background-image: url(../../resources/assets/themes/adult/16x9/ui/cookies/popup_image_children.svg);
}

.cookiesPopup.is-children .cookiesPopup--footer--btnAcceptCookies {
  background-color: #c30a14;
  color: #fff;
  border-radius: 4px;
  background-image: none;
  height: vwFn(2.5);
  padding-right: 0;
}

.cookiesPopup.is-children .cookiesPopup--footer--btnAcceptCookies--label {
  color: #fff;
  width: 100%;
  text-align: center;
  vertical-align: middle;
  line-height: vwFn(2.5);
}
//#endregion


.cookiesPopup--overlay{
  animation-duration: 0s !important;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  background-color: rgba(0,0,0,0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999999;
}