@use "../../../common/styles/variables.scss" as *;

.hudDisplay--messages--container {
  overflow-y: hidden;
  height: 100%;
}

.hudDisplay--messages--container--message {
  margin-top: 0;
  margin-bottom: 0;
  font-size: vhFn(1.897983);
  font-family: $primaryFont;
}

.hudDisplay--messages--container--message:first-of-type {
  animation: hudMessageFadeIn ease-in 0.5s forwards;
}

.hudDisplay--messages--container--message.messageTypeSpecialLog {
  color: #ff684d;
  font-size: vhFn(2);
  font-weight: bold;
}

@keyframes hudMessageFadeIn {
  0% {
    opacity: 0;
    transform: translateY(vhFn(1));
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
