@use "../../common/styles/variables.scss" as *;

.birthdayCake--cake {
  position: absolute;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowhistory/background-layers/firstYear/cake.svg);
  background-repeat: no-repeat;
  width: vwFn(19.033);
  height: vhFn(59.285);
  left: vwFn(17.96422);
  top: vhFn(20.9573);
}

.birthdayCake--number {
  position: absolute;
  width: vwFn(19.033);
  text-align: center;
  left: vwFn(17.96422);
  top: vhFn(27.9573);
  font-family: "Amatic SC", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: vhFn(13.285);
  color: black;
  text-shadow: 0px 10px 0.02px white, 9.8px 2.1px 0.02px white,
    4.2px -9.1px 0.02px white, -8px -6px 0.02px white, -7.6px 6.5px 0.02px white,
    4.8px 8.8px 0.02px white, 9.6px -2.8px 0.02px white,
    -0.7px -10px 0.02px white, -9.9px -1.5px 0.02px white,
    -3.5px 9.4px 0.02px white, 8.4px 5.4px 0.02px white, 7.1px -7px 0.02px white,
    -5.4px -8.4px 0.02px white, -9.4px 3.5px 0.02px white,
    1.4px 9.9px 0.02px white, 10px 0.8px 0.02px white, 2.9px -9.6px 0.02px white,
    -8.7px -4.8px 0.02px white, -6.6px 7.5px 0.02px white,
    5.9px 8px 0.02px white, 9.1px -4.1px 0.02px white,
    -2.1px -9.8px 0.02px white, -10px -0.1px 0.02px white,
    -2.2px 9.8px 0.02px white, 9.1px 4.2px 0.02px white, 6.1px -8px 0.02px white,
    -6.5px -7.6px 0.02px white, -8.8px 4.7px 0.02px white,
    2.7px 9.6px 0.02px white, 10px -0.6px 0.02px white,
    1.5px -9.9px 0.02px white, -9.3px -3.6px 0.02px white,
    -5.5px 8.4px 0.02px white, 7px 7.2px 0.02px white, 8.5px -5.3px 0.02px white,
    -3.4px -9.4px 0.02px white, -9.9px 1.3px 0.02px white,
    -0.8px 10px 0.02px white, 9.6px 2.9px 0.02px white,
    4.9px -8.7px 0.02px white, -7.5px -6.7px 0.02px white,
    -8.1px 5.9px 0.02px white, 4px 9.2px 0.02px white, 9.8px -2px 0.02px white,
    0.2px -10px 0.02px white, -9.7px -2.3px 0.02px white,
    -4.3px 9px 0.02px white, 7.9px 6.1px 0.02px white;
}
