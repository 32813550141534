.recover-account-container--sendEmailPage {
  background-color: #bf0011;
  border-radius: 4px;
  width: 40vw;
  padding-left: 1vw;
  padding-top: 1vw;
  padding-bottom: 10px;

  h4 {
    font-family: "Roboto", "Ubuntu", sans-serif;
    font-size: 26px;
    font-weight: 300;
    text-align: left;
    margin-top: 0;
    margin-bottom: 10px;
    color: #fff;
  }

  h5 {
    color: #fff;
    font-family: "Roboto", "Ubuntu", sans-serif;
    font-weight: 300;
    text-align: left;
    margin-top: 0;
    font-size: 16px;
  }

  .labelled-text-input--label {
    color: #fff;
    font-size: 14px;
    text-align: left;
    padding-top: 0;
  }

  .btn-returnToLogin,
  .btn-resendEmail,
  .btn-sendEmail {
    display: block;
    float: left;
    width: auto;
    margin-right: 10px;
    text-transform: none;
  }

  .recover-account--textField {
    position: relative;
    width: 90%;
    left: -5%;
    margin-bottom: 0;
  }

  .recover-account-container--error-msg {
    margin-top: 0;
    margin-bottom: 10px;
    color: #fff;
    text-align: left;
    font-size: 14px;
  }

  .labelled-text-input {
    margin-top: 0;
    top: 0;
    padding-top: 0;
  }
}
