@use "../../../common/styles/variables.scss" as *;

.CowParentsPage {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  width: vwFn(100);
  height: vhFn(100);
}

.textCaption--cowParentPage {
  position: absolute;
  min-width: vwFn(22.667);
  min-height: vhFn(1);
  left: vwFn(8.8731);
  top: vhFn(40.13);
  padding: vwFn(1.4) vwFn(1.4) vwFn(1.8);
  z-index: 3;
}

.textCaption--cowParentPage {
  position: absolute;
  //width: vwFn(22.59);
  //height: vhFn(20);
  left: vwFn(20.56);
  top: vhFn(40.13);

  .cow-cowParentPage--text {
    font-size: vhFn(3.73);
    line-height: vhFn(6.21);
    width: vwFn(19.635);
    height: vhFn(12.8);
    position: fixed;
    margin-left: vwFn(1);
    margin-right: vwFn(1);
  }
}

.CowParentsPage--text {
  position: relative;
  min-width: vwFn(10.633); 
  max-width: vwFn(28);
  //height: vhFn(12.8);
  left: vwFn(1.477);
  top: vhFn(0);
  font-size: vhFn(3.73);
  line-height: vhFn(6.4);
  margin: 0px;
  z-index: 3;
}

.CowParentsPage--text--name {
  position: absolute;
  right: vwFn(1);
  min-width: vwFn(22.59);
  max-height: vhFn(10.04);
  top: vhFn(15.66);
  font-family: $secondaryFont;
  font-style: normal;
  font-weight: bold;
  font-size: vhFn(17.06);
  line-height: vhFn(10.04);
  margin: vhFn(1.86) 0px;
  color: black;
  z-index: 3;
  text-shadow: 0px 10px 0.02px white, 9.8px 2.1px 0.02px white,
    4.2px -9.1px 0.02px white, -8px -6px 0.02px white, -7.6px 6.5px 0.02px white,
    4.8px 8.8px 0.02px white, 9.6px -2.8px 0.02px white,
    -0.7px -10px 0.02px white, -9.9px -1.5px 0.02px white,
    -3.5px 9.4px 0.02px white, 8.4px 5.4px 0.02px white, 7.1px -7px 0.02px white,
    -5.4px -8.4px 0.02px white, -9.4px 3.5px 0.02px white,
    1.4px 9.9px 0.02px white, 10px 0.8px 0.02px white, 2.9px -9.6px 0.02px white,
    -8.7px -4.8px 0.02px white, -6.6px 7.5px 0.02px white,
    5.9px 8px 0.02px white, 9.1px -4.1px 0.02px white,
    -2.1px -9.8px 0.02px white, -10px -0.1px 0.02px white,
    -2.2px 9.8px 0.02px white, 9.1px 4.2px 0.02px white, 6.1px -8px 0.02px white,
    -6.5px -7.6px 0.02px white, -8.8px 4.7px 0.02px white,
    2.7px 9.6px 0.02px white, 10px -0.6px 0.02px white,
    1.5px -9.9px 0.02px white, -9.3px -3.6px 0.02px white,
    -5.5px 8.4px 0.02px white, 7px 7.2px 0.02px white, 8.5px -5.3px 0.02px white,
    -3.4px -9.4px 0.02px white, -9.9px 1.3px 0.02px white,
    -0.8px 10px 0.02px white, 9.6px 2.9px 0.02px white,
    4.9px -8.7px 0.02px white, -7.5px -6.7px 0.02px white,
    -8.1px 5.9px 0.02px white, 4px 9.2px 0.02px white, 9.8px -2px 0.02px white,
    0.2px -10px 0.02px white, -9.7px -2.3px 0.02px white,
    -4.3px 9px 0.02px white, 7.9px 6.1px 0.02px white;
}

.cow--parent--name {
  position: absolute;
  max-height: vhFn(10.04);
  top: vhFn(1);
  right: vwFn(-2);
  font-family: $secondaryFont;
  font-style: normal;
  font-weight: bold;
  font-size: vhFn(17.06);
  line-height: vhFn(10.04);
  margin: vhFn(1.86) 0px;
  color: black;
  z-index: 3;
  text-shadow: 0px 10px 0.02px white, 9.8px 2.1px 0.02px white,
    4.2px -9.1px 0.02px white, -8px -6px 0.02px white, -7.6px 6.5px 0.02px white,
    4.8px 8.8px 0.02px white, 9.6px -2.8px 0.02px white,
    -0.7px -10px 0.02px white, -9.9px -1.5px 0.02px white,
    -3.5px 9.4px 0.02px white, 8.4px 5.4px 0.02px white, 7.1px -7px 0.02px white,
    -5.4px -8.4px 0.02px white, -9.4px 3.5px 0.02px white,
    1.4px 9.9px 0.02px white, 10px 0.8px 0.02px white, 2.9px -9.6px 0.02px white,
    -8.7px -4.8px 0.02px white, -6.6px 7.5px 0.02px white,
    5.9px 8px 0.02px white, 9.1px -4.1px 0.02px white,
    -2.1px -9.8px 0.02px white, -10px -0.1px 0.02px white,
    -2.2px 9.8px 0.02px white, 9.1px 4.2px 0.02px white, 6.1px -8px 0.02px white,
    -6.5px -7.6px 0.02px white, -8.8px 4.7px 0.02px white,
    2.7px 9.6px 0.02px white, 10px -0.6px 0.02px white,
    1.5px -9.9px 0.02px white, -9.3px -3.6px 0.02px white,
    -5.5px 8.4px 0.02px white, 7px 7.2px 0.02px white, 8.5px -5.3px 0.02px white,
    -3.4px -9.4px 0.02px white, -9.9px 1.3px 0.02px white,
    -0.8px 10px 0.02px white, 9.6px 2.9px 0.02px white,
    4.9px -8.7px 0.02px white, -7.5px -6.7px 0.02px white,
    -8.1px 5.9px 0.02px white, 4px 9.2px 0.02px white, 9.8px -2px 0.02px white,
    0.2px -10px 0.02px white, -9.7px -2.3px 0.02px white,
    -4.3px 9px 0.02px white, 7.9px 6.1px 0.02px white;
}

.flow-container-Cow-slide-CowParentsPage .cow-mother--text,
.flow-container-Cow-slide-CowParentsPage .cow-father--text,
.flow-container-Cow-slide-CowFirstYearSlide .cow-mother--text,
.flow-container-Cow-slide-CowFirstYearSlide .cow-father--text {
  opacity: 0;
}

.flow-container-Cow-slide-CowParentsPage .cow--bigger {
  width: vwFn(14.28) !important;
  height: vhFn(33.66) !important;
  left: vwFn(67.66) !important;
  top: vhFn(65.13) !important;
  background-size: 100% 100%;
  background-repeat: none;
}

.CowParentsPage-leftTree {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/background-trees/trees6.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  top: vhFn(31.852);
  left: vwFn(3.427339);
  width: vwFn(34.42857);
  height: vhFn(48.909);
}

.cow-full--name {
  position: relative;
  min-width: 100%;
  right: vwFn(-12);
  max-height: vhFn(10.04);
  letter-spacing: vwFn(0);
  top: vhFn(8.66);
  font-family: $secondaryFont;
  font-style: normal;
  font-weight: bold;
  font-size: vhFn(17.06);
  line-height: vhFn(10.04);
  margin: vhFn(1.86) 0px;
  color: black;
  z-index: 3;
  text-shadow: 0px 10px 0.02px white, 9.8px 2.1px 0.02px white,
    4.2px -9.1px 0.02px white, -8px -6px 0.02px white, -7.6px 6.5px 0.02px white,
    4.8px 8.8px 0.02px white, 9.6px -2.8px 0.02px white,
    -0.7px -10px 0.02px white, -9.9px -1.5px 0.02px white,
    -3.5px 9.4px 0.02px white, 8.4px 5.4px 0.02px white, 7.1px -7px 0.02px white,
    -5.4px -8.4px 0.02px white, -9.4px 3.5px 0.02px white,
    1.4px 9.9px 0.02px white, 10px 0.8px 0.02px white, 2.9px -9.6px 0.02px white,
    -8.7px -4.8px 0.02px white, -6.6px 7.5px 0.02px white,
    5.9px 8px 0.02px white, 9.1px -4.1px 0.02px white,
    -2.1px -9.8px 0.02px white, -10px -0.1px 0.02px white,
    -2.2px 9.8px 0.02px white, 9.1px 4.2px 0.02px white, 6.1px -8px 0.02px white,
    -6.5px -7.6px 0.02px white, -8.8px 4.7px 0.02px white,
    2.7px 9.6px 0.02px white, 10px -0.6px 0.02px white,
    1.5px -9.9px 0.02px white, -9.3px -3.6px 0.02px white,
    -5.5px 8.4px 0.02px white, 7px 7.2px 0.02px white, 8.5px -5.3px 0.02px white,
    -3.4px -9.4px 0.02px white, -9.9px 1.3px 0.02px white,
    -0.8px 10px 0.02px white, 9.6px 2.9px 0.02px white,
    4.9px -8.7px 0.02px white, -7.5px -6.7px 0.02px white,
    -8.1px 5.9px 0.02px white, 4px 9.2px 0.02px white, 9.8px -2px 0.02px white,
    0.2px -10px 0.02px white, -9.7px -2.3px 0.02px white,
    -4.3px 9px 0.02px white, 7.9px 6.1px 0.02px white;
}
