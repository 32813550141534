@use "../../common/styles/admin_variables.scss" as adminVar;

.sidebar-fixed-wrapper {
  position: fixed;
  z-index: adminVar.$zindex-sidebar;
  left: 0;
  height: calc(var(--vh, 1vh) * 100); //minus top-navigation
  width: adminVar.$sideMenuWidth;
  background-color: #c30a14;
}

.sidebar__navlist {
  // padding: 0 1em;
  padding: 0 0.5em;
}

.sidebar__navitem {
  display: block;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  // padding-bottom: 1em;
}

.sidebar__navitem:hover {
  background-color: adminVar.$color-LelyRedDark;
}

.sidebar__selectedOption {
  background-color: adminVar.$color-LelyRedDark;
}

.sidebar__navitem a {
  text-decoration: none;
  color: #fff;

  i[class^="lely-icon-"] {
    padding-right: 0.5em;
  }
}
