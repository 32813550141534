@use "../../common/styles//variables.scss" as *;

// .vectorPopup {
//   position: absolute;
//   top: vhFn(15);
//   left: vwFn(10.5);
//   background-color: $color-LelyWhite;
//   width: vwFn(67.73);
//   min-height: vhFn(70.93);
//   max-height: vhFn(85.93);
//   border-left: vwFn(0.12) solid $color-GrayDairyLight;
//   border-right: vwFn(0.12) solid $color-GrayDairyLight;
//   border-top: vhFn(0.27) solid $color-GrayDairyLight;
//   border-bottom: vhFn(0.27) solid $color-GrayDairyLight;
//   box-sizing: border-box;
//   box-shadow: 0px 2px 5px rgba(222, 219, 214, 0.4);
//   border-radius: vwFn(0.5) vwFn(0.5) vwFn(0.5) vwFn(0.5);
//   padding: vhFn(2) vwFn(2.46);
// }

// .vectorPopup--title {
//   width: vwFn(43.1);
//   font-family: $secondaryFont;
//   font-weight: $font-bold;
//   font-style: $font-DefaultFontStyle;
//   color: $color-gray-popup;
//   font-size: vhFn(6.4);
//   line-height: vhFn(9.33);
//   margin-bottom: vhFn(1.87);
// }

// .vectorPopup--description {
//   width: vwFn(47.78);
//   min-height: vhFn(45.93);
//   font-family: $primaryFont;
//   font-weight: $font-regular;
//   font-style: $font-DefaultFontStyle;
//   color: $color-gray-popup;
//   font-size: vhFn(3.73);
//   line-height: vhFn(6.4);
// }

// .vectorPopup--subtitle--text {
//   width: vwFn(33.63);
//   height: vhFn(6.4);
//   line-height: vhFn(6.4);
//   font-family: $primaryFont;
//   font-weight: $font-regular;
//   color: $color-gray-popup;
//   font-size: vhFn(3.73);
//   display: inline-block;
//   margin-bottom: vhFn(1.87);
// }

// .vectorPopup--btnClosePopup {
//   position: absolute;
//   top: vhFn(-2.5);
//   left: vwFn(50);
//   background-image: url(../../resources/assets/themes/lely/16x9/ui/popup/close-popup-button.svg);
//   background-repeat: no-repeat;
//   background-size: 100% 100%;
//   width: vwFn(8.5);
//   height: vhFn(11.5);
// }

.junoPopup-callToActionButton {
  background-color: $color-LelyRed;
  background-repeat: no-repeat;
  border-radius: vwFn(0.5) vwFn(0.5) vwFn(0.5) vwFn(0.5);
  border-left: vwFn(0.12) solid $color-GrayDairyLight;
  border-right: vwFn(0.12) solid $color-GrayDairyLight;
  border-top: vhFn(0.27) solid $color-GrayDairyLight;
  border-bottom: vhFn(0.27) solid $color-GrayDairyLight;
  background-size: 100% 100%;
  min-width: vwFn(19.889);
  min-height: vhFn(10.66);
  font-size: vwFn(1.5);
  color: $color-LelyWhite;
  top: vhFn(3.86);
  line-height: vhFn(4.266);
}

.junoPopup--vector {
  background-image: url(../../resources/assets/themes/lely/16x9/popups/juno/junoPopup.svg);
  position: absolute;
  padding-top: vhFn(6.32);
  margin-left: vwFn(3.3);
  width: vhFn(73.35);
  height: vhFn(73.35);
  background-size: 100% 100%;
  left: vwFn(53);
  top: vhFn(0);
}

.cow-daily--eating--slide .vectorPopup-callToActionButton {
  display: none;
}

// //Iphone 6/7/8
// @media only screen and (min-device-height: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
//   .vectorPopup--description {
//     font-size: vwFn(1.72);
//     line-height: vhFn(5.8);
//     max-height: vhFn(40);
//   }
// }
.theme-adult .junoPopup-callToActionButton {
  background-color: transparent; 
  border-color: transparent; 
  color: #FF684D; 
  // width: vwFn(1.7);
  // height: vhFn(4);
  // padding: vhFn(2); 
  min-height: 0;
  min-width: 0;
  order: 0; 
  font-size: vhFn(3.733);
} 
.theme-adult .junoPopup-callToActionButton--text {
  color: #FF684D;  
} 
.theme-adult .junoPopupButton-arrows {
  background-image: url(../../resources/assets/themes/lely/16x9/ui/popup/orange_button_arrows.svg); 
  width: vwFn(1.7);
  height: vhFn(4);
  background-repeat: no-repeat;  
  order: 1;
} 
.theme-adult .junoPopupButton--container {
  width: vwFn(19.45); 
  height: vhFn(12.8);  
  display: flex;
  flex-direction: row;
  align-items: center; 
  left: vwFn(30);
  position: absolute;
} 
.theme-adult .junoPopup--vector {
  background-image: url(../../resources/assets/themes/lely/16x9/popups/juno/junoPopupAdult.svg);
}