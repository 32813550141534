@use "../../../../common/styles/variables.scss" as *;

.WelcomeScreenKool {
  background-image: url(../../../../resources/assets/themes/lely/16x9/screens/cowIntro/cow-intro-second-slide.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  width: vwFn(100);
  height: vhFn(100);
}

.WelcomeScreenKool--minifarm {
  position: relative;
  background-repeat: no-repeat;
  height: vhFn(100);
  background-image: url(../../../../resources/assets/themes/mijnmelk/16x9/onboarding/welcomeScreen/miniFarm.svg);
  background-size: cover;
  left: vwFn(0);
}

.WelcomeScreenKool--farmer {
  position: absolute;
  left: vwFn(75.49);
  top: vhFn(30.74);
  width: vwFn(20.04);
  height: vhFn(50.632);
  background-image: url(../../../../resources/assets/themes/kaasfarm/16x9/onboarding/welcomeScreenKaas/farmer-image.svg);
  background-size: 100%;
  background-repeat: no-repeat;
  z-index: 1;
}

.WelcomeScreenKool--logo--farmName {
  position: absolute;
  left: vwFn(-0.5);
  top: vhFn(36.78);
  width: vwFn(70.28);
  height: vhFn(24.06);
  background-image: url(../../../../resources/assets/themes/kaasfarm/16x9/onboarding/welcomeScreenKaas/ribbon-title.svg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.WelcomeScreenKool--logo--farmName--text {
  position: relative;
  width: vwFn(42.78);
  height: vhFn(10.93);
  left: vwFn(16.68);
  top: vhFn(3.5);
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
  font-style: normal;
  color: #ffffff;
  font-size: vwFn(4.43);
  line-height: vhFn(10.93);
  align-items: center;
  text-align: center;
}

.WelcomeScreenKool--cloud {
  position: absolute;
  left: vwFn(85.09);
  top: vhFn(8.26);
  width: vwFn(10.96);
  height: vhFn(16.73);
  background-image: url(../../../../resources/assets/themes/lely/16x9/ui/elements/cloud.svg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.WelcomeScreenKool--swipe {
  position: relative;
  width: vwFn(6.77);
  height: vhFn(22.4);
  left: vwFn(61.68);
  top: vhFn(-8.5);
  background-image: url(../../../../resources/assets/themes/mijnmelk/16x9/onboarding/welcomeScreen/swipe-icon.svg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
