@use "../../../../common/styles/variables.scss" as *;

.WelcomeScreen {
  background-image: url(../../../../resources/assets/themes/lely/16x9/screens/cowIntro/cow-intro-second-slide.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  width: vwFn(100);
  height: vhFn(100);
}

.WelcomeScreen--minifarm {
  position: relative;
  background-repeat: no-repeat;
  height: vhFn(100);
  background-image: url(../../../../resources/assets/themes/mijnmelk/16x9/onboarding/welcomeScreen/miniFarm.svg);
  background-size: cover;
  left: vwFn(0);
}

.WelcomeScreen--farmer {
  position: absolute;
  left: vwFn(69.09);
  top: vhFn(16.26);
  width: vwFn(33.96);
  height: vhFn(83.73);
  background-image: url(../../../../resources/assets/themes/mijnmelk/16x9/onboarding/welcomeScreen/farmer-image.svg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  z-index: 1;
}

.WelcomeScreen--logo--mijnMelk {
  position: absolute;
  left: vwFn(2);
  top: vhFn(30.78);
  width: vwFn(14.35);
  height: vhFn(36.49);
  background-image: url(../../../../resources/assets/themes/mijnmelk/16x9/onboarding/welcomeScreen/welcome-screen-mijn-melk.svg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  z-index: 1;
}

.WelcomeScreen--logo--farmName {
  position: absolute;
  left: vwFn(-0.5);
  top: vhFn(36.78);
  width: vwFn(70.28);
  height: vhFn(24.06);
  background-image: url(../../../../resources/assets/themes/mijnmelk/16x9/onboarding/welcomeScreen/ribbon-title.svg);
  background-size: 100%;
  background-repeat: no-repeat;
}

.WelcomeScreen--logo--farmName--text {
  position: relative;
  width: vwFn(42.78);
  height: vhFn(10.93);
  left: vwFn(16.68);
  top: vhFn(3.5);
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
  font-style: normal;
  color: #ffffff;
  font-size: vwFn(4.43);
  line-height: vhFn(10.93);
  align-items: center;
  text-align: center;
}

.WelcomeScreen--cloud {
  position: absolute;
  left: vwFn(90.09);
  top: vhFn(8.26);
  width: vwFn(10.96);
  height: vhFn(16.73);
  background-image: url(../../../../resources/assets/themes/lely/16x9/ui/elements/cloud.svg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.WelcomeScreen--swipe {
  position: relative;
  width: vwFn(6.77);
  height: vhFn(22.4);
  left: vwFn(61.68);
  top: vhFn(-8.5);
  background-image: url(../../../../resources/assets/themes/mijnmelk/16x9/onboarding/welcomeScreen/swipe-icon.svg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
