.add-new-animal-container {
  position: relative;
  left: -5px;
  background: #FFFFFF;
  border: 1px dashed #CFCBC3;
  box-shadow: 0px 2px 5px rgba(222, 219, 214, 0.4);
  border-radius: 4px;
  width: 95%;
  margin: 0 auto;
  margin-bottom: 5px;
  margin-top: 3px;
}

.btn-add-new-animal-block {
  background: transparent;
  border: 0;
  outline: 0;
  min-width: 160px;
  margin-top: 6px;
  margin-bottom: 4px;
  cursor: pointer;
}

.add-new-animal-label {
  margin-left: 5px;
  font-size: 14px;
}

.btn-add-new-animal-block .lely-icon-plus {
  padding: 3px;
  padding-bottom: 0;
  border: 1px solid #766E68;
  color: #766E68;
}