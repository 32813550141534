@use "../../../common/styles/variables.scss" as *;

.cow-calm--robot--secondSlide {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  width: vwFn(100);
  height: vhFn(100);
}

.cow-calm--robot--milkRobot {
  position: absolute;
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistory/background-layers/firstYear/calmRobot.svg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  left: vwFn(2.99);
  top: vhFn(49.28);
  width: vwFn(8.5295);
  height: vhFn(34.082);
  cursor: pointer;
}

.cowCalmRobot--button {
  position: absolute;
  background-image: url(../../../resources/assets/themes/lely/16x9/screens/cowCalmRobot/cow-calm-slide-button.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  left: vwFn(10.4);
  top: vhFn(59);
  height: vhFn(7);
  width: vhFn(6.5);
  padding: 0 !important;
  margin: 0 !important;
  z-index: 6;
}

.cow-calm--robot--arrowText {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  position: absolute;
  width: vwFn(25.39);
  height: vhFn(12.8);
  left: vwFn(10.78);
  bottom: vhFn(14);
}

.cow-calm--robot--arrow {
  position: absolute;
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/milk/arrow.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  rotate: 90deg;
  transform: scaleX(-1);
  width: vwFn(4.92);
  height: vhFn(10.8);
  left: 0%;
  right: 80.6%;
  top: 55.87%;
  bottom: 28.6%;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.cow-calm--robot--textDrinking {
  position: absolute;
  min-width: vwFn(32.33);
  height: vhFn(12.8);
  font-family: $secondaryFont;
  font-weight: bold;
  font-size: vwFn(1.9);
  line-height: vhFn(6.4);
  color: black;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: vhFn(2.13);
  left: vwFn(5);
  top: vhFn(6.4);
  z-index: 7;
}

.cow-calm-slide--background-overlay {
  display: none;
  position: fixed;
  width: 100%;
  height: vhFn(100);
  background-color: #121212;
  opacity: 0.4;
  pointer-events: fill;
}

.cow-calm-slide--background-overlay.is-calm-popup-displayed {
  display: block;
  z-index: 10;
}

.cow-calm--robot--tree {
  position: absolute;
  background-size: 100% 100%;
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistory/background-layers/firstYear/bigTree.svg);
  width: vwFn(58);
  height: vhFn(124.61);
  left: vwFn(55);
  top: vhFn(-18.37);
  z-index: 6;
}
.theme-adult .cow-calm--robot--tree {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistoryForAdult/background-layers/trees/full_tree.png); 
  position: absolute;
  background-size: 100% 100%;
  width: vwFn(65);
  left: vwFn(50);
  height: vhFn(125);
  display: block;
  z-index: 1;
}
.cow-calm--robot--greyPart {
  position: absolute;
  background-size: 100% 100%;
  background-color: url(../../../resources/assets/themes/lely/16x9/stories/orbiterstory/orbitersixthslide.svg);
  width: vwFn(100);
  height: vhFn(124.61);
  left: vwFn(10);
  top: vhFn(-18.37);
  z-index: 5;
}
