@use "../../common/styles/variables.scss" as *;

.moon--container {
  width: 100%;
  height: 100%;
  position: relative;
}

.moon {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/sky/moon.svg);
  position: absolute;
  width: vwFn(20);
  height: vwFn(20);
  background-repeat: no-repeat;
  background-size: contain;
  transition: 1s transform;
}
