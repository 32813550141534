.logo-container {
  text-align: center;
  width: 100%;
  height: 60px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(../../../resources/assets/themes/lely/16x9/adminpanel/login/login-page-background.svg);
}

.login--textInput {
  background: white;
}

.login--textInput .MuiInputBase-root {
  background: white;
}

.login-form-container .labelled-text-input--label {
  color: white;
  text-align: left;
}

.login-form-container .labelled-text-input .labelled-text-input--textInput {
  width: 100%;
}

.login-form-container .btn-login {
  margin-top: 20px;
  text-transform: none;
}

.login-container--error-msg {
  color: #fff;
  font-size: 14px;
  text-align: left;
}

.login-form-container a {
  color: #fff;
  text-decoration: underline;
  text-align: left;
  margin-top: 10px;
  display: block;
  cursor: pointer;
}
