@use "../../styles/variables.scss" as *;

.dropdown-text-input--textInput {
  background-color: white !important;
  border: 1px solid $color-gray-light !important;
  box-shadow: inset 0px 2px 5px rgba(222, 219, 214, 0.4);
  border-radius: 4px;
  padding: 2px;
  height: 32px;
  font-size: 14px !important;
  color: $color-gray-popup;
  background-color: $color-LelyWhite !important;
}
.dropdown-text-input--languages {
  font-size: 14px !important;
}
.MuiInput-underline:after {
  border: none !important;
}
.MuiInput-underline:before {
  border: none !important;
}
