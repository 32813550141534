@use "../../common/styles/variables.scss" as *;

// .vehiclePopup {
//   position: absolute;
//   top: vhFn(13.5);
//   left: vwFn(7.66);
//   background-color: $color-LelyWhite;
//   width: vwFn(67.73);
//   min-height: vhFn(50.5);
//   border-left: vwFn(0.12) solid $color-GrayDairyLight;
//   border-right: vwFn(0.12) solid $color-GrayDairyLight;
//   border-top: vhFn(0.27) solid $color-GrayDairyLight;
//   border-bottom: vhFn(0.27) solid $color-GrayDairyLight;
//   box-sizing: border-box;
//   box-shadow: 0px vhFn(0.52) vwFn(0.65) rgba(222, 219, 214, 0.4);
//   background-color: $color-LelyWhite;
//   border-radius: vwFn(0.5) vwFn(0.5) vwFn(0.5) vwFn(0.5);
//   padding: vhFn(4) vwFn(2.46);
// }

// .vehiclePopup--title {
//   width: 100%;
//   font-family: $secondaryFont;
//   letter-spacing: vwFn(0.25);
//   color: $color-LelyBlack;
//   font-weight: bold;
//   font-size: vwFn(3);
//   line-height: vhFn(9.33);
//   margin-top: 0 !important;
//   //padding-top: vhFn(4);
//   margin-bottom: 0;
//   //padding-left: vwFn(2.5);
// }

// .vehiclePopup--description {
//   width: vwFn(43.1);
//   min-height: vhFn(50.1);
//   left: vwFn(2.46);
//   top: vhFn(1.86);
//   margin: 0;
//   margin-left: vwFn(2.46);
//   padding-bottom: vhFn(2.13);
//   font-family: $primaryFont;
//   font-size: vwFn(1.72);
//   line-height: vhFn(6.4);
//   font-weight: normal;
//   overflow: hidden;
// }

// .vehiclePopup--frame {
//   position: absolute;
//   top: vhFn(0.2);
//   left: vwFn(-4);
//   width: vhFn(73.33);
//   height: vhFn(73.33);
//   background-repeat: no-repeat;
//   background-size: 100% 100%;
//   background-image: url(../../resources/assets/themes/lely/16x9/actors/actorFrame/actorFrame.svg);
//   z-index: 9;
// }
// .theme-adult .vehiclePopup--frame {
//   background-image: url(../../resources/assets/themes/lely/16x9/actors/actorFrame/actorFrameAdult.svg);
// }
// .vehiclePopup--image-wrapper {
//   position: absolute;
//   top: vhFn(0);
//   left: vwFn(52.5);
//   width: vhFn(73.35);
//   height: vhFn(73.35);

//   .vehiclePopup--image {
//     position: relative;
//     top: vhFn(4);
//     left: vwFn(-2.4);
//     width: vhFn(65.2);
//     height: vhFn(65.2);
//     background-repeat: no-repeat;
//     background-size: 100% 100%;
//     -webkit-clip-path: polygon(
//       0 25%,
//       50% 0,
//       100% 25%,
//       100% 75%,
//       50% 100%,
//       0 75%
//     );
//     clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
//     object-fit: cover;
//   }

//   .vehiclePopup--noImage {
//     position: relative;
//     top: vhFn(5);
//     right: vwFn(0.9);
//     display: flex;
//     width: vhFn(62.2);
//     height: vhFn(65.35);
//     background-color: #ffffff;
//     background-repeat: no-repeat;
//     background-size: 100% 100%;
//     //background-image: url(../../resources/assets/themes/lely/16x9/actors/shop/shop.svg);
//     -webkit-clip-path: polygon(
//       0 25%,
//       50% 0,
//       100% 25%,
//       100% 75%,
//       50% 100%,
//       0 75%
//     );
//     clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
//   }
// }

// .vehiclePopup--btnClosePopup {
//   position: absolute;
//   top: vhFn(-2);
//   left: vwFn(50);
//   background-image: url(../../resources/assets/themes/lely/16x9/ui/popup/close-popup-button.svg);
//   background-repeat: no-repeat;
//   background-size: 100% 100%;
//   width: vwFn(8.5);
//   height: vhFn(11.5);
//   z-index: 10;
// }
.vehiclePopup--noImage {
    width: vhFn(68);
    height: vhFn(77);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    -webkit-clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
    clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
    object-fit:cover;
    z-index: 10;
    margin-left: vhFn(4);
    background-image: url(../../resources/assets/themes/lely/16x9/popups/default/defaultTractor.svg);
  }