@use "../../common/styles/variables.scss" as *;

@media only screen and (orientation: landscape) {
}

@media only screen and (orientation: portrait) {
  html {
    height: calc(var(--vh, 1vh) * 100) !important;
    width: calc(var(--vh, 1vh) * 100) !important;
    padding: 0 !important;
    margin: 0 !important;
    position: fixed !important;
    right: 0;
    left: 0;
    bottom: 0;
    top: 0vh;
  }

  body {
    transform: rotate(90deg);
    //perspective: 1px;
    height: calc(var(--vh, 1vh) * 100) !important;
    width: calc(var(--vh, 1vh) * 100) !important;
    top: vwFn(0) !important;
    //transform-origin: top center;
    left: 0 !important;
    margin: 0 !important;
    position: fixed !important;
  }
}
