@use "../../styles/variables.scss" as *;

.radio-group-selection {
  display: flex;
  flex-wrap: wrap;
  background-color: white;
  width: 100%;
  font-size: 13px;
}

.radio-group-selection .radio-group-selection-label {
  font-size: 13px !important;
  font-family: Ubuntu, sans-serif;
  padding-left: 15px;
}

.radio-group {
  padding-left: 10px;
}

.radio-item {
  flex-basis: 50%;
}

.image-radio-selection--form-control {
  height: 130px;
  width: 110px;
  background-size: auto 100px;
  background-position-y: 10px;
  background-repeat: no-repeat;
  align-items: flex-end !important;
  margin-left: 0 !important;
  flex: 20%;
}

.image-radio-selection--radio-item {
  flex-basis: 15%;
}

.image-radio-selection--radio-group {
  flex-direction: row !important;
  width: 100%;
}

.image-radio-selection {
  width: 100%;
}
