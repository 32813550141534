@use "../../common/styles/variables.scss" as *;

.clouds-container {
  width: vwFn(200);
  height: 100%;
}


// @supports (-webkit-touch-callout: none) {
//   .clouds--dark {
//     backdrop-filter: unset;
//   }
// }  
// @supports not (-webkit-touch-callout: none) {
//   .clouds--dark {
//     backdrop-filter: brightness(0.7) blur(4px);
//   }
// } 

.cloud {
  position: absolute;
  background-size: contain;
  background-repeat: no-repeat;
  transition: 1s;
}

.cloud--small {
  width: vwFn(20);
  height: vhFn(20);
}

.cloud--big {
  width: vwFn(35);
  height: vhFn(30);
}

.cloud--dark {
  //background-blend-mode: multiply;
}

.cloud-1 {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/clouds/background_weather_cloud_01.svg);
}

.cloud-2 {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/clouds/background_weather_cloud_02.svg);
}

.cloud-3 {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/clouds/background_weather_cloud_03.svg);
}

.cloud-4 {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/clouds/background_weather_cloud_04.svg);
}

.cloud-5 {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/clouds/background_weather_cloud_05.svg);
}

.cloud-6 {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/clouds/background_weather_cloud_06.svg);
}

.cloud-7 {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/clouds/background_weather_cloud_07.svg);
}

.cloud-8 {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/clouds/background_weather_cloud_08.svg);
}

.cloud-9 {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/clouds/background_weather_cloud_09.svg);
}

.cloud-10 {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/clouds/background_weather_cloud_10.svg);
}

.cloud-11 {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/clouds/background_weather_cloud_11.svg);
}

.cloud-12 {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/clouds/background_weather_cloud_12.svg);
}

.cloud-13 {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/clouds/background_weather_cloud_13.svg);
}

.cloud-14 {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/clouds/background_weather_cloud_14.svg);
}

.cloud-15 {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/clouds/background_weather_cloud_15.svg);
}

.cloud-16 {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/clouds/background_weather_cloud_16.svg);
}

.cloud-17 {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/clouds/background_weather_cloud_17.svg);
}

.cloud-18 {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/clouds/background_weather_cloud_18.svg);
}

.cloud-19 {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/clouds/background_weather_cloud_19.svg);
}

/* GREY CLOUDS STYLES */
.cloud-1--grey {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/clouds/background_weather_cloud_01_gray.svg);
}

.cloud-2--grey {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/clouds/background_weather_cloud_02_gray.svg);
}

.cloud-3--grey {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/clouds/background_weather_cloud_03_gray.svg);
}

.cloud-4--grey {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/clouds/background_weather_cloud_04_gray.svg);
}

.cloud-5--grey {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/clouds/background_weather_cloud_05_gray.svg);
}

.cloud-6--grey {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/clouds/background_weather_cloud_06_gray.svg);
}

.cloud-7--grey {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/clouds/background_weather_cloud_07_gray.svg);
}

.cloud-8--grey {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/clouds/background_weather_cloud_08_gray.svg);
}

.cloud-9--grey {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/clouds/background_weather_cloud_09_gray.svg);
}

.cloud-10--grey {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/clouds/background_weather_cloud_10_gray.svg);
}

.cloud-11--grey {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/clouds/background_weather_cloud_11_gray.svg);
}

.cloud-12--grey {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/clouds/background_weather_cloud_12_gray.svg);
}

.cloud-13--grey {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/clouds/background_weather_cloud_13_gray.svg);
}

.cloud-14--grey {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/clouds/background_weather_cloud_14_gray.svg);
}

.cloud-15--grey {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/clouds/background_weather_cloud_15_gray.svg);
}

.cloud-16--grey {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/clouds/background_weather_cloud_16_gray.svg);
}

.cloud-17--grey {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/clouds/background_weather_cloud_17_gray.svg);
}

.cloud-18--grey {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/clouds/background_weather_cloud_18_gray.svg);
}

.cloud-19--grey {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/clouds/background_weather_cloud_19_gray.svg);
}
