@use "../../styles/variables.scss" as *;

.btn-save-changes {
  display: block !important;
  justify-content: center;
  align-items: center;
  padding: 12px 15px;
  width: 200px;
  height: 40px;
  background: $color-LelyRed;
  border-radius: 4px;
  font-family: $primaryFont;
  font-style: normal;
  font-weight: normal;
  // font-size: 0.6rem !important;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
}

.btn-already-saved {
  background-color: $color-BrandSuccess !important;
  width: 200px;
  height: 40px;
}
