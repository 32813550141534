@use "../../../common/styles/variables.scss" as *;

.cow--birthdayCakeSlide {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistory/background-layers/firstYear/birthdayCake.svg);
  position: absolute;
  width: vwFn(100.1);
  height: vhFn(100.1);
}

.cow--birthdayCakeSlide--whistle {
  position: absolute;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-image: url(../../../resources/assets/themes/lely/16x9/screens/cowFirstBirthday/cow-first-birthday-slide-whistle.svg);
  width: vwFn(8.94);
  height: vhFn(13.33);
  left: vwFn(37.5);
  top: vhFn(68);
}

.cow--birthdayCakeSlide--arrow {
  position: absolute;
  width: vwFn(90);
  background-color: #766e68;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: vhFn(0.4);
  top: vhFn(80);
  border: vhFn(0.4);
}

.textCaption--birthdayCakeSlide--degrees {
  position: absolute;
  padding: vwFn(1.5);
  min-height: vhFn(11.2);
  min-width: vwFn(1);
  top: vhFn(21.853);
  left: vwFn(10.1576);
  z-index: 4;
  font-weight: 300;
  font-size: vhFn(4.266);
  line-height: vhFn(4.8);
}

.cow--birthdayCakeSlide--box {
  position: absolute;
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistory/background-layers/firstYear/presentBox.svg);
  background-repeat: no-repeat;
  width: vwFn(7.0098);
  height: vhFn(22.058);
  left: vwFn(66.0886);
  top: vhFn(58.2413);
  z-index: 5;
}

.cow--birthdayCakeSlide--bigBox {
  position: absolute;
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistory/background-layers/firstYear/bigPresentBox.svg);
  background-repeat: no-repeat;
  width: vwFn(7.0098);
  height: vhFn(31.5493);
  left: vwFn(61.0714);
  top: vhFn(49.2413);
  z-index: 5;
}

.cow--birthdayCakeSlide--bigPartyFlag {
  position: absolute;
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistory/background-layers/firstYear/bigPartyFlag.svg);
  background-repeat: no-repeat;
  width: vwFn(41.7);
  height: vhFn(33.602);
  left: vwFn(41.8115);
  top: vhFn(0);
  z-index: 5;
}

.cow-birthdayCakeSlide--temperature {
  position: absolute;
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/cowhistory/background-layers/firstYear/thermometer.svg);
  background-repeat: no-repeat;
  width: vwFn(5);
  height: vhFn(15.2);
  left: vwFn(6.28571);
  top: vhFn(20.5);
  z-index: 5;
}
