@use "../../../common/styles/variables.scss" as *;

.borders {
  font-family: $secondaryFont;
  position: absolute;
  width: calc(var(--vh, 1vh) * 24.9);
  height: calc(var(--vh, 1vh) * 20.38);
  animation: fadeIn ease-in 4s forwards;
  -webkit-animation: fadeIn ease-in 4s forwards;
  -moz-animation: fadeIn ease-in 4s forwards;
  -o-animation: fadeIn ease-in 4s forwards;
  -ms-animation: fadeIn ease-in 4s forwards;
  overflow: hidden;
  z-index: 3;
  -webkit-transform: translate3d(0, 0, 1px);
  transform: translate3d(0, 0, 1px);
}

.borderOne {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/milk/border.svg);
  background-repeat: no-repeat;
  position: absolute;
  display: block;
  width: vwFn(10.9);
  height: vhFn(20.38);
  left: vwFn(1.2);
  top: vhFn(36.11);
  z-index: 5;
  -webkit-transform: translate3d(0, 0, 1px);
  transform: translate3d(0, 0, 1px);
}

.borderTwo {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/milk/border.svg);
  background-repeat: no-repeat;
  position: absolute;
  width: vwFn(10.9);
  height: vhFn(10.38);
  left: vwFn(1.2);
  top: vhFn(26.11);
  -webkit-transform: translate3d(0, 0, 1px);
  transform: translate3d(0, 0, 1px);
}

.borderThree {
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/milk/border.svg);
  background-repeat: no-repeat;
  position: absolute;
  width: vwFn(10.9);
  height: vhFn(8.38);
  left: vwFn(1.2);
  top: vhFn(69.11);
  border-bottom-left-radius: 10%;
  border-bottom-right-radius: 10%;
  -webkit-transform: translate3d(0, 0, 1px);
  transform: translate3d(0, 0, 1px);
}

.cowMilkedFirstPlace {
  font-family: $secondaryFont;
  position: absolute;
  width: vhFn(24.9);
  height: vhFn(5.38);
  left: vhFn(5);
  top: vhFn(54.11);
  margin: vwFn(2) 0px;
  text-align: center;
  animation: fadeIn ease-in 3s forwards;
  -webkit-animation: fadeIn ease-in 3s forwards;
  -moz-animation: fadeIn ease-in 3s forwards;
  -o-animation: fadeIn ease-in 3s forwards;
  -ms-animation: fadeIn ease-in 3s forwards;
  font-size: vhFn(3.733);
  overflow: hidden;
  z-index: 4;
  -webkit-transform: translate3d(0, 0, 1px);
  transform: translate3d(0, 0, 1px);
}

.cowMilkedThirdPlace {
  font-family: $secondaryFont;
  position: absolute;
  width: vhFn(24.9);
  height: vhFn(5.38);
  left: vhFn(5);
  top: vhFn(38.11);
  margin: vwFn(2) 0px;
  text-align: center;
  animation: fadeIn ease-in 7s forwards;
  -webkit-animation: fadeIn ease-in 7s forwards;
  -moz-animation: fadeIn ease-in 7s forwards;
  -o-animation: fadeIn ease-in 7s forwards;
  -ms-animation: fadeIn ease-in 7s forwards;
  font-size: vhFn(3.733);
  z-index: 4;
  -webkit-transform: translate3d(0, 0, 1px);
  transform: translate3d(0, 0, 1px);
}

.cowMilkedSecondPlace {
  font-family: $secondaryFont;
  position: absolute;
  width: vhFn(24.9);
  height: vhFn(5.38);
  left: vhFn(5);
  top: vhFn(46.11);
  margin: vwFn(2) 0px;
  text-align: center;
  animation: fadeIn ease-in 5s forwards;
  -webkit-animation: fadeIn ease-in 5s forwards;
  -moz-animation: fadeIn ease-in 5s forwards;
  -o-animation: fadeIn ease-in 5s forwards;
  -ms-animation: fadeIn ease-in 5s forwards;
  font-size: vhFn(3.733);
  z-index: 4;
  -webkit-transform: translate3d(0, 0, 1px);
  transform: translate3d(0, 0, 1px);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.bottle {
  position: absolute;
  background-image: url(../../../resources/assets/themes/lely/16x9/stories/milk/mijnMelkBottleOnboarding.svg);
  background-repeat: none;
  background-size: 100% 100%;
  width: vwFn(16.13);
  height: vhFn(71.73);
  top: vhFn(13.28);
  left: vwFn(41);
  //perspective: 1px;
  -webkit-transform: translate3d(0, 0, 1px);
  transform: translate3d(0, 0, 1px);
  z-index: 10;
}

.shape {
  position: absolute;
  overflow: hidden;
  left: vwFn(2.13);
  width: vwFn(12);
  height: vhFn(28.2);
  border-bottom-left-radius: vhFn(2.8);
  border-bottom-right-radius: vhFn(2.8);
  //clip-path: polygon(10% 0, 94% 0, 100% 18%, 100% 100%, 0 100%, 0 11%);
  top: vhFn(38.5);
  margin: 0 0;
  // -webkit-transform: translate3d(0, 0, 1px);
  // transform: translate3d(0, 0, 1px);
}

.shape::before {
  content: "";
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: white;
}

.shape::after {
  content: "";
  position: absolute;
  height: 200%;
  width: 200%;
  bottom: -65%;
  left: -20%;
  background-color: #efedea;
  border-radius: 100%;
  animation: spin 13s ease-out infinite;
  z-index: 4;
}

@keyframes spin {
  0% {
    transform: translateY(0) rotate(0deg);
  }

  60% {
    transform: translateY(-60%) rotate(100deg);
  }

  80% {
    transform: translateY(-85%) rotate(110deg);
  }

  90% {
    transform: translateY(-90%) rotate(110deg);
  }

  100% {
    transform: translateY(-100%) rotate(100deg);
  }
}
