@use "../../common/styles/variables.scss" as *;

.discoveryStoryStartingSlide {
  position: absolute;
  background-size: 100% 100%;
  width: vwFn(100.5);
  height: vhFn(100);
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowdaily/background-layers/resting/resting2.svg);
}

.discoveryStory--bottomColorOverlay {
  position: absolute;
  width: 100%;
  height: vhFn(20);
  bottom: 0;
  background-color: #cfcbc3;
  z-index: -1;
}

.discoveryStoryStartingSlide--textCaption {
  position: absolute;
  min-width: vwFn(34.605);
  min-height: vhFn(24.26);
  top: vhFn(28.9733);
  left: vwFn(20.096);
  padding: vhFn(3.2) vwFn(1.48);
}

.discoveryStoryStartingSlide--line {
  position: absolute;
  background-color: #766e68;
  width: vwFn(20.096);
  height: vhFn(0.53);
  top: vhFn(50.29);
}

.discoveryStoryStartingSlide--robot1 {
  position: absolute;
  background-size: 100% 100%;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/discoverystory/robot.svg);
  width: vwFn(29.18);
  height: vhFn(61.525);
  left: vwFn(64);
  top: vhFn(44);
}
.discoveryStoryStartingSlide--robot0 {
  position: absolute;
  background-size: 100% 100%;
  background-image: url(../../resources/assets/themes/lely/16x9/stories/discoverySw/discoverySw.svg);
  width: vwFn(20.9359);
  height: vhFn(23.7339);
  left: vwFn(54.08);
  top: vhFn(59);
}

.discoveryStoryStartingSlide--title {
  font-family: $secondaryFont;
  font-style: $font-DefaultFontStyle;
  font-weight: $font-bold;
  font-size: vhFn(4.27);
  line-height: vhFn(4.8);
  color: $color-gray-popup;
}

.discoveryStoryStartingSlide--name {
  font-family: $secondaryFont;
  font-style: $font-DefaultFontStyle;
  font-weight: $font-bold;
  font-size: vhFn(12.8);
  line-height: vhFn(10.4);
  color: $color-gray-popup;
  margin: vhFn(1.87) 0;
}
.theme-adult .discoveryStoryStartingSlide {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/cowDailyForAdult/backgrounds/resting/background-pcd-restingAdult3.svg);
} 
.theme-adult .discoveryStoryStartingSlide--title {
  font-family: $primaryFont;
  font-weight: 300;
}
.theme-adult .discoveryStory--bottomColorOverlay {
  display: none;
} 
.theme-adult .discoveryStoryStartingSlide--robot0 {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/discoverySw/discoverySwAdult.svg); 
  width: vwFn(17.16133);
  height: vhFn(15.6906);
  left: vwFn(54.064); 
  top: vhFn(65.6);
} 
.theme-adult .discoveryStoryStartingSlide--robot1 {
  background-image: url(../../resources/assets/themes/lely/16x9/stories/discoverystory/discoveryAdult.svg); 
  width: vwFn(17.16133);
  height: vhFn(16.893); 
  top: vhFn(65.6);
  left: vwFn(54.086);
} 
.theme-adult .discoveryStoryStartingSlide--textCaption { 
  min-width: vwFn(0);
  width: vwFn(25);
}