@use "../../../common/styles/variables.scss" as *;

.cowParentPage {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  width: vwFn(100);
  height: vhFn(100);
}

.textCaption--cowParentPage {
  position: absolute;
  //width: vwFn(22.59);
  //height: vhFn(20);
  left: vwFn(20.56);
  top: vhFn(40.13);

  .cow-cowParentPage--text {
    font-size: vhFn(3.73);
    line-height: vhFn(6.21);
    width: vwFn(19.635);
    height: vhFn(12.8);
    position: fixed;
    margin-left: vwFn(1);
    margin-right: vwFn(1);
  }
}

.flow-container-Cow-slide-CowHistoryRelativesPage .cow--fixed {
  width: vwFn(13.55) !important;
  height: vhFn(30.66) !important;
  left: vwFn(67.66) !important;
  top: vhFn(65.13) !important;
  background-size: 100% 100%;
}

.flow-container-Cow-slide-CowHistoryRelativesPage .parent--holstein-white {
  background-image: url(../../../resources/assets/themes/lely/16x9/popups/cowlist/cow-list-popup-head-1.svg);
}

.flow-container-Cow-slide-CowHistoryRelativesPage .parent--holstein-brown {
  background-image: url(../../../resources/assets/themes/lely/16x9/popups/cowlist/cow-list-popup-head-2.svg);
}

.flow-container-Cow-slide-CowHistoryRelativesPage .parent--holstein-black {
  background-image: url(../../../resources/assets/themes/lely/16x9/popups/cowlist/cow-list-popup-head-3.svg);
}

.flow-container-Cow-slide-CowHistoryRelativesPage .parent--holstein-rainbow {
  background-image: url(../../../resources/assets/themes/lely/16x9/popups/cowlist/cow-list-popup-head-4.svg);
}

.flow-container-Cow-slide-CowHistoryRelativesPage .parent--maasRijn-brown {
  background-image: url(../../../resources/assets/themes/lely/16x9/popups/cow/cow-head-rijn-one.svg);
}

.flow-container-Cow-slide-CowHistoryRelativesPage .parent--blaarkop-black {
  background-image: url(../../../resources/assets/themes/lely/16x9/popups/cow/cow-head-blaarkop-one.svg);
}

.flow-container-Cow-slide-CowHistoryRelativesPage .parent--blaarkop-brown {
  background-image: url(../../../resources/assets/themes/lely/16x9/popups/cow/cow-head-blaarkop-two.svg);
}

.flow-container-Cow-slide-CowHistoryRelativesPage .parent--jersey-brown {
  background-image: url(../../../resources/assets/themes/lely/16x9/popups/cow/cow-head-jersey-one.svg);
}

.flow-container-Cow-slide-CowHistoryRelativesPage .parent--jersey-white {
  background-image: url(../../../resources/assets/themes/lely/16x9/popups/cow/cow-head-jersey-two.svg);
}

.flow-container-Cow-slide-CowHistoryRelativesPage .parent--lakenvelder-black {
  background-image: url(../../../resources/assets/themes/lely/16x9/popups/cow/cow-head-lakenvelder-one.svg);
}

.flow-container-Cow-slide-CowHistoryRelativesPage .parent--lakenvelder-brown {
  background-image: url(../../../resources/assets/themes/lely/16x9/popups/cow/cow-head-lakenvelder-two.svg);
}
