@use "../../common/styles/variables.scss" as *;

:root {
  --bird1FlightDurationInMilliseconds: 2000ms;
  --bird2FlightDurationInMilliseconds: 2000ms;

  --bird1WingspanDurationInMilliseconds: 300ms;
  --bird2WingspanDurationInMilliseconds: 300ms;
}

@keyframes birdFlying {
  from {
    background-position: left;
  }
  to {
    background-position: right;
  }
}

.bird {
  position: absolute;
  background-repeat: no-repeat;
  width: vwFn(8);
  height: vwFn(8);
  pointer-events: none;
  opacity: 1;
  background-size: 400% 100%; // 1100% 100%;
}

.is-flying {
  background-image: url(../../resources/assets/themes/lely/16x9/ui/birds/bird_flying.svg);
  transition-timing-function: linear;
}

.bird-1.is-flying {
  animation: birdFlying var(--bird1WingspanDurationInMilliseconds) steps(3)
    infinite;
  transition: var(--bird1FlightDurationInMilliseconds);
}

.bird-2.is-flying {
  animation: birdFlying var(--bird2WingspanDurationInMilliseconds) steps(3)
    infinite;
  transition: var(--bird2FlightDurationInMilliseconds);
}
