@use "../../common/styles/variables.scss" as *;

.cowNameVoting {
  background-image: url(../../resources/assets/themes/lely/16x9/popups/cow/namePollBackground.svg);
  background-repeat: no-repeat;
  position: absolute;
  left: vhFn(7.05);
  top: vhFn(-11);
  padding-top: vhFn(0);
  padding-bottom: vhFn(4);
  padding-left: vwFn(2.78);
  padding-right: vhFn(10);
  width: vwFn(76.75);
  filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.1));
  background-size: cover;
  //background-size: 100% 100%;
  //background-position-x: vwFn(-4);
  z-index: 11;
}

.cowNameVoting.is-cow-name-chosen-already {
  height: vhFn(14.626);
  top: vhFn(-5);
  padding: vhFn(4) vhFn(5.33);
}

.cowNameVoting--party {
  position: absolute;
  background-image: url(../../resources/assets/themes/lely/16x9/popups/cow/cowNameChosenParty.svg);
  background-repeat: no-repeat;
  width: vwFn(29.52);
  height: vhFn(17.797);
  right: 0;
  left: vwFn(30);
  top: vhFn(0.5) !important;
}

.cowNameVoting--header {
  font-family: $secondaryFont;
  font-style: normal;
  font-weight: 700;
  font-size: vhFn(6.4);
  line-height: vhFn(9.33);
  color: #121212;
  margin-top: vhFn(2.86);
  margin-bottom: vhFn(1.86);
}

.cowNameVoting--subheading {
  font-family: $primaryFont;
  font-weight: 400;
  font-size: vhFn(3.73);
  line-height: vhFn(5.6);
  width: vwFn(53);
  margin: 0;
  padding-bottom: vhFn(1.86);
}

.cowPopup--hasNameBeenSelected .cowPopup--btnClosePopup {
  //top: calc(-6.85 * var(--vh, 1vh));
}
