@use "../../common/styles/variables.scss" as *;

.milkFactory {
  position: absolute;
  background-repeat: no-repeat;
  pointer-events: fill;
  width: vhFn(25);
  height: vhFn(30);
  background: url(../../resources/assets/themes/lely/16x9/actors/milkfactory/milk-factory-new.svg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  top: vhFn(46.5);
  left: vhFn(135.25066);
  z-index: 9;
}

/*
.is-virtual-farm-tour-active .milkFactory.virtualFarm--highlighted {
  background-image: url(../../resources/assets/themes/lely/16x9/actors/milkfactory/milk-factory-new.svg) !important;
  background-size: 100% 100%;
}

.is-virtual-farm-tour-active .milkFactory.virtualFarm--highlighted svg {
  display: none;
}
*/

.milkFactory--title {
  display: none;
}

.milkFactory--bigger {
  width: vwFn(30);
  height: vhFn(65);
  background-size: cover;
}

.milkFactory--fixed {
  position: absolute;
  width: vwFn(16);
  height: vhFn(72);
  right: vwFn(55);
  top: vhFn(20);
}

/* ANIMATION LOGIC GOES BELOW vvv */
.is-virtual-farm-tour-active {
  #exEUen0FNv4190_to,
  #exEUen0FNv4192_to,
  #exEUen0FNv4201_to,
  #exEUen0FNv4210_to,
  #exEUen0FNv4220_to,
  #exEUen0FNv4230_to,
  #exEUen0FNv4240_to,
  #exEUen0FNv4250_to,
  #exEUen0FNv4280_to,
  #exEUen0FNv4290_to,
  #exEUen0FNv4290,
  #exEUen0FNv4270_to,
  #exEUen0FNv4260_to {
    animation: none;
  }
}

#exEUen0FNv4190_to {
  animation: exEUen0FNv4190_to__to 1200ms linear infinite normal forwards;
}
@keyframes exEUen0FNv4190_to__to {
  0% {
    transform: translate(32.8839px, 52.220919px);
  }
  33.333333% {
    transform: translate(32.8839px, 52.220919px);
  }
  100% {
    transform: translate(32.8839px, 52.220919px);
  }
}
#exEUen0FNv4192_to {
  animation: exEUen0FNv4192_to__to 1200ms linear infinite normal forwards;
}
@keyframes exEUen0FNv4192_to__to {
  0% {
    transform: translate(52.8839px, 73.300902px);
  }
  33.333333% {
    transform: translate(72.88px, 83.3px);
  }
  100% {
    transform: translate(72.88px, 83.3px);
  }
}
#exEUen0FNv4201_to {
  animation: exEUen0FNv4201_to__to 1200ms linear infinite normal forwards;
}
@keyframes exEUen0FNv4201_to__to {
  0% {
    transform: translate(32.88px, 63.3px);
  }
  33.333333% {
    transform: translate(52.88px, 73.3px);
  }
  100% {
    transform: translate(52.88px, 73.3px);
  }
}
#exEUen0FNv4210_to {
  animation: exEUen0FNv4210_to__to 1200ms linear infinite normal forwards;
}
@keyframes exEUen0FNv4210_to__to {
  0% {
    transform: translate(72.883904px, 83.3009px);
  }
  33.333333% {
    transform: translate(90.88px, 93.3px);
  }
  100% {
    transform: translate(90.88px, 93.3px);
  }
}
#exEUen0FNv4220_to {
  animation: exEUen0FNv4220_to__to 1200ms linear infinite normal forwards;
}
@keyframes exEUen0FNv4220_to__to {
  0% {
    transform: translate(90.883904px, 93.30085px);
  }
  33.333333% {
    transform: translate(108.88px, 106.3px);
  }
  100% {
    transform: translate(108.88px, 106.3px);
  }
}
#exEUen0FNv4230_to {
  animation: exEUen0FNv4230_to__to 1200ms linear infinite normal forwards;
}
@keyframes exEUen0FNv4230_to__to {
  0% {
    transform: translate(108.884003px, 106.30085px);
  }
  33.333333% {
    transform: translate(128.88px, 117.3px);
  }
  100% {
    transform: translate(128.88px, 117.3px);
  }
}
#exEUen0FNv4240_to {
  animation: exEUen0FNv4240_to__to 1200ms linear infinite normal forwards;
}
@keyframes exEUen0FNv4240_to__to {
  0% {
    transform: translate(128.884003px, 117.300999px);
  }
  33.333333% {
    transform: translate(150.27px, 128.47px);
  }
  100% {
    transform: translate(150.27px, 128.47px);
  }
}
#exEUen0FNv4250_to {
  animation: exEUen0FNv4250_to__to 1200ms linear infinite normal forwards;
}
@keyframes exEUen0FNv4250_to__to {
  0% {
    transform: translate(150.266006px, 128.472996px);
  }
  33.333333% {
    transform: translate(172.27px, 139.47px);
  }
  100% {
    transform: translate(172.27px, 139.47px);
  }
}
#exEUen0FNv4260_to {
  animation: exEUen0FNv4260_to__to 1200ms linear infinite normal forwards;
}
@keyframes exEUen0FNv4260_to__to {
  0% {
    transform: translate(172.266006px, 139.472996px);
  }
  33.333333% {
    transform: translate(196.27px, 144.47px);
  }
  100% {
    transform: translate(196.27px, 144.47px);
  }
}
#exEUen0FNv4270_to {
  animation: exEUen0FNv4270_to__to 1200ms linear infinite normal forwards;
}
@keyframes exEUen0FNv4270_to__to {
  0% {
    transform: translate(196.266006px, 144.472996px);
  }
  33.333333% {
    transform: translate(218.27px, 133.32px);
  }
  100% {
    transform: translate(218.27px, 133.32px);
  }
}
#exEUen0FNv4280_to {
  animation: exEUen0FNv4280_to__to 1200ms linear infinite normal forwards;
}
@keyframes exEUen0FNv4280_to__to {
  0% {
    transform: translate(218.266006px, 133.322003px);
  }
  33.333333% {
    transform: translate(238.27px, 122.45px);
  }
  100% {
    transform: translate(238.27px, 122.45px);
  }
}
#exEUen0FNv4290_to {
  animation: exEUen0FNv4290_to__to 1200ms linear infinite normal forwards;
}
@keyframes exEUen0FNv4290_to__to {
  0% {
    transform: translate(238.266006px, 122.453003px);
  }
  33.333333% {
    transform: translate(255.563px, 115.818px);
  }
  66.666667% {
    transform: translate(255.563px, 145.82px);
  }
  100% {
    transform: translate(255.563px, 145.82px);
  }
}
#exEUen0FNv4290 {
  animation: exEUen0FNv4290_c_o 1200ms linear infinite normal forwards;
}
@keyframes exEUen0FNv4290_c_o {
  0% {
    opacity: 1;
  }
  33.333333% {
    opacity: 1;
  }
  66.666667% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
