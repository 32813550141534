@use "../../common/styles/variables.scss" as *;

.mijnMelkOnboardingAdult-container {
  position: relative;
  width: vwFn(100);
  height: vhFn(100);
}

.mijnMelkOnboardingAdult--layer {
  width: 100%;
  height: 100%;
  position: absolute;
}

#applyTexture {
  fill: url(#texturePattern);
}

.mijnMelkOnboardingAdult--layer {
  pointer-events: none;
}

.mijnMelkOnboardingAdult--layer .mijnMelkOnboardingAdult--layer--slides {
  pointer-events: all;
}

//#region LAYER -- SLIDES
.mijnMelkOnboardingAdult--slide {
  position: absolute;
  width: vwFn(100);
  height: vhFn(100);
  pointer-events: none;
}

.mijnMelkOnboardingAdult--slide .graphicElements {
  position: absolute;
  background-repeat: no-repeat;
  background-size: contain;
  width: vwFn(80);
  height: vhFn(80);
  left: vwFn(15);
  top: vhFn(10);
  pointer-events: none;
}

.mijnMelkOnboardingAdult--slide1 .graphicElements {
  //background-image: url(../../resources/assets/themes/mijnmelk/16x9/onboarding/adult/slide1/slide1GraphicElements.svg);
}

.mijnMelkOnboardingAdult--slide2 .graphicElements {
  //background-image: url(../../resources/assets/themes/mijnmelk/16x9/onboarding/adult/slide2/slide2GraphicElements.svg);
  // left: vwFn(10);
  // width: vwFn(70);
}

.mijnMelkOnboardingAdult--slide3 .graphicElements {
  //background-image: url(../../resources/assets/themes/mijnmelk/16x9/onboarding/adult/slide3/slide3GraphicElements.svg);
}

.mijnMelkOnboardingAdult--slide4 .graphicElements {
  //background-image: url(../../resources/assets/themes/mijnmelk/16x9/onboarding/adult/slide4/slide4GraphicElements.svg);
}
//#endregion

//#region LAYER -- SWIPE HAND
.mijnMelkOnboardingAdult--swipeHand {
  position: absolute;
  left: vwFn(91.871);
  top: vhFn(81.333);
  width: vwFn(6.896);
  height: vhFn(16);
}
//#endregion

//#region LAYER -- WEATHER ICON
.mijnMelkOnboardingAdult--layer--weatherIcon {
  position: absolute;
  top: vhFn(7);
  left: vwFn(80);
}
//#endregion

//#region LAYER -- SLIDE HEADERS
.mijnMelkOnboardingAdult-container--header {
  transform: translateX(vwFn(12.5));
  margin-top: vhFn(1.5);
  font-family: BourtonBase !important;
  font-size: vhFn(5);
  font-weight: normal;
}
//#endregion

//#region LAYER -- GRASS
.mijnMelkOnboardingAdult--layer--grass {
  position: absolute;
  left: vwFn(-20);
  top: vhFn(50);
  transform-origin: 50% 50%;
  width: vwFn(140);
  height: vhFn(160);
  background-size: cover;
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/onboarding/adult/landSurface.svg);
  transition: 2s transform;
  transform: rotate(3deg);
}

.is-tilted-left {
  transform: rotate(16deg);
}

.is-tilted-right {
  transform: rotate(-16deg);
}
//#endregion

//#region slide fade in/fade out logic
.mijnMelkOnboardingAdult--slide {
  position: absolute;
  opacity: 0;
  transition: 2s all;
  transform: translateX(vwFn(-50)) translateY(vhFn(-50vh)) rotateX(-45deg);
  pointer-events: none;
}

.mijnMelkOnboardingAdult--slide.is-faded-in {
  animation: fade_in_mijn_melk_adult_onboarding_slide 2s;
  animation-fill-mode: forwards;
  pointer-events: all;
}

.mijnMelkOnboardingAdult--slide.is-faded-out {
  animation: fade_out_mijn_melk_adult_onboarding_slide 2s;
  animation-fill-mode: forwards;
  pointer-events: none;
}

@keyframes fade_in_mijn_melk_adult_onboarding_slide {
  0% {
    opacity: 0;
    left: vwFn(-50);
    top: vhFn(100);
    transform: rotate(-10deg);
  }

  100% {
    opacity: 1;
    left: 0;
    top: 0;
    transform: rotate(0);
  }
}

@keyframes fade_out_mijn_melk_adult_onboarding_slide {
  0% {
    opacity: 1;
    left: 0;
    top: 0;
    transform: rotate(0);
  }

  100% {
    opacity: 0;
    left: vwFn(50);
    top: vhFn(100);
    transform: rotate(10deg);
  }
}
.mijnMelkAdultOnboarding-slide1--locationPin {
  position: absolute;
  left: vwFn(21);
  top: vhFn(20.53);
  width: vwFn(8.374);
  height: vhFn(62.93);
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/onboarding/adult/slide1/leftSideIconsPinAndClock.svg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.mijnMelkAdultOnboarding-slide1--mijnMelk {
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/onboarding/adult/slide1/mijnMelkPlate.svg);
  width: vwFn(13.315);
  height: vhFn(23.08);
  top: vhFn(40.821);
  left: vwFn(41.8669);
  position: absolute;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.mijnMelkAdultOnboarding-slide1--grass {
  background-image: url(../../resources/assets/themes/mijnmelk/16x9/onboarding/adult/slide1/littleGrass.svg);
  width: vwFn(7.15147);
  height: vhFn(6.24);
  top: vhFn(70.741);
  left: vwFn(78.69458);
  position: absolute;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
//#endregion
