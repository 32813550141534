// @use "../../common/styles/variables.scss" as *;

// .farmPopup {
//    position: absolute;
//   top: vhFn(11.2);
//   left: vwFn(6.3546);
//   // /* bring your own prefixes */
//   // transform: translate(-50%, -50%);
//   width: vwFn(87.684);
//   height: vhFn(77.6);
// }

// .farmPopup--container {
//   //display: inline;
//   position: fixed;
//   width: vwFn(67.73);
//   min-height: vhFn(30.66);
//   background-color: white;
//   top: 50%;
//   transform: translate(0%, -50%);
//   border-left: vwFn(0.12) solid $color-GrayDairyLight;
//   border-right: vwFn(0.12) solid $color-GrayDairyLight;
//   border-top: vhFn(0.27) solid $color-GrayDairyLight;
//   border-bottom: vhFn(0.27) solid $color-GrayDairyLight;
//   box-sizing: border-box;
//   box-shadow: 0px 2px 5px 5px rgba(222, 219, 214, 0.4);
//   border-radius: vwFn(0.5) vwFn(0.5) vwFn(0.5) vwFn(0.5); 
//   padding: vhFn(2) vwFn(2.5);
//   padding-top: vhFn(1.5);
// }

// .farmPopup--frame {
//   position: absolute;
//   top: vhFn(0.2);
//   left: vwFn(55.3546);
//   width: vhFn(70.6);
//   height: vhFn(77.6);
//   background-repeat: no-repeat;
//   background-size: 100% 100%;
//   background-image: url(../../resources/assets/themes/lely/16x9/actors/actorFrame/actorFrame.svg); 
//   z-index: 99999;
// }
// .theme-adult .farmPopup--frame {
//   background-image: url(../../resources/assets/themes/lely/16x9/actors/actorFrame/actorFrameAdult.svg);
// }

// .farmPopup--title {
//  position: absolute;
//   top: vhFn(3.2);
//   font-family: $secondaryFont;
//   font-weight: bold;
//   color: $color-LelyBlack;
//   font-size: vhFn(6.4);
//   letter-spacing: vwFn(0.3);
//   max-width: vwFn(43.22);
// }

// .farmPopup--description {
//   width: vwFn(50.1);
//   font-size: vhFn(3.73);
//   line-height: vhFn(6.4);
//   //font-style: italic;
//   margin-top: vhFn(1); 
//   margin-bottom: 0 !important;
//   overflow-wrap: break-word;
// }

// .farmPopup--btnClosePopup {
//    display: inline;
//   background-image: url(../../resources/assets/themes/lely/16x9/ui/popup/close-popup-button.svg);
//   background-repeat: no-repeat;
//   background-size: 100% 100%;
//   width: vwFn(8.7);
//   height: vhFn(11.5);
//   z-index: 10;
//   margin-top: vhFn(-2); 
//   margin-left: vwFn(40);
// }

// .farmPopup--farmImageContainer { 
//   // position: absolute; 
//   // left: vwFn(60.96);
//   // top: vhFn(11.2);
//   width: vhFn(70.6);
//   height: vhFn(77.6);
//   margin-left: vwFn(55);
//   -webkit-clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
//   clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
// }

// .farmPopup--farmImage {
//   //position: absolute;
//   width: vhFn(65);
//   height: vhFn(70.5);
//   background-repeat: no-repeat;
//   background-size: 100% 100%;
//   -webkit-clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
//   clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
//   object-fit:cover;
//   z-index: 10;
//   margin-left: vhFn(3.5);
//   margin-top: vhFn(2);
// }
// .theme-adult {
//   .farmPopup--title {
//     font-size: vhFn(6.4); 
//     margin-left: vwFn(7.25);
//   } 
//   .farmPopup--description { 
//     margin-left: vwFn(1.25) !important; 
//   }
//   .farmPopup--btnClosePopup {
//     background-image: url(../../resources/assets/themes/lely/16x9/ui/popup/close-popup-button-adult-theme.svg);
//     width: vwFn(7);
//     height: vhFn(13.9); 
//     margin-left: 0 !important;
//     //margin-top: vhFn(0); 
//     padding-left: 0 !important;
// } 
// .farmPopup--container {
//   padding: vhFn(2) vwFn(1.25);
//   box-shadow: none;
// }
// }  
// // @media only screen and (orientation: portrait) { 
// //   .farmPopup, .farmerPopup {
// //     transform: translate(-20%, -50%) !important;
// //   }
// // } 
.farmPopup--farmDefaultImage {
    background-image: url(../../resources/assets/themes/lely/16x9/popups/default/defaultHouse.svg);
}